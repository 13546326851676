import React from "react";

export const IQala = () => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        border: "1px solid gray",
      }}
      title="Monitoring state"
      src="https://app.powerbi.com/view?r=eyJrIjoiMTVkMTU4M2UtZWM1Yy00ZGQ1LTg2NTItNTIwMWZhNTRmNTc0IiwidCI6ImZkYTc1ZGJiLTc5MjUtNGM5Ny1hM2NhLTNiZDJmZDU0OWU5MCIsImMiOjl9"
    ></iframe>
  );
};
