import { BaseAddress } from "../config";
import performRequest from "./requestHelpers";

export default function request(url, option) {
  if (!url.startsWith("/")) {
    url = "/" + url;
  }

  if (localStorage.getItem("token") !== null) {
    let token = localStorage.getItem("token");
    option = {
      ...option,
      headers: { Authorization: "Bearer " + token },
    };
  }

  return performRequest(BaseAddress.Api + url, option);
}
