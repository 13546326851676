export const menGroups = [
  { percentage: 0.28, multiplier: 0.95 },
  { percentage: 0.18, multiplier: 0.14 },
  { percentage: 0.14, multiplier: 0.37 },
  { percentage: 0.13, multiplier: 1.9 },
  { percentage: 0.09, multiplier: 1.42 },
  { percentage: 0.08, multiplier: 2.84 },
  { percentage: 0.04, multiplier: 5.42 },
  { percentage: 0.01, multiplier: 10.63 },
  { percentage: 0.01, multiplier: 26.64 },
];

export const womenGroups = [
  { percentage: 0.26, multiplier: 1.04 },
  { percentage: 0.17, multiplier: 0.07 },
  { percentage: 0.13, multiplier: 0.13 },
  { percentage: 0.13, multiplier: 0.4 },
  { percentage: 0.1, multiplier: 0.72 },
  { percentage: 0.09, multiplier: 1.32 },
  { percentage: 0.06, multiplier: 2.22 },
  { percentage: 0.02, multiplier: 5.22 },
  { percentage: 0.01, multiplier: 29.3 },
];
