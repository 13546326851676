import requestAdmin from "../utils/requestAdmin";
import requestAuth from "../utils/requestAuth";
import axios from "axios";

export function login({ login, password }) {
  return requestAuth("/login", {
    method: "POST",
    body: { login, password },
  });
}

export function ccadminLogin({ username, password }) {
  const params = new URLSearchParams();
  params.append("login", username);
  params.append("password", password);
  return requestAdmin("/login", {
    method: "POST",
    body: params,
  });
}

export function logout() {
  localStorage.clear();
  return requestAuth("/logout");
}

const API_URL = "http://10.202.65.2:8080/api/kulsary";

const instance = (authorization = true, isBasic = false, url = null) => {
  const axiosInstance = axios.create({
    baseURL: url || API_URL,
  });

  if (authorization) {
    axiosInstance.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem(
          "token"
        )}`;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  return axiosInstance;
};

export default instance;
