import { combineReducers } from "redux";
import { createAction, handleActions } from "redux-actions";
import { LOGIN, LOGOUT } from "./auth";
import { getTargetPath } from "../utils/login";

export const REDIRECT = "scvko/redirect/REDIRECT";
export const redirect = createAction(REDIRECT);

const redirectTo = handleActions(
  {
    [REDIRECT]: { next: (state) => null },
    [LOGIN]: { next: (state, { payload }) => getTargetPath("redirect") || "/" },
    [LOGOUT]: { next: (state, { payload }) => "/login" },
  },
  null
);

export default combineReducers({
  redirectTo,
});
