import React from "react";
import { Input, Pagination, Row } from "antd";
import styled from "styled-components";

class DtpTable extends React.Component {
  state = {
    selectedObject: {},
    objects: [],
    current: 1,
  };

  onInputChange = (e) => {
    let page = Number(e.target.value);

    if (!isNaN(page) && page > 0 && page <= this.state.objects.length) {
      this.setState(
        { current: page },
        () => this.onChange(page) // Смена страницы сразу после ввода
      );
    }
  };

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.object) !== JSON.stringify(this.props.object)) {
      if (!this.props.object.length) {
        this.setState({
          objects: [this.props.object],
          selectedObject: this.props.object,
          current: 1,
        });
      } else {
        this.setState({
          objects: this.props.object,
          selectedObject: this.props.object[0],
          current: 1,
        });
      }
    }
  }

  onChange = (page) => {
    this.setState((state) => {
      return {
        current: page,
        selectedObject: state.objects.length ? state.objects[page - 1] : {},
      };
    });
  };

  formatValue = (value) => {
    const { selectedObject } = this.state;
    return typeof value === "function"
      ? value(selectedObject)
      : selectedObject[value] !== null
      ? selectedObject[value]
      : "Нет данных";
  };

  render() {
    const { objects } = this.state;

    return (
      <Container className="table_wrap withScroll" {...this.props}>
        <Row>
          <Title>{this.props.title}</Title>
        </Row>
        <Row type="flex" align="middle">
          <div className="dark_table dark-pagination-appeals">
            <Pagination
              size="small"
              pageSize={1}
              showSizeChanger={false}
              current={this.state.current}
              onChange={this.onChange}
              total={objects.length}
            />
          </div>
          <Input
            style={{
              width: 70,
              backgroundColor: "#2f2f39",
              color: "white",
              marginBottom: 10,
              marginTop: 10,
            }}
            placeholder="Стр."
            maxLength={4}
            // value={this.state.current}
            onChange={this.onInputChange}
          />
        </Row>
        {this.props.tableRows.map((row) => (
          <div className="t_row dtpTable" key={row.name}>
            <div className="column">
              <p>{row.name}:</p>
            </div>
            <div className="column">
              <p>{this.formatValue(row.key)}</p>
            </div>
          </div>
        ))}
      </Container>
    );
  }
  static defaultProps = {
    title: "Данные:",
  };
}
export default DtpTable;

export let Container = styled.div`
  padding: 10px;
  min-height: 400px;
`;

export let Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`;
