import React from "react";
import { Menu, Layout } from "antd";
import { menuList } from "data/menuItems";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import requestAdmin from "../../utils/requestAdmin";
let { Sider } = Layout;
const { SubMenu } = Menu;

export default function SideMenu({
  toggleSideMenu,
  showMenu,
  isMobile,
  ...rest
}) {
  const { t, i18n } = useTranslation();

  const logClickedCase = (caseName) => {
    const payload = {
      caseCode: caseName.replace("/", ""),
      event: "OPEN_CASE",
    };
    // requestAdmin("/user-activity", {
    //   method: "POST",
    //   body: payload,
    // });
  };

  const handler = (children) =>
    children.map((subOption) => {
      if (!subOption.children) {
        return (
          <Menu.Item key={subOption.name}>
            {subOption.url === "/route-schedule" ? (
              <a
                href={subOption.url}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span className="no-child">{t(subOption.name)}</span>
              </a>
            ) : (
              <Link
                to={subOption.url ? subOption.url : window.location.pathname}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={() => logClickedCase(subOption.url)}
              >
                <span className="no-child">{t(subOption.name)}</span>
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <SubMenu
          key={subOption.name}
          title={
            <>
              {subOption.img ? (
                <img
                  src={"img/menu/" + subOption.img}
                  style={{ marginRight: 10 }}
                />
              ) : (
                ""
              )}
              <span className="no-child">{t(subOption.name)}</span>
            </>
          }
        >
          {handler(subOption.children)}
        </SubMenu>
      );
    });
  let siderWidth = showMenu ? (isMobile ? "100vw" : 400) : 0;
  return (
    <Sider className="main-menu" width={siderWidth}>
      <Container>
        <div
          className="side-menu-toggle"
          onClick={() => toggleSideMenu(!showMenu)}
          style={{
            width: "100px",
            background: "rgba(255, 255, 255, 0.1)",
          }}
        >
          <img src="/img/menu-close.svg" />
          <span>{t("sideMenu.menu")}</span>
        </div>
        <Menu mode="inline" theme="dark">
          {handler(menuList)}
        </Menu>
      </Container>
    </Sider>
  );
}

let Container = styled.div`
  padding: 20px;
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: transparent;
    box-shadow: none;
    margin-left: 7px;
  }
`;
