import React from "react";
import { Radio } from "antd";

import styles from "./RadioGroup.module.scss";

export function RadioGroup({ list = [], ...componentProps }) {
  return (
    <div className={styles.radio_group_wrapper}>
      <Radio.Group className={styles.radio_group} {...componentProps}>
        {list.map((item, index) => (
          <Radio.Button
            key={index}
            className={styles.radio_group_button}
            value={item.key}
          >
            {item.value}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
}
