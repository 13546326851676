import Axios from "axios";
import { BaseAddress } from "../../config";

export const REGIONS = [
  {
    label: "Аккольский",
    label_en: "Akkol",
    value: 1,
  },
  // { value: 2, label:"Аршалынский", label_en:"Arshaly district" },
  // { value: 3, label:"Астраханский", label_en:"Astrakhan district" },
  { value: 4, label: "Атбасарский", label_en: "Atbasar district" },
  { value: 5, label: "Буландинский", label_en: "Bulandy district" },
  { value: 6, label: "Бурабайский", label_en: "Burabay district" },
  { value: 7, label: "Егиндыкольский", label_en: "Egindykol district" },
  { value: 8, label: "Ерейментауский", label_en: "Ereimentau district" },
  { value: 9, label: "Есильский", label_en: "Esil district" },
  { value: 10, label: "Жаксынский", label_en: "Zhaksy district" },
  { value: 11, label: "Жаркаинский", label_en: "Zharkaini district" },
  { value: 12, label: "Зерендинский", label_en: "Zerendinsky district" },
  { value: 13, label: "Кокшетау Г.А.", label_en: "Kokshetau G.A." },
  { value: 14, label: "Коргалжынский", label_en: "Korgalzhyn district" },
  { value: 15, label: "Биржан сал", label_en: "Birzhan Sal district" },
  { value: 16, label: "Сандыктауский", label_en: "Sandyktau district" },
  { value: 17, label: "Степногорск Г.А.", label_en: "Stepnogorsk G.A." },
  {
    value: 18,
    label: "Целиноградский",
    label_en: "Tselinograd district",
  },
  { value: 19, label: "Шортандинский", label_en: "Shortandy district" },
];

const integrationRequest = (endpoint, startDate, endDate) => {
  const base = BaseAddress.Api + "/ddo/";
  const promises = REGIONS.map((el) =>
    Axios.get(
      `${base}${el.value}/${endpoint}?start_date=${startDate}&end_date=${endDate}`
    )
  );
  return Promise.all(promises).then((data) => data.map((el) => el.data));
};

export const GetInQueue = (startDate, endDate) =>
  integrationRequest("inQueue", startDate, endDate);
export const GetDirections = (startDate, endDate) =>
  integrationRequest("directions", startDate, endDate);
export const GetEnrolled = (startDate, endDate) =>
  integrationRequest("enrolled", startDate, endDate);
export const GetPlaceTotal = (startDate, endDate) =>
  integrationRequest("freePlaceTotal", startDate, endDate);
export const GetEgov = (startDate, endDate) =>
  integrationRequest("fromegov", startDate, endDate);
export const GetWebPortal = (startDate, endDate) =>
  integrationRequest("fromwebportal", startDate, endDate);
