import React from "react";
import { Tooltip } from "antd";

const renderRowColorAndWordBreak = (text, record, maxWidth) => {
  return {
    props: {
      style: { color: record.color },
    },
    children: (
      <Tooltip title={text}>
        <div
          style={{
            whiteSpace: "pre",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: maxWidth,
          }}
        >
          {text}
        </div>
      </Tooltip>
    ),
  };
};

export const categoryTable = [
  {
    key: "key",
    label: "№",
    width: 50,
    sorter: function (a, b) {
      return a.key - b.key;
    },
  },
  {
    key: "category",
    label: "Исполнительный орган",
    width: 140,
    sorter: function (a, b) {
      return a.category.localeCompare(b.category);
    },
  },
  {
    key: "countCategory",
    label: "Общее количество обращений",
    width: 120,
    sorter: function (a, b) {
      return a.countCategory - b.countCategory;
    },
  },
  {
    key: "countAppeals",
    label: "Обращения",
    width: 120,
    sorter: function (a, b) {
      return a.countAppeals - b.countAppeals;
    },
  },
  {
    key: "countIncident",
    label: "Инцидент",
    width: 120,
    sorter: function (a, b) {
      return a.countIncident - b.countIncident;
    },
  },
  {
    key: "countInfo",
    label: "Запрос информации",
    width: 120,
    sorter: function (a, b) {
      return a.countInfo - b.countInfo;
    },
  },
];
export const subCategoryTable = [
  {
    key: "key",
    label: "№",
    width: 20,
    sorter: function (a, b) {
      return a.key - b.key;
    },
  },
  {
    key: "subCategory",
    label: "Подкатегория",
    width: 100,
    sorter: function (a, b) {
      return a.subCategory.localeCompare(b.subCategory);
    },
  },
  {
    key: "countCategory",
    label: "Общее количество обращений",
    width: 100,
    sorter: function (a, b) {
      return a.countCategory - b.countCategory;
    },
  },
  {
    key: "countAppeals",
    label: "Обращения",
    width: 100,
    sorter: function (a, b) {
      return a.countAppeals - b.countAppeals;
    },
  },
  {
    key: "countIncident",
    label: "Инцидент",
    width: 100,
    sorter: function (a, b) {
      return a.countIncident - b.countIncident;
    },
  },
  {
    key: "countInfo",
    label: "Запрос информации",
    width: 100,
    sorter: function (a, b) {
      return a.countInfo - b.countInfo;
    },
  },
];

export const colors = [
  { category: "Запрос информации", color: "#2BA6FF" },
  { category: "Обращение", color: "#CAE9FF" },
  { category: "Инцидент", color: "#FF7324" },
];

export const columns = [
  { title: "№", dataIndex: "key", key: "key", width: "50px" },
  {
    title: "Номер заявки",
    dataIndex: "Appeals.appealNum",
    key: "appealNum",
    width: "140px",
  },
  {
    title: "ФИО",
    dataIndex: "Appeals.fio",
    key: "fio",
    width: "250px",
  },
  {
    title: "Исполнительный орган",
    dataIndex: "Appeals.category",
    key: "category",
    width: "250px",
  },
  {
    title: "Подкатегория обращения",
    dataIndex: "Appeals.subCategory",
    key: "subCategory",
    width: "250px",
  },
  {
    title: "Исполняющий орган",
    dataIndex: "Appeals.executor",
    key: "executor",
    width: "250px",
  },
  {
    title: "Текст обращения",
    dataIndex: "Appeals.appealText",
    key: "appealText",
    width: "250px",
    textWrap: "word-break",
    render: (text, record) => renderRowColorAndWordBreak(text, record, "35ch"),
    sorter: (a, b) => {
      return a["Appeals.appealText"].localeCompare(b["Appeals.appealText"]);
    },
  },
  {
    title: "Статус обращения",
    dataIndex: "Appeals.status",
    key: "status",
    width: "250px",
  },
  {
    title: "Дата обращения",
    dataIndex: "Appeals.submissionDate",
    key: "submissionDate",
    width: "250px",
  },
  {
    title: "Время обращения",
    dataIndex: "Appeals.submissionTime",
    key: "submissionTime",
    width: "250px",
  },
  {
    title: "Тип обращения",
    dataIndex: "Appeals.appealType",
    key: "appealType",
    width: "250px",
  },
];

export const pagination = {
  defaultPageSize: 100,
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  locale: { items_per_page: "" },
};

export const fields = [
  { title: "№", dataIndex: "key" },
  { title: "Номер заявки", dataIndex: "Appeals.appealNum" },
  { title: "ФИО", dataIndex: "Appeals.fio" },
  {
    title: "Исполнительный орган",
    dataIndex: "Appeals.category",
  },
  {
    title: "Подкатегория обращения",
    dataIndex: "Appeals.subCategory",
  },
  { title: "Исполняющий орган", dataIndex: "Appeals.executor" },
  { title: "Статус обращения", dataIndex: "Appeals.status" },
  {
    title: "Дата обращения",
    dataIndex: "Appeals.submissionDate",
  },
  {
    title: "Время обращения",
    dataIndex: "Appeals.submissionTime",
  },
  { title: "Тип обращения", dataIndex: "Appeals.appealType" },
];

export const pieFields = [
  { title: "Категория", dataIndex: "category" },
  { title: "Подкатегория", dataIndex: "subCategory" },
  {
    title: "Общее количество обращений",
    dataIndex: "countCategory",
  },
  { title: "Обращения", dataIndex: "countAppeals" },
  { title: "Инцидент", dataIndex: "catecountIncidentgory" },
  { title: "Запрос информации", dataIndex: "countInfo" },
];
