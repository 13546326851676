export const fields = [
  {
    title: "№",
    dataIndex: "key",
  },
  {
    title: "Категория",
    dataIndex: "Appeals.category",
  },
  {
    title: "Общее количество обращений",
    dataIndex: "Appeals.appealsNum",
  },
  {
    title: "На исполнении",
    dataIndex: "Appeals.registredAppeals",
  },
  {
    title: "Просрочено",
    dataIndex: "Appeals.expiredForOperatorAppeals",
  },
  {
    title: "Завершено",
    dataIndex: "Appeals.finishedAppeals",
  },
  {
    title: "Исполнено с нарушением срока",
    dataIndex: "Appeals.outOfTimeForOperator",
  },
  {
    title: "Коэффициент эффективности %",
    dataIndex: "Appeals.efficiencyCoef",
  },
];

export const categoryFields = [
  {
    title: "№",
    dataIndex: "key",
  },
  {
    title: "Номер в системе",
    dataIndex: "Appeals.appealNum",
  },
  {
    title: "Источник",
    dataIndex: "Appeals.source",
  },
  {
    title: "Регион",
    dataIndex: "Appeals.region",
  },
  {
    title: "Населенный пункт",
    dataIndex: "Appeals.district",
  },
  {
    title: "Тип обращения",
    dataIndex: "Appeals.appealType",
  },
  {
    title: "Категория",
    dataIndex: "Appeals.category",
  },
  {
    title: "Подкатегория обращения",
    dataIndex: "Appeals.subCategory",
  },
  {
    title: "ФИО",
    dataIndex: "Appeals.fio",
  },
  {
    title: "Телефон",
    dataIndex: "Appeals.phoneNumber",
  },

  {
    title: "Текст обращения",
    dataIndex: "Appeals.appealText",
  },
  {
    title: "Дата подачи",
    dataIndex: "Appeals.submissionDate",
  },
  {
    title: "Время подачи",
    dataIndex: "Appeals.submissionTime",
  },
  {
    title: "Дата закрытия (регламент)",
    dataIndex: "Appeals.replyDateReglament",
  },
  {
    title: "Ответ по обращению",
    dataIndex: "Appeals.answer",
  },
  {
    title: "Дата закрытия (фактическая)",
    dataIndex: "Appeals.replyDate",
  },
  {
    title: "Статус",
    dataIndex: "Appeals.theStatus",
  },
  {
    title: "Количество дней просрочки",
    dataIndex: "Appeals.replyDays",
  },
];
