import React from "react";

import styles from "./FilterItem.module.scss";

export function FilterItem({ action = null, children }) {
  return (
    <div
      className={`${styles.filter_item} ${
        action ? styles.filter_item_action : ""
      }`}
    >
      {children}
    </div>
  );
}
