import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "./styles.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale-provider/ru_RU";
import store from "./store";
import "./fonts/ibm_plex_sans.ttf";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_amchartsdark from "@amcharts/amcharts4/themes/amchartsdark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { initDataTableThemes } from "./utils/datatables";

am4core.useTheme(am4themes_amchartsdark);
am4core.useTheme(am4themes_animated);

initDataTableThemes();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
