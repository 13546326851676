import React from "react";
import styled from "styled-components";

export default function TitleComp({ children }) {
  return <Title>{children}</Title>;
}

let Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #ffffff;
`;
