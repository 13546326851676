export const localStoreKeys = {
  RouteList: "routeList",
  RoleId: "role_ID",
  UserId: "user_ID",
  VideoMonitoringSettings: "video-monitoring-settings",
  Authority: "AKM_SIT_CENTER.authority",
};

export const localStore = {
  /**
   * Перегнать `value` в JSON и сохранить под ключом `key`.
   *
   * Возвращается JSON, сохранённый в localStorage.
   *
   * @param key {string}
   * @param value {any}
   * @returns {string}
   */
  set(key, value) {
    const json = JSON.stringify(value);
    localStorage.setItem(key, json);
    return json;
  },

  /**
   * Прочитать сохранённое значение и вернуть результат.
   * Если значения нет, либо не удалось распарсить его как JSON, возвращается
   * дефолтное значение `defaultValue`:
   *
   * Если `defaultValue`:
   *
   * * **функция**: возвращается результат её вызова;
   * * **ненулевое значение**: возвращается `defaultValue`;
   * * **нулевое значение**: возвращается `null`.
   *
   * @param key {string}
   * @param defaultValue {function|any}
   * @returns {any|null}
   */
  get(key, defaultValue = null) {
    const json = localStorage.getItem(key);
    if (json) {
      try {
        const stored = JSON.parse(json);
        if (stored) {
          return stored;
        }
      } catch (e) {
        console.error("could not parse localStorage data", key, json);
      }
    }
    if (defaultValue) {
      return typeof defaultValue === "function" ? defaultValue() : defaultValue;
    }
    return null;
  },
};
