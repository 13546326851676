import React, { Component } from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { numberWithCommas } from "../../utils/helpers";
import styles from "./index.module.scss";

export default class Indicator extends Component {
  render() {
    const {
      title,
      value,
      unit,
      direction,
      isPositive,
      period,
      children,
    } = this.props;

    const ArrowIcon = direction === "up" ? ArrowUpOutlined : ArrowDownOutlined;

    return (
      <div className={styles.card}>
        <div className={styles.cardHeading}>
          <h4 className={styles.cardTitle}>{title}</h4>
        </div>
        <div className={styles.cardBody}>
          <h2>
            <ArrowIcon
              style={{
                color: isPositive ? "#83FF49" : "#E94D4D",
                marginRight: 10,
              }}
            />
            <b style={{ color: isPositive ? "#83FF49" : "#E94D4D" }}>
              {numberWithCommas(value, " ")}
            </b>{" "}
            <small>{unit}</small>
          </h2>
          <p>{period}</p>
        </div>
        {children}
      </div>
    );
  }
}
