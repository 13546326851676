import React, { Component } from "react";
import Slider from "react-rangeslider";
import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

import "react-rangeslider/lib/index.css";

class RangeSlider extends Component {
  render() {
    const {
      title,
      tooltip,
      value,
      measure,
      min,
      max,
      step = 1,
      handleChange,
      handleDecrement,
      handleIncrement,
    } = this.props;
    return (
      <div className="fc-control">
        <div className="fc-control__title">
          {title}
          {!!tooltip && (
            <Tooltip title={tooltip}>
              <InfoCircleTwoTone
                twoToneColor="#52c41a"
                style={{ marginLeft: 10 }}
              />
            </Tooltip>
          )}
        </div>
        <div className="slider_wrap">
          <div className="fc-control__slider">
            <Slider
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={handleChange}
            />
          </div>
          <div className="fc-control__arrows">
            <div className="fc-control__arrow" onClick={handleDecrement}>
              &lt;
            </div>
            <div className="fc-control__value">
              {value}
              {measure}
            </div>
            <div className="fc-control__arrow" onClick={handleIncrement}>
              &gt;
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RangeSlider;
