import React, { Component } from "react";
import { Row, Col, DatePicker } from "antd";
import moment from "moment";
import cubejs from "../../cube";
import {
  HorizontalBarChart,
  VerticalBarChart,
  PieChart,
} from "../../components/visualizations";
import RowStat from "components/RowStat";
import { Title, Container } from "components/common/Elements";
import Localize from "../../utils/chartLocalization";

const cubejsApi = cubejs({ appId: 1 });

class DamuMed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      count1: 0,
      count2: 0,
      count3: 0,
      chart1: [],
      chart2: [],
      chart3: [],
      chart4: [],
      chart5: [],
      chart6: [],
      isDataLoaded: false,
    };
  }

  dateChange = (val, key) => {
    this.setState(
      {
        [key]: moment(val),
        pieData: [],
      },
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.getDashData();
    this.getChartData();
  };

  getDashData = () => {
    const { startDate, endDate } = this.state;
    const range = [startDate, endDate];
    let req = [
      {
        measures: ["HospitalQueueCharts.currentValue"],
        filters: [
          {
            member: "HospitalQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
      {
        measures: ["InfectiousArvi.currentValue"],
        filters: [
          {
            member: "InfectiousArvi.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
      {
        measures: ["TraumatologySickCharts.sumCurrentValues"],
        filters: [
          {
            member: "TraumatologySickCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
    ];
    if (!startDate || !endDate) {
      req.forEach((it) => {
        delete it.filters;
      });
    }
    Promise.all(req.map((e) => cubejsApi.load(e))).then((res) => {
      let data = res.map((e) => e.rawData());
      this.setState({
        count1: data[0][0]["HospitalQueueCharts.currentValue"],
        count2: data[1][0]["InfectiousArvi.currentValue"],
        count3: data[2][0]["TraumatologySickCharts.sumCurrentValues"],
      });
    });
  };

  getChartData = async () => {
    this.setState({ isDataLoaded: false });
    const { startDate, endDate } = this.state;
    const range = [startDate, endDate];
    const queries = [
      {
        measures: ["HospitalQueueCharts.currentValue"],
        filters: [
          {
            member: "HospitalQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["HospitalQueueCharts.orgHealthCareNameRu"],
      },
      {
        measures: [
          "InfectiousArvi.currentValue",
          "InfectiousArvi.currentValue1",
        ],
        filters: [
          {
            member: "InfectiousArvi.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["InfectiousArvi.orgHealthCareNameRu"],
      },
      {
        measures: [
          "TraumatologySickCharts.currentValue",
          "TraumatologySickCharts.currentValue1",
          "TraumatologySickCharts.currentValue2",
        ],
        filters: [
          {
            member: "TraumatologySickCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
      {
        measures: [
          "TraumatologySickCharts.currentValue",
          "TraumatologySickCharts.currentValue1",
          "TraumatologySickCharts.currentValue2",
        ],
        filters: [
          {
            member: "TraumatologySickCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["TraumatologySickCharts.orgHealthCareNameRu"],
      },
      {
        measures: ["PolyclinicQueueCharts.currentValue"],
        filters: [
          {
            member: "PolyclinicQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["PolyclinicQueueCharts.orgHealthCareNameRu"],
      },
      {
        measures: [
          "TraumatologyDeathesCharts.currentValue",
          "TraumatologyDeathesCharts.currentValue1",
          "TraumatologyDeathesCharts.currentValue2",
        ],
        filters: [
          {
            member: "TraumatologyDeathesCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["TraumatologyDeathesCharts.orgHealthCareNameRu"],
      },
    ];
    if (!startDate || !endDate) {
      queries.forEach((it) => {
        delete it.filters;
      });
    }
    Promise.all(queries.map((e) => cubejsApi.load(e))).then((res) => {
      let data = res.map((e) => e.rawData());
      let { t } = this.props;
      this.setState({
        chart1: data[0].map((el) => ({
          category: el["HospitalQueueCharts.orgHealthCareNameRu"],
          value: el["HospitalQueueCharts.currentValue"],
        })),
        chart2: data[1].map((el) => ({
          category: el["InfectiousArvi.orgHealthCareNameRu"],
          value: el["InfectiousArvi.currentValue"],
          value1: el["InfectiousArvi.currentValue1"],
        })),
        chart3: [
          {
            category: t("healthCare.damuMed.numberOfAccidents"),
            value: data[2][0]["TraumatologySickCharts.currentValue"],
            color: "#0D6ABF",
          },
          {
            category: t("healthCare.damuMed.numberOfSchools"),
            value: data[2][0]["TraumatologySickCharts.currentValue1"],
            color: "#41A4FF",
          },
          {
            category: t("healthCare.damuMed.numberOfIndustrialFacilities"),
            value: data[2][0]["TraumatologySickCharts.currentValue2"],
            color: "#A0D1FF",
          },
        ],
        chart4: data[3].map((el) => ({
          category: el["TraumatologySickCharts.orgHealthCareNameRu"],
          ["Количество ДТП"]: el["TraumatologySickCharts.currentValue"],
          ["Количество Школьные"]: el["TraumatologySickCharts.currentValue1"],
          ["Количество Промышленные"]:
            el["TraumatologySickCharts.currentValue2"],
        })),
        chart5: data[4].map((el) => ({
          category: el["PolyclinicQueueCharts.orgHealthCareNameRu"],
          value: el["PolyclinicQueueCharts.currentValue"],
        })),
        chart6: data[5].map((el) => ({
          category: el["TraumatologyDeathesCharts.orgHealthCareNameRu"],
          value: el["TraumatologyDeathesCharts.currentValue"],
          value1: el["TraumatologyDeathesCharts.currentValue1"],
          value2: el["TraumatologyDeathesCharts.currentValue2"],
        })),
        isDataLoaded: true,
      });
    });
  };

  render() {
    let { isMobile, t } = this.props;
    let stats = [
      { name: t("healthCare.damuMed.total"), value: this.state.count1 },
      {
        name: t("healthCare.damuMed.numberOfPeopleWithARVI"),
        value: this.state.count2,
      },
      {
        name: t("healthCare.damuMed.numberOfTraumaVisits"),
        value: this.state.count3,
      },
    ];

    const mappedChart1 = Localize(this.state.chart1);

    return (
      <div className="p-10 damumed">
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 16}>
              <Row type="flex">
                {stats.map((stat) => (
                  <RowStat
                    key={stat.name}
                    label={stat.name}
                    count={stat.value}
                  />
                ))}
              </Row>
            </Col>
            <Col span={isMobile ? 24 : 8} style={{ textAlign: "right" }}>
              <DatePicker
                style={{ width: isMobile ? "100%" : "" }}
                placeholder="От"
                defaultValue={this.state.startDate}
                onChange={(e) => this.dateChange(e, "startDate")}
              />
              <DatePicker
                style={{ width: isMobile ? "100%" : "" }}
                placeholder="До"
                defaultValue={this.state.endDate}
                onChange={(e) => this.dateChange(e, "endDate")}
              />
            </Col>
          </Row>
        </Container>
        <Row className="text-center" style={{ marginTop: 20 }}>
          <Col span={isMobile ? 24 : 12}>
            <Title>
              {t("healthCare.damuMed.numberOfVisitsToTheEmergencyRoom")}
            </Title>
            <HorizontalBarChart
              responsive
              loading={!this.state.isDataLoaded}
              data={mappedChart1}
              id="chartID1"
              values={[
                {
                  title: t("healthCare.damuMed.numberOfRequests"),
                  key: "value",
                },
              ]}
              height="400px"
            />
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Title>{t("healthCare.damuMed.numberOfCasesWithARVI")}</Title>
            <VerticalBarChart
              responsive
              loading={!this.state.isDataLoaded}
              data={this.state.chart2}
              id="chartID2"
              scrollEnd={0.5}
              rotate
              values={[
                {
                  title: t("healthCare.damuMed.numberFromYesterday"),
                  key: "value",
                  color: "#00A4D8",
                },
                {
                  title: t("healthCare.damuMed.numberFromToday"),
                  key: "value1",
                  color: "#34C792",
                },
              ]}
              height="400px"
              scrollbarX
            />
          </Col>
        </Row>
        <Row className="text-center" style={{ marginTop: 20 }}>
          <Col span={isMobile ? 24 : 12}>
            <Title>{t("healthCare.damuMed.numberOfTraumaVisits")}</Title>
            <PieChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart3}
              responsive
              id="chartID3"
              height="400px"
            />
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Title>{t("healthCare.damuMed.numberOfTraumaVisits")}</Title>
            <VerticalBarChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart4}
              responsive
              id="chartID4"
              scrollEnd={0.5}
              rotate
              values={[
                {
                  title: t("healthCare.damuMed.numberOfAccidents"),
                  key: "Количество ДТП",
                  color: "#0D6ABF",
                },
                {
                  title: t("healthCare.damuMed.numberOfSchools"),
                  key: "Количество Школьные",
                  color: "#41A4FF",
                },
                {
                  title: t("healthCare.damuMed.numberOfIndustrialFacilities"),
                  key: "Количество Промышленные",
                  color: "#A0D1FF",
                },
              ]}
              height="400px"
            />
          </Col>
        </Row>
        <Row className="text-center" style={{ marginTop: 20 }}>
          <Col span={isMobile ? 24 : 12}>
            <Title>{t("healthCare.damuMed.numberOfQueuesInClinics")}</Title>
            <VerticalBarChart
              responsive
              loading={!this.state.isDataLoaded}
              data={this.state.chart5}
              id="chartID5"
              scrollEnd={0.5}
              rotate
              values={[
                {
                  title: t("healthCare.damuMed.numberOfQueues"),
                  key: "value",
                  color: "#EB895F",
                },
              ]}
              height="400px"
            />
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Title>
              {t("healthCare.damuMed.numberOfFatalitiesFromInjuries")}
            </Title>
            <VerticalBarChart
              responsive
              loading={!this.state.isDataLoaded}
              data={this.state.chart6}
              id="chartID6"
              scrollEnd={0.5}
              rotate
              values={[
                {
                  title: t("healthCare.damuMed.numberOfInjuries"),
                  key: "value",
                  color: "#EB895F",
                },
                {
                  title: t("healthCare.damuMed.numberOfThermalInjuries"),
                  key: "value1",
                  color: "#41A4FF",
                },
                {
                  title: t("healthCare.damuMed.numberOfPoisonings"),
                  key: "value2",
                  color: "#41A4FF",
                },
              ]}
              height="400px"
              scrollbarX
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default DamuMed;
