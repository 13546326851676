import React, { Component } from "react";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const COLORS = [
  "#388E3C",
  "#FBC02D",
  "#0288d1",
  "#F44336",
  "#8E24AA",
  "#f1b40e",
  "#2a73dc",
  "#0a9574",
  "#a60c0a",
];

export default class Funnel extends Component {
  componentDidMount() {
    am4core.options.commercialLicense = true;
    const {
      id,
      data,
      nameKey,
      dataKey,
      showLegend,
      showLabels,
      legendPosition,
    } = this.props;
    let chart = am4core.create(id, am4charts.SlicedChart);

    chart.data = data;

    // number formatter
    chart.numberFormatter.numberFormat = "#.0 a";
    chart.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "тыс" },
      { number: 1e6, suffix: "млн" },
      { number: 1e9, suffix: "млрд" },
    ];

    // Create series
    var series = chart.series.push(new am4charts.FunnelSeries());
    series.dataFields.value = dataKey;
    series.dataFields.category = nameKey;
    series.labels.template.text =
      "{category}: [bold]{value}[/] ({value.percent.formatNumber('#.0')}%)";
    series.alignLabels = true;

    // legend
    if (showLegend) {
      chart.legend = new am4charts.Legend();
      // chart.legend.useDefaultMarker = true;
      chart.legend.fontSize = 11;
      chart.legend.position = legendPosition;
      chart.legend.labels.template.text = "{name}[/]";
      chart.legend.valueLabels.template.text = null; // remove percentage
      chart.legend.labels.template.adapter.add("text", function (
        labelText,
        target
      ) {
        const { category } = target.dataItem;
        return !!category && category !== "" ? labelText : "Не определено";
      });
      chart.legend.itemContainers.template.paddingTop = 1;
      chart.legend.itemContainers.template.paddingBottom = 1;

      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 11;
      markerTemplate.height = 11;
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
    }
    if (!showLabels) {
      series.labels.template.disabled = true;
    }

    if (data.length) {
      const colorSet = new am4core.ColorSet();
      const colors = data[0].color ? data.map((el) => el.color) : COLORS;
      colorSet.list = colors.map((color) => new am4core.color(color));
      series.colors = colorSet;
    }

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { id, height } = this.props;
    return <div id={id} style={{ width: "100%", height }} />;
  }
}

Funnel.defaultProps = {
  id: "funnel-chart",
  nameKey: "category",
  dataKey: "value",
  showLegend: false,
  showLabels: true,
  height: "300px",
  legendPosition: "bottom",
};

Funnel.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string,
  dataKey: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  height: PropTypes.number,
  legendPosition: PropTypes.string,
};
