import React, { Component } from "react";

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { t } = this.props;
    return (
      <div>
        <h1>{t("error.doNotHaveAccessToViewThisModule")}</h1>
      </div>
    );
  }
}

export default Error;
