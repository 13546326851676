import React from "react";
import styled from "styled-components";
import { Col } from "antd";

export let Card = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export let Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export let Container = styled.div`
  background: #2a2c36;
  border-radius: 4px;
  padding: 20px 10px;
  margin-bottom: 20px;
`;

export let RowStatLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export let OrangeBox = styled.div`
  background: #f2994a;
  border-radius: 4px;
  padding: 5px 10px;
  text-align: center;
  margin-left: 5px;
`;
