export const detailQuery = {
  dimensions: [
    "Appeals.appealNum",
    "Appeals.appealType",
    "Appeals.expired",
    "Appeals.answerType",
    "Appeals.executor",
    "Appeals.category",
    "Appeals.subCategory",
    "Appeals.submissionDate",
    "Appeals.replyDate",
    "Appeals.source",
    "Appeals.submissionTime",
  ],
};

export const fields = [
  { title: "№", dataIndex: "id" },
  { title: "Рег. номер обращения", dataIndex: "Appeals.appealNum" },
  { title: "Тип", dataIndex: "Appeals.appealType" },
  { title: "Тип подачи", dataIndex: "Appeals.source" },
  { title: "Истек", dataIndex: "Appeals.expired" },
  { title: "Способ ответа", dataIndex: "Appeals.answerType" },
  { title: "Исполнитель", dataIndex: "Appeals.executor" },
  { title: "Категория", dataIndex: "Appeals.category" },
  { title: "Подкатегория", dataIndex: "Appeals.subCategory" },
  { title: "Дата подачи", dataIndex: "Appeals.submissionDate" },
  { title: "Время подачи", dataIndex: "Appeals.submissionTime" },
  { title: "Дата закрытия обращения", dataIndex: "Appeals.replyDate" },
];

export const icons = {
  "Call-центр": "/icons/operator.png",
  "С портала": "/icons/portal.png",
  "Телеграмм бот": "/icons/telegram.png",
  Facebook: "/icons/facebook.png",
  "Соц. сети (вручную)": "/icons/instagram.png",
  "Мобильное приложение": "/icons/smartphone.png",
};

export const colors = ["#2BA6FF", "#CAE9FF", "#FF7324"];
