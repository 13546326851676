const CalculatePreSchool = (data) => {
  const totalArea = data.reduce((a, b) => a + b.square, 0);
  const totalSeats = data.reduce((a, b) => a + b.seats, 0);
  const totalContingent = data.reduce((a, b) => a + b.kontingent, 0);
  let pieStat = {
    surplus: 0,
    shortage: 0,
    normal: 0,
  };
  data.forEach((item, index) => {
    data[index].x = data[index].longitude;
    data[index].y = data[index].latitude;
    let percent = (item.kontingent / item.seats) * 100;
    if (percent < 90) {
      pieStat.surplus++;
      data[index].status = "Профицит";
      data[index].color = "#01b8aa";
    } else if (percent > 100) {
      pieStat.shortage++;
      data[index].status = "Дефицит";
      data[index].color = "#fd625e";
    } else {
      pieStat.normal++;
      data[index].status = "Норма";
      data[index].color = "#fbc02d";
    }
    if (item.languageKz && item.languageRu) {
      data[index].studyLang = "смешанный";
    } else if (item.languageKz) {
      data[index].studyLang = "казахский";
    } else {
      data[index].studyLang = "русский";
    }
  });
  let barChart = data.map((el) => {
    return {
      category: el.name,
      value: el.seats,
      value1: el.kontingent,
    };
  });
  barChart = barChart.filter((el) => el.value && el.value1);
  const pieData = [
    {
      category: "Профицит",
      value: pieStat.surplus,
    },
    {
      category: "Норма",
      value: pieStat.normal,
    },
    {
      category: "Дефицит",
      value: pieStat.shortage,
    },
  ];
  const tableHead = [
    {
      key: "name",
      label: "Название",
    },
    {
      key: "address",
      label: "Адрес",
    },
    {
      key: "district",
      label: "Район",
    },
    {
      key: "studyLang",
      label: "Язык обучения",
    },
    {
      key: "form",
      label: "Форма учреждения",
    },
    {
      key: "seats",
      label: "Проектая мощность",
    },
    {
      key: "kontingent",
      label: "Фактическая наполненность",
    },
    {
      key: "status",
      label: "Заполненность",
    },
    {
      key: "type",
      label: "Вид ДДО",
    },
    {
      key: "time",
      label: "Продолжительность нахождения",
    },
  ];

  let pieLang = [
    {
      category: "смешанный",
      value: 0,
    },
    {
      category: "казахский",
      value: 0,
    },
    {
      category: "русский",
      value: 0,
    },
  ];
  data.forEach((item) => {
    pieLang.forEach((pie) => {
      if (
        item.studyLang ===
        pie.category.charAt(0).toLowerCase() + pie.category.slice(1)
      )
        pie.value++;
    });
  });
  let typePieChart = [
    ...new Set(data.filter((e) => e.type).map((e) => e.type)),
  ];
  typePieChart = typePieChart.map((e) => {
    return {
      category: e,
      value: 0,
    };
  });
  typePieChart.forEach((el) => {
    data.forEach((item) => {
      if (el.category === item.type) el.value++;
    });
  });

  let timePieChart = [...new Set(data.map((e) => e.time))];
  timePieChart = timePieChart.map((e) => {
    return {
      category: e,
      value: 0,
    };
  });
  timePieChart.forEach((el) => {
    data.forEach((item) => {
      if (el.category === item.time) el.value++;
    });
  });

  const tableData = {
    objects: data,
    head: tableHead,
  };

  return {
    totalArea,
    totalSeats,
    totalContingent,
    data,
    pieData,
    pieLang,
    tableData,
    barChart,
    timePieChart,
    typePieChart,
  };
};
export default CalculatePreSchool;
