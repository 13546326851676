import React from "react";
import { Tag } from "antd";

import styles from "./styles.module.scss";

export default function PieTag({ onClose, title }) {
  return (
    <Tag closable color="transparent" className={styles.tag} onClose={onClose}>
      <span className={styles.tag__title}>{title}</span>
    </Tag>
  );
}
