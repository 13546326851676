const storageKey = "SCVKO_TARGET_PATH_AFTER_LOGIN";

export function isLoginUrl(url) {
  return url && url.includes("/login");
}

export function saveTargetPath(path) {
  path = path || window.location.hash.substr(1);
  if (isLoginUrl(path)) {
    return false;
  }
  sessionStorage.setItem(storageKey, path);
  return true;
}

export function getTargetPath(qwe) {
  let path = sessionStorage.getItem(storageKey);
  if (isLoginUrl(path)) {
    path = "/";
    saveTargetPath(path);
  }
  return path;
}

export const numberWithCommas = (x, delimitter = ",") => {
  return (Math.round(x * 10) / 10)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, delimitter);
};
