import performRequest from "./requestHelpers";
import { BaseAddress } from "../config";

export default function requestAuth(url, option) {
  if (!url.startsWith("/")) {
    url = "/" + url;
  }
  if (localStorage.getItem("token") !== null) {
    let token = localStorage.getItem("token");
    option = {
      ...option,
      headers: { Authorization: "Bearer " + token },
    };
  }

  return performRequest(BaseAddress.Auth + url, option);
}
