import React, { Component } from "react";

import styles from "./index.module.scss";
import * as PropTypes from "prop-types";
import CustomPropTypes from "../../utils/propTypes";

export default class Table extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
      })
    ).isRequired,
    objects: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRowClick: PropTypes.func,
    height: CustomPropTypes.Height,
    sort: PropTypes.shape({
      col: PropTypes.number.isRequired,
      dir: PropTypes.oneOf(["asc", "desc"]).isRequired,
    }),
    hideSorting: PropTypes.bool,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      sort: this.props.sort || { col: 0, dir: "asc" },
      tLabels: [],
      tKeys: [],
      headers: [],
    };

    this.keyColumn = this.keyColumn.bind(this);
    this.selectedRow = this.selectedRow.bind(this);
    this.setSorting = this.setSorting.bind(this);
  }
  componentDidMount() {
    this.setState({
      headers: this.props.headers,
      tLabels: this.props.headers.map((header) => header.label),
      tKeys: this.props.headers.map((header) => header.key),
    });
  }
  selectedRow(index) {
    this.props.onRowClick(index);
  }

  keyColumn(key, i, index, obj) {
    let content = (
      <td key={index}>
        <pre style={{ fontFamily: "IBM_plex" }}>
          {obj[key] ? obj[key] : "-"}
        </pre>
      </td>
    );

    switch (key) {
      case "text":
        content = (
          <td key={index}>
            <input
              type="checkbox"
              id={`text_checkbox${i}`}
              style={{ display: "none" }}
            ></input>
            <div id="hidden">
              <p>{obj[key] ? obj[key] : "-"}</p>
            </div>
            <label htmlFor={`text_checkbox${i}`} className="clickable">
              Подробнее/Скрыть
            </label>
          </td>
        );
        break;
      case "result":
        content = (
          <td key={index}>
            <input
              type="checkbox"
              id={`result_checkbox${i}`}
              style={{ display: "none" }}
            ></input>
            <div id="hidden">
              <p>{obj[key] ? obj[key] : "-"}</p>
            </div>
            <label htmlFor={`result_checkbox${i}`} className="clickable">
              Подробнее/Скрыть
            </label>
          </td>
        );
        break;
      case "files":
        content = (
          <td key={index}>
            {obj[key].map((el, file_key) => {
              return (
                <a
                  href={el.link}
                  key={file_key}
                  target="_blank"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "200px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {el.name}
                </a>
              );
            })}
          </td>
        );
        break;
      case "fileReq":
        content = (
          <td key={index}>
            {obj.files.foreach((el, file_key) => {
              if (el.category === "request")
                return (
                  <a
                    href={el.link}
                    key={file_key}
                    target="_blank"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      display: "block",
                      maxWidth: "200px",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {el.name}
                  </a>
                );
            })}
          </td>
        );
        break;
      case "fileRes":
        content = (
          <td key={index}>
            {obj.files.foreach((el, file_key) => {
              if (el.category === "response")
                return (
                  <a
                    href={el.link}
                    key={file_key}
                    target="_blank"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      display: "block",
                      maxWidth: "200px",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {el.name}
                  </a>
                );
            })}
          </td>
        );
        break;
      default:
        break;
    }
    return content;
  }

  setSorting(col) {
    const dir = this.state.sort.dir === "asc" ? "desc" : "asc";
    this.setState({
      sort: { col, dir },
    });
  }

  componentDidUpdate() {
    // was this.state.headers.length !== this.props.headers.length
    if (this.state.headers !== this.props.headers) {
      this.setState({
        headers: this.props.headers,
        tLabels: this.props.headers.map((header) => header.label),
        tKeys: this.props.headers.map((header) => header.key),
      });
    }
  }

  render() {
    const objects = this.props.objects;
    const { col, dir } = this.state.sort;
    const { height = "40vh" } = this.props;

    objects.sort((lhs, rhs) => {
      const key = this.state.tKeys[col];
      const left = lhs.raw || lhs[key];
      const right = lhs.raw || rhs[key];

      let order = 0;
      if (left < right) {
        order = -1;
      } else if (right < left) {
        order = 1;
      }

      return dir === "asc" ? order : -order;
    });
    return (
      <div className={styles.table}>
        <table className={this.props.className || ""}>
          <thead>
            <tr>
              {this.state.tLabels.map((header, i) =>
                this.props.hideSorting ? (
                  <th key={i}>{header}</th>
                ) : (
                  <th
                    key={i}
                    onClick={() => this.setSorting(i)}
                    className={"clickable"}
                    data-sort={
                      this.state.sort.col === i ? this.state.sort.dir : ""
                    }
                  >
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody style={{ height: height }}>
            {this.props.onRowClick
              ? objects.map((obj, i) => (
                  <tr
                    key={i}
                    className="clickable"
                    onClick={() => this.selectedRow(i)}
                  >
                    {this.state.tKeys.map((key, index) => {
                      return (
                        <td key={index}>
                          <p>{obj[key] ? obj[key] : "-"}</p>
                        </td>
                      );
                    })}
                  </tr>
                ))
              : objects.map((obj, i) => (
                  <tr key={i}>
                    {this.state.tKeys.map((key, index) => {
                      return this.keyColumn(key, i, index, obj);
                    })}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    );
  }
}
