import React from "react";
import { Table } from "antd";

function OtherDetails({ data, type }) {
  const columns = [
    {
      title: type === "Appeals.category" ? "Категория" : "Подкатегория",
      dataIndex: "title",
      width: 400,
    },
    {
      title: "Количество обращений",
      dataIndex: "value",
      align: "center",
    },
  ];

  return (
    <Table
      rowKey={(record) => record.title}
      columns={columns}
      dataSource={data || []}
      size="small"
      scroll={{ x: "max-content" }}
      pagination={true}
    />
  );
}

export default OtherDetails;
