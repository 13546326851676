import React, { Component } from "react";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

export default class LineChartWrap extends Component {
  componentDidMount() {
    const {
      id,
      data,
      category,
      categoryText,
      valueText,
      values,
      color,
      scroll,
      showLegend,
      critical,
    } = this.props;
    am4core.options.commercialLicense = true;
    let chart = am4core.create(id, am4charts.XYChart);

    chart.language.locale = am4lang_ru_RU;
    chart.data = data;

    // Legend
    if (showLegend) {
      chart.legend = new am4charts.Legend();
      // chart.legend.useDefaultMarker = true;
      chart.legend.fontSize = 11;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 11;
      markerTemplate.height = 11;
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
    }

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    if (categoryText) dateAxis.title.text = categoryText;
    // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = category;
    // categoryAxis.title.text = categoryText;
    chart.dateFormatter.dateFormat = "MM-dd";
    dateAxis.dateFormatter = new am4core.DateFormatter();
    dateAxis.dateFormatter.dateFormat = "MM-dd";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueText) valueAxis.title.text = valueText;

    // Create series
    values.forEach((value, i) => {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = value.key;
      series.dataFields.dateX = category;
      series.name = value.title;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = true;

      if (color) {
        series.stroke = am4core.color("#00bcd4");
        series.fill = am4core.color("#00bcd4");
        series.strokeWidth = 2;
        // series.tensionX = 0.77;
        series.tooltip.background.fill = am4core.color("#00bcd4");
        series.tooltip.label.fill = am4core.color("#00bcd4");
      }

      if (critical) {
        let range = valueAxis.createSeriesRange(series);
        range.value = critical;
        range.endValue = Number.MAX_VALUE;
        range.contents.stroke = am4core.color("#FF0000");
        // range.contents.fill = range.contents.stroke;
      }
    });

    if (this.props.totals) {
      this.props.totals.forEach(({ title, value, color }, index) => {
        const key = "total-" + value;
        for (const point of data) {
          point[key] = value;
        }
        const line = new am4charts.StepLineSeries();
        line.name = title;
        line.dataFields.valueY = key;
        line.dataFields.dateX = category;
        if (color) {
          line.stroke = am4core.color(color);
          line.strokeWidth = 2;
        }
        line.tooltipText = "{name}:[bold]{valueY}";
        chart.series.push(line);
      });
    }

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    if (scroll) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.start = scroll.start;
      chart.scrollbarX.end = scroll.end;
      chart.scrollbarX.parent = chart.bottomAxesContainer;
    }

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { id, height } = this.props;
    return <div id={id} style={{ width: "100%", height }} />;
  }
}

LineChartWrap.defaultProps = {
  category: "category",
  height: "480px",
  showLegend: true,
};

LineChartWrap.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  categoryText: PropTypes.string,
  valueText: PropTypes.string,
  values: PropTypes.array,
  height: PropTypes.number,
  color: PropTypes.string,
  scroll: PropTypes.object,
  showLegend: PropTypes.bool,
  critical: PropTypes.number,
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
};
