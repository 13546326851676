import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CHART_LOCALE, colorArray } from "../common/Constants";
import * as PropTypes from "prop-types";
import { formatLongNumber } from "../../utils/helpers";

export default class PieChartWrap extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    colorsArr: PropTypes.arrayOf(PropTypes.string),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showLegend: PropTypes.bool,
    showTotal: PropTypes.bool,
    totalFontSize: PropTypes.number,
    legendLabels: PropTypes.oneOf(["wrap", "truncate"]),
    totalValue: PropTypes.string,
    percentLabels: PropTypes.bool,
    panel: PropTypes.bool,
    legendPosition: PropTypes.oneOf(["bottom", "top", "right", "left"]),
    solid: PropTypes.bool,
    hideLabels: PropTypes.bool,
    handleClick: PropTypes.func,
    disabledLegend: PropTypes.bool,
    showSmall: PropTypes.bool,
    oneActiveSlice: PropTypes.bool,
    tooltip: PropTypes.string,
    labelRadius: PropTypes.number,
    separateLegend: PropTypes.bool,
    responsive: PropTypes.bool,
  };

  componentDidMount() {
    this.initChart();
  }

  initChart = () => {
    const { data } = this.props;
    let chart = am4core.create(this.props.id, am4charts.PieChart);
    this.chart = chart;
    chart.data = data;
    chart.language.locale = CHART_LOCALE;
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    chart.innerRadius = am4core.percent(this.props.solid ? 0 : 50);
    pieSeries.slices.template.strokeWidth = 0;
    pieSeries.slices.template.propertyFields.isActive = "pulled";
    pieSeries.labels.template.disabled = this.props.hideLabels;
    pieSeries.ticks.template.disabled = this.props.hideLabels;
    pieSeries.labels.template.text = this.props.percentLabels
      ? '{category}: {value.percent.formatNumber("#.0")}%'
      : "{category}: {value}";

    pieSeries.labels.template.fontSize = 12;
    pieSeries.labels.template.wrap = true;
    pieSeries.labels.template.maxWidth = 150;
    pieSeries.tooltip.label.maxWidth = 300;
    pieSeries.tooltip.label.wrap = true;

    if (this.props.tooltip) {
      pieSeries.slices.template.tooltipText = this.props.tooltip;
    }

    if (data && data[0]) {
      var colorSet = new am4core.ColorSet();
      if (data[0].color) {
        let colors = data.map((el) => el.color);
        colorSet.list = colors.map(function (color) {
          return new am4core.color(color);
        });
      } else {
        colorSet.list = ["#fd625e", "#fbc02d", "#01b8aa"].map(function (color) {
          return new am4core.color(color);
        });
      }
      if (this.props.colorsArr) {
        colorSet.list = this.props.colorsArr.map(function (color) {
          return new am4core.color(color);
        });
      } else {
        colorSet.list = colorArray.map(function (color) {
          return new am4core.color(color);
        });
      }
      pieSeries.colors = colorSet;
    }

    if (this.props.data && this.props.showTotal && this.props.data[0]) {
      let totalNumber = this.props.data.reduce((a, b) => a + b.value, 0);
      let total = pieSeries.createChild(am4core.Label);

      total.text = this.props.totalValue
        ? this.props.totalValue
        : formatLongNumber(totalNumber);
      total.fontSize = this.props.totalFontSize || 12;
      total.fontWeight = "bold";
      total.horizontalCenter = "middle";
      total.verticalCenter = "middle";
    }

    if (this.props.responsive) {
      chart.responsive.enabled = true;
    }

    if (this.props.showLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.fontSize = 12;
      chart.legend.position = this.props.legendPosition || "bottom";
      chart.legend.labels.template.maxWidth = 110;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 8;
      markerTemplate.height = 8;

      switch (this.props.legendLabels) {
        case "wrap":
          chart.legend.labels.template.wrap = true;
          chart.legend.labels.template.truncate = false;
          break;

        case "truncate":
          chart.legend.labels.template.wrap = false;
          chart.legend.labels.template.truncate = true;
          break;

        default:
          chart.legend.labels.template.truncate = true;
      }
      if (this.props.separateLegend) {
        let legendContainer = am4core.create("legenddiv", am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(90);
        chart.legend.parent = legendContainer;
        chart.legend.scrollable = true;
      }
      if (this.props.legendPosition) {
        chart.legend.position = this.props.legendPosition;
      }
    }

    if (this.props.handleClick) {
      pieSeries.slices.template.events.on("hit", (ev) => {
        const category = ev.target.dataItem.dataContext.category;
        const object = data.find((e) => e.category === category);
        this.props.handleClick(category, object);
      });
      pieSeries.labels.template.events.on("down", (ev) => {
        const { category } = ev.target.dataItem.dataContext;
        const object = data.find((e) => e.category === category);
        this.props.handleClick(category, object);
      });
    }

    if (this.props.disabledLegend) {
      chart.legend.itemContainers.template.clickable = false;
    }

    if (this.props.oneActiveSlice) {
      pieSeries.slices.template.events.on("hit", (ev) => {
        const series = ev.target.dataItem.component;
        series.slices.each((item) => {
          if (item.isActive && item !== ev.target) {
            item.isActive = false;
          }
        });
      });
    }

    this.chart = chart;
    if (!this.props.showSmall) {
      pieSeries.ticks.template.adapter.add("hidden", hideSmall);
      pieSeries.labels.template.adapter.add("hidden", hideSmall);

      function hideSmall(hidden, target) {
        return target.dataItem.values.value.percent < 5 ? true : false;
      }
    }
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.data) !== JSON.stringify(this.props.data)) {
      setTimeout(() => {
        this.initChart();
      }, 500);
    }
  }

  render() {
    const { height = "50vh" } = this.props;
    return <div id={this.props.id} style={{ height: height }} />;
  }
}
