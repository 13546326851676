var REGION_TYPE_COUNTRY = 0;
var REGION_TYPE_AREA = 1;
var REGION_TYPE_DISTRICT = 2;
var REGION_TYPE_RURAL_DISTRICT = 3;

var MapObjectType = {
  DistrictInfo: 18,
  RuralDistrictInfo: 19,
};

export const MapCenter = {
  Vko: [82.0091891, 48.5776079],
  UstKam: [82.6148696, 49.9563982],
  Semey: [80.2439997, 50.4156372],
  Kokshe: [69.3883, 53.2775],
};

export const mapRegions = [];

mapRegions.push({
  id: 1,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Урджарский район",
  centerTitle: "село Урджар",
  centerTitleShort: "с. Урджар",
  centerGeometry: [47.0786562, 81.6347694],
  geometry: [
    [46.8938944, 80.0539703],
    [46.8329822, 80.2681658],
    [46.7622766, 80.2639152],
    [46.6587149, 80.6066288],
    [46.5464402, 80.8976676],
    [46.5410728, 81.2090066],
    [46.4820637, 81.2652967],
    [46.4832192, 81.3129421],
    [46.5083239, 81.3689951],
    [46.4575721, 81.3984757],
    [46.4316264, 81.4306936],
    [46.4752628, 81.4756473],
    [46.4749756, 81.5051347],
    [46.4344822, 81.4671552],
    [46.4293519, 81.5008021],
    [46.4282123, 81.5358803],
    [46.4102436, 81.5708855],
    [46.4515871, 81.603386],
    [46.4521569, 81.6272804],
    [46.4170833, 81.6088263],
    [46.3911399, 81.558152],
    [46.3805875, 81.5721208],
    [46.3865748, 81.6156546],
    [46.3540697, 81.5803599],
    [46.0839648, 81.9122248],
    [45.877213, 81.9903718],
    [45.7538867, 82.1529768],
    [45.6019823, 82.1220101],
    [45.5450016, 82.1340645],
    [45.5222855, 82.1708064],
    [45.5478911, 82.2718292],
    [45.5505421, 82.270252],
    [45.5605266, 82.2681991],
    [45.5684502, 82.2659419],
    [45.571614, 82.2660304],
    [45.5725098, 82.2656269],
    [45.5745191, 82.2655695],
    [45.5751107, 82.265322],
    [45.5775359, 82.2650303],
    [45.5913077, 82.2625851],
    [45.5949741, 82.261862],
    [45.6165769, 82.2579086],
    [45.6247953, 82.2667683],
    [45.6273186, 82.2697018],
    [45.6292387, 82.2728359],
    [45.6321053, 82.2757599],
    [45.6339967, 82.2764704],
    [45.6350709, 82.2772207],
    [45.6494019, 82.2826764],
    [45.6612567, 82.2845576],
    [45.6696224, 82.2845852],
    [45.6754439, 82.2840763],
    [45.6789512, 82.2826056],
    [45.6834167, 82.2824269],
    [45.6932894, 82.2841732],
    [45.7029541, 82.2860842],
    [45.7087896, 82.2881234],
    [45.7195581, 82.2990702],
    [45.7257961, 82.3029716],
    [45.7379569, 82.3103158],
    [45.7540253, 82.3134611],
    [45.7584409, 82.3149549],
    [45.7640991, 82.3185828],
    [45.7682282, 82.3245646],
    [45.7713835, 82.3283573],
    [45.7807936, 82.337604],
    [45.792437, 82.3418943],
    [45.7956763, 82.3450812],
    [45.8112918, 82.3428378],
    [45.82477, 82.3438012],
    [45.8279466, 82.344709],
    [45.8337535, 82.346212],
    [45.8389546, 82.3454056],
    [45.8476219, 82.3398967],
    [45.8501598, 82.3385174],
    [45.8554687, 82.3368917],
    [45.8609069, 82.3350351],
    [45.8662151, 82.3337422],
    [45.8743617, 82.3326921],
    [45.8869463, 82.3299547],
    [45.8914112, 82.3344301],
    [45.8945007, 82.334669],
    [45.8963731, 82.3353219],
    [45.9014758, 82.3390158],
    [45.9027049, 82.3393857],
    [45.9080862, 82.3407047],
    [45.9125007, 82.3407286],
    [45.9148385, 82.3406393],
    [45.9237477, 82.3388069],
    [45.929166, 82.3369795],
    [45.9321693, 82.335889],
    [45.9338879, 82.3351293],
    [45.935363, 82.3349645],
    [45.9391583, 82.3356564],
    [45.9641161, 82.3756967],
    [45.9658905, 82.3915856],
    [45.9690968, 82.4103939],
    [45.9673915, 82.4265272],
    [45.9771683, 82.4561069],
    [46.0598832, 82.4810762],
    [46.0950877, 82.4931164],
    [46.1546288, 82.5118959],
    [46.213997, 82.5517225],
    [46.2195571, 82.5549193],
    [46.2218752, 82.5561477],
    [46.2296732, 82.5610764],
    [46.2338837, 82.5633863],
    [46.2477464, 82.5725105],
    [46.259995, 82.5817073],
    [46.2984008, 82.608009],
    [46.3209856, 82.6218116],
    [46.3472792, 82.6374869],
    [46.3548457, 82.6417337],
    [46.377435, 82.6546413],
    [46.3841387, 82.6586955],
    [46.4086651, 82.6737806],
    [46.4229831, 82.6829499],
    [46.4264222, 82.6848588],
    [46.4360443, 82.6904085],
    [46.4408824, 82.693298],
    [46.4599688, 82.7059756],
    [46.4626505, 82.7075277],
    [46.4670925, 82.7098076],
    [46.4732769, 82.7131599],
    [46.4851535, 82.7214549],
    [46.4899857, 82.7238128],
    [46.4926546, 82.7250402],
    [46.4979652, 82.7270387],
    [46.5078076, 82.734301],
    [46.518156, 82.73952],
    [46.5252771, 82.7412486],
    [46.5356602, 82.744758],
    [46.5430989, 82.747902],
    [46.548368, 82.750017],
    [46.561794, 82.7549268],
    [46.5710855, 82.7586225],
    [46.5904849, 82.766074],
    [46.5937956, 82.7671068],
    [46.5999209, 82.7691774],
    [46.6191023, 82.7726138],
    [46.6322464, 82.7736342],
    [46.6347587, 82.7740102],
    [46.6367248, 82.7742165],
    [46.6386369, 82.7741732],
    [46.6443564, 82.7738509],
    [46.6589226, 82.7733525],
    [46.6609326, 82.7752632],
    [46.662352, 82.7764192],
    [46.6633628, 82.7771321],
    [46.6643917, 82.7777338],
    [46.6672679, 82.7792103],
    [46.6690741, 82.7802264],
    [46.6808159, 82.7861068],
    [46.6922385, 82.7876167],
    [46.7005295, 82.7933298],
    [46.7085798, 82.7985819],
    [46.7191551, 82.8058341],
    [46.7257839, 82.8101897],
    [46.7478222, 82.8252786],
    [46.7493439, 82.8264946],
    [46.7525325, 82.8292315],
    [46.7559425, 82.8319639],
    [46.7571502, 82.8330182],
    [46.7599295, 82.8347744],
    [46.7631039, 82.8370944],
    [46.7681931, 82.8398382],
    [46.7862305, 82.8520075],
    [46.7957035, 82.8602581],
    [46.79887, 82.8626021],
    [46.8039101, 82.8650074],
    [46.8049908, 82.8652801],
    [46.808406, 82.8656775],
    [46.8125755, 82.8664329],
    [46.8165569, 82.8667076],
    [46.8177364, 82.8673861],
    [46.8255311, 82.8714966],
    [46.8313869, 82.8732773],
    [46.8407126, 82.8759587],
    [46.86779, 82.8872611],
    [46.8755377, 82.8901216],
    [46.8838982, 82.894081],
    [46.8913218, 82.8988838],
    [46.8951775, 82.9017676],
    [46.9031609, 82.9056798],
    [46.9081212, 82.9079655],
    [46.9184192, 82.9134965],
    [46.9253396, 82.9174472],
    [46.9258751, 82.9177649],
    [46.9278958, 82.9193098],
    [46.9359298, 82.919181],
    [46.9376205, 82.9193469],
    [46.9390792, 82.9198166],
    [46.9400194, 82.9217181],
    [46.9402531, 82.9220002],
    [46.9403306, 82.9222408],
    [46.9407515, 82.9222446],
    [46.9409006, 82.9221517],
    [46.9412928, 82.922192],
    [46.9414336, 82.9220021],
    [46.9415148, 82.9219801],
    [46.9418099, 82.92203],
    [46.9421431, 82.9218999],
    [46.9424391, 82.9219177],
    [46.9429519, 82.9215767],
    [46.9431985, 82.9210069],
    [46.9433221, 82.9209071],
    [46.9438652, 82.9207966],
    [46.9445674, 82.9215027],
    [46.944739, 82.9218204],
    [46.9449016, 82.9220084],
    [46.9451237, 82.9220217],
    [46.9454896, 82.9215666],
    [46.9457298, 82.9215319],
    [46.9458217, 82.921396],
    [46.9459526, 82.921367],
    [46.9461735, 82.921122],
    [46.9463705, 82.9211338],
    [46.9468745, 82.9214426],
    [46.9469965, 82.9214315],
    [46.9472977, 82.9211668],
    [46.947647, 82.92124],
    [46.9478612, 82.9212008],
    [46.9483006, 82.9209533],
    [46.9485394, 82.9207467],
    [46.9487265, 82.9206776],
    [46.9492337, 82.9206779],
    [46.9496272, 82.9213141],
    [46.9499973, 82.9215816],
    [46.9502715, 82.921337],
    [46.9505706, 82.9211842],
    [46.9508369, 82.9212203],
    [46.9510657, 82.9213746],
    [46.9516158, 82.9220503],
    [46.951852, 82.9221035],
    [46.9523128, 82.9220508],
    [46.952466, 82.9217185],
    [46.9526775, 82.9215103],
    [46.9531563, 82.9215491],
    [46.954059, 82.9221154],
    [46.9543727, 82.9221744],
    [46.9546415, 82.9221178],
    [46.9547878, 82.9222014],
    [46.9550526, 82.9225303],
    [46.9551982, 82.9228045],
    [46.9557723, 82.9230363],
    [46.9559589, 82.9230067],
    [46.9561596, 82.9230975],
    [46.9562981, 82.9234597],
    [46.956372, 82.9241378],
    [46.9566822, 82.9245035],
    [46.9569255, 82.9246066],
    [46.9571761, 82.9246148],
    [46.9574849, 82.9245313],
    [46.957614, 82.9245663],
    [46.9581553, 82.9244859],
    [46.9584176, 82.9242431],
    [46.958713, 82.9244718],
    [46.9590725, 82.9250718],
    [46.9600774, 82.9255652],
    [46.9602108, 82.9257774],
    [46.9603921, 82.9257746],
    [46.9604805, 82.925603],
    [46.9604984, 82.9251277],
    [46.9603837, 82.9247466],
    [46.9604292, 82.9246132],
    [46.9611901, 82.9248086],
    [46.961525, 82.9249581],
    [46.9618045, 82.9249272],
    [46.9619893, 82.9248307],
    [46.9622885, 82.9250937],
    [46.9623715, 82.9252824],
    [46.9625348, 82.9254215],
    [46.9629138, 82.9249884],
    [46.9630033, 82.9249461],
    [46.9631544, 82.9250232],
    [46.9636148, 82.9255001],
    [46.9638749, 82.9256654],
    [46.964203, 82.9263742],
    [46.9643083, 82.9264282],
    [46.964459, 82.9263555],
    [46.9647311, 82.9260929],
    [46.9650206, 82.9262523],
    [46.9651068, 82.9264381],
    [46.9653388, 82.9265442],
    [46.9654403, 82.9266859],
    [46.9656237, 82.9266833],
    [46.9658583, 82.9268303],
    [46.9660904, 82.9272618],
    [46.9661433, 82.9278024],
    [46.9660862, 82.9282545],
    [46.9661042, 82.9284047],
    [46.9664404, 82.9289777],
    [46.9667435, 82.9291858],
    [46.9673997, 82.9291411],
    [46.9676228, 82.9292358],
    [46.9680912, 82.9296219],
    [46.9682053, 82.9297466],
    [46.9682942, 82.9300095],
    [46.9685574, 82.9303236],
    [46.9688382, 82.9309743],
    [46.9690617, 82.9310154],
    [46.969435, 82.9312799],
    [46.9698012, 82.9311728],
    [46.9700586, 82.9312006],
    [46.9706749, 82.9318614],
    [46.9710774, 82.9321779],
    [46.971344, 82.9322453],
    [46.9715955, 82.9321835],
    [46.9720131, 82.9324117],
    [46.9721497, 82.9323338],
    [46.97217, 82.9319843],
    [46.9723837, 82.9308191],
    [46.9725422, 82.9306317],
    [46.9727078, 82.9305648],
    [46.9731538, 82.9307547],
    [46.9733149, 82.9309618],
    [46.9734414, 82.931317],
    [46.9735333, 82.9314087],
    [46.973614, 82.9313767],
    [46.9736341, 82.9312579],
    [46.9737648, 82.9310873],
    [46.9741881, 82.9307406],
    [46.9748156, 82.9305301],
    [46.9750764, 82.9308843],
    [46.9754503, 82.9312001],
    [46.9758111, 82.9312834],
    [46.9759962, 82.9310453],
    [46.9761207, 82.9306498],
    [46.9763751, 82.9304444],
    [46.9765309, 82.9304291],
    [46.9767616, 82.9307353],
    [46.9771969, 82.930955],
    [46.9775618, 82.9303678],
    [46.9779971, 82.930061],
    [46.9782127, 82.9303079],
    [46.9783107, 82.9306307],
    [46.97831, 82.93097],
    [46.978194, 82.9314368],
    [46.9782422, 82.9316643],
    [46.9785666, 82.9319239],
    [46.978891, 82.931885],
    [46.9791188, 82.9317645],
    [46.9793883, 82.9319623],
    [46.9795424, 82.9326054],
    [46.9798783, 82.9328009],
    [46.9804254, 82.9324524],
    [46.9806394, 82.932533],
    [46.9808654, 82.9328943],
    [46.9810192, 82.9328758],
    [46.9811477, 82.9327851],
    [46.9813993, 82.932271],
    [46.9814656, 82.9322289],
    [46.9817629, 82.9322371],
    [46.9818851, 82.9323706],
    [46.9820151, 82.9323753],
    [46.9824406, 82.9318191],
    [46.9826792, 82.931695],
    [46.9829844, 82.9316672],
    [46.9833787, 82.9318294],
    [46.9837461, 82.9317739],
    [46.9839773, 82.9318371],
    [46.9842024, 82.9319707],
    [46.9844822, 82.9323229],
    [46.9845943, 82.9323666],
    [46.984809, 82.9318965],
    [46.9852036, 82.9317788],
    [46.985388, 82.9320884],
    [46.9856382, 82.9323743],
    [46.9859717, 82.932651],
    [46.9860928, 82.9326737],
    [46.9865807, 82.9316692],
    [46.9866412, 82.9316051],
    [46.9871075, 82.9315217],
    [46.9875204, 82.931245],
    [46.9877071, 82.9310255],
    [46.9878714, 82.9306454],
    [46.9880674, 82.9303926],
    [46.9889972, 82.9308518],
    [46.9892841, 82.9311738],
    [46.9894307, 82.9312626],
    [46.9896485, 82.9312049],
    [46.9899365, 82.931028],
    [46.9901016, 82.9308318],
    [46.9901939, 82.9300095],
    [46.9903719, 82.9296856],
    [46.9905546, 82.9296074],
    [46.9911493, 82.9298832],
    [46.9915638, 82.9302557],
    [46.9923044, 82.930645],
    [46.9924406, 82.9305762],
    [46.9927231, 82.9300412],
    [46.9930453, 82.9304545],
    [46.9933733, 82.9305881],
    [46.9939946, 82.9304839],
    [46.9941655, 82.9303772],
    [46.9942938, 82.9303695],
    [46.994579, 82.9306052],
    [46.9947982, 82.9311778],
    [46.9950965, 82.9316205],
    [46.9953916, 82.9323103],
    [46.9957307, 82.9325708],
    [46.9959063, 82.9326139],
    [46.9959581, 82.9324139],
    [46.9962012, 82.9323824],
    [46.9963598, 82.9322159],
    [46.9966841, 82.9321596],
    [46.9971172, 82.9323785],
    [46.9971922, 82.9325359],
    [46.9972187, 82.9329195],
    [46.9972617, 82.9329767],
    [46.9973763, 82.9329618],
    [46.9976228, 82.9327156],
    [46.9981384, 82.9326448],
    [46.9984884, 82.9327314],
    [46.9986943, 82.9329519],
    [46.9988776, 82.933054],
    [46.9990495, 82.9330279],
    [46.9991615, 82.9329364],
    [46.9992562, 82.9331859],
    [46.9994453, 82.9334339],
    [46.9995392, 82.9343346],
    [46.9997955, 82.9347844],
    [47.0003583, 82.9350658],
    [47.0005454, 82.9352991],
    [47.0006699, 82.9353721],
    [47.0010176, 82.9354297],
    [47.0013113, 82.9349598],
    [47.0014684, 82.9348419],
    [47.0015298, 82.9348638],
    [47.001669, 82.9350323],
    [47.0018196, 82.9356819],
    [47.0023314, 82.9360046],
    [47.0024562, 82.9362873],
    [47.0025147, 82.9363235],
    [47.0028872, 82.9361386],
    [47.0030463, 82.9359517],
    [47.0032414, 82.935898],
    [47.0035482, 82.9360184],
    [47.0037469, 82.9362605],
    [47.004008, 82.9362398],
    [47.0043409, 82.9364714],
    [47.0048052, 82.9364869],
    [47.0048675, 82.9365904],
    [47.0049288, 82.9374335],
    [47.0050572, 82.9376033],
    [47.0051691, 82.9376557],
    [47.0056914, 82.9372046],
    [47.0059867, 82.9366781],
    [47.0060869, 82.9362353],
    [47.0061448, 82.9361787],
    [47.006387, 82.9362307],
    [47.0065156, 82.9361927],
    [47.0070708, 82.9352913],
    [47.0072613, 82.9351657],
    [47.0073684, 82.9351887],
    [47.0075224, 82.9353278],
    [47.0077665, 82.9356168],
    [47.0077974, 82.9357267],
    [47.0080034, 82.9358596],
    [47.0080695, 82.9360298],
    [47.0082814, 82.9361148],
    [47.0085322, 82.9364666],
    [47.0085739, 82.9365979],
    [47.0089299, 82.9369352],
    [47.0090777, 82.9372644],
    [47.0097579, 82.9378665],
    [47.0099628, 82.9379134],
    [47.0102156, 82.9377916],
    [47.0105535, 82.937866],
    [47.0107045, 82.9378377],
    [47.0114936, 82.9370338],
    [47.0119905, 82.9369974],
    [47.0122316, 82.9372945],
    [47.0125941, 82.9376032],
    [47.0128836, 82.9378114],
    [47.013262, 82.937959],
    [47.0133699, 82.9379489],
    [47.0134524, 82.9378375],
    [47.0138168, 82.9376459],
    [47.014205, 82.9373411],
    [47.0143454, 82.9373869],
    [47.0144728, 82.9378975],
    [47.0146, 82.9380177],
    [47.0152424, 82.9381303],
    [47.01559, 82.9378777],
    [47.0160284, 82.9380606],
    [47.0160963, 82.9380671],
    [47.0161473, 82.9381063],
    [47.0161838, 82.9381456],
    [47.0162178, 82.9381991],
    [47.0163052, 82.9382384],
    [47.0163732, 82.9383276],
    [47.0164047, 82.9384097],
    [47.0165237, 82.9385239],
    [47.0165723, 82.938613],
    [47.0166232, 82.9387165],
    [47.0166815, 82.9387665],
    [47.0167714, 82.9388094],
    [47.0167908, 82.938845],
    [47.0167859, 82.9388842],
    [47.0167665, 82.938902],
    [47.0167397, 82.9389055],
    [47.0167251, 82.9389376],
    [47.0167518, 82.938984],
    [47.0167542, 82.9390731],
    [47.0167833, 82.9391088],
    [47.0167468, 82.939155],
    [47.016776, 82.9392121],
    [47.0167492, 82.9393047],
    [47.0166884, 82.9393225],
    [47.0166689, 82.9393688],
    [47.0166956, 82.9394365],
    [47.0167661, 82.9394774],
    [47.0168196, 82.9396444],
    [47.0169187, 82.9397804],
    [47.0169479, 82.9397804],
    [47.0169853, 82.9397495],
    [47.0170113, 82.9397448],
    [47.0170389, 82.9397758],
    [47.0170536, 82.9398068],
    [47.0170438, 82.9398235],
    [47.0170243, 82.9398258],
    [47.017021, 82.9398449],
    [47.0170421, 82.9399069],
    [47.0170437, 82.9399427],
    [47.0170583, 82.9399689],
    [47.0171152, 82.9400072],
    [47.017156, 82.9400593],
    [47.0172161, 82.9401094],
    [47.0172502, 82.9401739],
    [47.0172859, 82.9401906],
    [47.0173071, 82.940143],
    [47.017325, 82.9401334],
    [47.0173461, 82.9401573],
    [47.0173152, 82.9402455],
    [47.0173379, 82.9403051],
    [47.0173427, 82.9403695],
    [47.0173687, 82.9404625],
    [47.0173816, 82.9405341],
    [47.0174287, 82.9406677],
    [47.0174709, 82.9407273],
    [47.0174741, 82.9408203],
    [47.0174738, 82.9408776],
    [47.0174429, 82.9409037],
    [47.0174542, 82.9409705],
    [47.0175126, 82.941147],
    [47.0175841, 82.9411996],
    [47.0176572, 82.9412522],
    [47.0176978, 82.9412689],
    [47.0177271, 82.9412713],
    [47.0177882, 82.9412848],
    [47.0178272, 82.9413349],
    [47.0178336, 82.9413874],
    [47.0178157, 82.9414255],
    [47.0177507, 82.9414707],
    [47.0177393, 82.9414731],
    [47.0177409, 82.9415064],
    [47.0178114, 82.9416267],
    [47.0178188, 82.9417044],
    [47.0178578, 82.9417427],
    [47.0178887, 82.9417427],
    [47.0179326, 82.9417428],
    [47.0179504, 82.9417619],
    [47.0179601, 82.9417976],
    [47.017978, 82.9418644],
    [47.0180283, 82.9419384],
    [47.0180575, 82.9419838],
    [47.0180852, 82.941991],
    [47.0181323, 82.9420077],
    [47.0181567, 82.9420364],
    [47.0181989, 82.9420555],
    [47.0182184, 82.9420818],
    [47.0182338, 82.9421495],
    [47.018237, 82.9421995],
    [47.0182484, 82.9422162],
    [47.018263, 82.9422187],
    [47.0182825, 82.9422091],
    [47.0182776, 82.942264],
    [47.0182597, 82.9422926],
    [47.0182385, 82.9423211],
    [47.0182369, 82.9423426],
    [47.0182726, 82.9423713],
    [47.0183182, 82.9423189],
    [47.0183507, 82.942338],
    [47.018349, 82.9424048],
    [47.0184042, 82.9424931],
    [47.0184172, 82.9425598],
    [47.0184074, 82.9426051],
    [47.0183911, 82.942648],
    [47.0183927, 82.9426742],
    [47.0184122, 82.9426814],
    [47.0184317, 82.9426791],
    [47.0184447, 82.9426982],
    [47.0184463, 82.9427339],
    [47.0184577, 82.9427435],
    [47.0184675, 82.942722],
    [47.0184886, 82.9427364],
    [47.0184886, 82.9427721],
    [47.018508, 82.9427841],
    [47.0185227, 82.9427579],
    [47.0185373, 82.9427651],
    [47.0185503, 82.9427937],
    [47.0185909, 82.9428558],
    [47.0185941, 82.9429082],
    [47.0186607, 82.9429918],
    [47.0186737, 82.9430204],
    [47.0186704, 82.9430514],
    [47.0186427, 82.9430919],
    [47.0186151, 82.9431681],
    [47.0185988, 82.9432325],
    [47.0185858, 82.9432253],
    [47.0185842, 82.9431872],
    [47.0185696, 82.9431561],
    [47.0185403, 82.9431489],
    [47.0185321, 82.9432181],
    [47.0185484, 82.9432562],
    [47.0185532, 82.9432944],
    [47.0185337, 82.9433134],
    [47.0185077, 82.9432967],
    [47.0184947, 82.943311],
    [47.018506, 82.9433682],
    [47.0185287, 82.9434112],
    [47.0185499, 82.9434208],
    [47.0185694, 82.943416],
    [47.0185873, 82.9433922],
    [47.0186068, 82.9433922],
    [47.0186198, 82.9434089],
    [47.0186474, 82.9433804],
    [47.0186799, 82.9433804],
    [47.0187059, 82.9433972],
    [47.0187059, 82.9434162],
    [47.0186531, 82.9434691],
    [47.0186489, 82.9435234],
    [47.0186372, 82.94358],
    [47.018642, 82.943654],
    [47.0186225, 82.9436849],
    [47.0185785, 82.9437278],
    [47.0185476, 82.9437802],
    [47.0185407, 82.9438528],
    [47.0185569, 82.9439005],
    [47.0185877, 82.9439768],
    [47.0186023, 82.9440269],
    [47.0186267, 82.9440413],
    [47.0186576, 82.9440175],
    [47.018674, 82.9440352],
    [47.0186805, 82.9440686],
    [47.0186495, 82.9441258],
    [47.0186088, 82.9441901],
    [47.0186088, 82.9442282],
    [47.018612, 82.9443307],
    [47.0186266, 82.9443928],
    [47.0186249, 82.9445215],
    [47.0186411, 82.9445645],
    [47.0186654, 82.9445979],
    [47.0186687, 82.9446241],
    [47.0186849, 82.9447267],
    [47.0186978, 82.9447577],
    [47.018758, 82.9447291],
    [47.018771, 82.9446934],
    [47.0187987, 82.9446815],
    [47.0188474, 82.9447531],
    [47.0188652, 82.9448199],
    [47.0188928, 82.94487],
    [47.0189204, 82.9449297],
    [47.0189561, 82.9449822],
    [47.0189447, 82.9450775],
    [47.0189122, 82.9451133],
    [47.0188861, 82.9451299],
    [47.0188866, 82.9451626],
    [47.0189581, 82.9452104],
    [47.0189823, 82.9454226],
    [47.0190083, 82.9454584],
    [47.0190311, 82.9454513],
    [47.0190506, 82.9454752],
    [47.0190457, 82.9455038],
    [47.0190196, 82.9455514],
    [47.0190131, 82.9455895],
    [47.0190283, 82.9456174],
    [47.019077, 82.9456556],
    [47.01909, 82.9457152],
    [47.0191143, 82.9458083],
    [47.0191029, 82.9458679],
    [47.0191191, 82.9458941],
    [47.0191419, 82.9458822],
    [47.0191532, 82.9459109],
    [47.0191581, 82.9459752],
    [47.0191808, 82.9460325],
    [47.019184, 82.9460754],
    [47.0192084, 82.9461112],
    [47.0192424, 82.9461923],
    [47.0192684, 82.9462615],
    [47.0193009, 82.946314],
    [47.019335, 82.9463308],
    [47.0193607, 82.9463428],
    [47.0193851, 82.9463214],
    [47.0194046, 82.9463381],
    [47.0194241, 82.9463239],
    [47.0194355, 82.9462953],
    [47.0194615, 82.9462929],
    [47.0194761, 82.9463263],
    [47.0194956, 82.946355],
    [47.0195216, 82.9463669],
    [47.0195444, 82.9464027],
    [47.0195525, 82.946429],
    [47.0195833, 82.9464624],
    [47.0195914, 82.9465125],
    [47.0195865, 82.9465721],
    [47.0195864, 82.9466365],
    [47.0195718, 82.9466817],
    [47.0195393, 82.9466936],
    [47.0195311, 82.9467174],
    [47.0195067, 82.9467341],
    [47.0194775, 82.9467436],
    [47.0194677, 82.9467698],
    [47.0194823, 82.9468056],
    [47.0194725, 82.946827],
    [47.0194059, 82.9468198],
    [47.0193782, 82.9468507],
    [47.0193635, 82.946927],
    [47.0193375, 82.9469794],
    [47.0193375, 82.9470152],
    [47.0193781, 82.9470486],
    [47.0194089, 82.9470773],
    [47.019417, 82.947144],
    [47.019417, 82.9471584],
    [47.0194104, 82.9472442],
    [47.0194088, 82.9473109],
    [47.0194201, 82.9473658],
    [47.0194135, 82.9474755],
    [47.01942, 82.9475089],
    [47.0194395, 82.9475256],
    [47.0194559, 82.9475217],
    [47.0194836, 82.9475432],
    [47.0194835, 82.9475623],
    [47.0194689, 82.9475813],
    [47.0194445, 82.9475884],
    [47.0194412, 82.947629],
    [47.0194265, 82.9477052],
    [47.0194428, 82.947722],
    [47.0194769, 82.9477029],
    [47.0195208, 82.9478079],
    [47.019524, 82.9478556],
    [47.0194865, 82.947908],
    [47.0194329, 82.9478722],
    [47.0194118, 82.9478435],
    [47.0193874, 82.9478673],
    [47.0193874, 82.9478983],
    [47.019389, 82.9479603],
    [47.0193873, 82.948039],
    [47.01941, 82.9480509],
    [47.0194523, 82.9479819],
    [47.0194735, 82.9479724],
    [47.0194897, 82.9480439],
    [47.0194767, 82.9480821],
    [47.0194848, 82.948125],
    [47.0195009, 82.9482061],
    [47.0195025, 82.94828],
    [47.0194879, 82.9482752],
    [47.0194733, 82.9482299],
    [47.0194603, 82.9482155],
    [47.0194392, 82.9482298],
    [47.0194147, 82.9482822],
    [47.0194228, 82.9483156],
    [47.0194651, 82.9483681],
    [47.0194537, 82.9483801],
    [47.019426, 82.9484086],
    [47.0194162, 82.948473],
    [47.0193772, 82.9485278],
    [47.0193625, 82.9485802],
    [47.0193657, 82.9486088],
    [47.0193885, 82.9486303],
    [47.0194616, 82.9486352],
    [47.0194957, 82.9486615],
    [47.0195054, 82.9487211],
    [47.0195493, 82.948745],
    [47.0195639, 82.9487736],
    [47.0195606, 82.9488046],
    [47.0195265, 82.9488356],
    [47.0195135, 82.9488666],
    [47.0194696, 82.9488784],
    [47.0194517, 82.9489094],
    [47.019476, 82.9489452],
    [47.0195053, 82.9489452],
    [47.0195231, 82.9489643],
    [47.0195231, 82.948981],
    [47.0194548, 82.9490453],
    [47.0194202, 82.9490846],
    [47.0194218, 82.9491275],
    [47.0194299, 82.9491728],
    [47.0194705, 82.9492611],
    [47.0194688, 82.9493135],
    [47.0194899, 82.9493636],
    [47.0195028, 82.9494829],
    [47.0195401, 82.9495545],
    [47.0195726, 82.9495927],
    [47.0196002, 82.9495927],
    [47.0196083, 82.9496333],
    [47.0196018, 82.9496905],
    [47.0195855, 82.9497357],
    [47.0195838, 82.9497834],
    [47.0195708, 82.9498287],
    [47.0195578, 82.9498621],
    [47.0195642, 82.9499169],
    [47.0195382, 82.950017],
    [47.0195836, 82.9500552],
    [47.019608, 82.9500434],
    [47.0196275, 82.9500601],
    [47.0196584, 82.9500792],
    [47.019686, 82.9500649],
    [47.0197137, 82.9500769],
    [47.019712, 82.950127],
    [47.0197347, 82.9502152],
    [47.0197737, 82.9502535],
    [47.0198111, 82.9502583],
    [47.0198582, 82.9501988],
    [47.0198176, 82.9501772],
    [47.0198063, 82.9501486],
    [47.0198323, 82.9501153],
    [47.0199282, 82.9501369],
    [47.0199006, 82.9500725],
    [47.0198909, 82.9500224],
    [47.0199169, 82.9500176],
    [47.019977, 82.9500917],
    [47.0200015, 82.9500919],
    [47.020021, 82.9500896],
    [47.0200453, 82.9501254],
    [47.0200502, 82.9501898],
    [47.020068, 82.9501993],
    [47.0200876, 82.9501517],
    [47.0201136, 82.9501875],
    [47.0201184, 82.9502137],
    [47.0201119, 82.9502566],
    [47.0200956, 82.9502757],
    [47.0200339, 82.9502589],
    [47.0199867, 82.9502946],
    [47.0199769, 82.9503589],
    [47.0199378, 82.9503875],
    [47.0199297, 82.9504232],
    [47.0199459, 82.9504471],
    [47.0199183, 82.9504804],
    [47.0199069, 82.9504566],
    [47.0198972, 82.9504112],
    [47.019881, 82.9503826],
    [47.0198679, 82.9504422],
    [47.0198711, 82.9505042],
    [47.0198451, 82.9505685],
    [47.019845, 82.9506043],
    [47.0198726, 82.9506711],
    [47.0198921, 82.9507284],
    [47.0198871, 82.9507951],
    [47.0198546, 82.9508642],
    [47.0198578, 82.9508857],
    [47.0198871, 82.9508881],
    [47.019931, 82.9508619],
    [47.0199586, 82.9508334],
    [47.0199765, 82.9508501],
    [47.0199732, 82.9508859],
    [47.0199293, 82.9509144],
    [47.0198984, 82.9509525],
    [47.019887, 82.9510121],
    [47.0198918, 82.9510502],
    [47.0199064, 82.9510574],
    [47.0199666, 82.9510337],
    [47.019991, 82.951048],
    [47.0200023, 82.9510767],
    [47.0200234, 82.9511077],
    [47.0200088, 82.951122],
    [47.0199682, 82.9511362],
    [47.0199616, 82.9511696],
    [47.0199437, 82.9511767],
    [47.0199405, 82.9511338],
    [47.0199145, 82.9511313],
    [47.0198868, 82.9511814],
    [47.0199047, 82.9512482],
    [47.0199648, 82.9513198],
    [47.0199891, 82.9513985],
    [47.020015, 82.9514915],
    [47.020027, 82.9515652],
    [47.0200513, 82.9515987],
    [47.0201228, 82.951625],
    [47.0201439, 82.951656],
    [47.0201748, 82.9516561],
    [47.0202106, 82.9516418],
    [47.0202382, 82.9516657],
    [47.0202983, 82.9517874],
    [47.020282, 82.951816],
    [47.0202934, 82.9518327],
    [47.0203177, 82.9518209],
    [47.0203454, 82.9518161],
    [47.0203697, 82.9518472],
    [47.0203665, 82.9518662],
    [47.0203307, 82.9518805],
    [47.0203339, 82.9518996],
    [47.0203745, 82.9519521],
    [47.020394, 82.9519879],
    [47.0204217, 82.9519784],
    [47.0204542, 82.9519427],
    [47.0204559, 82.9518855],
    [47.0204868, 82.9518307],
    [47.0204884, 82.9517901],
    [47.0205177, 82.9517687],
    [47.0205421, 82.9517807],
    [47.0205421, 82.9518069],
    [47.0205079, 82.9518546],
    [47.0205128, 82.951876],
    [47.0205485, 82.9518761],
    [47.0205827, 82.9518738],
    [47.020607, 82.9518953],
    [47.0206151, 82.951931],
    [47.020646, 82.9519645],
    [47.0206558, 82.9519311],
    [47.0206623, 82.9518596],
    [47.0206315, 82.9517642],
    [47.0206413, 82.9517332],
    [47.0206689, 82.9517404],
    [47.0206965, 82.9517619],
    [47.0207339, 82.9517906],
    [47.0207387, 82.9518526],
    [47.0207598, 82.9518884],
    [47.0208118, 82.9519218],
    [47.020833, 82.951898],
    [47.0208496, 82.9518705],
    [47.0208675, 82.9518539],
    [47.0208741, 82.9518181],
    [47.0209017, 82.9517943],
    [47.0209424, 82.9517825],
    [47.0210025, 82.951835],
    [47.0210252, 82.9518351],
    [47.0210626, 82.951859],
    [47.0210739, 82.9519234],
    [47.021095, 82.9519473],
    [47.0211048, 82.9519258],
    [47.0211064, 82.951902],
    [47.0211406, 82.9519092],
    [47.0211649, 82.951945],
    [47.0211925, 82.9519665],
    [47.0212153, 82.9519713],
    [47.0212234, 82.9520071],
    [47.0212201, 82.9520595],
    [47.0211876, 82.9521072],
    [47.0212054, 82.9521406],
    [47.021233, 82.9521478],
    [47.0212656, 82.9521407],
    [47.0212883, 82.9520954],
    [47.0213257, 82.9520859],
    [47.0213631, 82.9521098],
    [47.021394, 82.9521433],
    [47.0213939, 82.9521886],
    [47.0213646, 82.9522386],
    [47.0213614, 82.9522672],
    [47.0213727, 82.9522935],
    [47.0213971, 82.9523007],
    [47.0214198, 82.9523031],
    [47.0214345, 82.9523341],
    [47.0214572, 82.9523461],
    [47.0214946, 82.9523318],
    [47.0215011, 82.9522961],
    [47.0215239, 82.9522842],
    [47.0215239, 82.9523319],
    [47.0215059, 82.9523724],
    [47.0215352, 82.9523915],
    [47.0215498, 82.9524273],
    [47.0215921, 82.952394],
    [47.0216279, 82.9523821],
    [47.0216295, 82.9523678],
    [47.0216084, 82.9523463],
    [47.0216149, 82.9523201],
    [47.0216409, 82.9523154],
    [47.0216588, 82.9523607],
    [47.021688, 82.952356],
    [47.0217108, 82.952356],
    [47.0217386, 82.9523268],
    [47.0217646, 82.9523007],
    [47.021776, 82.9523054],
    [47.0217792, 82.9523412],
    [47.0218214, 82.9523627],
    [47.0218555, 82.95242],
    [47.0218359, 82.9525082],
    [47.0218099, 82.9525511],
    [47.0217888, 82.9525582],
    [47.0218082, 82.9526012],
    [47.0218082, 82.9526393],
    [47.0218715, 82.9527563],
    [47.0219349, 82.9528064],
    [47.0219755, 82.9528566],
    [47.0219819, 82.9529091],
    [47.0219982, 82.9529329],
    [47.0219884, 82.9529806],
    [47.0219965, 82.9530092],
    [47.0220273, 82.9530212],
    [47.0220518, 82.9529736],
    [47.0220599, 82.9529402],
    [47.022081, 82.9529426],
    [47.0220859, 82.9529855],
    [47.0220891, 82.9530141],
    [47.0221037, 82.9530404],
    [47.0220972, 82.9530905],
    [47.0221151, 82.9530953],
    [47.0221541, 82.9530786],
    [47.0221687, 82.9531144],
    [47.0221849, 82.9531335],
    [47.0222158, 82.953136],
    [47.0222499, 82.9531456],
    [47.0222889, 82.9531981],
    [47.0223165, 82.9532268],
    [47.0223311, 82.9532482],
    [47.0223523, 82.9532459],
    [47.022375, 82.9532269],
    [47.0223994, 82.953246],
    [47.0224173, 82.9532865],
    [47.022453, 82.9532444],
    [47.0224514, 82.9532134],
    [47.0224709, 82.9532134],
    [47.0224823, 82.9532635],
    [47.022505, 82.9532612],
    [47.0225196, 82.953297],
    [47.0225343, 82.9533065],
    [47.0225505, 82.9533376],
    [47.0225765, 82.9533543],
    [47.0226285, 82.9533639],
    [47.0226496, 82.9533902],
    [47.0226512, 82.9534331],
    [47.0226772, 82.9534761],
    [47.022669, 82.9535047],
    [47.0226836, 82.9535262],
    [47.0226771, 82.9535667],
    [47.0227047, 82.9536144],
    [47.0227095, 82.9536526],
    [47.0227355, 82.9536812],
    [47.022742, 82.9537051],
    [47.0227322, 82.9537408],
    [47.0227501, 82.9537552],
    [47.0227712, 82.9537385],
    [47.0227875, 82.9537791],
    [47.0228004, 82.9538936],
    [47.0228247, 82.9539294],
    [47.0228263, 82.9539771],
    [47.0228003, 82.9540009],
    [47.0227986, 82.9540581],
    [47.0228425, 82.9540749],
    [47.0228473, 82.9541535],
    [47.0228611, 82.9542463],
    [47.0229, 82.954356],
    [47.0229325, 82.9543942],
    [47.0229747, 82.9544277],
    [47.0230088, 82.9544826],
    [47.0230218, 82.9545303],
    [47.023051, 82.9545852],
    [47.0230542, 82.9546281],
    [47.0230379, 82.9546782],
    [47.0230005, 82.9547676],
    [47.0229793, 82.9548201],
    [47.0229858, 82.9548749],
    [47.0230134, 82.9549274],
    [47.0230637, 82.9549704],
    [47.0231287, 82.9549992],
    [47.0231824, 82.9549993],
    [47.0232214, 82.9550232],
    [47.0232425, 82.9550494],
    [47.0232636, 82.9550209],
    [47.0232734, 82.9549946],
    [47.0232913, 82.9550185],
    [47.0232961, 82.9550543],
    [47.0233091, 82.9550901],
    [47.023296, 82.9551258],
    [47.0233041, 82.9551545],
    [47.0233382, 82.9551974],
    [47.0233805, 82.9551784],
    [47.0234114, 82.9551976],
    [47.0234293, 82.9552286],
    [47.0234536, 82.9552382],
    [47.0234715, 82.9552215],
    [47.0234894, 82.9552454],
    [47.0234682, 82.9552859],
    [47.0234942, 82.9553145],
    [47.0235397, 82.955317],
    [47.0235739, 82.9553171],
    [47.0235917, 82.9553052],
    [47.0236096, 82.9553267],
    [47.0236014, 82.9553648],
    [47.0236193, 82.9553648],
    [47.0236388, 82.9553482],
    [47.0236568, 82.9553101],
    [47.0236847, 82.9553054],
    [47.0237107, 82.9553269],
    [47.0237221, 82.9553603],
    [47.0237123, 82.955408],
    [47.0237204, 82.9554438],
    [47.023748, 82.9555106],
    [47.0237609, 82.9555511],
    [47.0237707, 82.9555535],
    [47.0237821, 82.955544],
    [47.0237902, 82.9555798],
    [47.0237852, 82.9556489],
    [47.0237641, 82.9556727],
    [47.0237592, 82.9557014],
    [47.0237722, 82.9557157],
    [47.0237836, 82.9557038],
    [47.0237982, 82.9556728],
    [47.0238161, 82.9556752],
    [47.0238275, 82.9556872],
    [47.0238454, 82.9556777],
    [47.0238633, 82.9556538],
    [47.0238811, 82.955661],
    [47.0239039, 82.955704],
    [47.0239185, 82.9556921],
    [47.0239315, 82.9556683],
    [47.0239445, 82.955685],
    [47.023951, 82.9557565],
    [47.0239867, 82.9557971],
    [47.024011, 82.9558616],
    [47.0240419, 82.9558807],
    [47.0240662, 82.9559427],
    [47.0240564, 82.9559832],
    [47.0240613, 82.9559976],
    [47.0240775, 82.9560238],
    [47.0240954, 82.9560191],
    [47.0240954, 82.9559976],
    [47.0241117, 82.9559833],
    [47.0241003, 82.9559571],
    [47.0241199, 82.9559404],
    [47.0241345, 82.9559118],
    [47.0241508, 82.9559095],
    [47.0241605, 82.955931],
    [47.0241588, 82.9559787],
    [47.0241897, 82.9559906],
    [47.0242466, 82.9559884],
    [47.0242775, 82.9559693],
    [47.0243051, 82.9559789],
    [47.0243246, 82.9560219],
    [47.0243165, 82.9560481],
    [47.0242657, 82.9560793],
    [47.0242364, 82.9560601],
    [47.0242315, 82.956084],
    [47.0242461, 82.9560935],
    [47.0242526, 82.9561222],
    [47.0242136, 82.9561173],
    [47.0242071, 82.956134],
    [47.0242233, 82.9561603],
    [47.0241892, 82.9561936],
    [47.0241973, 82.9562389],
    [47.0242233, 82.9562485],
    [47.0242525, 82.9562772],
    [47.0242704, 82.9562796],
    [47.024285, 82.9562963],
    [47.0242996, 82.9563178],
    [47.0242979, 82.9563607],
    [47.0243191, 82.9563583],
    [47.0243435, 82.956356],
    [47.0243629, 82.9564061],
    [47.0243759, 82.9564061],
    [47.0243824, 82.9563895],
    [47.024376, 82.9563418],
    [47.0243679, 82.9563107],
    [47.0243728, 82.9562845],
    [47.024389, 82.956275],
    [47.0244232, 82.9562918],
    [47.0244361, 82.9563371],
    [47.0244654, 82.9563562],
    [47.0244865, 82.9563563],
    [47.0245011, 82.9563682],
    [47.0245499, 82.9563754],
    [47.0245596, 82.9563564],
    [47.024571, 82.9563683],
    [47.0246019, 82.9564042],
    [47.0246311, 82.9564281],
    [47.0246213, 82.9564423],
    [47.0245823, 82.9564423],
    [47.0245579, 82.9564589],
    [47.0245774, 82.9564804],
    [47.0246245, 82.9565306],
    [47.0246261, 82.956564],
    [47.0246667, 82.9566117],
    [47.0247171, 82.9566428],
    [47.0247171, 82.9566142],
    [47.0247366, 82.9566214],
    [47.0247431, 82.9566548],
    [47.0247349, 82.9566858],
    [47.0247512, 82.9567001],
    [47.0247772, 82.9566954],
    [47.0247902, 82.9566811],
    [47.0248097, 82.9566859],
    [47.0248292, 82.9567026],
    [47.0248503, 82.9567122],
    [47.0248471, 82.9567289],
    [47.0248194, 82.9567503],
    [47.0247999, 82.9567503],
    [47.0247982, 82.9567741],
    [47.0248275, 82.9567909],
    [47.0248519, 82.9567814],
    [47.0248746, 82.9567981],
    [47.0249023, 82.9568029],
    [47.0249315, 82.956834],
    [47.0249331, 82.9568149],
    [47.0249121, 82.9567362],
    [47.0249251, 82.9567243],
    [47.0249446, 82.9567314],
    [47.0249641, 82.9567553],
    [47.024981, 82.9567649],
    [47.0249972, 82.956784],
    [47.0249939, 82.9568197],
    [47.0250037, 82.9568412],
    [47.0249955, 82.9568793],
    [47.0249906, 82.956927],
    [47.0250101, 82.9569533],
    [47.0250328, 82.9569557],
    [47.0250442, 82.9569724],
    [47.0250442, 82.9569963],
    [47.0250734, 82.9570226],
    [47.0250685, 82.9570631],
    [47.0250734, 82.9570774],
    [47.0250945, 82.9570774],
    [47.0251627, 82.9571157],
    [47.025179, 82.9571515],
    [47.0251659, 82.9571825],
    [47.0251919, 82.9572016],
    [47.0252521, 82.9571874],
    [47.0252976, 82.9571493],
    [47.0253301, 82.9571589],
    [47.0253562, 82.9571471],
    [47.0253806, 82.9571137],
    [47.0253903, 82.9571209],
    [47.0253822, 82.9571566],
    [47.025361, 82.9571924],
    [47.0253707, 82.9572138],
    [47.025387, 82.9572043],
    [47.0253934, 82.9573045],
    [47.0253462, 82.9574093],
    [47.0253641, 82.9574261],
    [47.0254079, 82.957419],
    [47.0254454, 82.9573928],
    [47.0254697, 82.9574286],
    [47.0254404, 82.9574787],
    [47.0254453, 82.9574954],
    [47.0255152, 82.9574788],
    [47.0255867, 82.9574908],
    [47.0256143, 82.95751],
    [47.0256452, 82.9575196],
    [47.0256695, 82.9575578],
    [47.0257312, 82.9576866],
    [47.0257181, 82.9577963],
    [47.0257424, 82.9578727],
    [47.0257603, 82.957918],
    [47.025809, 82.9579443],
    [47.0258269, 82.95793],
    [47.0258431, 82.9579468],
    [47.0258431, 82.9579802],
    [47.0258597, 82.9580097],
    [47.0258808, 82.9580098],
    [47.0258922, 82.9579859],
    [47.0258841, 82.9579621],
    [47.0258988, 82.9579406],
    [47.0259426, 82.9579479],
    [47.0259572, 82.957998],
    [47.0259719, 82.9579837],
    [47.0259817, 82.9579503],
    [47.0259784, 82.9578955],
    [47.0260045, 82.9578765],
    [47.0260402, 82.957898],
    [47.0260646, 82.9579409],
    [47.0260759, 82.9579386],
    [47.0260922, 82.9579148],
    [47.026115, 82.9579387],
    [47.026136, 82.9579959],
    [47.0261588, 82.9580031],
    [47.0261897, 82.9579769],
    [47.0262596, 82.957958],
    [47.0262791, 82.9579342],
    [47.0262922, 82.957908],
    [47.0263571, 82.9579915],
    [47.0264027, 82.9579511],
    [47.0264953, 82.9579417],
    [47.0265132, 82.9579656],
    [47.0264709, 82.9580108],
    [47.0264644, 82.958037],
    [47.0264838, 82.9580705],
    [47.0264871, 82.9580395],
    [47.0265034, 82.95803],
    [47.0265196, 82.9580658],
    [47.0265375, 82.9580777],
    [47.026544, 82.9580515],
    [47.0265635, 82.9580182],
    [47.026583, 82.958042],
    [47.0266009, 82.9580039],
    [47.0266172, 82.9580206],
    [47.026635, 82.9580445],
    [47.0266496, 82.9580588],
    [47.026661, 82.9580732],
    [47.0266919, 82.9580589],
    [47.0267228, 82.9580637],
    [47.0267341, 82.9580924],
    [47.0267341, 82.9581568],
    [47.0267503, 82.9581568],
    [47.0267731, 82.9581139],
    [47.0267926, 82.9581259],
    [47.0267959, 82.9581473],
    [47.0267796, 82.9581831],
    [47.0267926, 82.9581831],
    [47.0268203, 82.9581474],
    [47.0268732, 82.9580858],
    [47.0268878, 82.9581001],
    [47.0268992, 82.9581121],
    [47.0268992, 82.9581455],
    [47.0269431, 82.958105],
    [47.0269821, 82.9580789],
    [47.026987, 82.9581003],
    [47.0269772, 82.9581408],
    [47.0269609, 82.958148],
    [47.0269788, 82.9581695],
    [47.026995, 82.9581838],
    [47.0270064, 82.9582124],
    [47.0270063, 82.958284],
    [47.0270014, 82.9583078],
    [47.0270144, 82.9583531],
    [47.0270258, 82.9584009],
    [47.0270176, 82.9584223],
    [47.0269997, 82.9584223],
    [47.0269964, 82.958458],
    [47.0270305, 82.9585082],
    [47.0270631, 82.9584772],
    [47.0270939, 82.9585059],
    [47.0270907, 82.9585321],
    [47.0270825, 82.9585631],
    [47.0270971, 82.9585822],
    [47.0271118, 82.9585775],
    [47.0271264, 82.9585537],
    [47.0271427, 82.9585394],
    [47.0271508, 82.9585156],
    [47.027159, 82.9584846],
    [47.0271704, 82.958487],
    [47.0271752, 82.9585347],
    [47.0271882, 82.9585562],
    [47.0271784, 82.9585895],
    [47.0271784, 82.9586205],
    [47.0271897, 82.9586349],
    [47.0271637, 82.9586634],
    [47.0271556, 82.9586849],
    [47.0271653, 82.9587064],
    [47.0271913, 82.9587016],
    [47.0272466, 82.9586397],
    [47.027284, 82.958616],
    [47.0272971, 82.958573],
    [47.0273133, 82.9585492],
    [47.0273134, 82.9585182],
    [47.0273231, 82.9584896],
    [47.027341, 82.9584873],
    [47.0273556, 82.9585088],
    [47.0273524, 82.9585302],
    [47.027354, 82.9585565],
    [47.0274044, 82.9585756],
    [47.027419, 82.9585971],
    [47.0274824, 82.9585972],
    [47.0275035, 82.9586163],
    [47.0275181, 82.9586355],
    [47.027562, 82.958657],
    [47.0275717, 82.9586999],
    [47.0275944, 82.9586952],
    [47.027609, 82.9587143],
    [47.0275594, 82.9588051],
    [47.0275432, 82.9588074],
    [47.0275318, 82.9587764],
    [47.0275172, 82.9587692],
    [47.0275026, 82.9587835],
    [47.0274961, 82.9587501],
    [47.027462, 82.9587286],
    [47.0274457, 82.9587643],
    [47.0274911, 82.9588574],
    [47.0274862, 82.9588884],
    [47.0275155, 82.9589147],
    [47.0275545, 82.9589076],
    [47.0275902, 82.9589077],
    [47.0276276, 82.9588934],
    [47.0276585, 82.9588696],
    [47.027665, 82.9588887],
    [47.0276487, 82.9589245],
    [47.0276634, 82.9589316],
    [47.0276878, 82.9589126],
    [47.0277121, 82.9589198],
    [47.0277365, 82.9589437],
    [47.0277674, 82.958939],
    [47.027782, 82.9589676],
    [47.0277739, 82.9589891],
    [47.0277511, 82.9589795],
    [47.0277105, 82.958977],
    [47.0276942, 82.9589961],
    [47.0277072, 82.9590152],
    [47.0277315, 82.9590415],
    [47.0277526, 82.9590844],
    [47.0277737, 82.9591155],
    [47.0277916, 82.9591369],
    [47.0278176, 82.9591275],
    [47.0278322, 82.9591418],
    [47.0278566, 82.959149],
    [47.0278452, 82.95918],
    [47.0278159, 82.9591895],
    [47.0278192, 82.9592157],
    [47.0278825, 82.9592492],
    [47.0279167, 82.9592588],
    [47.0279167, 82.9592373],
    [47.0279086, 82.9592159],
    [47.0279265, 82.9592016],
    [47.0279508, 82.9592255],
    [47.0279785, 82.9592279],
    [47.0279996, 82.9592494],
    [47.0280207, 82.959259],
    [47.0280353, 82.9592757],
    [47.0280467, 82.9592638],
    [47.0280483, 82.9592471],
    [47.0280419, 82.959228],
    [47.0280581, 82.9592114],
    [47.0280955, 82.9592305],
    [47.0281231, 82.9592592],
    [47.0281426, 82.9592473],
    [47.0281475, 82.9592807],
    [47.0281426, 82.9593307],
    [47.0281523, 82.9593546],
    [47.0281751, 82.9593237],
    [47.0281978, 82.9593189],
    [47.0282319, 82.9593715],
    [47.028253, 82.9594049],
    [47.0282497, 82.959443],
    [47.0282615, 82.9594723],
    [47.0283103, 82.9594987],
    [47.0283282, 82.9594677],
    [47.0283476, 82.9595297],
    [47.0283688, 82.9595393],
    [47.0283834, 82.9595274],
    [47.0284159, 82.9595442],
    [47.0284305, 82.959568],
    [47.0284598, 82.9595705],
    [47.0284988, 82.9595992],
    [47.0285394, 82.9596112],
    [47.0285979, 82.9596494],
    [47.0286174, 82.9596328],
    [47.028619, 82.9596018],
    [47.0286662, 82.9596138],
    [47.0287068, 82.9596353],
    [47.0287344, 82.9596378],
    [47.0287588, 82.9596664],
    [47.0287571, 82.9596926],
    [47.0287457, 82.9597093],
    [47.0287538, 82.959726],
    [47.028775, 82.9597094],
    [47.0287994, 82.9597118],
    [47.0288091, 82.9596975],
    [47.0287913, 82.9596712],
    [47.0288059, 82.9596522],
    [47.0288335, 82.959688],
    [47.0288514, 82.9597071],
    [47.0288677, 82.9596905],
    [47.0289017, 82.9597644],
    [47.0288757, 82.9597882],
    [47.0288822, 82.9598431],
    [47.0289487, 82.9599434],
    [47.0289682, 82.9599673],
    [47.0289844, 82.9600126],
    [47.0289974, 82.9600079],
    [47.0290039, 82.9599936],
    [47.0289893, 82.9599673],
    [47.0290088, 82.9599673],
    [47.0290316, 82.9599793],
    [47.0290869, 82.9599794],
    [47.0291194, 82.9599962],
    [47.029134, 82.9600367],
    [47.0291634, 82.9600749],
    [47.0291683, 82.9601154],
    [47.0291617, 82.9601655],
    [47.0291796, 82.9601774],
    [47.0292024, 82.9601799],
    [47.0292478, 82.9602252],
    [47.0292722, 82.9602897],
    [47.0293047, 82.9602277],
    [47.0292853, 82.9601872],
    [47.0292853, 82.9601562],
    [47.0293113, 82.9601705],
    [47.0293405, 82.960223],
    [47.0293616, 82.9602302],
    [47.0293697, 82.9602446],
    [47.02936, 82.9602636],
    [47.0293372, 82.9602636],
    [47.0293713, 82.9603256],
    [47.0293956, 82.9603591],
    [47.0294086, 82.9603615],
    [47.0294103, 82.9603424],
    [47.0293827, 82.9603185],
    [47.0293957, 82.9602994],
    [47.0294315, 82.9602995],
    [47.0294559, 82.9602709],
    [47.0294705, 82.9602734],
    [47.0294786, 82.9602924],
    [47.0294786, 82.9603211],
    [47.02949, 82.9603139],
    [47.0294981, 82.9602901],
    [47.0295128, 82.960271],
    [47.029503, 82.9602305],
    [47.0295112, 82.9601995],
    [47.0295323, 82.9601852],
    [47.0295502, 82.9602163],
    [47.0295502, 82.9602401],
    [47.0295615, 82.9602449],
    [47.0295876, 82.9602378],
    [47.0296265, 82.9602689],
    [47.0296233, 82.9602975],
    [47.0296395, 82.9603047],
    [47.0296655, 82.9602952],
    [47.0296736, 82.9603071],
    [47.0296752, 82.9603405],
    [47.0296964, 82.9603572],
    [47.0297126, 82.9603263],
    [47.0297305, 82.9603096],
    [47.0298053, 82.960305],
    [47.0298573, 82.9603718],
    [47.0298768, 82.9603695],
    [47.0298784, 82.9603862],
    [47.029815, 82.9604409],
    [47.0298166, 82.9604552],
    [47.0298458, 82.9604601],
    [47.0298864, 82.9604888],
    [47.0298945, 82.9605198],
    [47.0298815, 82.9605555],
    [47.0298912, 82.9605722],
    [47.0298863, 82.9605889],
    [47.0298652, 82.9605913],
    [47.029849, 82.9605769],
    [47.029836, 82.9605984],
    [47.0298359, 82.9606437],
    [47.0298473, 82.9606699],
    [47.0298814, 82.9606986],
    [47.0299268, 82.9607678],
    [47.0299609, 82.9608347],
    [47.0299837, 82.9608562],
    [47.0300107, 82.9608568],
    [47.0300367, 82.9608068],
    [47.0300677, 82.9607711],
    [47.0300953, 82.960795],
    [47.0301375, 82.9608356],
    [47.0301863, 82.9608142],
    [47.0302139, 82.9607714],
    [47.0302807, 82.960688],
    [47.0302775, 82.9606403],
    [47.0302629, 82.9606069],
    [47.0302629, 82.9605854],
    [47.030284, 82.9605545],
    [47.0303376, 82.9605832],
    [47.0303538, 82.96065],
    [47.0303847, 82.960693],
    [47.0303667, 82.9608169],
    [47.0303878, 82.9608647],
    [47.030391, 82.9609124],
    [47.0303731, 82.9609529],
    [47.0303877, 82.9609792],
    [47.0304429, 82.9610007],
    [47.0304771, 82.9610342],
    [47.0304965, 82.9610676],
    [47.0305387, 82.9611201],
    [47.0305387, 82.9611344],
    [47.0305452, 82.9611988],
    [47.0305631, 82.9612132],
    [47.0305874, 82.961218],
    [47.0306053, 82.9612562],
    [47.030628, 82.9612777],
    [47.0306312, 82.9613445],
    [47.0306458, 82.9613493],
    [47.0306682, 82.9613212],
    [47.0307024, 82.9613141],
    [47.0307251, 82.9613404],
    [47.0307234, 82.9613762],
    [47.0307657, 82.9614192],
    [47.0308225, 82.9614264],
    [47.0308696, 82.9614909],
    [47.0309086, 82.9615291],
    [47.0309411, 82.9615411],
    [47.030959, 82.9615316],
    [47.0309785, 82.9615507],
    [47.0310159, 82.9615508],
    [47.0310419, 82.9615413],
    [47.0310549, 82.9615676],
    [47.03106, 82.9616019],
    [47.0310795, 82.9616162],
    [47.0311152, 82.9616067],
    [47.0311836, 82.9615687],
    [47.0312259, 82.9615282],
    [47.0312616, 82.9615068],
    [47.031312, 82.9614974],
    [47.0313445, 82.9614903],
    [47.0313998, 82.9615166],
    [47.0314681, 82.9615025],
    [47.0314989, 82.9615383],
    [47.0315201, 82.9615383],
    [47.0315266, 82.9615145],
    [47.0315542, 82.9615074],
    [47.0315932, 82.9615265],
    [47.0316224, 82.9615743],
    [47.0316192, 82.9616005],
    [47.0316386, 82.9616268],
    [47.0316646, 82.9616483],
    [47.0316988, 82.9616555],
    [47.0317085, 82.9617128],
    [47.0317247, 82.9617223],
    [47.0317361, 82.9617104],
    [47.0317442, 82.9617319],
    [47.0317442, 82.9617582],
    [47.0317588, 82.9617701],
    [47.031782, 82.9617253],
    [47.031782, 82.9616991],
    [47.0317658, 82.9616395],
    [47.0317772, 82.9615846],
    [47.0318195, 82.9615489],
    [47.0318553, 82.9615585],
    [47.0318585, 82.9616086],
    [47.0318487, 82.9616682],
    [47.0318583, 82.9617803],
    [47.0318827, 82.9617565],
    [47.031912, 82.9617781],
    [47.0319444, 82.9618473],
    [47.0319655, 82.9618998],
    [47.0319704, 82.9619379],
    [47.0319931, 82.9619833],
    [47.0320434, 82.9620311],
    [47.0321165, 82.9620837],
    [47.0321539, 82.9621577],
    [47.0322059, 82.9621745],
    [47.0322513, 82.9622414],
    [47.0322822, 82.9622843],
    [47.0323114, 82.9623082],
    [47.0323616, 82.9623643],
    [47.0324022, 82.9624073],
    [47.032446, 82.9624337],
    [47.0324818, 82.9624433],
    [47.0325094, 82.9624672],
    [47.0325289, 82.9625173],
    [47.0325565, 82.9625507],
    [47.0325808, 82.9626509],
    [47.03261, 82.9627154],
    [47.0326424, 82.9627536],
    [47.0326766, 82.9627227],
    [47.0326945, 82.9627203],
    [47.0327059, 82.9626917],
    [47.0327059, 82.9626464],
    [47.0327124, 82.9626226],
    [47.0327336, 82.9626226],
    [47.032753, 82.9627133],
    [47.0327725, 82.9627419],
    [47.0327936, 82.9627539],
    [47.0328164, 82.9627182],
    [47.0328326, 82.9627969],
    [47.0328277, 82.9628565],
    [47.0328455, 82.9628852],
    [47.0328324, 82.9629662],
    [47.0328633, 82.9629949],
    [47.0328958, 82.9629997],
    [47.0329218, 82.9630355],
    [47.0329413, 82.9630451],
    [47.032938, 82.9630904],
    [47.0329623, 82.9631286],
    [47.0329574, 82.9631668],
    [47.0329704, 82.9631692],
    [47.0329883, 82.9631525],
    [47.0329948, 82.9631788],
    [47.0330012, 82.9632384],
    [47.0330141, 82.9632617],
    [47.0330628, 82.9632713],
    [47.0330725, 82.9633047],
    [47.033092, 82.9633167],
    [47.0331067, 82.9633072],
    [47.0331067, 82.9632786],
    [47.0331262, 82.963281],
    [47.0331441, 82.9632953],
    [47.0331701, 82.9632739],
    [47.033188, 82.9632954],
    [47.0331896, 82.9633264],
    [47.0332123, 82.9633503],
    [47.033266, 82.9633361],
    [47.0332806, 82.9633505],
    [47.0332757, 82.9633719],
    [47.0332058, 82.9633909],
    [47.0331797, 82.9634147],
    [47.0331358, 82.9634217],
    [47.0331212, 82.9634408],
    [47.0331082, 82.9634789],
    [47.0330886, 82.9635123],
    [47.0331, 82.9635481],
    [47.0331292, 82.9635648],
    [47.0331487, 82.9635768],
    [47.0331536, 82.9636078],
    [47.033134, 82.9636674],
    [47.0331518, 82.9637056],
    [47.0331795, 82.9637008],
    [47.0331941, 82.9637438],
    [47.0332054, 82.9638082],
    [47.0332265, 82.9638393],
    [47.0332411, 82.9638321],
    [47.033246, 82.9638035],
    [47.0332704, 82.9638083],
    [47.033272, 82.9638918],
    [47.0332654, 82.9639419],
    [47.0332881, 82.9639706],
    [47.0332947, 82.9639419],
    [47.0333126, 82.9639372],
    [47.0333174, 82.963973],
    [47.0333515, 82.9640303],
    [47.0333742, 82.9640375],
    [47.0333905, 82.9640137],
    [47.0334116, 82.9640185],
    [47.0334149, 82.9640495],
    [47.0334067, 82.9640853],
    [47.0334148, 82.964102],
    [47.0334311, 82.9640877],
    [47.0334327, 82.9640543],
    [47.033449, 82.9640448],
    [47.033462, 82.9640639],
    [47.0334603, 82.9640973],
    [47.0334457, 82.9641426],
    [47.0334538, 82.9641879],
    [47.0334716, 82.9642189],
    [47.033496, 82.9642285],
    [47.0334944, 82.9642585],
    [47.033496, 82.9643085],
    [47.0335382, 82.964404],
    [47.0335658, 82.9644637],
    [47.0336031, 82.9644948],
    [47.0336454, 82.9644949],
    [47.0336828, 82.9645259],
    [47.0337201, 82.9645475],
    [47.0337249, 82.9646214],
    [47.0337574, 82.9646477],
    [47.0337606, 82.9647002],
    [47.0337931, 82.9647098],
    [47.0338158, 82.9648028],
    [47.0338093, 82.9648505],
    [47.0338369, 82.9648601],
    [47.0338629, 82.9648268],
    [47.0338921, 82.9648769],
    [47.0338872, 82.9649485],
    [47.0338953, 82.9649986],
    [47.033892, 82.9650796],
    [47.0339082, 82.9651274],
    [47.0339423, 82.9651728],
    [47.0339536, 82.9651942],
    [47.0340219, 82.9652325],
    [47.0340641, 82.9652732],
    [47.0340559, 82.9653208],
    [47.0340754, 82.9653543],
    [47.0341014, 82.9653591],
    [47.0341168, 82.9653689],
    [47.0341265, 82.9653951],
    [47.03412, 82.9654261],
    [47.0341314, 82.9654404],
    [47.0341476, 82.9654285],
    [47.0341509, 82.9653975],
    [47.0341347, 82.9653617],
    [47.0341461, 82.9653379],
    [47.0341704, 82.9653737],
    [47.0341883, 82.9653785],
    [47.0341932, 82.9654024],
    [47.034172, 82.9654644],
    [47.034172, 82.9655001],
    [47.0341849, 82.9655169],
    [47.034211, 82.9654525],
    [47.034237, 82.965486],
    [47.034237, 82.9655122],
    [47.0342158, 82.9655241],
    [47.0342191, 82.9655479],
    [47.0342369, 82.9655575],
    [47.0342645, 82.9655766],
    [47.0342775, 82.9656029],
    [47.0343035, 82.9655839],
    [47.034302, 82.9655433],
    [47.0343198, 82.9655314],
    [47.0343377, 82.9655672],
    [47.034354, 82.9655434],
    [47.0343718, 82.9655578],
    [47.0343848, 82.9655888],
    [47.0343815, 82.9656317],
    [47.0343961, 82.9656389],
    [47.034427, 82.9656247],
    [47.0344514, 82.9656414],
    [47.0344872, 82.9656152],
    [47.0345197, 82.9656105],
    [47.034536, 82.9656273],
    [47.0345555, 82.9656344],
    [47.0345587, 82.9656702],
    [47.0346009, 82.9656989],
    [47.0346204, 82.9657324],
    [47.0346415, 82.9657252],
    [47.0346512, 82.965773],
    [47.0346626, 82.9657825],
    [47.0346821, 82.9657706],
    [47.0347293, 82.965801],
    [47.0347357, 82.9658294],
    [47.0347389, 82.9658955],
    [47.034755, 82.9659074],
    [47.0347808, 82.9658933],
    [47.0347953, 82.965872],
    [47.0348065, 82.965891],
    [47.0348145, 82.9659737],
    [47.0348354, 82.965995],
    [47.0348805, 82.9660187],
    [47.0349111, 82.96604],
    [47.0349336, 82.9660424],
    [47.0349385, 82.9660661],
    [47.0349078, 82.9660731],
    [47.0348579, 82.9660588],
    [47.0348482, 82.9660754],
    [47.0348643, 82.966099],
    [47.0348643, 82.9661321],
    [47.0348933, 82.966144],
    [47.0349158, 82.966144],
    [47.0349303, 82.9661393],
    [47.0349948, 82.9661418],
    [47.0350141, 82.9661159],
    [47.0349932, 82.9660875],
    [47.0350125, 82.966071],
    [47.0350463, 82.9661159],
    [47.0350672, 82.9661349],
    [47.0350737, 82.9661561],
    [47.0350882, 82.9661444],
    [47.0350882, 82.9661184],
    [47.0351075, 82.9661066],
    [47.0351236, 82.966161],
    [47.0351187, 82.9661846],
    [47.0351558, 82.9661965],
    [47.0351525, 82.9662343],
    [47.0351686, 82.9662627],
    [47.0351686, 82.9662769],
    [47.0351492, 82.9662792],
    [47.035138, 82.9662981],
    [47.0351637, 82.966317],
    [47.0351862, 82.966362],
    [47.035249, 82.966407],
    [47.0352731, 82.9664118],
    [47.035286, 82.9664401],
    [47.0352989, 82.9664591],
    [47.0353134, 82.9664496],
    [47.0353375, 82.9664355],
    [47.0353456, 82.9664379],
    [47.0353633, 82.9664686],
    [47.0353584, 82.9664923],
    [47.0353375, 82.9665111],
    [47.0353503, 82.9665395],
    [47.0353713, 82.9665585],
    [47.0353825, 82.9665538],
    [47.035397, 82.9665325],
    [47.0354212, 82.9665562],
    [47.0354518, 82.9665799],
    [47.0354577, 82.966628],
    [47.0354995, 82.9666541],
    [47.0355221, 82.9666612],
    [47.0355253, 82.9666943],
    [47.035543, 82.9667015],
    [47.0355639, 82.9666991],
    [47.0355993, 82.9667323],
    [47.0356154, 82.9667654],
    [47.0356476, 82.9667773],
    [47.0356862, 82.9667844],
    [47.0357249, 82.9667703],
    [47.0357411, 82.9667302],
    [47.0357427, 82.9666877],
    [47.035783, 82.9666736],
    [47.0357926, 82.9666901],
    [47.035791, 82.9667161],
    [47.0358055, 82.9667327],
    [47.0358232, 82.9667256],
    [47.0358441, 82.9667351],
    [47.0358586, 82.9667682],
    [47.0358811, 82.9667706],
    [47.0359069, 82.9667778],
    [47.0359165, 82.9668014],
    [47.0359471, 82.966811],
    [47.0359809, 82.9668394],
    [47.0360131, 82.9668536],
    [47.0360276, 82.9668749],
    [47.0360324, 82.9669009],
    [47.0360469, 82.9669104],
    [47.0360614, 82.9668939],
    [47.0360759, 82.9668726],
    [47.0360904, 82.9668727],
    [47.036121, 82.9668964],
    [47.0361613, 82.966913],
    [47.0361905, 82.9669291],
    [47.0362114, 82.9669457],
    [47.0362372, 82.9669245],
    [47.0362211, 82.9668938],
    [47.0362276, 82.966882],
    [47.036263, 82.9668844],
    [47.0363, 82.9668963],
    [47.0363226, 82.9668585],
    [47.0363291, 82.9668183],
    [47.0363371, 82.9668278],
    [47.03635, 82.9668515],
    [47.0363645, 82.9668751],
    [47.0363677, 82.9669059],
    [47.0363821, 82.9669177],
    [47.0363983, 82.9668965],
    [47.0363983, 82.9668752],
    [47.036408, 82.9668681],
    [47.0364321, 82.9668942],
    [47.0364611, 82.966925],
    [47.0364868, 82.9669439],
    [47.0365045, 82.9669439],
    [47.0365223, 82.9669156],
    [47.0365706, 82.9669275],
    [47.0365867, 82.9669347],
    [47.0365867, 82.9669559],
    [47.0366414, 82.9669631],
    [47.0366511, 82.9669466],
    [47.0366511, 82.9669182],
    [47.036635, 82.966904],
    [47.0366431, 82.9668851],
    [47.036693, 82.9668923],
    [47.0367075, 82.9669302],
    [47.0367204, 82.9669278],
    [47.0367252, 82.9669042],
    [47.0367107, 82.9668853],
    [47.0367172, 82.9668711],
    [47.0367494, 82.9668735],
    [47.0367816, 82.9669043],
    [47.0367832, 82.9669469],
    [47.0367976, 82.9669682],
    [47.0368089, 82.9669564],
    [47.0368202, 82.9669327],
    [47.0368395, 82.9669281],
    [47.0368395, 82.9669635],
    [47.0368508, 82.9669753],
    [47.0368894, 82.9669612],
    [47.036904, 82.9669258],
    [47.0369217, 82.966914],
    [47.0369378, 82.9669259],
    [47.0369474, 82.966959],
    [47.0369716, 82.9669756],
    [47.0369957, 82.966978],
    [47.0370491, 82.9670148],
    [47.0370942, 82.967022],
    [47.0371054, 82.9670385],
    [47.0371183, 82.9670244],
    [47.0371376, 82.9670339],
    [47.0371424, 82.9670623],
    [47.0371537, 82.9670694],
    [47.037165, 82.9670505],
    [47.0372053, 82.9670435],
    [47.0372101, 82.9670648],
    [47.0372036, 82.9670931],
    [47.0372133, 82.9671215],
    [47.0372294, 82.9671333],
    [47.0372487, 82.9671215],
    [47.0372841, 82.9671571],
    [47.0372938, 82.9671405],
    [47.0372986, 82.9671216],
    [47.0373115, 82.9671264],
    [47.0373308, 82.9671595],
    [47.0373646, 82.967169],
    [47.0373871, 82.9672069],
    [47.0374209, 82.9672211],
    [47.0374483, 82.9672708],
    [47.0374789, 82.9672827],
    [47.0374981, 82.9673276],
    [47.0375223, 82.9673655],
    [47.0375625, 82.9673774],
    [47.0375915, 82.9674082],
    [47.0376287, 82.9673808],
    [47.0376658, 82.967362],
    [47.0376867, 82.9673786],
    [47.0377108, 82.9674259],
    [47.0377398, 82.9674259],
    [47.037764, 82.9674165],
    [47.037793, 82.967426],
    [47.0378236, 82.9674096],
    [47.0378525, 82.9674309],
    [47.0378896, 82.9674451],
    [47.0379137, 82.9674499],
    [47.0379394, 82.9675138],
    [47.0379941, 82.9675871],
    [47.0380553, 82.9676085],
    [47.038094, 82.967611],
    [47.0381374, 82.9676843],
    [47.0381422, 82.9677316],
    [47.038155, 82.96776],
    [47.0381744, 82.9677671],
    [47.0382033, 82.9677743],
    [47.0382162, 82.9678003],
    [47.0382001, 82.9678144],
    [47.0381872, 82.9678309],
    [47.0382017, 82.9678499],
    [47.0382322, 82.9678688],
    [47.038241, 82.9679312],
    [47.0382554, 82.9679501],
    [47.038278, 82.9679289],
    [47.0382941, 82.9678841],
    [47.0383248, 82.9678558],
    [47.0383602, 82.9678582],
    [47.0383554, 82.9678794],
    [47.0383714, 82.9678937],
    [47.0384166, 82.9678867],
    [47.0384407, 82.9679269],
    [47.0384326, 82.9679623],
    [47.0384358, 82.967986],
    [47.0384728, 82.9679908],
    [47.0384954, 82.9679601],
    [47.0385083, 82.9679648],
    [47.0385292, 82.9679909],
    [47.0385775, 82.9679744],
    [47.0386049, 82.9679887],
    [47.0386387, 82.9680242],
    [47.0386806, 82.9679344],
    [47.0387, 82.9679416],
    [47.038737, 82.9679747],
    [47.0387579, 82.9679724],
    [47.0387835, 82.9679662],
    [47.0388044, 82.9679851],
    [47.0388205, 82.9679757],
    [47.0388447, 82.9679545],
    [47.0388672, 82.9679569],
    [47.038901, 82.9679853],
    [47.0389219, 82.9680185],
    [47.0389396, 82.9680138],
    [47.0389558, 82.9679854],
    [47.0389767, 82.9679855],
    [47.0390202, 82.9680257],
    [47.0390652, 82.9680566],
    [47.0390668, 82.9681251],
    [47.0390474, 82.9681534],
    [47.0390426, 82.9681889],
    [47.0390409, 82.9682219],
    [47.0390666, 82.968274],
    [47.039123, 82.9682954],
    [47.0391519, 82.9683403],
    [47.0391858, 82.9683404],
    [47.0392116, 82.9683097],
    [47.0392309, 82.9683381],
    [47.0392405, 82.9683807],
    [47.0392243, 82.968435],
    [47.0392291, 82.9684799],
    [47.0392291, 82.9685319],
    [47.0392435, 82.9685556],
    [47.0392629, 82.968558],
    [47.0392838, 82.9685415],
    [47.0392935, 82.9685013],
    [47.0393145, 82.9684659],
    [47.0393467, 82.968466],
    [47.0393628, 82.968492],
    [47.0393934, 82.9684802],
    [47.0394223, 82.9685205],
    [47.0394385, 82.968504],
    [47.0394611, 82.9684662],
    [47.0394916, 82.968497],
    [47.0394802, 82.9685816],
    [47.0395124, 82.9685912],
    [47.0395539, 82.9685229],
    [47.0396144, 82.968551],
    [47.0396714, 82.96864],
    [47.0397147, 82.9686197],
    [47.0397787, 82.9686072],
    [47.0398099, 82.9685488],
    [47.0399015, 82.9687242],
    [47.0399326, 82.9687319],
    [47.0399396, 82.9685948],
    [47.0399691, 82.9685847],
    [47.0400001, 82.9686711],
    [47.0400382, 82.9687042],
    [47.0400841, 82.9686797],
    [47.0401405, 82.968668],
    [47.0402323, 82.9686777],
    [47.0403222, 82.968656],
    [47.0403721, 82.9685923],
    [47.040398, 82.9685262],
    [47.0404205, 82.9685144],
    [47.0404543, 82.9685239],
    [47.0404849, 82.9685287],
    [47.0405188, 82.9685051],
    [47.0405542, 82.9685147],
    [47.040609, 82.9684982],
    [47.0406685, 82.9685385],
    [47.0406765, 82.9685953],
    [47.0407135, 82.9686473],
    [47.0407425, 82.9686757],
    [47.0407651, 82.9686687],
    [47.0408021, 82.9686333],
    [47.0408037, 82.9686357],
    [47.0408327, 82.9686594],
    [47.040881, 82.968676],
    [47.0409309, 82.9687045],
    [47.0409734, 82.9687626],
    [47.0410252, 82.9688084],
    [47.0411031, 82.9688264],
    [47.041136, 82.9688061],
    [47.0411689, 82.9688113],
    [47.0411879, 82.9688291],
    [47.0412017, 82.9688266],
    [47.0412087, 82.9687961],
    [47.0412294, 82.9687936],
    [47.0412675, 82.9687861],
    [47.0413333, 82.9687659],
    [47.0413627, 82.9687253],
    [47.0414216, 82.9687026],
    [47.0414891, 82.9686773],
    [47.0415341, 82.9686597],
    [47.0415895, 82.9686141],
    [47.0416639, 82.9685507],
    [47.0417314, 82.9685255],
    [47.0418089, 82.968522],
    [47.0418884, 82.9685501],
    [47.0419386, 82.9685782],
    [47.0419593, 82.9686087],
    [47.0419627, 82.9686595],
    [47.0419956, 82.9686976],
    [47.0420094, 82.9687357],
    [47.0420336, 82.9687332],
    [47.0420544, 82.968718],
    [47.042089, 82.9687308],
    [47.0421391, 82.968769],
    [47.0421599, 82.968802],
    [47.0421771, 82.9688605],
    [47.0421996, 82.9688859],
    [47.0422186, 82.9689062],
    [47.0422428, 82.9688809],
    [47.042267, 82.9688835],
    [47.0422913, 82.9689038],
    [47.0423239, 82.9689248],
    [47.0423463, 82.9689858],
    [47.0424103, 82.9690468],
    [47.0424327, 82.9691027],
    [47.042495, 82.9691054],
    [47.042539, 82.9690985],
    [47.0426168, 82.9691215],
    [47.0426877, 82.9691852],
    [47.0427482, 82.9692234],
    [47.0428226, 82.9692235],
    [47.0429178, 82.9692059],
    [47.0429787, 82.9691841],
    [47.0430341, 82.969169],
    [47.0430653, 82.9691462],
    [47.0430878, 82.9691006],
    [47.0431138, 82.9690448],
    [47.0431381, 82.9690169],
    [47.0431519, 82.9690271],
    [47.0431744, 82.9690322],
    [47.0431917, 82.9689992],
    [47.0432212, 82.9689536],
    [47.0432658, 82.9689134],
    [47.0433142, 82.9688957],
    [47.0433644, 82.9688755],
    [47.0434146, 82.9688934],
    [47.0434561, 82.9689188],
    [47.0434803, 82.9689189],
    [47.0434977, 82.9688783],
    [47.0435167, 82.9688377],
    [47.0435479, 82.9688276],
    [47.043605, 82.9688404],
    [47.0436673, 82.968838],
    [47.043714, 82.9688508],
    [47.0437607, 82.9688712],
    [47.0438125, 82.968917],
    [47.0438575, 82.9689298],
    [47.043885, 82.9689416],
    [47.0439179, 82.9689442],
    [47.0439473, 82.968929],
    [47.0439732, 82.9689595],
    [47.0439905, 82.968952],
    [47.0440044, 82.9689393],
    [47.0440442, 82.9689648],
    [47.0440736, 82.9689877],
    [47.0441099, 82.9690157],
    [47.0441462, 82.9690411],
    [47.0441531, 82.9690031],
    [47.0441757, 82.9689523],
    [47.0441809, 82.9689219],
    [47.0442017, 82.9689245],
    [47.0442155, 82.9689473],
    [47.0442051, 82.9689803],
    [47.0442154, 82.9690032],
    [47.0442345, 82.9689982],
    [47.044257, 82.9689931],
    [47.0442534, 82.9690515],
    [47.0442725, 82.9690668],
    [47.0443089, 82.9690034],
    [47.0443365, 82.9690263],
    [47.0443694, 82.9690137],
    [47.0443867, 82.9689858],
    [47.0444092, 82.9689884],
    [47.04443, 82.9690214],
    [47.0444195, 82.9690442],
    [47.0443832, 82.9690772],
    [47.0443797, 82.9691127],
    [47.0444022, 82.9691432],
    [47.0444368, 82.9691281],
    [47.0444731, 82.9691332],
    [47.044513, 82.9691133],
    [47.0445217, 82.9690879],
    [47.0445217, 82.9690498],
    [47.0445321, 82.9690245],
    [47.0445511, 82.969027],
    [47.0445563, 82.9690651],
    [47.0445684, 82.9691007],
    [47.0445874, 82.9691008],
    [47.0446099, 82.9690678],
    [47.0446324, 82.9690399],
    [47.0446843, 82.9690578],
    [47.0446981, 82.9690908],
    [47.0447171, 82.9691162],
    [47.0447344, 82.9691163],
    [47.0447431, 82.9690909],
    [47.0447622, 82.9690681],
    [47.0447708, 82.9690884],
    [47.0447864, 82.9690935],
    [47.0448002, 82.9690732],
    [47.0448279, 82.969086],
    [47.0448331, 82.9691063],
    [47.0448192, 82.9691444],
    [47.0448399, 82.9691774],
    [47.0448399, 82.9692384],
    [47.0448589, 82.9692714],
    [47.0448814, 82.9692461],
    [47.044916, 82.9692461],
    [47.0449575, 82.9692437],
    [47.0449904, 82.9692311],
    [47.0450302, 82.9691829],
    [47.0450631, 82.9691652],
    [47.0450866, 82.9690885],
    [47.0451213, 82.9690098],
    [47.0451576, 82.9689769],
    [47.0452511, 82.9689238],
    [47.0453307, 82.9688706],
    [47.0453948, 82.96882],
    [47.0454243, 82.9687642],
    [47.045471, 82.9687186],
    [47.0455645, 82.968668],
    [47.0456251, 82.9686122],
    [47.0456615, 82.9685615],
    [47.0457005, 82.9685148],
    [47.0457559, 82.9684844],
    [47.0458096, 82.9684591],
    [47.045846, 82.9684135],
    [47.0459013, 82.9683984],
    [47.0459861, 82.9683605],
    [47.0460242, 82.9683402],
    [47.046052, 82.9682946],
    [47.0460915, 82.9682717],
    [47.0461227, 82.9682336],
    [47.0461937, 82.968216],
    [47.0462507, 82.9682313],
    [47.0462905, 82.9682213],
    [47.0463269, 82.968229],
    [47.046358, 82.9682214],
    [47.0463943, 82.9682519],
    [47.0464445, 82.9682927],
    [47.0464704, 82.968341],
    [47.0464721, 82.9683791],
    [47.0465118, 82.9683995],
    [47.0465205, 82.9684198],
    [47.0464858, 82.9684527],
    [47.0464944, 82.9684984],
    [47.0465239, 82.968496],
    [47.0465689, 82.9684173],
    [47.0466451, 82.9684099],
    [47.0466762, 82.9684302],
    [47.0466987, 82.9684176],
    [47.0467651, 82.9684488],
    [47.0467928, 82.9684514],
    [47.046817, 82.9684083],
    [47.0468672, 82.9683855],
    [47.0469192, 82.9683653],
    [47.0469538, 82.96834],
    [47.046997, 82.9683401],
    [47.0470714, 82.9683428],
    [47.0471735, 82.9683633],
    [47.047279, 82.9683584],
    [47.0473431, 82.9683687],
    [47.047407, 82.9683942],
    [47.047456, 82.9684514],
    [47.0475027, 82.9684794],
    [47.0475459, 82.9685404],
    [47.0475978, 82.9685735],
    [47.0476427, 82.9686447],
    [47.0476704, 82.96866],
    [47.0476928, 82.9686753],
    [47.0477257, 82.9687084],
    [47.0477516, 82.9687236],
    [47.0477879, 82.968739],
    [47.0478537, 82.9687772],
    [47.0479073, 82.9687544],
    [47.0479915, 82.9687119],
    [47.0480262, 82.9686384],
    [47.0480643, 82.9685572],
    [47.0481076, 82.9685192],
    [47.0481457, 82.968532],
    [47.0481767, 82.9686006],
    [47.0481991, 82.96872],
    [47.0482302, 82.9688241],
    [47.0482907, 82.9688674],
    [47.0483668, 82.9689031],
    [47.0483928, 82.9688626],
    [47.0484257, 82.9688347],
    [47.0484606, 82.968772],
    [47.0484918, 82.9687467],
    [47.0485385, 82.9687468],
    [47.0485749, 82.968724],
    [47.0486199, 82.9686784],
    [47.0486441, 82.9686479],
    [47.0486666, 82.9686454],
    [47.0486891, 82.9686861],
    [47.0486787, 82.9687216],
    [47.048696, 82.9687344],
    [47.0487185, 82.9687141],
    [47.0487358, 82.9687319],
    [47.0488032, 82.968793],
    [47.0489018, 82.9688186],
    [47.0489779, 82.968867],
    [47.0490383, 82.9689661],
    [47.0490417, 82.9690499],
    [47.0490347, 82.9691109],
    [47.0489862, 82.9692047],
    [47.0489792, 82.9692682],
    [47.0490034, 82.9693368],
    [47.0490414, 82.9693978],
    [47.0490621, 82.9694583],
    [47.049114, 82.9695118],
    [47.049136, 82.9695632],
    [47.0492087, 82.969604],
    [47.0492225, 82.9696167],
    [47.0492674, 82.9696701],
    [47.0492691, 82.9696752],
    [47.0492881, 82.9697616],
    [47.0493088, 82.9698099],
    [47.049333, 82.9698658],
    [47.049326, 82.9699673],
    [47.0493519, 82.9700182],
    [47.0493519, 82.9700258],
    [47.049331, 82.9701426],
    [47.0493759, 82.9701934],
    [47.0494346, 82.9703231],
    [47.0494512, 82.9703923],
    [47.0495099, 82.9705473],
    [47.0495116, 82.9705498],
    [47.049553, 82.9706489],
    [47.049572, 82.9707302],
    [47.0496125, 82.9708495],
    [47.0496365, 82.9710146],
    [47.0496745, 82.9711238],
    [47.0497453, 82.9712763],
    [47.0497988, 82.9713958],
    [47.0498852, 82.971495],
    [47.0498904, 82.9714975],
    [47.0499509, 82.971551],
    [47.0500219, 82.9716243],
    [47.0500789, 82.9717413],
    [47.0500961, 82.9718454],
    [47.050115, 82.9719826],
    [47.0501392, 82.9720613],
    [47.0501667, 82.9722163],
    [47.0502254, 82.9722951],
    [47.0502444, 82.9723637],
    [47.0502407, 82.9725872],
    [47.0502527, 82.9727066],
    [47.0502821, 82.9727853],
    [47.050291, 82.9729044],
    [47.0503168, 82.9730898],
    [47.0503167, 82.9732041],
    [47.0503477, 82.9733717],
    [47.050396, 82.9734988],
    [47.0504028, 82.973641],
    [47.0504009, 82.9738238],
    [47.0503953, 82.9739934],
    [47.0504177, 82.9740899],
    [47.0504834, 82.9741739],
    [47.0505283, 82.9742146],
    [47.0505681, 82.9742451],
    [47.0505733, 82.9742604],
    [47.0505957, 82.9743061],
    [47.0506372, 82.9743429],
    [47.0507081, 82.9743812],
    [47.0507634, 82.9743585],
    [47.0508241, 82.9742976],
    [47.0508517, 82.9743053],
    [47.0508717, 82.9743636],
    [47.0509149, 82.9743967],
    [47.0509512, 82.9743917],
    [47.0509875, 82.974407],
    [47.0510065, 82.9744528],
    [47.0510342, 82.9745062],
    [47.0510843, 82.9745291],
    [47.0511397, 82.9745293],
    [47.0511915, 82.97457],
    [47.0512295, 82.9746513],
    [47.0512398, 82.9747402],
    [47.0512623, 82.9747987],
    [47.0513037, 82.9748369],
    [47.051366, 82.9748649],
    [47.0514377, 82.9748899],
    [47.0514637, 82.9749205],
    [47.0514809, 82.9749611],
    [47.0515121, 82.9749637],
    [47.0515553, 82.9749461],
    [47.0515934, 82.9749487],
    [47.0516729, 82.9749945],
    [47.0516989, 82.9750022],
    [47.0517196, 82.9750835],
    [47.0517455, 82.9751191],
    [47.0517731, 82.975142],
    [47.0517973, 82.9751751],
    [47.0518285, 82.9752006],
    [47.0518422, 82.9752438],
    [47.0518508, 82.9752971],
    [47.0518839, 82.9753594],
    [47.0519115, 82.9754001],
    [47.051908, 82.9754484],
    [47.0519409, 82.9754662],
    [47.0519512, 82.9755043],
    [47.051972, 82.9755272],
    [47.0519996, 82.9755349],
    [47.0520186, 82.9755756],
    [47.052029, 82.9756264],
    [47.0520584, 82.9756442],
    [47.052093, 82.9756494],
    [47.0521016, 82.9756723],
    [47.0521224, 82.9756596],
    [47.0521241, 82.9756368],
    [47.0521449, 82.9756343],
    [47.0521899, 82.9756191],
    [47.0522193, 82.9756141],
    [47.0522418, 82.9755913],
    [47.0522557, 82.9755507],
    [47.0523007, 82.9755533],
    [47.0523508, 82.9755941],
    [47.0523837, 82.9755967],
    [47.0524478, 82.9755384],
    [47.0524616, 82.9755638],
    [47.0524893, 82.9755461],
    [47.0525238, 82.9755741],
    [47.0525463, 82.975597],
    [47.0525861, 82.9755743],
    [47.052619, 82.9755667],
    [47.0526622, 82.9756023],
    [47.0526899, 82.9755973],
    [47.0527107, 82.9756151],
    [47.0527435, 82.9756178],
    [47.0527663, 82.9756085],
    [47.052806, 82.9756568],
    [47.0528337, 82.9756619],
    [47.0528613, 82.9756874],
    [47.052889, 82.9756874],
    [47.052915, 82.9756977],
    [47.0529946, 82.9756775],
    [47.0530569, 82.97567],
    [47.053107, 82.9756752],
    [47.053133, 82.9757057],
    [47.0531554, 82.9757464],
    [47.0531969, 82.9757643],
    [47.0532437, 82.9757441],
    [47.053299, 82.9757543],
    [47.0533111, 82.9757848],
    [47.0533232, 82.9758128],
    [47.053337, 82.9758154],
    [47.0533664, 82.9757951],
    [47.053401, 82.9758155],
    [47.0534274, 82.9758998],
    [47.0534499, 82.9759404],
    [47.0534845, 82.9759405],
    [47.0535104, 82.9759609],
    [47.0535243, 82.9759381],
    [47.0535019, 82.9758898],
    [47.0535105, 82.9758466],
    [47.0535365, 82.9758314],
    [47.0535573, 82.9758315],
    [47.0535556, 82.9757883],
    [47.0535781, 82.9757503],
    [47.0535937, 82.9757427],
    [47.0536162, 82.9757706],
    [47.0536456, 82.9757859],
    [47.0536593, 82.9758418],
    [47.0536835, 82.97588],
    [47.053706, 82.9758902],
    [47.0537527, 82.975936],
    [47.0537907, 82.9759589],
    [47.0538409, 82.9760048],
    [47.0538928, 82.9759744],
    [47.0539222, 82.9759745],
    [47.0539429, 82.9760024],
    [47.0539758, 82.9760127],
    [47.054, 82.9760457],
    [47.0539965, 82.9760864],
    [47.0540103, 82.9761169],
    [47.0540293, 82.9761194],
    [47.0540535, 82.9761347],
    [47.0540639, 82.9761094],
    [47.0540571, 82.9760687],
    [47.0540779, 82.9760408],
    [47.0541004, 82.9760333],
    [47.0541349, 82.9760765],
    [47.0541314, 82.9761019],
    [47.0541574, 82.9761146],
    [47.0541747, 82.9761172],
    [47.0541868, 82.976135],
    [47.054185, 82.9761731],
    [47.0542178, 82.9762036],
    [47.0542386, 82.9762164],
    [47.0542618, 82.9762602],
    [47.0542808, 82.9762755],
    [47.0543016, 82.9762984],
    [47.0543534, 82.9763417],
    [47.0543673, 82.9763722],
    [47.054381, 82.9764256],
    [47.0544242, 82.9764765],
    [47.0544346, 82.9765247],
    [47.0544502, 82.9765273],
    [47.054483, 82.9765147],
    [47.0545107, 82.9765325],
    [47.0545504, 82.9766012],
    [47.0545659, 82.976652],
    [47.0546212, 82.9767511],
    [47.0546437, 82.9767817],
    [47.0546194, 82.9768502],
    [47.0546488, 82.9768756],
    [47.0546728, 82.9768857],
    [47.0547333, 82.9769519],
    [47.0547332, 82.9769976],
    [47.0547661, 82.9770256],
    [47.0548195, 82.977234],
    [47.0548091, 82.977267],
    [47.0548177, 82.9772898],
    [47.054835, 82.9772924],
    [47.0548558, 82.9772798],
    [47.0548748, 82.9773052],
    [47.05493, 82.9774069],
    [47.0549439, 82.9774069],
    [47.0549578, 82.9773841],
    [47.054994, 82.9774375],
    [47.0550044, 82.9774655],
    [47.054994, 82.9774934],
    [47.0550113, 82.9775112],
    [47.055006, 82.977534],
    [47.0549853, 82.9775391],
    [47.0549541, 82.9775822],
    [47.0550025, 82.9776204],
    [47.0549731, 82.9776559],
    [47.0549385, 82.9776533],
    [47.0549453, 82.977699],
    [47.0549487, 82.9777664],
    [47.0549625, 82.977868],
    [47.0549676, 82.9779468],
    [47.0549536, 82.978094],
    [47.0549778, 82.9781423],
    [47.0549933, 82.9781906],
    [47.0549967, 82.9782262],
    [47.0549707, 82.9782718],
    [47.0549706, 82.9783455],
    [47.0549688, 82.9784344],
    [47.0549237, 82.9785409],
    [47.0549098, 82.9786755],
    [47.0548751, 82.978711],
    [47.0548405, 82.9787465],
    [47.0548231, 82.9788226],
    [47.0548226, 82.978886],
    [47.054845, 82.978914],
    [47.0548467, 82.9789673],
    [47.0548328, 82.9790232],
    [47.0547895, 82.9790891],
    [47.0547894, 82.9791653],
    [47.0547616, 82.979249],
    [47.0547477, 82.9793354],
    [47.0547528, 82.9794268],
    [47.0547822, 82.97947],
    [47.0548081, 82.9795437],
    [47.0548201, 82.9796149],
    [47.054846, 82.9796987],
    [47.0548788, 82.9797318],
    [47.054922, 82.9797446],
    [47.0549601, 82.9797777],
    [47.0550004, 82.9798228],
    [47.055035, 82.9798533],
    [47.0550592, 82.9798966],
    [47.0550435, 82.9799651],
    [47.0550245, 82.9800184],
    [47.0550348, 82.9800667],
    [47.055059, 82.9800845],
    [47.0550815, 82.9800693],
    [47.0551109, 82.9800491],
    [47.0551507, 82.9800745],
    [47.0551749, 82.9800949],
    [47.0551939, 82.9801407],
    [47.0552131, 82.9801694],
    [47.0552235, 82.9802253],
    [47.0552598, 82.9802686],
    [47.0552718, 82.9803245],
    [47.0553029, 82.9803703],
    [47.0553132, 82.980416],
    [47.0554048, 82.980533],
    [47.0554687, 82.9806652],
    [47.0554634, 82.9807262],
    [47.055491, 82.9808278],
    [47.0555186, 82.9808837],
    [47.055529, 82.9809295],
    [47.055548, 82.9809651],
    [47.0555704, 82.9810235],
    [47.0555703, 82.98112],
    [47.0555806, 82.981209],
    [47.0556065, 82.9812877],
    [47.055641, 82.9813361],
    [47.0556686, 82.9814047],
    [47.0556703, 82.9814555],
    [47.0556495, 82.9815113],
    [47.0556598, 82.981552],
    [47.0556858, 82.9815774],
    [47.0557221, 82.9815572],
    [47.0557377, 82.9815191],
    [47.0557585, 82.9814963],
    [47.055781, 82.981504],
    [47.0557913, 82.9815421],
    [47.0558103, 82.9815675],
    [47.0558328, 82.9815676],
    [47.0558501, 82.9815651],
    [47.0558501, 82.9815854],
    [47.0558449, 82.9816133],
    [47.0558604, 82.9816515],
    [47.0558967, 82.981682],
    [47.0559452, 82.9816847],
    [47.0560011, 82.9816404],
    [47.0560184, 82.9816506],
    [47.0560409, 82.9816735],
    [47.056072, 82.9816634],
    [47.0561083, 82.9816711],
    [47.0561239, 82.9816864],
    [47.0561464, 82.9816864],
    [47.0561671, 82.9817017],
    [47.0561827, 82.981689],
    [47.0561896, 82.9816992],
    [47.0561792, 82.9817373],
    [47.0562034, 82.9817526],
    [47.0562242, 82.9817374],
    [47.056238, 82.9818136],
    [47.0562691, 82.9818416],
    [47.0563193, 82.9818036],
    [47.0563366, 82.981796],
    [47.056347, 82.9817808],
    [47.056366, 82.9817809],
    [47.0563885, 82.9818139],
    [47.0564283, 82.9818267],
    [47.0564767, 82.9818675],
    [47.0564974, 82.9819005],
    [47.0565165, 82.9818726],
    [47.0565407, 82.9818752],
    [47.0565735, 82.981888],
    [47.0565995, 82.9818881],
    [47.0566271, 82.9819084],
    [47.0566427, 82.9819567],
    [47.0566461, 82.9820151],
    [47.0566703, 82.9820406],
    [47.0567239, 82.9820636],
    [47.056786, 82.9821856],
    [47.0567912, 82.982239],
    [47.0567894, 82.9822923],
    [47.0568049, 82.9823406],
    [47.0568222, 82.9823609],
    [47.0568238, 82.9824397],
    [47.0568233, 82.9825444],
    [47.0568232, 82.9826612],
    [47.056849, 82.9827705],
    [47.0568593, 82.9828289],
    [47.0568835, 82.9828467],
    [47.0568904, 82.9829229],
    [47.0569197, 82.9830043],
    [47.0569352, 82.9830856],
    [47.0569593, 82.9831466],
    [47.0569853, 82.9831568],
    [47.0570061, 82.9831289],
    [47.0570372, 82.9831112],
    [47.0570545, 82.983129],
    [47.057051, 82.9831798],
    [47.0570925, 82.9832434],
    [47.0570976, 82.9833145],
    [47.0571252, 82.9833806],
    [47.0571563, 82.9834264],
    [47.0571666, 82.9834696],
    [47.0571787, 82.9835458],
    [47.0572046, 82.9835966],
    [47.0572391, 82.9836272],
    [47.0572823, 82.9836857],
    [47.0573187, 82.9836756],
    [47.0573498, 82.9836782],
    [47.0573515, 82.9836884],
    [47.0573324, 82.9837265],
    [47.0573445, 82.9837519],
    [47.0573705, 82.9837697],
    [47.0573998, 82.9838739],
    [47.0574313, 82.9839179],
    [47.0574641, 82.9839637],
    [47.0574952, 82.9839943],
    [47.0575297, 82.9841086],
    [47.0575556, 82.984129],
    [47.0575746, 82.9841798],
    [47.0575919, 82.9841926],
    [47.0575936, 82.9842256],
    [47.0576126, 82.984246],
    [47.0576316, 82.9842714],
    [47.0576367, 82.9843273],
    [47.0576558, 82.9843273],
    [47.0576679, 82.9843527],
    [47.0576575, 82.9843807],
    [47.0576626, 82.9844111],
    [47.0576782, 82.9844213],
    [47.0576816, 82.9844493],
    [47.0577093, 82.9844722],
    [47.0577629, 82.9844545],
    [47.0577922, 82.9845308],
    [47.0577887, 82.9845714],
    [47.0577645, 82.9845993],
    [47.0577679, 82.9846222],
    [47.0578077, 82.9846375],
    [47.0578561, 82.9846833],
    [47.057882, 82.9847621],
    [47.0579235, 82.9847902],
    [47.0579269, 82.9848333],
    [47.0579389, 82.9849527],
    [47.0579302, 82.9849806],
    [47.0579163, 82.9849959],
    [47.0579163, 82.9850238],
    [47.0579422, 82.985095],
    [47.0579741, 82.9851185],
    [47.0580121, 82.9851745],
    [47.0580605, 82.9851924],
    [47.0580864, 82.9852204],
    [47.0581331, 82.9852814],
    [47.0581659, 82.9852917],
    [47.0581849, 82.9853222],
    [47.0582057, 82.98534],
    [47.058223, 82.9853248],
    [47.0582368, 82.9853528],
    [47.0582524, 82.9853503],
    [47.0582576, 82.98533],
    [47.0582576, 82.9853097],
    [47.0582818, 82.9852919],
    [47.0583061, 82.9853047],
    [47.0583199, 82.9853327],
    [47.0583181, 82.9853733],
    [47.0583423, 82.9854064],
    [47.0583527, 82.9853988],
    [47.0583527, 82.9853708],
    [47.0583683, 82.9853633],
    [47.0583873, 82.9853912],
    [47.0583769, 82.9854369],
    [47.058401, 82.9854878],
    [47.0584235, 82.9855107],
    [47.0584494, 82.9855133],
    [47.058465, 82.9855311],
    [47.0585083, 82.9855083],
    [47.0585238, 82.9855008],
    [47.0585412, 82.9854881],
    [47.0585671, 82.9855034],
    [47.0585532, 82.9855592],
    [47.0585705, 82.9855847],
    [47.058593, 82.9855974],
    [47.0585946, 82.985633],
    [47.0585911, 82.9856863],
    [47.0586136, 82.9857067],
    [47.0586465, 82.9856915],
    [47.0586689, 82.9857195],
    [47.0586654, 82.9857779],
    [47.0586654, 82.9858109],
    [47.0586654, 82.9858287],
    [47.0586861, 82.9858643],
    [47.0586947, 82.9859075],
    [47.0587206, 82.9859254],
    [47.0587449, 82.9858975],
    [47.0587708, 82.9859128],
    [47.058769, 82.9859661],
    [47.0587258, 82.9859838],
    [47.0587084, 82.9860168],
    [47.0587118, 82.9860853],
    [47.0587429, 82.986126],
    [47.0587723, 82.9861515],
    [47.0587913, 82.9862049],
    [47.0588392, 82.9862523],
    [47.0588547, 82.986293],
    [47.0588703, 82.9863006],
    [47.0588876, 82.986288],
    [47.0589032, 82.9863109],
    [47.0588806, 82.986387],
    [47.0588494, 82.9864047],
    [47.0588649, 82.9864733],
    [47.0588925, 82.9865293],
    [47.0588942, 82.9865877],
    [47.0588889, 82.9866689],
    [47.0589079, 82.9866893],
    [47.0589235, 82.9866716],
    [47.0589512, 82.9866792],
    [47.0589771, 82.9867098],
    [47.0590135, 82.9866895],
    [47.0590533, 82.9866896],
    [47.0590655, 82.9866313],
    [47.0591018, 82.9866288],
    [47.059126, 82.9866441],
    [47.0591675, 82.9866569],
    [47.0592038, 82.9867281],
    [47.0591985, 82.9867839],
    [47.0592141, 82.9868043],
    [47.0592331, 82.9867993],
    [47.0592573, 82.9868349],
    [47.0592434, 82.9868958],
    [47.0592711, 82.9869035],
    [47.0592918, 82.9869137],
    [47.0593109, 82.986896],
    [47.0593506, 82.9869215],
    [47.0593523, 82.9869773],
    [47.0593661, 82.9869697],
    [47.0593852, 82.9869393],
    [47.0594164, 82.9869267],
    [47.0594579, 82.9869395],
    [47.0594942, 82.986965],
    [47.0595028, 82.986998],
    [47.0594871, 82.9870742],
    [47.0594646, 82.987196],
    [47.0594767, 82.9872189],
    [47.059494, 82.9872113],
    [47.0595166, 82.9871479],
    [47.0595426, 82.9871454],
    [47.0595598, 82.9871658],
    [47.0595425, 82.9872216],
    [47.0595511, 82.9872724],
    [47.0595684, 82.9872598],
    [47.059603, 82.987204],
    [47.0596256, 82.9871812],
    [47.0596428, 82.9872066],
    [47.0596428, 82.9872472],
    [47.0596185, 82.9873284],
    [47.0596219, 82.9873589],
    [47.0596392, 82.9873717],
    [47.05966, 82.9873514],
    [47.0596912, 82.9873134],
    [47.0597136, 82.9873236],
    [47.0597257, 82.9873465],
    [47.0597378, 82.9873719],
    [47.0597776, 82.9873999],
    [47.0598, 82.9874457],
    [47.0598329, 82.9874534],
    [47.0598813, 82.9874688],
    [47.0599021, 82.9874891],
    [47.0599124, 82.9875069],
    [47.059947, 82.9875146],
    [47.0599574, 82.987535],
    [47.0599573, 82.9875781],
    [47.0599764, 82.9875807],
    [47.0599867, 82.9875807],
    [47.0600092, 82.9876062],
    [47.0600352, 82.9875961],
    [47.0600594, 82.9876165],
    [47.0600939, 82.987642],
    [47.060132, 82.9876446],
    [47.0601718, 82.9876142],
    [47.0601961, 82.9876117],
    [47.0602304, 82.9876291],
    [47.0602719, 82.9876444],
    [47.0603186, 82.9876344],
    [47.0603325, 82.9876522],
    [47.0603497, 82.9876751],
    [47.0603792, 82.9876828],
    [47.0604085, 82.9877032],
    [47.0604258, 82.9877286],
    [47.0604275, 82.9877642],
    [47.0604188, 82.9878124],
    [47.060417, 82.9878886],
    [47.0604308, 82.9879343],
    [47.0604516, 82.9879268],
    [47.0604516, 82.9879014],
    [47.0604654, 82.9878887],
    [47.0604844, 82.9879091],
    [47.0604983, 82.9879066],
    [47.0605052, 82.9878812],
    [47.0605226, 82.9878609],
    [47.060545, 82.9879016],
    [47.0606003, 82.9879525],
    [47.0606418, 82.9880237],
    [47.0606815, 82.9880568],
    [47.0607248, 82.9880696],
    [47.060742, 82.988123],
    [47.0607385, 82.988156],
    [47.0607644, 82.9881789],
    [47.0608059, 82.9881943],
    [47.0608215, 82.9881816],
    [47.0608319, 82.9882045],
    [47.0608076, 82.9882375],
    [47.0608249, 82.9882527],
    [47.060856, 82.9882909],
    [47.0608877, 82.9883],
    [47.0609085, 82.9882797],
    [47.0609362, 82.9882544],
    [47.0609604, 82.9882748],
    [47.0609846, 82.9883231],
    [47.0609759, 82.9883662],
    [47.0609879, 82.9884171],
    [47.0610035, 82.9884171],
    [47.0610295, 82.9883867],
    [47.0610831, 82.98843],
    [47.061135, 82.9884428],
    [47.0611539, 82.9885013],
    [47.0611521, 82.9885521],
    [47.0611278, 82.9886155],
    [47.0611468, 82.988679],
    [47.0611277, 82.9887577],
    [47.061145, 82.9887654],
    [47.0611674, 82.9887858],
    [47.061183, 82.988829],
    [47.0611777, 82.9888594],
    [47.0611432, 82.9888492],
    [47.0611172, 82.9888644],
    [47.0611171, 82.9889075],
    [47.0611517, 82.9889508],
    [47.0611586, 82.9889889],
    [47.061143, 82.9890219],
    [47.0611014, 82.9890624],
    [47.0610754, 82.9891437],
    [47.0610874, 82.9892072],
    [47.0611271, 82.9893114],
    [47.0611461, 82.9893445],
    [47.0611477, 82.9894105],
    [47.0611633, 82.9894385],
    [47.0611875, 82.9894487],
    [47.0612169, 82.9894513],
    [47.0612169, 82.9894107],
    [47.0612377, 82.9894056],
    [47.0612636, 82.9894286],
    [47.061316, 82.9894973],
    [47.0613471, 82.9895609],
    [47.0614387, 82.9896424],
    [47.0614784, 82.9896577],
    [47.0614991, 82.9897264],
    [47.0614558, 82.9898075],
    [47.0614558, 82.989838],
    [47.0614903, 82.9899016],
    [47.0615093, 82.9899448],
    [47.061549, 82.9900033],
    [47.0615767, 82.990011],
    [47.0616251, 82.9899984],
    [47.06167, 82.9900646],
    [47.0616717, 82.9901052],
    [47.0616751, 82.9901535],
    [47.0617183, 82.9901968],
    [47.0617374, 82.9901867],
    [47.0617634, 82.9901537],
    [47.0617945, 82.9901639],
    [47.0618187, 82.9901538],
    [47.0618551, 82.9901666],
    [47.0618793, 82.9901895],
    [47.061855, 82.9902174],
    [47.0618601, 82.9902962],
    [47.0618964, 82.9903369],
    [47.0619067, 82.9904106],
    [47.0619482, 82.9904386],
    [47.0619776, 82.9904285],
    [47.062014, 82.9903829],
    [47.0620434, 82.990416],
    [47.0620668, 82.9904164],
    [47.0620875, 82.9904824],
    [47.0620754, 82.9905256],
    [47.0621185, 82.9905816],
    [47.0621479, 82.9905918],
    [47.0621687, 82.9906427],
    [47.0621686, 82.9907265],
    [47.062191, 82.9908027],
    [47.062229, 82.9908181],
    [47.062255, 82.990813],
    [47.0622878, 82.9908563],
    [47.0623154, 82.9909122],
    [47.0623482, 82.9909631],
    [47.0623482, 82.9910063],
    [47.0623429, 82.9910571],
    [47.062355, 82.9911079],
    [47.0623134, 82.9912069],
    [47.0623167, 82.9912881],
    [47.0623046, 82.9913135],
    [47.0622976, 82.9913491],
    [47.0623166, 82.991377],
    [47.0623253, 82.9913542],
    [47.0623426, 82.9913441],
    [47.0623651, 82.9913619],
    [47.0623737, 82.9913823],
    [47.0623876, 82.9913671],
    [47.062398, 82.9913391],
    [47.0624101, 82.9913265],
    [47.0624292, 82.9913087],
    [47.0624465, 82.9913189],
    [47.0624568, 82.9913571],
    [47.0624083, 82.9914179],
    [47.0623944, 82.9914788],
    [47.0623856, 82.9915474],
    [47.0624012, 82.9915728],
    [47.0623977, 82.9916262],
    [47.0624097, 82.991649],
    [47.0624219, 82.9916491],
    [47.0624409, 82.9916313],
    [47.0624617, 82.991639],
    [47.0624582, 82.9916695],
    [47.0624374, 82.9917202],
    [47.0624373, 82.9917456],
    [47.0624563, 82.9917685],
    [47.0624719, 82.9917686],
    [47.062517, 82.9917052],
    [47.0625619, 82.9917078],
    [47.0625878, 82.991746],
    [47.0625999, 82.9917841],
    [47.0626206, 82.9918096],
    [47.0626223, 82.99184],
    [47.0626119, 82.9919086],
    [47.0626378, 82.9919493],
    [47.0626845, 82.9919316],
    [47.0627105, 82.9919342],
    [47.0627329, 82.9919648],
    [47.0627751, 82.9920475],
    [47.0627958, 82.9920603],
    [47.0628062, 82.9920883],
    [47.0628009, 82.9921263],
    [47.0628182, 82.9921391],
    [47.0628442, 82.9921391],
    [47.0628719, 82.9921341],
    [47.0628961, 82.9921012],
    [47.06291, 82.9920758],
    [47.0629342, 82.9920759],
    [47.0629601, 82.9921166],
    [47.0629947, 82.9921217],
    [47.0630379, 82.9921625],
    [47.0630656, 82.9921752],
    [47.0630967, 82.9921804],
    [47.0631192, 82.9922135],
    [47.0631435, 82.9921881],
    [47.0631746, 82.9922034],
    [47.0632022, 82.9922188],
    [47.0632299, 82.9922036],
    [47.0632403, 82.992229],
    [47.0632403, 82.9922544],
    [47.0632645, 82.9922621],
    [47.0632818, 82.9922316],
    [47.0632922, 82.9922469],
    [47.0632904, 82.9922723],
    [47.063306, 82.9922774],
    [47.0633233, 82.9922622],
    [47.0633475, 82.9922674],
    [47.0633613, 82.9922953],
    [47.0633716, 82.9923843],
    [47.063401, 82.9923996],
    [47.0634252, 82.9924504],
    [47.0634373, 82.9924428],
    [47.0634495, 82.9923946],
    [47.0634737, 82.9923794],
    [47.0635048, 82.99241],
    [47.0635169, 82.992443],
    [47.0635411, 82.9924228],
    [47.0635602, 82.9924279],
    [47.0635792, 82.9924153],
    [47.0635954, 82.9924325],
    [47.0635971, 82.9924579],
    [47.0636247, 82.9924809],
    [47.0636559, 82.9924885],
    [47.0636801, 82.9924683],
    [47.0637008, 82.992481],
    [47.0637284, 82.9926183],
    [47.0637283, 82.9926513],
    [47.0637421, 82.992692],
    [47.0637646, 82.9926869],
    [47.0637871, 82.9926717],
    [47.0638113, 82.9926896],
    [47.0638356, 82.9926896],
    [47.0638615, 82.99271],
    [47.0639099, 82.9927889],
    [47.0639375, 82.9927915],
    [47.0639479, 82.9927788],
    [47.0639583, 82.9927636],
    [47.0639773, 82.9927738],
    [47.0640153, 82.9928298],
    [47.0640361, 82.9928222],
    [47.0640621, 82.9928375],
    [47.0640879, 82.9929341],
    [47.0641173, 82.9929342],
    [47.0641657, 82.9929521],
    [47.0641899, 82.992975],
    [47.0642414, 82.993005],
    [47.0642569, 82.9930482],
    [47.064288, 82.9930686],
    [47.0643503, 82.9931017],
    [47.0643987, 82.9930993],
    [47.0644281, 82.9930842],
    [47.0644507, 82.9930436],
    [47.064475, 82.9929852],
    [47.0644923, 82.9929522],
    [47.0645339, 82.9929193],
    [47.0645892, 82.9929169],
    [47.0646031, 82.9928763],
    [47.064591, 82.992856],
    [47.0646014, 82.9928407],
    [47.0646603, 82.9928231],
    [47.0647001, 82.992841],
    [47.064733, 82.9928233],
    [47.0647572, 82.9927878],
    [47.0647798, 82.9927447],
    [47.0648126, 82.9927346],
    [47.0649078, 82.9927424],
    [47.0648939, 82.9927856],
    [47.0649095, 82.9927983],
    [47.0649493, 82.9927857],
    [47.0649804, 82.9927604],
    [47.0650047, 82.9927223],
    [47.0650272, 82.9927275],
    [47.0650652, 82.9927555],
    [47.0650877, 82.9927886],
    [47.0650928, 82.9928318],
    [47.0651084, 82.9928445],
    [47.0651601, 82.9928487],
    [47.0651999, 82.9928818],
    [47.0652188, 82.9929555],
    [47.0652638, 82.9929556],
    [47.0652966, 82.9930065],
    [47.0653191, 82.9930447],
    [47.0653433, 82.9930625],
    [47.0653675, 82.9930829],
    [47.0653969, 82.9931058],
    [47.0654193, 82.9931491],
    [47.065447, 82.9931822],
    [47.0654746, 82.9932025],
    [47.0654954, 82.9931924],
    [47.0655092, 82.9931925],
    [47.0655248, 82.9931671],
    [47.065556, 82.993157],
    [47.0655751, 82.9931317],
    [47.0655976, 82.9931114],
    [47.0656166, 82.9931191],
    [47.0656287, 82.993142],
    [47.06562, 82.9932181],
    [47.0656511, 82.9932462],
    [47.0656995, 82.9932564],
    [47.0657912, 82.9932313],
    [47.0658293, 82.9932466],
    [47.0658604, 82.9932213],
    [47.0658933, 82.9932315],
    [47.0659244, 82.9932367],
    [47.065947, 82.9932062],
    [47.0659695, 82.993186],
    [47.0659678, 82.9931657],
    [47.0659314, 82.9931757],
    [47.0659211, 82.9931579],
    [47.065935, 82.9931224],
    [47.0659627, 82.9931072],
    [47.0659921, 82.9931124],
    [47.0660375, 82.993161],
    [47.0660548, 82.993156],
    [47.0660738, 82.9931128],
    [47.0660895, 82.9930697],
    [47.0661034, 82.993024],
    [47.0661224, 82.9930088],
    [47.0661449, 82.9930114],
    [47.0661674, 82.9930292],
    [47.0662053, 82.9931208],
    [47.0662208, 82.9931691],
    [47.0662399, 82.9931869],
    [47.0662692, 82.9932429],
    [47.0663073, 82.9932404],
    [47.0663627, 82.9931821],
    [47.0663697, 82.9931034],
    [47.066413, 82.9930349],
    [47.0664425, 82.9930299],
    [47.0664545, 82.9930604],
    [47.0664683, 82.9931113],
    [47.0664943, 82.9931215],
    [47.0665479, 82.9930937],
    [47.0665704, 82.993076],
    [47.0665895, 82.9930481],
    [47.0666103, 82.9930532],
    [47.0666293, 82.9930913],
    [47.0666639, 82.9930914],
    [47.066733, 82.9931068],
    [47.066759, 82.9931044],
    [47.0667884, 82.9931298],
    [47.0668074, 82.9931604],
    [47.0668593, 82.9931808],
    [47.0668783, 82.9931707],
    [47.0669289, 82.9931388],
    [47.0669393, 82.9930982],
    [47.0669411, 82.9930576],
    [47.0669238, 82.993027],
    [47.0669308, 82.9930067],
    [47.0669481, 82.9929915],
    [47.066969, 82.9929052],
    [47.0670001, 82.9928951],
    [47.0670312, 82.992913],
    [47.0670711, 82.9928572],
    [47.0670884, 82.9928318],
    [47.0671231, 82.9927989],
    [47.0671317, 82.9927989],
    [47.0671715, 82.9928193],
    [47.0672286, 82.9927865],
    [47.0673082, 82.9927994],
    [47.0673445, 82.9928223],
    [47.0673704, 82.9928783],
    [47.067424, 82.9928809],
    [47.0674534, 82.9928835],
    [47.0674863, 82.9928912],
    [47.0675105, 82.9928761],
    [47.0675313, 82.9928736],
    [47.0675573, 82.9928533],
    [47.0675936, 82.9928813],
    [47.0676161, 82.9928763],
    [47.0676611, 82.9928612],
    [47.0676628, 82.9928612],
    [47.0676922, 82.992879],
    [47.0677407, 82.9928715],
    [47.067822, 82.9928311],
    [47.0678989, 82.9928249],
    [47.0679197, 82.9927614],
    [47.0679423, 82.9927107],
    [47.0679734, 82.9927133],
    [47.0679941, 82.9927514],
    [47.0680356, 82.9928405],
    [47.0680667, 82.9928507],
    [47.0681203, 82.9928483],
    [47.0681584, 82.992856],
    [47.0681878, 82.9928256],
    [47.0682224, 82.9928358],
    [47.0682691, 82.992869],
    [47.0682725, 82.9928969],
    [47.0683054, 82.9928919],
    [47.0683815, 82.9929378],
    [47.068423, 82.992976],
    [47.0684576, 82.992966],
    [47.068487, 82.9929508],
    [47.068513, 82.9929686],
    [47.0685215, 82.9930245],
    [47.068548, 82.9930392],
    [47.068567, 82.9930748],
    [47.0685583, 82.9931332],
    [47.0685721, 82.9931586],
    [47.0686049, 82.9931816],
    [47.0686326, 82.9931766],
    [47.0686448, 82.9931563],
    [47.0686655, 82.9931589],
    [47.0686828, 82.9931792],
    [47.0687036, 82.9931615],
    [47.0687278, 82.9931311],
    [47.0687781, 82.9931033],
    [47.0688386, 82.9930933],
    [47.0688732, 82.993073],
    [47.06892, 82.993068],
    [47.0689667, 82.9930402],
    [47.0690325, 82.9930251],
    [47.0690879, 82.9929923],
    [47.069119, 82.9929796],
    [47.0691595, 82.9930008],
    [47.0691838, 82.9929932],
    [47.0692374, 82.9930111],
    [47.0692754, 82.9930392],
    [47.0692996, 82.9930265],
    [47.0693446, 82.9930546],
    [47.0693896, 82.9930572],
    [47.06945, 82.9931336],
    [47.0695089, 82.9930829],
    [47.0695833, 82.993073],
    [47.0696248, 82.9931035],
    [47.0696733, 82.993096],
    [47.0697026, 82.9931444],
    [47.0697476, 82.9931572],
    [47.0697579, 82.9932055],
    [47.0697821, 82.9932208],
    [47.0698167, 82.9932183],
    [47.0698945, 82.9932744],
    [47.0699322, 82.9932679],
    [47.0699772, 82.9932375],
    [47.0700188, 82.9932376],
    [47.0700447, 82.9932479],
    [47.0700688, 82.9933343],
    [47.0700809, 82.9933826],
    [47.0701051, 82.9934182],
    [47.0701898, 82.9934616],
    [47.0702832, 82.9934974],
    [47.0703151, 82.9934655],
    [47.0703911, 82.9935038],
    [47.0704153, 82.9935572],
    [47.0704725, 82.9934939],
    [47.0705365, 82.9935245],
    [47.0705917, 82.9935907],
    [47.070654, 82.9936264],
    [47.0706799, 82.9936696],
    [47.0706798, 82.9937332],
    [47.0707023, 82.993784],
    [47.070742, 82.9938121],
    [47.0707351, 82.99384],
    [47.0707654, 82.9938861],
    [47.0708035, 82.9938938],
    [47.070838, 82.993932],
    [47.0708812, 82.9939854],
    [47.0709262, 82.9940033],
    [47.070952, 82.9940745],
    [47.0709866, 82.9941228],
    [47.0710558, 82.9941637],
    [47.0711076, 82.9942197],
    [47.0711577, 82.9943087],
    [47.0712216, 82.9943673],
    [47.071277, 82.9943852],
    [47.0713496, 82.9943981],
    [47.0713965, 82.9943831],
    [47.0714328, 82.9943501],
    [47.0714709, 82.9943197],
    [47.0715229, 82.9943122],
    [47.0715696, 82.9943124],
    [47.0715955, 82.9942972],
    [47.0716596, 82.994277],
    [47.0717392, 82.9942772],
    [47.0717444, 82.9942772],
    [47.0718447, 82.9942826],
    [47.0719121, 82.9943259],
    [47.0719454, 82.9943967],
    [47.0720127, 82.9944833],
    [47.0720438, 82.9945545],
    [47.0720818, 82.9945622],
    [47.0721216, 82.9945674],
    [47.0722012, 82.9946235],
    [47.07226, 82.9946389],
    [47.0723067, 82.9946415],
    [47.0723343, 82.9946721],
    [47.0723619, 82.9947585],
    [47.0723809, 82.9947636],
    [47.0723965, 82.9948069],
    [47.072431, 82.9948349],
    [47.0724812, 82.9948528],
    [47.0725244, 82.9949012],
    [47.07254, 82.9949063],
    [47.0725918, 82.9949928],
    [47.0726402, 82.995031],
    [47.0727631, 82.9950997],
    [47.0728132, 82.9951125],
    [47.0728737, 82.9951609],
    [47.0729723, 82.9952297],
    [47.0730034, 82.9952705],
    [47.0730033, 82.995301],
    [47.0730154, 82.9953289],
    [47.0730586, 82.9953824],
    [47.0731173, 82.9954714],
    [47.0731415, 82.9955071],
    [47.0731848, 82.9955148],
    [47.0732211, 82.9955428],
    [47.0732625, 82.9956014],
    [47.0733006, 82.9956345],
    [47.0733458, 82.9956641],
    [47.0733925, 82.9957251],
    [47.0734219, 82.9957252],
    [47.073453, 82.9957634],
    [47.0734529, 82.9958091],
    [47.0734702, 82.9958346],
    [47.0734927, 82.9958423],
    [47.0735118, 82.9958118],
    [47.0735291, 82.9958042],
    [47.0735498, 82.9958297],
    [47.0735792, 82.9958628],
    [47.0736189, 82.9958883],
    [47.0736777, 82.9959342],
    [47.0737313, 82.9959521],
    [47.0737729, 82.9959395],
    [47.0737833, 82.995909],
    [47.0737747, 82.9958658],
    [47.0737453, 82.9958429],
    [47.0737419, 82.9958149],
    [47.0737661, 82.995782],
    [47.0737938, 82.995782],
    [47.0738457, 82.9957898],
    [47.0738654, 82.9958263],
    [47.0738722, 82.9958771],
    [47.0739034, 82.9958899],
    [47.0739293, 82.995862],
    [47.0739675, 82.9958291],
    [47.0740107, 82.9957936],
    [47.0740592, 82.9957836],
    [47.0740938, 82.9958116],
    [47.074111, 82.9958625],
    [47.07413, 82.9959108],
    [47.0741629, 82.995916],
    [47.0741974, 82.9959389],
    [47.0742407, 82.9959289],
    [47.0742788, 82.9958705],
    [47.0742789, 82.9958172],
    [47.0743014, 82.9957715],
    [47.0743447, 82.9957488],
    [47.0744053, 82.9957184],
    [47.0744625, 82.9956779],
    [47.074485, 82.9956424],
    [47.0745196, 82.9956298],
    [47.0745542, 82.9956299],
    [47.0746009, 82.9956706],
    [47.0746406, 82.9957165],
    [47.0746891, 82.9957318],
    [47.0747841, 82.9957905],
    [47.074836, 82.9959079],
    [47.0749068, 82.9960351],
    [47.0749085, 82.9960732],
    [47.0749084, 82.996152],
    [47.0749291, 82.9962257],
    [47.0749619, 82.9962614],
    [47.0749983, 82.9962615],
    [47.0750485, 82.9962286],
    [47.0751056, 82.9962135],
    [47.0751488, 82.9962339],
    [47.075237, 82.9963256],
    [47.0752596, 82.9963866],
    [47.0752873, 82.996435],
    [47.0753392, 82.9964478],
    [47.0753893, 82.9964378],
    [47.0754274, 82.996448],
    [47.0755173, 82.9965245],
    [47.0755847, 82.9965958],
    [47.0756417, 82.9966467],
    [47.0756971, 82.9966596],
    [47.0757853, 82.9966547],
    [47.0758824, 82.9965636],
    [47.0759431, 82.9965773],
    [47.0759673, 82.9966256],
    [47.0760002, 82.9966384],
    [47.0760468, 82.9966995],
    [47.0761108, 82.9967276],
    [47.0761488, 82.9967505],
    [47.0761765, 82.9967278],
    [47.0762059, 82.9967507],
    [47.0762491, 82.9968245],
    [47.0763027, 82.9968373],
    [47.0763356, 82.9968247],
    [47.076365, 82.9968476],
    [47.0763927, 82.9968528],
    [47.0764204, 82.9968071],
    [47.0764395, 82.9967919],
    [47.076474, 82.9968149],
    [47.0765121, 82.9968429],
    [47.0765415, 82.9968405],
    [47.0765709, 82.9967999],
    [47.0765935, 82.9967822],
    [47.0766023, 82.9967952],
    [47.0766368, 82.9968258],
    [47.0766956, 82.9968488],
    [47.0767198, 82.9968971],
    [47.0767647, 82.9969353],
    [47.0768235, 82.9969533],
    [47.0768581, 82.9969508],
    [47.0768841, 82.996961],
    [47.0769083, 82.9969814],
    [47.076936, 82.9969789],
    [47.0769689, 82.9969257],
    [47.0769931, 82.9969257],
    [47.0770364, 82.9969513],
    [47.0770779, 82.9969666],
    [47.0771125, 82.9969616],
    [47.0771419, 82.9969287],
    [47.0771731, 82.9968779],
    [47.0772251, 82.9968501],
    [47.0772701, 82.9968197],
    [47.0772995, 82.99683],
    [47.0773393, 82.9968504],
    [47.0773669, 82.9968505],
    [47.0773929, 82.9968251],
    [47.0774172, 82.9968023],
    [47.0774379, 82.9968125],
    [47.0774482, 82.9968608],
    [47.0774673, 82.9968863],
    [47.0775087, 82.9969093],
    [47.0775552, 82.9969732],
    [47.0775655, 82.9970088],
    [47.0775949, 82.9970318],
    [47.0776312, 82.9970471],
    [47.0776485, 82.9970675],
    [47.0776554, 82.9971005],
    [47.0776796, 82.9971209],
    [47.0777245, 82.9971693],
    [47.0777591, 82.9971948],
    [47.077792, 82.9971949],
    [47.0778196, 82.9972051],
    [47.0778353, 82.9971619],
    [47.0778768, 82.9971798],
    [47.0779131, 82.9972155],
    [47.0779286, 82.9972638],
    [47.0779544, 82.9973452],
    [47.0779873, 82.9973605],
    [47.0780011, 82.9973936],
    [47.0780027, 82.9974672],
    [47.0780356, 82.997508],
    [47.0780424, 82.9975436],
    [47.0780354, 82.9976096],
    [47.0780475, 82.9976554],
    [47.0780492, 82.9977011],
    [47.0780768, 82.9977291],
    [47.0781322, 82.997742],
    [47.0781613, 82.9977561],
    [47.0782149, 82.9977512],
    [47.0782581, 82.9978123],
    [47.078291, 82.9978327],
    [47.0783464, 82.9978049],
    [47.0783931, 82.997772],
    [47.0784398, 82.9977568],
    [47.0785021, 82.997757],
    [47.0785488, 82.9978054],
    [47.0785696, 82.9978054],
    [47.0785938, 82.9977776],
    [47.0786112, 82.997742],
    [47.0786353, 82.9977321],
    [47.0786631, 82.9976814],
    [47.0786908, 82.9976636],
    [47.0787479, 82.9976409],
    [47.0787911, 82.9976537],
    [47.078831, 82.9976259],
    [47.078869, 82.9976184],
    [47.0788864, 82.9976108],
    [47.0789089, 82.9975905],
    [47.0789297, 82.9975575],
    [47.0789695, 82.9975348],
    [47.0790231, 82.9975552],
    [47.0790975, 82.9975885],
    [47.0791459, 82.9975733],
    [47.0791979, 82.9975303],
    [47.0792743, 82.9974522],
    [47.0793315, 82.9974433],
    [47.0793734, 82.9974726],
    [47.0794066, 82.9974305],
    [47.079431, 82.9973916],
    [47.0794642, 82.9973787],
    [47.0794907, 82.9973918],
    [47.0795084, 82.9973691],
    [47.0795328, 82.9973367],
    [47.0795681, 82.9973206],
    [47.0795969, 82.9972914],
    [47.0796698, 82.9973176],
    [47.079703, 82.9972917],
    [47.0797119, 82.9972268],
    [47.0797363, 82.9972106],
    [47.0797628, 82.9972204],
    [47.0798159, 82.9971751],
    [47.0798668, 82.9971363],
    [47.0799221, 82.997104],
    [47.0799575, 82.9970911],
    [47.0800105, 82.9971139],
    [47.0800856, 82.9971174],
    [47.0801254, 82.9971434],
    [47.080174, 82.9971695],
    [47.0802742, 82.997266],
    [47.0803095, 82.997344],
    [47.0803492, 82.9973961],
    [47.0804066, 82.9974189],
    [47.0804839, 82.9974775],
    [47.080506, 82.9975295],
    [47.0805457, 82.9975589],
    [47.0806231, 82.9975623],
    [47.0807181, 82.997569],
    [47.0808286, 82.9975758],
    [47.0809369, 82.9976085],
    [47.0810363, 82.997664],
    [47.0811224, 82.9977681],
    [47.0811908, 82.9978494],
    [47.0812379, 82.9978487],
    [47.0812666, 82.9978552],
    [47.081344, 82.9979577],
    [47.0814101, 82.9979562],
    [47.0814279, 82.9979271],
    [47.0814478, 82.9979304],
    [47.0814983, 82.9981805],
    [47.081538, 82.9982617],
    [47.0815778, 82.9982813],
    [47.0816064, 82.9983787],
    [47.0816328, 82.9984243],
    [47.081666, 82.9984373],
    [47.0817145, 82.9985186],
    [47.0817675, 82.9985707],
    [47.0818006, 82.9986389],
    [47.0818227, 82.9986455],
    [47.0818382, 82.9986293],
    [47.0818691, 82.9986456],
    [47.0819045, 82.9986652],
    [47.081889, 82.9986197],
    [47.0819288, 82.9986198],
    [47.0819487, 82.9986523],
    [47.082015, 82.9986622],
    [47.0820525, 82.998711],
    [47.0820923, 82.9987273],
    [47.0821276, 82.9987534],
    [47.082163, 82.998747],
    [47.0821873, 82.9987633],
    [47.0822116, 82.9987763],
    [47.0822403, 82.9987634],
    [47.0822536, 82.998744],
    [47.082342, 82.9987312],
    [47.0823841, 82.9986924],
    [47.0824239, 82.998686],
    [47.0824717, 82.9986784],
    [47.0825027, 82.998646],
    [47.0825314, 82.9986461],
    [47.0825536, 82.9986202],
    [47.0825735, 82.9986267],
    [47.082611, 82.9986658],
    [47.0826419, 82.9986626],
    [47.0826795, 82.9986465],
    [47.0826972, 82.99864],
    [47.0827348, 82.9986109],
    [47.0827548, 82.9986012],
    [47.0827724, 82.9986045],
    [47.082799, 82.9985916],
    [47.0828277, 82.9985916],
    [47.0828409, 82.9986274],
    [47.0828608, 82.9986274],
    [47.0829316, 82.9986114],
    [47.0829603, 82.9986407],
    [47.0829779, 82.9986732],
    [47.0830088, 82.998696],
    [47.0830354, 82.9986993],
    [47.0830773, 82.9987384],
    [47.0831105, 82.9987417],
    [47.0831502, 82.9988002],
    [47.0831855, 82.9988101],
    [47.0832584, 82.9988492],
    [47.0832894, 82.9988753],
    [47.0833601, 82.9988787],
    [47.0833998, 82.9989177],
    [47.0834263, 82.9989308],
    [47.0834617, 82.9989179],
    [47.0834971, 82.9989407],
    [47.0835362, 82.9989803],
    [47.0835826, 82.9990097],
    [47.0835981, 82.999],
    [47.0836114, 82.998974],
    [47.0836313, 82.9989838],
    [47.0836468, 82.9989936],
    [47.0836622, 82.9989807],
    [47.0836733, 82.998958],
    [47.0836888, 82.9989548],
    [47.0837197, 82.9989743],
    [47.0837374, 82.9989646],
    [47.0837375, 82.9989354],
    [47.0837353, 82.9989029],
    [47.083784, 82.9988414],
    [47.083837, 82.9988805],
    [47.0838481, 82.9988643],
    [47.0838614, 82.9988383],
    [47.0838856, 82.9988579],
    [47.083921, 82.9988937],
    [47.0839741, 82.9988581],
    [47.0840139, 82.9988582],
    [47.0840537, 82.9987966],
    [47.084162, 82.9988131],
    [47.0841796, 82.9988521],
    [47.0842128, 82.9988327],
    [47.0842349, 82.998849],
    [47.0842681, 82.9988296],
    [47.0842947, 82.9987908],
    [47.0843123, 82.9987908],
    [47.0843566, 82.9987422],
    [47.0843898, 82.9987423],
    [47.0844472, 82.9987879],
    [47.0844583, 82.9987749],
    [47.084487, 82.9987425],
    [47.0845158, 82.9987004],
    [47.0845423, 82.9986972],
    [47.0845711, 82.9987265],
    [47.0846042, 82.9987591],
    [47.0846572, 82.9987527],
    [47.0846904, 82.998769],
    [47.0847499, 82.9988725],
    [47.0847786, 82.9988791],
    [47.0848008, 82.9988434],
    [47.0849245, 82.9988405],
    [47.0849996, 82.9989543],
    [47.0850283, 82.9989349],
    [47.0850284, 82.998857],
    [47.0850152, 82.9988018],
    [47.0850771, 82.9987922],
    [47.0851124, 82.998854],
    [47.0851566, 82.9988671],
    [47.0852295, 82.9989192],
    [47.0852781, 82.9989258],
    [47.0853091, 82.9988934],
    [47.0853555, 82.9988773],
    [47.0854174, 82.9988742],
    [47.0854684, 82.9988094],
    [47.0855192, 82.9988096],
    [47.0855723, 82.998748],
    [47.0856962, 82.9987094],
    [47.0857536, 82.9987452],
    [47.0857912, 82.9987031],
    [47.0858201, 82.9986817],
    [47.0859018, 82.9987241],
    [47.086019, 82.9986855],
    [47.086103, 82.9987247],
    [47.0861339, 82.9986955],
    [47.0861781, 82.9987216],
    [47.0861825, 82.9987378],
    [47.0862289, 82.9987867],
    [47.086293, 82.9987836],
    [47.0863614, 82.9988454],
    [47.0863746, 82.9988942],
    [47.0864211, 82.9989073],
    [47.0864365, 82.9989073],
    [47.0864498, 82.9989073],
    [47.0865404, 82.9989141],
    [47.0866243, 82.9989987],
    [47.0867275, 82.9990174],
    [47.0868336, 82.9990339],
    [47.0869308, 82.9991121],
    [47.0869352, 82.9991154],
    [47.0869838, 82.9991285],
    [47.0870148, 82.9991123],
    [47.0870281, 82.9990572],
    [47.0870602, 82.9990569],
    [47.0870889, 82.9990862],
    [47.0871309, 82.9991318],
    [47.0872038, 82.9991807],
    [47.0872568, 82.9992035],
    [47.0873031, 82.9992588],
    [47.0874203, 82.9992527],
    [47.0874845, 82.9991879],
    [47.0875332, 82.9990906],
    [47.0875884, 82.9991427],
    [47.0876632, 82.999144],
    [47.0877871, 82.9990729],
    [47.0878403, 82.998953],
    [47.0878868, 82.9988914],
    [47.0879399, 82.9988234],
    [47.0880527, 82.9987652],
    [47.0881102, 82.9987816],
    [47.0882383, 82.9988306],
    [47.0882957, 82.9989054],
    [47.0883412, 82.9989297],
    [47.0883876, 82.9989428],
    [47.0885178, 82.9991152],
    [47.0885797, 82.9991446],
    [47.0886239, 82.999122],
    [47.0887012, 82.9991514],
    [47.0887807, 82.9992101],
    [47.088885, 82.9993046],
    [47.0889712, 82.9993763],
    [47.0890308, 82.9994219],
    [47.0890728, 82.9994447],
    [47.0891103, 82.9994448],
    [47.0891966, 82.9994191],
    [47.0893558, 82.9993675],
    [47.0894553, 82.9993094],
    [47.089504, 82.9992575],
    [47.0896232, 82.9991242],
    [47.0897139, 82.9990238],
    [47.089767, 82.9990175],
    [47.0898421, 82.9990534],
    [47.0898863, 82.9990989],
    [47.0898972, 82.9991866],
    [47.0899259, 82.9992224],
    [47.0899635, 82.9992322],
    [47.0900121, 82.9992551],
    [47.0900607, 82.9992974],
    [47.0900806, 82.9992942],
    [47.0901115, 82.9992878],
    [47.0901667, 82.9993204],
    [47.0901932, 82.9993497],
    [47.0902201, 82.9993173],
    [47.0903416, 82.9993306],
    [47.0903859, 82.9993014],
    [47.090419, 82.9993308],
    [47.0904587, 82.9994185],
    [47.0905072, 82.9994803],
    [47.0905536, 82.9995129],
    [47.0906066, 82.999552],
    [47.0906927, 82.9996302],
    [47.090728, 82.9997276],
    [47.0908045, 82.9998636],
    [47.0909127, 82.9999776],
    [47.0909568, 83.0000491],
    [47.0910187, 83.000098],
    [47.0910739, 83.0001695],
    [47.0911268, 83.0002963],
    [47.091204, 83.0004004],
    [47.0912614, 83.0004557],
    [47.0913364, 83.0005858],
    [47.0914146, 83.0006929],
    [47.0914742, 83.000745],
    [47.091556, 83.0007582],
    [47.091629, 83.0007356],
    [47.0917131, 83.0006417],
    [47.0917619, 83.0005314],
    [47.0918532, 83.0004144],
    [47.0919019, 83.0003074],
    [47.0919551, 83.0002232],
    [47.0919927, 83.0002103],
    [47.092059, 83.0002039],
    [47.0921253, 83.0001879],
    [47.0921606, 83.0002237],
    [47.0922546, 83.0002804],
    [47.0923253, 83.000339],
    [47.0924159, 83.0003458],
    [47.0924733, 83.0003719],
    [47.0925109, 83.0004077],
    [47.0925307, 83.0004857],
    [47.0925969, 83.0005897],
    [47.0926587, 83.0006711],
    [47.092716, 83.0007686],
    [47.0927778, 83.000824],
    [47.0927977, 83.000876],
    [47.0928662, 83.0009119],
    [47.0929457, 83.0009316],
    [47.0930076, 83.0009447],
    [47.0930584, 83.0009611],
    [47.0931285, 83.001009],
    [47.0931727, 83.0009896],
    [47.0932325, 83.0009378],
    [47.0933187, 83.0009283],
    [47.0934071, 83.0009545],
    [47.0934933, 83.0009677],
    [47.0935463, 83.0009776],
    [47.0936148, 83.000981],
    [47.093695, 83.001024],
    [47.0938143, 83.0010373],
    [47.093958, 83.0010441],
    [47.0940066, 83.0010702],
    [47.0940574, 83.0011028],
    [47.0941303, 83.001103],
    [47.0941812, 83.0011031],
    [47.0942453, 83.0010806],
    [47.0943049, 83.0011197],
    [47.0943713, 83.0011134],
    [47.094469, 83.0011539],
    [47.0945685, 83.0011866],
    [47.0945751, 83.0011899],
    [47.0946612, 83.001281],
    [47.0947694, 83.0013592],
    [47.0948643, 83.0014699],
    [47.0949527, 83.0015285],
    [47.0949813, 83.0015806],
    [47.0951316, 83.0016654],
    [47.0952, 83.0017045],
    [47.0953326, 83.0017471],
    [47.095463, 83.0018059],
    [47.0955027, 83.0018644],
    [47.0956457, 83.0019805],
    [47.0957874, 83.0021127],
    [47.096001, 83.0023031],
    [47.0961369, 83.0023885],
    [47.0962325, 83.0024143],
    [47.0964496, 83.0024361],
    [47.0965655, 83.0023726],
    [47.096722, 83.0022795],
    [47.0969652, 83.0022333],
    [47.0970956, 83.0021486],
    [47.0971623, 83.0021233],
    [47.0972549, 83.0020937],
    [47.0973534, 83.002094],
    [47.0974492, 83.002149],
    [47.0975968, 83.0021877],
    [47.0976432, 83.0021325],
    [47.0977909, 83.0021499],
    [47.0978574, 83.0022352],
    [47.0979268, 83.0022566],
    [47.0980311, 83.0022229],
    [47.098089, 83.0022315],
    [47.0981323, 83.0023082],
    [47.0982567, 83.0024191],
    [47.0982683, 83.0024234],
    [47.0983985, 83.0025258],
    [47.0984679, 83.0025643],
    [47.0986417, 83.0025052],
    [47.098691, 83.0024415],
    [47.0987864, 83.0023121],
    [47.0989168, 83.0022231],
    [47.0991166, 83.0022662],
    [47.0992323, 83.0023601],
    [47.099345, 83.002539],
    [47.099504, 83.0027181],
    [47.0996631, 83.0028249],
    [47.0997469, 83.0029612],
    [47.0998569, 83.0030508],
    [47.0999003, 83.0030552],
    [47.1000044, 83.0031618],
    [47.1000507, 83.0031747],
    [47.1000912, 83.003162],
    [47.1001867, 83.0032176],
    [47.100298, 83.0032275],
    [47.1003907, 83.003164],
    [47.1004168, 83.0031257],
    [47.1004864, 83.0030579],
    [47.1005588, 83.0030496],
    [47.1007151, 83.0030585],
    [47.100747, 83.0030203],
    [47.100805, 83.0029439],
    [47.1008948, 83.0028973],
    [47.1010222, 83.0029019],
    [47.1012509, 83.0029876],
    [47.1013116, 83.0030303],
    [47.1014015, 83.0029327],
    [47.1015925, 83.0030055],
    [47.1017923, 83.0031646],
    [47.1018098, 83.0033288],
    [47.1017977, 83.0035794],
    [47.1018328, 83.0037782],
    [47.1018207, 83.0039812],
    [47.1018734, 83.0041758],
    [47.1019381, 83.0042235],
    [47.1020231, 83.0044052],
    [47.1021113, 83.00444],
    [47.1022464, 83.0046132],
    [47.1023198, 83.004717],
    [47.1024814, 83.0048255],
    [47.1026252, 83.0050808],
    [47.102681, 83.0051328],
    [47.1027548, 83.005279],
    [47.1028223, 83.0053656],
    [47.102887, 83.0054133],
    [47.1029192, 83.0055128],
    [47.1029396, 83.0056684],
    [47.1029895, 83.0057592],
    [47.1030393, 83.0058631],
    [47.1031216, 83.0059238],
    [47.1032392, 83.0059673],
    [47.1033332, 83.006041],
    [47.1034214, 83.0060542],
    [47.1035156, 83.0060199],
    [47.1036393, 83.0058258],
    [47.1036983, 83.0057395],
    [47.1037571, 83.0057008],
    [47.1038013, 83.0056621],
    [47.1038925, 83.0056537],
    [47.1040601, 83.0056584],
    [47.104222, 83.0057385],
    [47.1043555, 83.0058947],
    [47.1044292, 83.0060101],
    [47.1046365, 83.0061393],
    [47.1048026, 83.0060585],
    [47.1049318, 83.0059979],
    [47.1050978, 83.0060119],
    [47.1055171, 83.0062637],
    [47.1059226, 83.006529],
    [47.1062553, 83.0067156],
    [47.1066005, 83.0069705],
    [47.1069459, 83.0071769],
    [47.1072337, 83.0073349],
    [47.1073487, 83.0074319],
    [47.1075461, 83.0074929],
    [47.1077927, 83.0077233],
    [47.1079158, 83.0080137],
    [47.1080387, 83.0083889],
    [47.1081782, 83.0086794],
    [47.1084163, 83.0090669],
    [47.1086055, 83.0091158],
    [47.1088855, 83.0089836],
    [47.1092644, 83.0086703],
    [47.1094455, 83.0085632],
    [47.1098789, 83.0085644],
    [47.1103124, 83.0085166],
    [47.1106957, 83.0086646],
    [47.1109959, 83.0085675],
    [47.1120297, 83.0083743],
    [47.1122965, 83.0083506],
    [47.1126797, 83.0085231],
    [47.1134794, 83.0089662],
    [47.1138788, 83.0094573],
    [47.1142283, 83.0099482],
    [47.1144271, 83.0104064],
    [47.1153995, 83.009391],
    [47.1159651, 83.009284],
    [47.1166993, 83.0083425],
    [47.1174956, 83.0069162],
    [47.1189634, 83.0049561],
    [47.1197596, 83.0036439],
    [47.120357, 83.0031759],
    [47.1208494, 83.0031396],
    [47.1218477, 83.0040724],
    [47.1231664, 83.0053262],
    [47.1247739, 83.006799],
    [47.1262495, 83.0079699],
    [47.1270634, 83.0089885],
    [47.1276419, 83.010216],
    [47.127832, 83.0110269],
    [47.1280667, 83.0122228],
    [47.1283741, 83.0129664],
    [47.1292337, 83.0147482],
    [47.1298333, 83.0167945],
    [47.1300774, 83.0174724],
    [47.1303864, 83.0181066],
    [47.1311045, 83.0188526],
    [47.1317379, 83.0193094],
    [47.132173, 83.0194167],
    [47.1328519, 83.0195744],
    [47.1335604, 83.0193207],
    [47.1342453, 83.0188261],
    [47.134672, 83.0183122],
    [47.1349587, 83.0180454],
    [47.1355273, 83.0186132],
    [47.1361489, 83.0196466],
    [47.1372349, 83.0196601],
    [47.1384259, 83.0193198],
    [47.139006, 83.0191843],
    [47.139691, 83.0193463],
    [47.1398947, 83.0192147],
    [47.1403276, 83.0188348],
    [47.1407925, 83.0187008],
    [47.1411698, 83.0187397],
    [47.141724, 83.0186895],
    [47.1419287, 83.0184105],
    [47.1421858, 83.0175697],
    [47.1423812, 83.0155027],
    [47.142502, 83.0145965],
    [47.142579, 83.0130922],
    [47.142741, 83.0125127],
    [47.1429597, 83.0114314],
    [47.1439915, 83.0116006],
    [47.1451288, 83.0116066],
    [47.1457598, 83.0119551],
    [47.1471588, 83.01229],
    [47.1474166, 83.0132488],
    [47.1478107, 83.0137915],
    [47.1482272, 83.0146786],
    [47.1487375, 83.0155378],
    [47.1495153, 83.0164686],
    [47.1502359, 83.0169602],
    [47.1507026, 83.017348],
    [47.1513426, 83.018344],
    [47.1519299, 83.0196282],
    [47.1523274, 83.0202717],
    [47.1527696, 83.0210387],
    [47.153133, 83.0213581],
    [47.1536569, 83.0217687],
    [47.1540298, 83.0220953],
    [47.1545531, 83.0224569],
    [47.1549626, 83.0226884],
    [47.1552983, 83.0231931],
    [47.1556277, 83.0238329],
    [47.1559413, 83.0240455],
    [47.1563168, 83.0241363],
    [47.1565054, 83.0243818],
    [47.1568287, 83.0244406],
    [47.1573972, 83.0243135],
    [47.1580247, 83.0239344],
    [47.1583862, 83.0236354],
    [47.1592103, 83.0233157],
    [47.15945, 83.0232787],
    [47.1597922, 83.0234082],
    [47.1602578, 83.0237086],
    [47.1608399, 83.0248188],
    [47.1610828, 83.0254457],
    [47.1611631, 83.0260847],
    [47.1611709, 83.0264585],
    [47.161253, 83.0266341],
    [47.1613576, 83.0268156],
    [47.1614265, 83.0271798],
    [47.1616597, 83.0275602],
    [47.1618611, 83.0279322],
    [47.1619905, 83.0280638],
    [47.1623729, 83.0280599],
    [47.1629085, 83.0278954],
    [47.1636588, 83.0275869],
    [47.1641648, 83.0271648],
    [47.1647959, 83.0264775],
    [47.1650769, 83.0262181],
    [47.1653669, 83.0262435],
    [47.1666652, 83.0267625],
    [47.1673834, 83.0269729],
    [47.1683868, 83.0268107],
    [47.1693018, 83.0262785],
    [47.1697504, 83.0260193],
    [47.1701241, 83.0261912],
    [47.1711041, 83.027044],
    [47.1714029, 83.0273875],
    [47.1717108, 83.0280769],
    [47.1719993, 83.0289479],
    [47.1726703, 83.0293825],
    [47.1739283, 83.0296442],
    [47.1750066, 83.0294717],
    [47.1771588, 83.0286821],
    [47.177924, 83.0282276],
    [47.1789031, 83.0270564],
    [47.1800324, 83.0270512],
    [47.1811538, 83.0272143],
    [47.1819878, 83.0271004],
    [47.182737, 83.0267085],
    [47.1831857, 83.0264727],
    [47.183839, 83.0258871],
    [47.1845441, 83.0256843],
    [47.185377, 83.0250754],
    [47.1864282, 83.0244108],
    [47.187038, 83.0240358],
    [47.187412, 83.024722],
    [47.1884516, 83.0253533],
    [47.1895767, 83.0257971],
    [47.1905978, 83.0272432],
    [47.1913511, 83.0278954],
    [47.1920825, 83.0281811],
    [47.1931161, 83.0282483],
    [47.1942805, 83.0280206],
    [47.1952819, 83.027652],
    [47.1965932, 83.0272266],
    [47.1982814, 83.026598],
    [47.1991573, 83.026193],
    [47.1996746, 83.025337],
    [47.1999371, 83.0251124],
    [47.2017024, 83.0251501],
    [47.2026024, 83.0262213],
    [47.2040869, 83.0273234],
    [47.2049358, 83.0276255],
    [47.20679, 83.0278989],
    [47.2084503, 83.0275722],
    [47.2098094, 83.0266744],
    [47.2112136, 83.0260091],
    [47.2122025, 83.0258746],
    [47.21309, 83.025077],
    [47.2136265, 83.0241394],
    [47.2140624, 83.0236854],
    [47.21461, 83.0233734],
    [47.2151395, 83.0235926],
    [47.2154747, 83.0252922],
    [47.215869, 83.0267301],
    [47.2161912, 83.0274253],
    [47.216751, 83.0279625],
    [47.2175309, 83.0281812],
    [47.232991, 82.9194341],
    [47.1962582, 82.8624489],
    [47.1492465, 82.838951],
    [47.1597282, 82.7693257],
    [47.1449936, 82.7272187],
    [47.2008963, 82.6071538],
    [47.211166, 82.482795],
    [47.2379915, 82.4437785],
    [47.2359494, 82.3456275],
    [47.1898607, 82.2319488],
    [47.2456562, 82.1125631],
    [47.273628, 82.122491],
    [47.278241, 82.0636597],
    [47.311366, 82.0391225],
    [47.347668, 81.9372247],
    [47.355098, 81.8313748],
    [47.4481574, 81.827735],
    [47.4807809, 81.6920466],
    [47.3810163, 81.6300268],
    [47.4642709, 81.4667814],
    [47.459095, 81.4170826],
    [47.491069, 81.4280247],
    [47.504174, 81.3965613],
    [47.4912604, 81.3143164],
    [47.4547304, 81.2778707],
    [47.4558518, 81.1874164],
    [47.4995542, 81.0672923],
    [47.514576, 80.942521],
    [47.4822236, 80.9068138],
    [47.4669352, 80.8143606],
    [47.522521, 80.807954],
    [47.5777969, 80.7761532],
    [47.5449751, 80.6888737],
    [47.5701686, 80.4979254],
    [47.5950477, 80.499947],
    [47.6220555, 80.3790206],
    [47.7011187, 80.3282005],
    [47.6979826, 80.2368162],
    [47.616214, 80.2221751],
    [47.6000367, 80.2745975],
    [47.5533418, 80.2781598],
    [47.5104325, 80.1995843],
    [47.4370261, 80.0987006],
    [47.3864794, 80.0123989],
    [47.3317711, 80.0135202],
    [47.2681057, 79.9298714],
    [47.2349237, 79.956886],
    [47.1971516, 80.0454258],
    [47.1201237, 80.0266799],
    [47.0980525, 80.0636133],
    [47.0367461, 80.0814818],
    [46.8938944, 80.0539703],
  ],
});
mapRegions.push({
  id: 2,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Тарбагатайский район",
  centerTitle: "село Аксуат",
  centerTitleShort: "с. Аксуат",
  centerGeometry: [47.7509818, 82.8073024],
  geometry: [
    [47.2175309, 83.0281812],
    [47.232991, 82.9194341],
    [47.1962582, 82.8624489],
    [47.1492465, 82.838951],
    [47.1597282, 82.7693257],
    [47.1449936, 82.7272187],
    [47.2008963, 82.6071538],
    [47.211166, 82.482795],
    [47.2379915, 82.4437785],
    [47.2359494, 82.3456275],
    [47.1898607, 82.2319488],
    [47.2456562, 82.1125631],
    [47.273628, 82.122491],
    [47.278241, 82.0636597],
    [47.311366, 82.0391225],
    [47.347668, 81.9372247],
    [47.355098, 81.8313748],
    [47.4481574, 81.827735],
    [47.4807809, 81.6920466],
    [47.5118137, 81.7113806],
    [47.6028492, 81.6646811],
    [47.663636, 81.6797097],
    [47.6799045, 81.6552044],
    [47.6893127, 81.5922023],
    [47.7997483, 81.5278691],
    [47.8670694, 81.6114655],
    [47.8707342, 81.6430065],
    [47.9336429, 81.671172],
    [47.9745557, 81.5944905],
    [48.048149, 81.6629717],
    [48.094085, 81.6365656],
    [48.1461518, 81.4297181],
    [48.2783576, 81.5551982],
    [48.3565821, 81.5094674],
    [48.5097013, 81.6067591],
    [48.5101207, 81.6315607],
    [48.4830924, 81.671204],
    [48.4963114, 81.6945104],
    [48.4831471, 81.8074347],
    [48.4833682, 82.1012655],
    [48.4668125, 82.1264271],
    [48.4247739, 82.3631199],
    [48.3197331, 82.4171605],
    [48.1705021, 82.4345549],
    [48.1579213, 82.5119178],
    [48.1686925, 82.5987848],
    [48.1511614, 82.7768788],
    [48.1812323, 83.0086737],
    [48.1810112, 83.2111042],
    [48.1490623, 83.3092803],
    [47.9969436, 83.7502976],
    [47.8132544, 84.1977694],
    [47.7951695, 84.2018901],
    [47.7229277, 84.2181244],
    [47.6157878, 84.3104636],
    [47.5381969, 84.2605368],
    [47.4745361, 84.2788611],
    [47.3786005, 84.2558372],
    [47.2799184, 84.2667611],
    [47.2056346, 84.2100517],
    [47.1730794, 84.2655486],
    [47.0245778, 84.4184878],
    [46.999939, 84.3699265],
    [47.0000267, 84.3673248],
    [47.0002098, 84.3620682],
    [47.0003891, 84.3568192],
    [47.0003777, 84.3543701],
    [47.0003471, 84.3491058],
    [47.0007362, 84.3438873],
    [47.0011253, 84.3386536],
    [47.0011139, 84.3384552],
    [47.0008163, 84.3332138],
    [47.000515, 84.3279724],
    [47.0004501, 84.3261032],
    [47.0002632, 84.3208542],
    [47.0000725, 84.3156052],
    [47, 84.3149948],
    [46.999382, 84.3098145],
    [46.9987679, 84.3046341],
    [46.9984131, 84.3005295],
    [46.9979706, 84.295311],
    [46.9988174, 84.2902298],
    [46.9996681, 84.2851257],
    [47.0002441, 84.2807999],
    [47.0009308, 84.2756424],
    [47.0011063, 84.2737579],
    [47.0015907, 84.2685471],
    [47.001503, 84.2676468],
    [47.0009995, 84.2624359],
    [47.0004921, 84.2572327],
    [47.0010071, 84.2538834],
    [47.0017891, 84.2487488],
    [47.0018044, 84.2486877],
    [47.002758, 84.2436142],
    [47.0037117, 84.2385406],
    [47.0036469, 84.2373428],
    [47.0011836, 84.2325636],
    [47.0008705, 84.2320286],
    [47.0008187, 84.231851],
    [47.0006882, 84.2313047],
    [47.0006104, 84.2310499],
    [47.0005874, 84.2307961],
    [47.0006072, 84.2305944],
    [47.0006228, 84.2302869],
    [47.0006747, 84.2300335],
    [47.0007434, 84.2299215],
    [47.0007425, 84.2297922],
    [47.000648, 84.2295919],
    [47.0004413, 84.2292622],
    [47.0001624, 84.2289386],
    [46.9999083, 84.2286325],
    [46.9997953, 84.2284269],
    [46.9996453, 84.2282721],
    [46.9996536, 84.2278007],
    [46.9996314, 84.227598],
    [46.9994669, 84.2273619],
    [46.9987674, 84.2271764],
    [46.9982818, 84.2269268],
    [46.9976855, 84.226456],
    [46.9972031, 84.2262045],
    [46.9972057, 84.2258085],
    [46.9971937, 84.225268],
    [46.9971921, 84.2249982],
    [46.9972344, 84.2248081],
    [46.9973269, 84.2247429],
    [46.9975071, 84.2246269],
    [46.9978126, 84.2242115],
    [46.9979004, 84.2239628],
    [46.9978686, 84.223659],
    [46.997716, 84.223272],
    [46.9976064, 84.2229642],
    [46.997623, 84.2228549],
    [46.9976835, 84.222644],
    [46.9978902, 84.2223589],
    [46.9980285, 84.2219285],
    [46.9981318, 84.2215587],
    [46.9982862, 84.221352],
    [46.9985537, 84.2206616],
    [46.9984742, 84.2200509],
    [46.9985324, 84.2194398],
    [46.9986704, 84.2191105],
    [46.9987841, 84.2187254],
    [46.9989892, 84.218243],
    [46.9990537, 84.2179346],
    [46.9993354, 84.2170969],
    [46.9995228, 84.2166324],
    [46.9997667, 84.2164525],
    [46.9999086, 84.2162747],
    [47.0000528, 84.2158784],
    [47.0003132, 84.2156207],
    [47.0004104, 84.2150529],
    [47.0006939, 84.2146364],
    [47.0008197, 84.2142274],
    [47.0008531, 84.2136852],
    [47.0007738, 84.2127215],
    [47.0004999, 84.2118537],
    [47.0004204, 84.2113462],
    [47.0005103, 84.2108816],
    [47.0008364, 84.2105507],
    [47.0011507, 84.2103487],
    [47.0014136, 84.2099489],
    [47.0013853, 84.2089591],
    [47.0012303, 84.2085789],
    [47.0009586, 84.2079397],
    [47.0009268, 84.2077198],
    [47.0007415, 84.2074975],
    [47.000642, 84.2070366],
    [47.0003293, 84.2068589],
    [47.0001978, 84.2066832],
    [47.0001065, 84.2065662],
    [47.0002087, 84.2062854],
    [47.0003958, 84.2060455],
    [47.0005941, 84.205803],
    [47.0008134, 84.2053424],
    [47.000794, 84.2050503],
    [47.0007612, 84.2048034],
    [47.0007906, 84.2044545],
    [47.0009034, 84.2042787],
    [47.0009516, 84.2041986],
    [47.0009985, 84.2040978],
    [47.0010469, 84.2039893],
    [47.0013453, 84.203375],
    [47.0016968, 84.2029],
    [47.0017421, 84.2026621],
    [47.0017772, 84.202146],
    [47.0020427, 84.2015636],
    [47.0022784, 84.2011446],
    [47.0020532, 84.2007445],
    [47.0015391, 84.1999143],
    [47.0014045, 84.1996147],
    [47.001301, 84.1990163],
    [47.0013018, 84.1981861],
    [47.0012244, 84.1978649],
    [47.0007969, 84.1974418],
    [47.0005713, 84.1973069],
    [47.0003573, 84.1972609],
    [47.0002276, 84.1972956],
    [47.0000359, 84.1972539],
    [46.9998806, 84.1970485],
    [46.9996341, 84.1969407],
    [46.9995294, 84.1968484],
    [46.9992024, 84.1961564],
    [46.9990448, 84.1959261],
    [46.9990208, 84.1958797],
    [46.9990106, 84.1958143],
    [46.9989947, 84.1957393],
    [46.9989668, 84.1956379],
    [46.9989678, 84.1952266],
    [46.9990078, 84.1949115],
    [46.999014, 84.1947201],
    [46.9989922, 84.1944008],
    [46.9989672, 84.1940792],
    [46.9990106, 84.1935295],
    [46.999024, 84.1932153],
    [46.9988244, 84.1928679],
    [46.9986576, 84.1925922],
    [46.9986485, 84.1923966],
    [46.9987382, 84.1919772],
    [46.9988025, 84.1915901],
    [46.9988646, 84.1912854],
    [46.9991055, 84.1908163],
    [46.9992709, 84.1903742],
    [46.9994942, 84.189643],
    [46.9996471, 84.1892501],
    [46.9998462, 84.1889941],
    [46.9999143, 84.1884767],
    [46.9998642, 84.188228],
    [46.9997764, 84.1880297],
    [46.999574, 84.1878458],
    [46.9993181, 84.1876335],
    [46.9988991, 84.1874752],
    [46.9987359, 84.187414],
    [46.9986096, 84.1873643],
    [46.9984672, 84.1872949],
    [46.9982742, 84.1871869],
    [46.9981639, 84.1871132],
    [46.9976955, 84.1869673],
    [46.9973698, 84.1869506],
    [46.9973833, 84.1866647],
    [46.9974624, 84.1864809],
    [46.9974557, 84.1863498],
    [46.9975024, 84.1859877],
    [46.9974459, 84.1858003],
    [46.9973743, 84.1855192],
    [46.9973695, 84.1848742],
    [46.9974222, 84.1842539],
    [46.9976185, 84.1838898],
    [46.9976568, 84.1836879],
    [46.9976424, 84.1832354],
    [46.9976256, 84.1829213],
    [46.9976066, 84.1825118],
    [46.9976617, 84.1821881],
    [46.9977117, 84.1819041],
    [46.9977694, 84.1817629],
    [46.9978174, 84.1811872],
    [46.9979174, 84.1808693],
    [46.9979346, 84.1806734],
    [46.9978683, 84.1804582],
    [46.9976874, 84.1801637],
    [46.9976817, 84.1801371],
    [46.9976573, 84.1801104],
    [46.9976306, 84.1800871],
    [46.9975907, 84.1800579],
    [46.9975554, 84.1799971],
    [46.9973563, 84.1794494],
    [46.9973222, 84.178946],
    [46.9975329, 84.1780136],
    [46.9974572, 84.1778055],
    [46.997093, 84.1774363],
    [46.9968987, 84.1774422],
    [46.996405, 84.1777565],
    [46.995885, 84.1776678],
    [46.9955053, 84.1773278],
    [46.9951495, 84.177381],
    [46.9947418, 84.1774187],
    [46.9942456, 84.1773751],
    [46.9934652, 84.1772794],
    [46.9931882, 84.1774021],
    [46.9928252, 84.1767699],
    [46.9926167, 84.1759854],
    [46.9927496, 84.1755783],
    [46.9929795, 84.1754066],
    [46.9932087, 84.1753514],
    [46.9934022, 84.175121],
    [46.9936252, 84.1747587],
    [46.9937846, 84.1742846],
    [46.9938799, 84.173712],
    [46.9939474, 84.1733618],
    [46.9941857, 84.1726925],
    [46.9941605, 84.1720042],
    [46.9945636, 84.1707545],
    [46.9949035, 84.1701476],
    [46.9950621, 84.1696833],
    [46.9948084, 84.1694938],
    [46.9949039, 84.1690433],
    [46.9947503, 84.1684788],
    [46.9945926, 84.1681],
    [46.9944724, 84.1676632],
    [46.9941926, 84.1673521],
    [46.9938543, 84.1673264],
    [46.9938192, 84.1668528],
    [46.9939056, 84.1664167],
    [46.9942571, 84.1660197],
    [46.9942883, 84.1657155],
    [46.9938869, 84.1654392],
    [46.9934269, 84.1653943],
    [46.9930591, 84.1652306],
    [46.9925227, 84.165142],
    [46.9921292, 84.1653092],
    [46.9918776, 84.1653227],
    [46.9916618, 84.1653511],
    [46.9909495, 84.1648133],
    [46.9907725, 84.1646627],
    [46.9905481, 84.1646777],
    [46.9902705, 84.1642229],
    [46.9901224, 84.1640233],
    [46.9898945, 84.1638512],
    [46.989709, 84.1635549],
    [46.989671, 84.1633469],
    [46.9896609, 84.1620012],
    [46.9890415, 84.1610306],
    [46.9888953, 84.1604316],
    [46.9890159, 84.1599737],
    [46.9889804, 84.1598504],
    [46.9888169, 84.1596071],
    [46.9882778, 84.1592673],
    [46.9878761, 84.1594711],
    [46.9874908, 84.1593495],
    [46.9873855, 84.1595181],
    [46.9869585, 84.1597944],
    [46.9867974, 84.1597994],
    [46.9863777, 84.1592757],
    [46.9860744, 84.1589499],
    [46.9854948, 84.159098],
    [46.9849006, 84.1587486],
    [46.9846101, 84.1584414],
    [46.9841836, 84.1582861],
    [46.9836094, 84.1584086],
    [46.9833005, 84.1585937],
    [46.9831248, 84.158615],
    [46.9830567, 84.1585992],
    [46.9827129, 84.1581482],
    [46.982407, 84.158211],
    [46.9819201, 84.1581632],
    [46.9813884, 84.1582854],
    [46.9810556, 84.1583065],
    [46.980858, 84.1583457],
    [46.9808052, 84.1579664],
    [46.9808982, 84.1577004],
    [46.9809723, 84.1574416],
    [46.9807472, 84.1569776],
    [46.9808389, 84.1559582],
    [46.98093, 84.155511],
    [46.9811185, 84.1553128],
    [46.9809775, 84.1545217],
    [46.9807431, 84.1537758],
    [46.9805682, 84.1531955],
    [46.9804628, 84.1526737],
    [46.9804526, 84.152395],
    [46.9803763, 84.1521582],
    [46.9799472, 84.1518769],
    [46.9796531, 84.1518814],
    [46.9789256, 84.1517332],
    [46.9785232, 84.151545],
    [46.9784008, 84.1514145],
    [46.9783045, 84.1511846],
    [46.9783209, 84.1508552],
    [46.9783711, 84.1506296],
    [46.978428, 84.1501307],
    [46.9785929, 84.1498534],
    [46.978679, 84.1495913],
    [46.9787304, 84.1488849],
    [46.978683, 84.1486805],
    [46.9783463, 84.1483254],
    [46.9780185, 84.1480439],
    [46.9777898, 84.1476619],
    [46.9774474, 84.1471754],
    [46.9768905, 84.1470191],
    [46.9766248, 84.1468551],
    [46.9763898, 84.146641],
    [46.9763126, 84.1464327],
    [46.9762159, 84.1457064],
    [46.9758564, 84.1445032],
    [46.9751994, 84.1440395],
    [46.9750785, 84.143821],
    [46.9748183, 84.1436116],
    [46.9746972, 84.1434115],
    [46.9745141, 84.1429483],
    [46.974416, 84.1427842],
    [46.9740893, 84.1425951],
    [46.9736205, 84.1420629],
    [46.9734335, 84.1413588],
    [46.9736329, 84.1398115],
    [46.9737236, 84.1389293],
    [46.97369, 84.138653],
    [46.9733447, 84.1381903],
    [46.9730286, 84.1374051],
    [46.9729538, 84.1367775],
    [46.9727179, 84.1363673],
    [46.9724372, 84.1356661],
    [46.9722736, 84.1350739],
    [46.9723505, 84.1347119],
    [46.9722325, 84.1339855],
    [46.9720006, 84.1334746],
    [46.9718005, 84.1330697],
    [46.9717244, 84.1327541],
    [46.9716604, 84.1322562],
    [46.9716608, 84.1315953],
    [46.9716937, 84.1313559],
    [46.9717052, 84.1312056],
    [46.9716231, 84.1308939],
    [46.9714881, 84.130586],
    [46.9713496, 84.1304268],
    [46.9711557, 84.1302658],
    [46.9709903, 84.1299595],
    [46.9708046, 84.1297542],
    [46.969976, 84.1291903],
    [46.9696591, 84.1290034],
    [46.9695207, 84.1289244],
    [46.9692281, 84.1286827],
    [46.969149, 84.128579],
    [46.9690955, 84.1283816],
    [46.9689988, 84.1282018],
    [46.9688849, 84.128069],
    [46.9686628, 84.1279272],
    [46.9685282, 84.1278663],
    [46.9681474, 84.1274882],
    [46.9679719, 84.12738],
    [46.9673945, 84.1264105],
    [46.9671867, 84.1254228],
    [46.9670554, 84.1251147],
    [46.9670148, 84.1246188],
    [46.9677594, 84.1231481],
    [46.9681344, 84.1226548],
    [46.9682548, 84.1224427],
    [46.9684373, 84.1223123],
    [46.9685423, 84.1221511],
    [46.9685803, 84.1217663],
    [46.9686276, 84.121539],
    [46.9689124, 84.1209614],
    [46.9690724, 84.1205039],
    [46.9690992, 84.1202511],
    [46.9690866, 84.1199869],
    [46.969025, 84.119832],
    [46.9686775, 84.1194123],
    [46.9684956, 84.1192574],
    [46.96828, 84.119204],
    [46.9681606, 84.1190931],
    [46.9679153, 84.1186929],
    [46.9677127, 84.1184785],
    [46.9670588, 84.1167798],
    [46.9669417, 84.1152486],
    [46.9670501, 84.1137597],
    [46.9673572, 84.1117428],
    [46.9674606, 84.1115787],
    [46.967745, 84.1107517],
    [46.9682672, 84.1102102],
    [46.968434, 84.1093701],
    [46.9684014, 84.1089306],
    [46.9688738, 84.1081566],
    [46.9691621, 84.1074744],
    [46.9692455, 84.1057458],
    [46.9692389, 84.1056063],
    [46.9692189, 84.1055361],
    [46.9687091, 84.1046009],
    [46.9686613, 84.1043004],
    [46.9686238, 84.1037457],
    [46.9686253, 84.1034494],
    [46.968707, 84.1029011],
    [46.9688299, 84.1027277],
    [46.9690753, 84.1025345],
    [46.9691524, 84.1024281],
    [46.9691922, 84.102111],
    [46.9692807, 84.1018458],
    [46.9693002, 84.1016938],
    [46.9693035, 84.1014565],
    [46.9693053, 84.1013152],
    [46.9693697, 84.1011061],
    [46.9692531, 84.1002071],
    [46.9691549, 84.0990748],
    [46.9690433, 84.0982543],
    [46.9687483, 84.0972366],
    [46.9686997, 84.0964135],
    [46.9687098, 84.0962442],
    [46.9688219, 84.0958327],
    [46.9688628, 84.0952868],
    [46.968677, 84.094464],
    [46.9686541, 84.0942482],
    [46.9685738, 84.0940034],
    [46.968599, 84.0937387],
    [46.9685917, 84.0933435],
    [46.9686099, 84.0932082],
    [46.9686032, 84.0929096],
    [46.9685652, 84.0925523],
    [46.9684285, 84.0916343],
    [46.9684254, 84.0913621],
    [46.9684432, 84.091211],
    [46.9685544, 84.0909254],
    [46.968593, 84.0907589],
    [46.9688293, 84.0903585],
    [46.9687695, 84.0900494],
    [46.9687645, 84.089914],
    [46.9687827, 84.0898423],
    [46.9688293, 84.0897661],
    [46.9689519, 84.0896606],
    [46.968985, 84.0895667],
    [46.9689657, 84.0892614],
    [46.9689882, 84.0889395],
    [46.9690534, 84.0887325],
    [46.9690624, 84.088661],
    [46.9690476, 84.0885145],
    [46.9689394, 84.0882886],
    [46.9688388, 84.0881198],
    [46.9688307, 84.0880777],
    [46.9688275, 84.0880256],
    [46.9688301, 84.0877299],
    [46.9687816, 84.0874331],
    [46.9688041, 84.0870919],
    [46.9687897, 84.0867536],
    [46.9688753, 84.0864746],
    [46.9689274, 84.0856076],
    [46.9689282, 84.0854035],
    [46.9688525, 84.0844187],
    [46.9688688, 84.0841456],
    [46.9688278, 84.0838131],
    [46.9689098, 84.0836597],
    [46.9691316, 84.0834926],
    [46.9691959, 84.0832537],
    [46.9691591, 84.0830057],
    [46.9686094, 84.0819319],
    [46.9682963, 84.080929],
    [46.9682008, 84.0801466],
    [46.9682161, 84.0792324],
    [46.9682925, 84.0788007],
    [46.9688352, 84.0777249],
    [46.9694803, 84.0768749],
    [46.9702648, 84.075505],
    [46.9709732, 84.0734245],
    [46.971418, 84.0705823],
    [46.9715198, 84.0679841],
    [46.9716965, 84.066011],
    [46.9715066, 84.0640065],
    [46.9719316, 84.0617635],
    [46.972407, 84.0604581],
    [46.9724641, 84.059279],
    [46.9709696, 84.0587232],
    [46.9697753, 84.0581317],
    [46.9686887, 84.0565283],
    [46.9684504, 84.0549389],
    [46.9687298, 84.0529535],
    [46.969089, 84.050778],
    [46.9702495, 84.0476542],
    [46.9702548, 84.0459787],
    [46.9709132, 84.0447026],
    [46.9719691, 84.0437423],
    [46.9730736, 84.0431206],
    [46.9734997, 84.0415708],
    [46.9742998, 84.0413102],
    [46.9744094, 84.0406602],
    [46.9749438, 84.039531],
    [46.9753844, 84.0366674],
    [46.9759823, 84.0353414],
    [46.9761568, 84.0342702],
    [46.977147, 84.0338228],
    [46.9778911, 84.0326083],
    [46.9780092, 84.028953],
    [46.9788731, 84.0255721],
    [46.9795272, 84.0251793],
    [46.9807725, 84.0249685],
    [46.9815493, 84.0236908],
    [46.9822963, 84.0217348],
    [46.9830343, 84.0201477],
    [46.9835294, 84.0181596],
    [46.9836187, 84.0171288],
    [46.9825055, 84.015438],
    [46.9820557, 84.0141396],
    [46.982884, 84.0111305],
    [46.9831715, 84.0102776],
    [46.9839193, 84.0099475],
    [46.9853239, 84.0098445],
    [46.9870787, 84.0093814],
    [46.9882608, 84.0088097],
    [46.9892662, 84.0075463],
    [46.9897516, 84.0052755],
    [46.990485, 84.0033125],
    [46.9914233, 83.9980645],
    [46.9920577, 83.9954653],
    [46.9930167, 83.9930693],
    [46.9938898, 83.9911822],
    [46.9941742, 83.9898929],
    [46.9942651, 83.9887505],
    [46.994398, 83.9879517],
    [46.9953264, 83.9873138],
    [46.9956643, 83.9865236],
    [46.9956887, 83.9856483],
    [46.9952583, 83.9847168],
    [46.994303, 83.983986],
    [46.9931644, 83.9832247],
    [46.9924287, 83.9822274],
    [46.9913575, 83.9788583],
    [46.9908358, 83.9776466],
    [46.9897879, 83.976027],
    [46.9881699, 83.9739551],
    [46.9873166, 83.9725316],
    [46.9867317, 83.9722563],
    [46.9858655, 83.9714358],
    [46.9846192, 83.9684234],
    [46.984007, 83.9674575],
    [46.9839837, 83.9650865],
    [46.9831835, 83.9627126],
    [46.9824428, 83.9607247],
    [46.9834217, 83.9592952],
    [46.9839803, 83.9588662],
    [46.9847263, 83.9587343],
    [46.9871008, 83.9579094],
    [46.9884457, 83.9575173],
    [46.989376, 83.9565511],
    [46.9902117, 83.9552103],
    [46.990767, 83.953847],
    [46.9910912, 83.9525414],
    [46.991203, 83.9515141],
    [46.9912358, 83.9497698],
    [46.9909876, 83.9472098],
    [46.9907005, 83.9457037],
    [46.9901767, 83.9444439],
    [46.9868494, 83.940075],
    [46.9862144, 83.938746],
    [46.9858449, 83.9369413],
    [46.9851954, 83.9357986],
    [46.9849145, 83.9346332],
    [46.984476, 83.9336839],
    [46.984347, 83.9328563],
    [46.9844879, 83.9321539],
    [46.9848609, 83.9316682],
    [46.9853567, 83.9313507],
    [46.9856634, 83.9306774],
    [46.9855825, 83.9293228],
    [46.9849912, 83.9270393],
    [46.9847255, 83.9262247],
    [46.9807705, 83.9228693],
    [46.979689, 83.9221645],
    [46.9767041, 83.9214029],
    [46.975557, 83.9193123],
    [46.9746904, 83.9172339],
    [46.9741109, 83.915311],
    [46.9742744, 83.9137058],
    [46.9742778, 83.9111855],
    [46.9747689, 83.9069039],
    [46.9750424, 83.9050274],
    [46.9752844, 83.9040578],
    [46.9756116, 83.9031728],
    [46.9762794, 83.9034106],
    [46.9775214, 83.9041214],
    [46.9797841, 83.9049597],
    [46.9808269, 83.9047918],
    [46.9816352, 83.9040732],
    [46.9827497, 83.9025205],
    [46.9834148, 83.9011404],
    [46.983773, 83.8999368],
    [46.983991, 83.8981376],
    [46.9841211, 83.8961989],
    [46.9851896, 83.895236],
    [46.9855518, 83.8945992],
    [46.9856459, 83.8933627],
    [46.9861611, 83.8933004],
    [46.9865998, 83.8935109],
    [46.9872638, 83.8934878],
    [46.9877265, 83.8932148],
    [46.9883669, 83.8919522],
    [46.9884619, 83.8911753],
    [46.9883495, 83.8903886],
    [46.9879466, 83.8895556],
    [46.9877247, 83.888843],
    [46.9867806, 83.8877098],
    [46.9865825, 83.887311],
    [46.9863271, 83.8869697],
    [46.9863079, 83.8865864],
    [46.9861473, 83.8862758],
    [46.9853531, 83.8857636],
    [46.9860007, 83.8827228],
    [46.9865643, 83.8793664],
    [46.9865787, 83.8783034],
    [46.9862418, 83.8766455],
    [46.9861943, 83.8755033],
    [46.9869029, 83.8748501],
    [46.9871846, 83.8743897],
    [46.9873207, 83.8737316],
    [46.9873016, 83.8732788],
    [46.9868178, 83.8719739],
    [46.9869775, 83.8708287],
    [46.9878368, 83.8709703],
    [46.9882424, 83.8708373],
    [46.9889542, 83.8702951],
    [46.9899469, 83.8700537],
    [46.9911857, 83.8702042],
    [46.9917798, 83.8704225],
    [46.9926472, 83.8709848],
    [46.993037, 83.871676],
    [46.9932478, 83.8718827],
    [46.9942859, 83.8714352],
    [46.9950365, 83.8708302],
    [46.995355, 83.8697271],
    [46.995555, 83.868548],
    [46.9958249, 83.8677405],
    [46.9963362, 83.8652255],
    [46.9964016, 83.8641698],
    [46.9961975, 83.8630088],
    [46.9961554, 83.862375],
    [46.9965769, 83.8613072],
    [46.9979424, 83.8592353],
    [46.9972238, 83.8568716],
    [46.9970003, 83.8559918],
    [46.9963868, 83.854218],
    [46.9953371, 83.852159],
    [46.995125, 83.8510398],
    [46.9953684, 83.8493871],
    [46.9955752, 83.8484097],
    [46.99543, 83.8474744],
    [46.9954925, 83.8469677],
    [46.9954117, 83.8463845],
    [46.9957223, 83.8462946],
    [46.9958432, 83.845912],
    [46.9961062, 83.8455812],
    [46.9962926, 83.8429608],
    [46.9965339, 83.8420875],
    [46.9967256, 83.8405391],
    [46.9967782, 83.8400055],
    [46.9967488, 83.8392053],
    [46.9964484, 83.8383961],
    [46.9957339, 83.8375593],
    [46.9952096, 83.8366831],
    [46.994816, 83.8363061],
    [46.9946104, 83.8356902],
    [46.9945833, 83.8353889],
    [46.9947106, 83.8349937],
    [46.9950009, 83.8347831],
    [46.995709, 83.8346699],
    [46.9962011, 83.8345218],
    [46.9968583, 83.8344702],
    [46.9972505, 83.8345128],
    [46.9976044, 83.8346388],
    [46.9980098, 83.8348986],
    [46.9982681, 83.8353034],
    [46.999089, 83.8350981],
    [46.9993818, 83.8353388],
    [47.0000724, 83.8352905],
    [47.0006803, 83.8353524],
    [47.0013348, 83.8357314],
    [47.0018618, 83.836386],
    [47.0020266, 83.8367107],
    [47.0024065, 83.8370773],
    [47.0029636, 83.83728],
    [47.0033519, 83.8372803],
    [47.0037101, 83.8370138],
    [47.0041796, 83.8361606],
    [47.0045655, 83.8357786],
    [47.0052249, 83.8349892],
    [47.0066192, 83.8343084],
    [47.0073666, 83.8336096],
    [47.0085674, 83.8320883],
    [47.009626, 83.8299918],
    [47.0103767, 83.8291175],
    [47.0107341, 83.8277607],
    [47.0112479, 83.8265408],
    [47.0116059, 83.8249815],
    [47.0117423, 83.8220047],
    [47.0122111, 83.8212351],
    [47.0123133, 83.8204593],
    [47.0124061, 83.8190434],
    [47.0129769, 83.817379],
    [47.0139912, 83.814857],
    [47.0150076, 83.8137163],
    [47.0159498, 83.8134186],
    [47.0164403, 83.8122754],
    [47.0165052, 83.8112356],
    [47.0163219, 83.810334],
    [47.016279, 83.808976],
    [47.016189, 83.8080448],
    [47.0158483, 83.8069065],
    [47.0159161, 83.8063648],
    [47.0163207, 83.8051382],
    [47.016658, 83.8036295],
    [47.0167882, 83.8021493],
    [47.0170267, 83.80086],
    [47.0176569, 83.7997202],
    [47.0182621, 83.7983852],
    [47.0183522, 83.7975974],
    [47.0183542, 83.7966899],
    [47.0182285, 83.7959453],
    [47.0174014, 83.7947394],
    [47.0169185, 83.7936999],
    [47.017878, 83.7922674],
    [47.018024, 83.7917032],
    [47.0182003, 83.7913883],
    [47.0189319, 83.7905205],
    [47.019484, 83.789744],
    [47.0200533, 83.7887735],
    [47.02063, 83.7881472],
    [47.0215683, 83.7867194],
    [47.0217948, 83.7862783],
    [47.0222747, 83.7860666],
    [47.0227139, 83.7859922],
    [47.0231472, 83.7856097],
    [47.0236266, 83.7850696],
    [47.0239789, 83.7845705],
    [47.024266, 83.7835121],
    [47.0243799, 83.7824338],
    [47.0244548, 83.7801236],
    [47.0243729, 83.778184],
    [47.024631, 83.7766683],
    [47.0250449, 83.7750852],
    [47.0255797, 83.7739415],
    [47.0258256, 83.7730201],
    [47.0258905, 83.7720851],
    [47.0265336, 83.7705567],
    [47.0268353, 83.769577],
    [47.0268847, 83.768341],
    [47.0267769, 83.765555],
    [47.0268305, 83.7641993],
    [47.026816, 83.7625949],
    [47.0263567, 83.7624459],
    [47.025892, 83.7620971],
    [47.0255396, 83.7614142],
    [47.0253458, 83.7608111],
    [47.0248626, 83.7597333],
    [47.024299, 83.7588178],
    [47.0241826, 83.758463],
    [47.024186, 83.7579634],
    [47.0246667, 83.756365],
    [47.025429, 83.7541293],
    [47.0257612, 83.7540348],
    [47.0264679, 83.7539781],
    [47.0269999, 83.7537068],
    [47.0273831, 83.753179],
    [47.0278515, 83.7523874],
    [47.0285105, 83.7514952],
    [47.028781, 83.7506508],
    [47.0292375, 83.7495062],
    [47.0294604, 83.7491928],
    [47.0322459, 83.7482064],
    [47.0326582, 83.7478271],
    [47.0327774, 83.7472713],
    [47.0327564, 83.7462353],
    [47.0330133, 83.7433038],
    [47.0330728, 83.7415976],
    [47.0330737, 83.7402355],
    [47.0328721, 83.7393797],
    [47.0325743, 83.7387186],
    [47.0315349, 83.7371751],
    [47.0302674, 83.735766],
    [47.0286434, 83.733755],
    [47.0275791, 83.7327498],
    [47.0274798, 83.732447],
    [47.0274217, 83.7308303],
    [47.0273478, 83.7300402],
    [47.0266568, 83.7282594],
    [47.0265437, 83.7277297],
    [47.0265905, 83.7266145],
    [47.0265121, 83.7254051],
    [47.0261062, 83.7218314],
    [47.0258113, 83.7187457],
    [47.0256147, 83.7178219],
    [47.0252311, 83.7168519],
    [47.0246416, 83.7157159],
    [47.0244041, 83.7148969],
    [47.0242114, 83.7132746],
    [47.0238876, 83.7120001],
    [47.0228059, 83.7114893],
    [47.0220158, 83.7106109],
    [47.0206325, 83.7087577],
    [47.0201101, 83.7084302],
    [47.0178498, 83.7075261],
    [47.0176522, 83.7073976],
    [47.0175119, 83.7070594],
    [47.017476, 83.7057041],
    [47.0175864, 83.7028872],
    [47.0175909, 83.7017351],
    [47.0173796, 83.6996554],
    [47.0173908, 83.6992007],
    [47.0176908, 83.697946],
    [47.0179066, 83.6975777],
    [47.0181363, 83.6974212],
    [47.0183785, 83.6973447],
    [47.0188688, 83.6977413],
    [47.0192444, 83.69844],
    [47.020347, 83.7015207],
    [47.0204998, 83.7021134],
    [47.0207155, 83.7022951],
    [47.0212955, 83.7019832],
    [47.0218898, 83.7014967],
    [47.0226524, 83.7010283],
    [47.0231334, 83.7008618],
    [47.0242435, 83.7008376],
    [47.0252378, 83.7010064],
    [47.0257454, 83.7010169],
    [47.0262205, 83.7008809],
    [47.0277578, 83.7001267],
    [47.0280396, 83.7000652],
    [47.0290104, 83.700575],
    [47.0294002, 83.7007209],
    [47.0297229, 83.7006139],
    [47.0304495, 83.6998043],
    [47.0309299, 83.6990642],
    [47.0311342, 83.6988903],
    [47.0317309, 83.6988826],
    [47.0325784, 83.6987176],
    [47.0333026, 83.6981086],
    [47.034115, 83.696809],
    [47.0346711, 83.6960259],
    [47.0349609, 83.6957879],
    [47.0356253, 83.6957761],
    [47.0357719, 83.6956365],
    [47.0357134, 83.6944999],
    [47.035843, 83.6934732],
    [47.0352908, 83.6885267],
    [47.0353072, 83.6879211],
    [47.0354542, 83.6863653],
    [47.0353203, 83.6850055],
    [47.03528, 83.6840346],
    [47.0353163, 83.682737],
    [47.035182, 83.6802048],
    [47.0353558, 83.6795936],
    [47.0361441, 83.6774243],
    [47.0368867, 83.6751102],
    [47.0372991, 83.6741836],
    [47.0378146, 83.6733986],
    [47.0384427, 83.6726313],
    [47.0385732, 83.672244],
    [47.0385807, 83.6718159],
    [47.0385259, 83.6714053],
    [47.0383654, 83.6707231],
    [47.0383092, 83.6700969],
    [47.0383114, 83.6692951],
    [47.0382505, 83.6687711],
    [47.0378939, 83.6671896],
    [47.0379179, 83.6668493],
    [47.0380822, 83.6664698],
    [47.0387044, 83.6660504],
    [47.0389819, 83.6656256],
    [47.0394742, 83.6631294],
    [47.0398215, 83.6599439],
    [47.0400617, 83.6586495],
    [47.0404757, 83.6575934],
    [47.0417122, 83.6555083],
    [47.0418864, 83.6549391],
    [47.0421871, 83.6529321],
    [47.0430233, 83.6480855],
    [47.0434287, 83.6459021],
    [47.0439557, 83.6438914],
    [47.0440115, 83.6433413],
    [47.0439751, 83.6427437],
    [47.04323, 83.640628],
    [47.04318, 83.6401409],
    [47.0436189, 83.6386536],
    [47.0443925, 83.6363885],
    [47.0446613, 83.6349769],
    [47.0457355, 83.626782],
    [47.0458231, 83.6258073],
    [47.0459669, 83.6253026],
    [47.0462925, 83.624947],
    [47.0467897, 83.6247102],
    [47.047482, 83.6244898],
    [47.0477972, 83.6242021],
    [47.0481453, 83.6236526],
    [47.048455, 83.6219308],
    [47.0486427, 83.6199658],
    [47.0487655, 83.6191093],
    [47.048857, 83.6177952],
    [47.0488638, 83.6172744],
    [47.0487547, 83.6164755],
    [47.0487077, 83.6143141],
    [47.048611, 83.6134347],
    [47.0483222, 83.6122142],
    [47.0477989, 83.6103883],
    [47.0467015, 83.6089383],
    [47.0462861, 83.6076547],
    [47.0462812, 83.6073911],
    [47.0463934, 83.6070943],
    [47.0465962, 83.6068754],
    [47.0468465, 83.606723],
    [47.0472553, 83.6066802],
    [47.0485347, 83.6067984],
    [47.0495551, 83.6067315],
    [47.0513445, 83.6068658],
    [47.0517985, 83.606796],
    [47.052055, 83.6066253],
    [47.0529088, 83.6053913],
    [47.0542407, 83.604328],
    [47.0546344, 83.6038533],
    [47.0549334, 83.6030122],
    [47.0551661, 83.6020213],
    [47.0555421, 83.5995148],
    [47.055799, 83.5981951],
    [47.0561796, 83.5968344],
    [47.0565789, 83.5956408],
    [47.0579004, 83.5930954],
    [47.058558, 83.5914237],
    [47.0589234, 83.5902153],
    [47.0591605, 83.5883092],
    [47.0593274, 83.5874911],
    [47.0593977, 83.5866458],
    [47.0598134, 83.5850222],
    [47.0598916, 83.5842267],
    [47.0598147, 83.5837106],
    [47.0594659, 83.5826752],
    [47.0594277, 83.5822275],
    [47.0597636, 83.5807912],
    [47.0602889, 83.5796951],
    [47.0605058, 83.5787929],
    [47.06054, 83.5779715],
    [47.0603801, 83.57415],
    [47.0602946, 83.5734988],
    [47.0599219, 83.5726853],
    [47.0593847, 83.57202],
    [47.0581084, 83.5708261],
    [47.0562788, 83.5693794],
    [47.05605, 83.5689292],
    [47.0560249, 83.5682286],
    [47.0562447, 83.5669235],
    [47.056267, 83.5660435],
    [47.0563578, 83.5651079],
    [47.0565509, 83.5647626],
    [47.0570618, 83.5641054],
    [47.0579247, 83.5626969],
    [47.0583487, 83.5621751],
    [47.0591784, 83.5614511],
    [47.0596004, 83.5608882],
    [47.0599509, 83.5601541],
    [47.0603638, 83.559811],
    [47.060693, 83.5597159],
    [47.0611355, 83.5596967],
    [47.0618251, 83.5598852],
    [47.062546, 83.5599555],
    [47.0629982, 83.5599111],
    [47.0635051, 83.560156],
    [47.0642854, 83.5609923],
    [47.0650964, 83.5617006],
    [47.0656166, 83.5619706],
    [47.0669935, 83.562377],
    [47.0677354, 83.5628139],
    [47.0698934, 83.564263],
    [47.0713409, 83.5648105],
    [47.0742131, 83.5653689],
    [47.0750534, 83.5652819],
    [47.0759527, 83.5653336],
    [47.0770684, 83.5655771],
    [47.0781228, 83.5660068],
    [47.0783995, 83.5655687],
    [47.0786391, 83.5650155],
    [47.0787779, 83.5644833],
    [47.0789161, 83.5634157],
    [47.0789115, 83.5624425],
    [47.0789991, 83.5615679],
    [47.0792271, 83.5605176],
    [47.0799158, 83.5563854],
    [47.080162, 83.5529383],
    [47.0801436, 83.5501268],
    [47.0801955, 83.5492089],
    [47.0802805, 83.5488762],
    [47.0814775, 83.5483095],
    [47.0824787, 83.547509],
    [47.0831011, 83.5465871],
    [47.0833384, 83.546096],
    [47.0834679, 83.5456613],
    [47.0835453, 83.5445866],
    [47.0835765, 83.5429126],
    [47.0833662, 83.5393715],
    [47.0830082, 83.536743],
    [47.0829887, 83.5359198],
    [47.0832976, 83.5341249],
    [47.0838294, 83.5326488],
    [47.0842035, 83.5313339],
    [47.0843475, 83.5305298],
    [47.0844987, 83.5281842],
    [47.084694, 83.5275441],
    [47.0852174, 83.5249815],
    [47.0853578, 83.5247836],
    [47.0855959, 83.5246996],
    [47.0858635, 83.5248232],
    [47.0873173, 83.5261486],
    [47.0882259, 83.5265583],
    [47.0897006, 83.5271028],
    [47.0900607, 83.5271246],
    [47.0904409, 83.5270625],
    [47.0907856, 83.5268647],
    [47.0917204, 83.5257789],
    [47.0921917, 83.5250274],
    [47.0923302, 83.5247063],
    [47.0924458, 83.5242328],
    [47.0929116, 83.5204916],
    [47.0930244, 83.5201281],
    [47.0932497, 83.5199147],
    [47.0939453, 83.519828],
    [47.0947468, 83.520014],
    [47.0949275, 83.5200006],
    [47.0950587, 83.5199018],
    [47.0950681, 83.5196134],
    [47.094999, 83.5192941],
    [47.0947939, 83.5189588],
    [47.094123, 83.5181045],
    [47.0939309, 83.5175558],
    [47.0939198, 83.5172898],
    [47.0939995, 83.5168726],
    [47.0941147, 83.5165784],
    [47.0945999, 83.5160123],
    [47.0952451, 83.5155738],
    [47.0967109, 83.515203],
    [47.0974718, 83.514833],
    [47.0982536, 83.513891],
    [47.0995674, 83.5119099],
    [47.0999977, 83.5115642],
    [47.102308, 83.5106578],
    [47.1028262, 83.5103847],
    [47.1034055, 83.5099549],
    [47.1038317, 83.5097614],
    [47.1044832, 83.5097831],
    [47.1051407, 83.5097029],
    [47.1058423, 83.5094136],
    [47.1069914, 83.5091771],
    [47.1074873, 83.5089198],
    [47.1084946, 83.5074308],
    [47.1088176, 83.5067049],
    [47.1095069, 83.5059297],
    [47.1096057, 83.5053935],
    [47.1091584, 83.5038333],
    [47.1085991, 83.5023686],
    [47.107894, 83.5011231],
    [47.1075335, 83.5001322],
    [47.1073796, 83.4994794],
    [47.1073653, 83.4986422],
    [47.1076453, 83.4976198],
    [47.1081906, 83.4966322],
    [47.1094073, 83.4951794],
    [47.1097264, 83.4946397],
    [47.1099607, 83.4938649],
    [47.1099958, 83.492623],
    [47.1098911, 83.4909775],
    [47.109634, 83.4890498],
    [47.1091635, 83.4863021],
    [47.1090626, 83.485206],
    [47.1091283, 83.4832018],
    [47.1093471, 83.4823304],
    [47.109785, 83.4816236],
    [47.1108371, 83.4827525],
    [47.1131113, 83.4842407],
    [47.1134315, 83.4842205],
    [47.1140476, 83.483235],
    [47.1147054, 83.4826034],
    [47.1157559, 83.4820634],
    [47.1170496, 83.4816676],
    [47.1175318, 83.4811574],
    [47.1177416, 83.480343],
    [47.1178473, 83.4785234],
    [47.1178908, 83.4760146],
    [47.1178299, 83.4748631],
    [47.1180498, 83.4729307],
    [47.1181267, 83.4711843],
    [47.1181349, 83.468577],
    [47.1182931, 83.4676178],
    [47.11841, 83.4671885],
    [47.1187367, 83.4665516],
    [47.1188234, 83.465673],
    [47.1190278, 83.464903],
    [47.1193598, 83.4645366],
    [47.1199945, 83.4643623],
    [47.1202383, 83.4641823],
    [47.1212527, 83.4630611],
    [47.1215788, 83.4629653],
    [47.1218375, 83.4631061],
    [47.1228613, 83.4640573],
    [47.1234983, 83.4644328],
    [47.1248899, 83.4650378],
    [47.1256597, 83.4657867],
    [47.1262158, 83.4667028],
    [47.1266133, 83.4678059],
    [47.1274048, 83.4706185],
    [47.1276157, 83.4709528],
    [47.1278081, 83.4709723],
    [47.1280202, 83.4707806],
    [47.1281123, 83.4703753],
    [47.12815, 83.4692626],
    [47.1276452, 83.4663978],
    [47.1277181, 83.465846],
    [47.128096, 83.4650684],
    [47.1281422, 83.4645897],
    [47.127939, 83.4637565],
    [47.1278496, 83.4627613],
    [47.1280036, 83.4621494],
    [47.1283355, 83.4614449],
    [47.1295775, 83.4600399],
    [47.130637, 83.4596666],
    [47.1317834, 83.4580555],
    [47.1320721, 83.4575395],
    [47.1320599, 83.45679],
    [47.1314113, 83.4551188],
    [47.1309837, 83.4536148],
    [47.1310452, 83.4530075],
    [47.1315694, 83.452119],
    [47.1316933, 83.4515327],
    [47.131404, 83.4492361],
    [47.1308146, 83.4485125],
    [47.1299821, 83.44685],
    [47.1291164, 83.4457051],
    [47.1265145, 83.4433589],
    [47.1253496, 83.4407553],
    [47.1248438, 83.4373703],
    [47.1240469, 83.4325937],
    [47.1232471, 83.4298919],
    [47.1216105, 83.4286587],
    [47.1201103, 83.4279678],
    [47.1196214, 83.4278959],
    [47.1183486, 83.4281857],
    [47.1155227, 83.429583],
    [47.1148225, 83.4292669],
    [47.1145463, 83.428751],
    [47.1145633, 83.427564],
    [47.1149999, 83.4248234],
    [47.1150401, 83.4233538],
    [47.1155042, 83.4208088],
    [47.1156948, 83.4189722],
    [47.1155085, 83.4170859],
    [47.1157795, 83.4159214],
    [47.1159925, 83.4134662],
    [47.1163075, 83.4130774],
    [47.1171198, 83.4127237],
    [47.117929, 83.4127468],
    [47.1199216, 83.4137478],
    [47.1205132, 83.4137282],
    [47.1211668, 83.4131044],
    [47.1218656, 83.4118506],
    [47.1222781, 83.4105169],
    [47.1226778, 83.4098131],
    [47.1234535, 83.4091187],
    [47.1242322, 83.4087417],
    [47.1253987, 83.4084168],
    [47.1267223, 83.4083806],
    [47.1273636, 83.4080983],
    [47.1284465, 83.407287],
    [47.131533, 83.4039484],
    [47.1324915, 83.4023264],
    [47.133389, 83.4013161],
    [47.1348535, 83.3994821],
    [47.1353344, 83.398218],
    [47.1358283, 83.3965239],
    [47.1365039, 83.3949058],
    [47.1384197, 83.3928164],
    [47.1394139, 83.3918781],
    [47.1401321, 83.3900842],
    [47.1409534, 83.3888253],
    [47.141844, 83.3880652],
    [47.1430428, 83.3875686],
    [47.144295, 83.3873423],
    [47.1448957, 83.3874316],
    [47.1466602, 83.38793],
    [47.1476245, 83.3879675],
    [47.148388, 83.3878178],
    [47.1491274, 83.3873021],
    [47.1502218, 83.3862801],
    [47.1507934, 83.3855536],
    [47.1511994, 83.384266],
    [47.1515434, 83.3820394],
    [47.1541963, 83.3806566],
    [47.1553906, 83.3794616],
    [47.1556167, 83.3790374],
    [47.1563501, 83.3769651],
    [47.1566287, 83.3764593],
    [47.1571205, 83.3759956],
    [47.1575868, 83.3752785],
    [47.1576109, 83.3748865],
    [47.1579079, 83.3747279],
    [47.1581223, 83.3744777],
    [47.1584182, 83.3729764],
    [47.1602114, 83.3696479],
    [47.1610007, 83.3683505],
    [47.1619175, 83.3670783],
    [47.1629946, 83.3653238],
    [47.1638665, 83.3645325],
    [47.1653444, 83.3642629],
    [47.1670508, 83.3643654],
    [47.1677913, 83.3642462],
    [47.1692218, 83.3626761],
    [47.1693753, 83.362293],
    [47.1695253, 83.3621692],
    [47.1701377, 83.3621325],
    [47.1710714, 83.3617584],
    [47.1716733, 83.3619773],
    [47.1725461, 83.3626195],
    [47.174437, 83.3642704],
    [47.1756683, 83.3652109],
    [47.1760834, 83.3652957],
    [47.1777807, 83.3648907],
    [47.177702, 83.3632217],
    [47.1778122, 83.3624068],
    [47.1779937, 83.3617894],
    [47.1780366, 83.3611461],
    [47.1777783, 83.3602895],
    [47.1777048, 83.3598785],
    [47.1775256, 83.3596153],
    [47.1774155, 83.3593379],
    [47.1773755, 83.3585761],
    [47.1772597, 83.3577903],
    [47.1768314, 83.3562085],
    [47.1767057, 83.3542658],
    [47.1763087, 83.3542551],
    [47.1760864, 83.3540286],
    [47.1754874, 83.3539183],
    [47.1751703, 83.3538846],
    [47.1745596, 83.3539787],
    [47.1743167, 83.3537514],
    [47.1740903, 83.3533516],
    [47.1737916, 83.3523148],
    [47.1738406, 83.350715],
    [47.1737982, 83.3498885],
    [47.1737201, 83.3495308],
    [47.173505, 83.3491744],
    [47.1732871, 83.3489376],
    [47.1729237, 83.3477985],
    [47.1727506, 83.3470139],
    [47.172667, 83.3463593],
    [47.172781, 83.3459084],
    [47.1726902, 83.3453198],
    [47.1727466, 83.344924],
    [47.1726679, 83.3438976],
    [47.1727307, 83.3427058],
    [47.1726118, 83.3423239],
    [47.1725565, 83.3409346],
    [47.1723277, 83.3393671],
    [47.1722153, 83.3390537],
    [47.1720461, 83.3388273],
    [47.1716293, 83.3384743],
    [47.1712296, 83.3374969],
    [47.1701885, 83.3366035],
    [47.1699977, 83.3360138],
    [47.1699743, 83.3355094],
    [47.1700044, 83.3350892],
    [47.1701173, 83.3348323],
    [47.1707772, 83.3343452],
    [47.1709123, 83.334092],
    [47.1709427, 83.333747],
    [47.170918, 83.3329946],
    [47.1708475, 83.3326114],
    [47.1707278, 83.3310657],
    [47.1714415, 83.3266529],
    [47.1714676, 83.3256114],
    [47.1712376, 83.3199267],
    [47.1713511, 83.318565],
    [47.1714321, 83.3181208],
    [47.1717175, 83.3178367],
    [47.1724707, 83.3164279],
    [47.1727742, 83.3161747],
    [47.1733609, 83.3152218],
    [47.1735664, 83.3149691],
    [47.1738422, 83.3148341],
    [47.1740458, 83.3145829],
    [47.1742975, 83.3138796],
    [47.1747855, 83.3128319],
    [47.1750112, 83.3114307],
    [47.1757921, 83.3098072],
    [47.1761237, 83.3089031],
    [47.1766958, 83.3083718],
    [47.1772308, 83.3076862],
    [47.1775543, 83.3070707],
    [47.1777188, 83.3063611],
    [47.177831, 83.3055112],
    [47.1777923, 83.3040997],
    [47.1782434, 83.3019178],
    [47.1783998, 83.3015467],
    [47.1785641, 83.3013542],
    [47.1786449, 83.3009767],
    [47.1794258, 83.2999285],
    [47.1798076, 83.299631],
    [47.1801441, 83.2990807],
    [47.1802875, 83.2981778],
    [47.1806033, 83.2969852],
    [47.18117, 83.2955498],
    [47.1813816, 83.295526],
    [47.1815046, 83.2952006],
    [47.1816526, 83.2945329],
    [47.1817268, 83.2937202],
    [47.1819985, 83.2930715],
    [47.1820762, 83.2916341],
    [47.182045, 83.2909783],
    [47.181872, 83.2903453],
    [47.1819586, 83.2898799],
    [47.1820068, 83.2892248],
    [47.1819923, 83.2884539],
    [47.1813043, 83.2873866],
    [47.1802661, 83.286497],
    [47.1783468, 83.2851866],
    [47.1769095, 83.2843874],
    [47.1765695, 83.283821],
    [47.1761675, 83.2829927],
    [47.1753496, 83.2807435],
    [47.1751998, 83.2800875],
    [47.1751904, 83.2797768],
    [47.1755651, 83.2781994],
    [47.1756223, 83.2776286],
    [47.1756128, 83.2761392],
    [47.1754503, 83.273186],
    [47.1754286, 83.2716202],
    [47.1757282, 83.2684188],
    [47.1757837, 83.2667043],
    [47.1759626, 83.265053],
    [47.1763499, 83.2627771],
    [47.1767867, 83.2609736],
    [47.1763123, 83.2574604],
    [47.1762194, 83.255453],
    [47.1763271, 83.2535006],
    [47.176571, 83.2516247],
    [47.176906, 83.2496754],
    [47.1776864, 83.2458889],
    [47.1782909, 83.2441188],
    [47.1784043, 83.2422275],
    [47.1788367, 83.2405909],
    [47.1792098, 83.2393404],
    [47.1805544, 83.2371546],
    [47.1811823, 83.2359786],
    [47.1817231, 83.2354482],
    [47.1823604, 83.2353498],
    [47.1835615, 83.235786],
    [47.184017, 83.2361037],
    [47.1850891, 83.2362974],
    [47.1854354, 83.2362556],
    [47.1859232, 83.2366727],
    [47.1861001, 83.2372079],
    [47.1862713, 83.237516],
    [47.1866789, 83.2376239],
    [47.186847, 83.2378806],
    [47.1871876, 83.2381095],
    [47.1876044, 83.2379905],
    [47.1880753, 83.2375114],
    [47.1885821, 83.236857],
    [47.1890704, 83.2364816],
    [47.189178, 83.2359518],
    [47.189257, 83.234414],
    [47.1892944, 83.2319901],
    [47.1889724, 83.2282937],
    [47.1887393, 83.22708],
    [47.1886614, 83.2260919],
    [47.1887218, 83.2242752],
    [47.1886969, 83.2234827],
    [47.1883298, 83.2225543],
    [47.187734, 83.2217705],
    [47.1879509, 83.2208862],
    [47.1882942, 83.2201456],
    [47.1890805, 83.2189374],
    [47.1896856, 83.2181442],
    [47.1902137, 83.2171639],
    [47.1905556, 83.2161666],
    [47.1906031, 83.2153991],
    [47.1906983, 83.214921],
    [47.1911049, 83.2146204],
    [47.1928057, 83.2137032],
    [47.1936939, 83.2130432],
    [47.1946385, 83.2120137],
    [47.1950305, 83.2112702],
    [47.195967, 83.209051],
    [47.1970577, 83.2085737],
    [47.1983366, 83.2071431],
    [47.1989022, 83.2068777],
    [47.1998257, 83.2069775],
    [47.200899, 83.20729],
    [47.2021575, 83.2079628],
    [47.2028329, 83.2078613],
    [47.2035414, 83.2076185],
    [47.2046008, 83.2071134],
    [47.2050554, 83.2072066],
    [47.2055333, 83.2074009],
    [47.206842, 83.208338],
    [47.2074661, 83.208493],
    [47.2086063, 83.2085783],
    [47.2093908, 83.2085675],
    [47.2101926, 83.2083481],
    [47.2111433, 83.2078327],
    [47.2120006, 83.2071567],
    [47.2125245, 83.2064531],
    [47.2129204, 83.2055124],
    [47.2131435, 83.2043375],
    [47.2133931, 83.2035621],
    [47.2140467, 83.202657],
    [47.2163289, 83.2004594],
    [47.2169099, 83.1991694],
    [47.2173806, 83.1979211],
    [47.2176965, 83.1946877],
    [47.2180022, 83.1936788],
    [47.2186359, 83.1923216],
    [47.2194087, 83.1908692],
    [47.2194899, 83.1891165],
    [47.219444, 83.1863751],
    [47.2193309, 83.1839966],
    [47.2196277, 83.1825169],
    [47.2201675, 83.1811491],
    [47.2202845, 83.1791806],
    [47.2202126, 83.1774793],
    [47.2202011, 83.1746447],
    [47.2204296, 83.1736792],
    [47.2215238, 83.1713122],
    [47.2235763, 83.167336],
    [47.2246766, 83.1648388],
    [47.2257656, 83.1628288],
    [47.2290029, 83.1576438],
    [47.231855, 83.1544107],
    [47.2336549, 83.1525252],
    [47.2346519, 83.1508936],
    [47.2344999, 83.147557],
    [47.2341593, 83.1450711],
    [47.2339171, 83.1427653],
    [47.233952, 83.1367628],
    [47.2337418, 83.1339192],
    [47.2330523, 83.1301983],
    [47.2324041, 83.1279992],
    [47.2317175, 83.1263735],
    [47.231332, 83.1249807],
    [47.2308813, 83.1213995],
    [47.2305362, 83.1202136],
    [47.2294949, 83.1175787],
    [47.2293139, 83.1158583],
    [47.2292846, 83.1139364],
    [47.2294305, 83.1123609],
    [47.2292993, 83.1099363],
    [47.2289793, 83.1083935],
    [47.2269706, 83.1038056],
    [47.2264667, 83.1014568],
    [47.22607, 83.0999408],
    [47.2250308, 83.097754],
    [47.2241734, 83.0957043],
    [47.2237893, 83.0941481],
    [47.2237379, 83.0929953],
    [47.2238057, 83.0922262],
    [47.226181, 83.0879531],
    [47.2270127, 83.0859948],
    [47.2279537, 83.0847879],
    [47.2282094, 83.0833289],
    [47.2283361, 83.0809517],
    [47.2282837, 83.0777688],
    [47.2279952, 83.075054],
    [47.2274899, 83.0727602],
    [47.2274716, 83.071321],
    [47.2278077, 83.0693584],
    [47.2278773, 83.0645418],
    [47.2277391, 83.0632232],
    [47.2270145, 83.0608325],
    [47.2261157, 83.058455],
    [47.225529, 83.0564769],
    [47.2252451, 83.0541777],
    [47.225277, 83.0516257],
    [47.2238065, 83.0510324],
    [47.2232843, 83.0504753],
    [47.2225656, 83.0481447],
    [47.2221025, 83.0474517],
    [47.2210164, 83.0467513],
    [47.219421, 83.0459653],
    [47.2154619, 83.0426895],
    [47.2150037, 83.0421513],
    [47.2149868, 83.0417047],
    [47.2151015, 83.0411585],
    [47.2155596, 83.0406945],
    [47.2162734, 83.0401504],
    [47.2168901, 83.0394956],
    [47.2172932, 83.0389327],
    [47.2183162, 83.0381235],
    [47.2186363, 83.0373001],
    [47.2197435, 83.0361215],
    [47.2210603, 83.0351542],
    [47.2223875, 83.0340657],
    [47.2237093, 83.0323286],
    [47.223613, 83.0317564],
    [47.2232667, 83.031327],
    [47.2226694, 83.0308957],
    [47.221903, 83.0306393],
    [47.220692, 83.0295141],
    [47.2198812, 83.0286795],
    [47.2193453, 83.0283988],
    [47.2187473, 83.028193],
    [47.2175309, 83.0281812],
  ],
});
mapRegions.push({
  id: 3,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Аягозский район",
  centerTitle: "город Аягоз",
  centerTitleShort: "г. Аягоз",
  centerGeometry: [47.94348672, 80.4233551],
  geometry: [
    [46.8938944, 80.0539703],
    [46.7795593, 79.5772777],
    [47.0816655, 79.2629325],
    [47.047246, 78.8168486],
    [47.1333358, 78.7682575],
    [47.1410302, 78.6418065],
    [47.180988, 78.5919868],
    [47.233962, 78.4722593],
    [47.3308936, 78.303405],
    [47.1910276, 78.1420444],
    [47.2825622, 78.0143057],
    [47.1699935, 77.8672627],
    [47.2095137, 77.6086196],
    [47.1749118, 77.5809623],
    [47.1446495, 77.4810404],
    [47.1838141, 77.3628239],
    [47.1573898, 77.3007061],
    [47.0661993, 77.2521901],
    [47.1044774, 77.1031255],
    [47.1535563, 77.0056833],
    [47.2142246, 76.953972],
    [47.2455377, 76.8639439],
    [47.2660567, 76.9428179],
    [47.3370085, 76.9856794],
    [47.4134325, 76.9808339],
    [47.4236066, 77.0757143],
    [47.3961977, 77.1669778],
    [47.448064, 77.1559057],
    [47.6017073, 77.1034697],
    [47.6085744, 77.0805735],
    [47.6488923, 77.0601661],
    [47.7389409, 77.1205677],
    [47.8809197, 77.1698609],
    [47.8793972, 77.219056],
    [47.9627156, 77.2284848],
    [47.9631235, 77.2969045],
    [48.0207606, 77.2375193],
    [48.0663069, 77.4237908],
    [48.1042636, 77.3529507],
    [48.0992882, 77.2519759],
    [48.1777497, 77.1230315],
    [48.2296229, 77.1490934],
    [48.2319635, 77.2371136],
    [48.2674064, 77.2589865],
    [48.2772135, 77.211585],
    [48.2744489, 77.0546643],
    [48.3182904, 77.041092],
    [48.3452753, 76.9543071],
    [48.3960568, 76.9173896],
    [48.4122249, 76.9967127],
    [48.4642986, 76.9851985],
    [48.4962704, 77.0374454],
    [48.4987751, 77.088302],
    [48.481285, 77.1652821],
    [48.6073805, 77.349769],
    [48.64585, 77.4168554],
    [48.6763996, 77.4440546],
    [48.7563516, 77.724892],
    [48.8815354, 77.873993],
    [48.8337944, 78.0188138],
    [48.8555328, 78.278649],
    [48.6939352, 78.288406],
    [48.655452, 78.3909215],
    [48.6714174, 78.5284947],
    [48.5794679, 78.6844947],
    [48.5048878, 78.6747126],
    [48.4630998, 78.8010406],
    [48.3901675, 78.7789831],
    [48.3751024, 78.9257822],
    [48.3905253, 78.9683838],
    [48.342864, 79.0737072],
    [48.308914, 79.1074065],
    [48.2772705, 79.2492371],
    [48.2892428, 79.3162984],
    [48.3619335, 79.3075785],
    [48.3771445, 79.3504877],
    [48.404542, 79.3655551],
    [48.4143901, 79.5087281],
    [48.4398572, 79.5680084],
    [48.4294575, 79.6732407],
    [48.3903247, 79.6221083],
    [48.3266069, 79.6268762],
    [48.3284827, 79.7040478],
    [48.3652542, 79.7416923],
    [48.3687049, 79.806855],
    [48.4142807, 79.8412312],
    [48.4141007, 79.9311522],
    [48.3914256, 79.9428488],
    [48.357667, 80.1184926],
    [48.4020737, 80.3288341],
    [48.395936, 80.5618438],
    [48.3232087, 80.5430979],
    [48.3180305, 80.6319956],
    [48.2626681, 80.664512],
    [48.2518125, 80.7634425],
    [48.3303675, 80.9109344],
    [48.2945668, 80.9809199],
    [48.3548477, 81.1912613],
    [48.3565821, 81.5094674],
    [48.2783576, 81.5551982],
    [48.1461518, 81.4297181],
    [48.094085, 81.6365656],
    [48.048149, 81.6629717],
    [47.9745557, 81.5944905],
    [47.9336429, 81.671172],
    [47.8707342, 81.6430065],
    [47.8670694, 81.6114655],
    [47.7997483, 81.5278691],
    [47.6893127, 81.5922023],
    [47.6799045, 81.6552044],
    [47.663636, 81.6797097],
    [47.6028492, 81.6646811],
    [47.5118137, 81.7113806],
    [47.4807809, 81.6920466],
    [47.3810163, 81.6300268],
    [47.4642709, 81.4667814],
    [47.459095, 81.4170826],
    [47.491069, 81.4280247],
    [47.504174, 81.3965613],
    [47.4912604, 81.3143164],
    [47.4547304, 81.2778707],
    [47.4558518, 81.1874164],
    [47.4995542, 81.0672923],
    [47.514576, 80.942521],
    [47.4822236, 80.9068138],
    [47.4669352, 80.8143606],
    [47.522521, 80.807954],
    [47.5777969, 80.7761532],
    [47.5449751, 80.6888737],
    [47.5701686, 80.4979254],
    [47.5950477, 80.499947],
    [47.6220555, 80.3790206],
    [47.7011187, 80.3282005],
    [47.6979826, 80.2368162],
    [47.616214, 80.2221751],
    [47.6000367, 80.2745975],
    [47.5533418, 80.2781598],
    [47.5104325, 80.1995843],
    [47.4370261, 80.0987006],
    [47.3864794, 80.0123989],
    [47.3317711, 80.0135202],
    [47.2681057, 79.9298714],
    [47.2349237, 79.956886],
    [47.1971516, 80.0454258],
    [47.1201237, 80.0266799],
    [47.0980525, 80.0636133],
    [47.0367461, 80.0814818],
    [46.8938944, 80.0539703],
  ],
});
mapRegions.push({
  id: 4,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Курчумский район",
  centerTitle: "село Курчум",
  centerTitleShort: "с. Курчум",
  centerGeometry: [48.5670659, 83.6536788],
  geometry: [
    [47.9198129, 85.5660528],
    [47.918045, 85.5589218],
    [47.9150315, 85.5483465],
    [47.9125222, 85.5341794],
    [47.9005977, 85.4867619],
    [47.9277491, 85.4563902],
    [47.912978, 85.4344034],
    [47.8747455, 85.4317094],
    [47.8610798, 85.3645478],
    [47.8862415, 85.339409],
    [47.8933364, 85.24472],
    [47.8792582, 85.2044135],
    [47.9075309, 85.1674049],
    [47.8823282, 85.1345284],
    [47.8795932, 85.0683673],
    [47.8998525, 85.0272608],
    [47.8704767, 84.9927684],
    [47.8326567, 84.9732066],
    [47.8176759, 84.8697201],
    [47.8235402, 84.8153992],
    [47.784118, 84.719459],
    [47.7871013, 84.6576625],
    [47.7856336, 84.6126701],
    [47.747474, 84.5939037],
    [47.750389, 84.5133887],
    [47.7395586, 84.4703746],
    [47.7617482, 84.4496527],
    [47.744224, 84.4160764],
    [47.7951695, 84.2018901],
    [47.8132544, 84.1977694],
    [47.9969436, 83.7502976],
    [48.1490623, 83.3092803],
    [48.2436646, 83.4232485],
    [48.2402277, 83.4811545],
    [48.2827792, 83.4997477],
    [48.2765662, 83.5299371],
    [48.3267391, 83.5321501],
    [48.3499681, 83.5423196],
    [48.3756836, 83.5404052],
    [48.3817073, 83.4901958],
    [48.4281835, 83.5105599],
    [48.4486113, 83.4930105],
    [48.4584504, 83.4441983],
    [48.5244838, 83.3664638],
    [48.541199, 83.3671042],
    [48.543076, 83.3853386],
    [48.552704, 83.4788822],
    [48.6915399, 83.4598468],
    [48.795979, 83.4656609],
    [48.9093865, 83.5556799],
    [48.9625883, 83.6723261],
    [49.0811194, 84.1983643],
    [49.0290981, 84.2213813],
    [48.9635479, 84.1664883],
    [48.9328274, 84.2703941],
    [48.9396443, 84.3385539],
    [48.9123197, 84.365532],
    [48.9203263, 84.4081564],
    [48.9674292, 84.409508],
    [49.0004311, 84.456718],
    [49.0046088, 84.4977037],
    [48.9889306, 84.5103232],
    [48.9773663, 84.5638966],
    [48.9946284, 84.6042396],
    [48.9709733, 84.613265],
    [48.9671329, 84.650734],
    [48.9250327, 84.7036372],
    [48.9567424, 84.7375485],
    [48.9356877, 84.7932734],
    [48.9232186, 84.9890215],
    [48.9425958, 85.0623937],
    [48.9996266, 85.1073155],
    [48.9827108, 85.2087484],
    [48.94152, 85.2986307],
    [48.9666862, 85.3289797],
    [48.9744079, 85.5648725],
    [48.9430356, 85.5880353],
    [48.9191252, 85.5216373],
    [48.8690458, 85.4993519],
    [48.8633776, 85.6020429],
    [48.9161692, 85.7164532],
    [48.9320707, 85.8082659],
    [48.9653438, 85.8231578],
    [48.9885751, 85.8701103],
    [48.9745971, 85.9002906],
    [49.0251666, 85.9416888],
    [49.031906, 86.0060425],
    [48.9823302, 86.0136252],
    [48.9451302, 86.0818716],
    [48.9154581, 86.1045764],
    [48.9012978, 86.1714713],
    [48.9680606, 86.2479864],
    [48.9542969, 86.317826],
    [48.982002, 86.3589256],
    [48.9463723, 86.4137799],
    [48.986125, 86.4703298],
    [48.9635342, 86.5197597],
    [49.0141287, 86.606964],
    [48.9862754, 86.6398929],
    [48.9529157, 86.6368867],
    [48.9386597, 86.7269741],
    [48.938382, 86.731482],
    [48.937746, 86.7313],
    [48.937343, 86.731195],
    [48.936766, 86.731416],
    [48.936384, 86.732163],
    [48.936147, 86.732512],
    [48.935771, 86.732787],
    [48.934959, 86.733096],
    [48.93452, 86.733417],
    [48.933575, 86.733753],
    [48.93317, 86.73379],
    [48.932457, 86.733606],
    [48.931198, 86.733667],
    [48.93059, 86.733793],
    [48.930121, 86.734018],
    [48.929257, 86.734871],
    [48.92785, 86.735462],
    [48.927366, 86.735591],
    [48.92678, 86.735197],
    [48.926423, 86.735164],
    [48.925695, 86.734956],
    [48.924915, 86.735172],
    [48.924309, 86.73518],
    [48.923887, 86.735406],
    [48.923498, 86.735443],
    [48.923013, 86.735714],
    [48.922635, 86.736083],
    [48.921938, 86.737271],
    [48.921357, 86.7378],
    [48.920734, 86.737926],
    [48.92014, 86.738217],
    [48.919809, 86.738611],
    [48.919316, 86.739615],
    [48.919357, 86.740089],
    [48.919102, 86.740603],
    [48.918549, 86.740908],
    [48.918083, 86.740825],
    [48.917729, 86.740508],
    [48.917592, 86.740268],
    [48.917392, 86.740074],
    [48.916957, 86.740038],
    [48.916475, 86.740097],
    [48.916145, 86.740419],
    [48.915957, 86.740509],
    [48.915446, 86.740307],
    [48.915198, 86.7403],
    [48.91492, 86.740151],
    [48.914687, 86.740145],
    [48.914436, 86.74028],
    [48.914136, 86.740745],
    [48.913338, 86.741174],
    [48.912464, 86.741458],
    [48.912088, 86.741709],
    [48.911836, 86.742009],
    [48.911707, 86.742455],
    [48.91159, 86.743185],
    [48.911096, 86.744213],
    [48.910734, 86.744653],
    [48.910365, 86.744859],
    [48.909408, 86.745714],
    [48.908826, 86.746019],
    [48.907809, 86.746373],
    [48.907299, 86.74724],
    [48.907021, 86.747432],
    [48.906705, 86.747444],
    [48.906401, 86.747556],
    [48.906123, 86.747769],
    [48.905817, 86.748081],
    [48.905348, 86.748789],
    [48.90503, 86.749061],
    [48.904332, 86.750223],
    [48.904236, 86.750601],
    [48.903917, 86.750912],
    [48.903333, 86.751337],
    [48.902645, 86.751699],
    [48.901496, 86.751706],
    [48.901311, 86.751801],
    [48.90107, 86.752135],
    [48.900289, 86.752614],
    [48.89914, 86.753004],
    [48.898813, 86.752895],
    [48.8979, 86.752191],
    [48.896952, 86.751086],
    [48.89673, 86.751],
    [48.896413, 86.751112],
    [48.896068, 86.751382],
    [48.895867, 86.751677],
    [48.895758, 86.752054],
    [48.895491, 86.752591],
    [48.894908, 86.755236],
    [48.8948, 86.755474],
    [48.893921, 86.756471],
    [48.893718, 86.756905],
    [48.89358, 86.757462],
    [48.893535, 86.757941],
    [48.893688, 86.758445],
    [48.893443, 86.759139],
    [48.89273, 86.7628],
    [48.892499, 86.763415],
    [48.892312, 86.76373],
    [48.891859, 86.764178],
    [48.891711, 86.764474],
    [48.891655, 86.764752],
    [48.891702, 86.765453],
    [48.892082, 86.766724],
    [48.892118, 86.767085],
    [48.892036, 86.768403],
    [48.891915, 86.7687],
    [48.891729, 86.768855],
    [48.891242, 86.768882],
    [48.890912, 86.768973],
    [48.890406, 86.76954],
    [48.890164, 86.769973],
    [48.890146, 86.770373],
    [48.889713, 86.771421],
    [48.889667, 86.77196],
    [48.889717, 86.773342],
    [48.889843, 86.773805],
    [48.889812, 86.774205],
    [48.889648, 86.77476],
    [48.889472, 86.775156],
    [48.889313, 86.775251],
    [48.888984, 86.775303],
    [48.888722, 86.775235],
    [48.888364, 86.775486],
    [48.887627, 86.776191],
    [48.886972, 86.777114],
    [48.886578, 86.778183],
    [48.886351, 86.778477],
    [48.885634, 86.779038],
    [48.885009, 86.779641],
    [48.883239, 86.780314],
    [48.882867, 86.780584],
    [48.882654, 86.780858],
    [48.882175, 86.781285],
    [48.881645, 86.781611],
    [48.881183, 86.781798],
    [48.880285, 86.781821],
    [48.879589, 86.782087],
    [48.878732, 86.782171],
    [48.878531, 86.782326],
    [48.878207, 86.782959],
    [48.878041, 86.78308],
    [48.877208, 86.783164],
    [48.876382, 86.783534],
    [48.875267, 86.783576],
    [48.875031, 86.783712],
    [48.874853, 86.783921],
    [48.874203, 86.785098],
    [48.87407, 86.785469],
    [48.874003, 86.786216],
    [48.873941, 86.786446],
    [48.873786, 86.786656],
    [48.873492, 86.78672],
    [48.873327, 86.78684],
    [48.8731, 86.787172],
    [48.872907, 86.787631],
    [48.872771, 86.788216],
    [48.872372, 86.789239],
    [48.872156, 86.789697],
    [48.87193, 86.789905],
    [48.87144, 86.790694],
    [48.87125, 86.791901],
    [48.871106, 86.793686],
    [48.870869, 86.794946],
    [48.870513, 86.796238],
    [48.870283, 86.796891],
    [48.869742, 86.797982],
    [48.869229, 86.798717],
    [48.868872, 86.799135],
    [48.868398, 86.799532],
    [48.867641, 86.799913],
    [48.867131, 86.800434],
    [48.866526, 86.80195],
    [48.86633, 86.802659],
    [48.866114, 86.803116],
    [48.865888, 86.803377],
    [48.865581, 86.803529],
    [48.865193, 86.803608],
    [48.864924, 86.803565],
    [48.864711, 86.803719],
    [48.864197, 86.804508],
    [48.864193, 86.804829],
    [48.863982, 86.805786],
    [48.86374, 86.806474],
    [48.86356, 86.806755],
    [48.8633, 86.806926],
    [48.862889, 86.806968],
    [48.862062, 86.806842],
    [48.861916, 86.80693],
    [48.861217, 86.808171],
    [48.860313, 86.809433],
    [48.85987, 86.809954],
    [48.859739, 86.810249],
    [48.859705, 86.810911],
    [48.859642, 86.811221],
    [48.859362, 86.811746],
    [48.858842, 86.813031],
    [48.85865, 86.813377],
    [48.857691, 86.814195],
    [48.857281, 86.814879],
    [48.857087, 86.815342],
    [48.856887, 86.815635],
    [48.856789, 86.815918],
    [48.856554, 86.816276],
    [48.856312, 86.816503],
    [48.856106, 86.816588],
    [48.855849, 86.816581],
    [48.855628, 86.816497],
    [48.855391, 86.816296],
    [48.855232, 86.816031],
    [48.855083, 86.815585],
    [48.854999, 86.815492],
    [48.854896, 86.815489],
    [48.854578, 86.81561],
    [48.852501, 86.816979],
    [48.851832, 86.817285],
    [48.851386, 86.817406],
    [48.850429, 86.817258],
    [48.850183, 86.817324],
    [48.849919, 86.817473],
    [48.849007, 86.818195],
    [48.847533, 86.818793],
    [48.846811, 86.818846],
    [48.84655, 86.818802],
    [48.846416, 86.81869],
    [48.845991, 86.817799],
    [48.845739, 86.81766],
    [48.845255, 86.817718],
    [48.844632, 86.818111],
    [48.843789, 86.818518],
    [48.84333, 86.818445],
    [48.84202, 86.818638],
    [48.841813, 86.818704],
    [48.841436, 86.819095],
    [48.84107, 86.819145],
    [48.840787, 86.818992],
    [48.840552, 86.818757],
    [48.840163, 86.818144],
    [48.839676, 86.817806],
    [48.839255, 86.81718],
    [48.838837, 86.816975],
    [48.837486, 86.817275],
    [48.837177, 86.817255],
    [48.836692, 86.817334],
    [48.836178, 86.817284],
    [48.835907, 86.817408],
    [48.835754, 86.817549],
    [48.835185, 86.818774],
    [48.834715, 86.818965],
    [48.833126, 86.819222],
    [48.832992, 86.819158],
    [48.832761, 86.818746],
    [48.832297, 86.818432],
    [48.831987, 86.817893],
    [48.831333, 86.81755],
    [48.830727, 86.817172],
    [48.830547, 86.816999],
    [48.829877, 86.815511],
    [48.829841, 86.815197],
    [48.829562, 86.814009],
    [48.829265, 86.813603],
    [48.828764, 86.812482],
    [48.828582, 86.811754],
    [48.828206, 86.810745],
    [48.8281, 86.81026],
    [48.827923, 86.809846],
    [48.827737, 86.809527],
    [48.827259, 86.808959],
    [48.826691, 86.808762],
    [48.82655, 86.808638],
    [48.826505, 86.808432],
    [48.826523, 86.808192],
    [48.826656, 86.80769],
    [48.82667, 86.806522],
    [48.826571, 86.806194],
    [48.82623, 86.80551],
    [48.826066, 86.805337],
    [48.825989, 86.805131],
    [48.825938, 86.804756],
    [48.825868, 86.804622],
    [48.825539, 86.804312],
    [48.825341, 86.804306],
    [48.825054, 86.804431],
    [48.824741, 86.804431],
    [48.824104, 86.804184],
    [48.823862, 86.803965],
    [48.823442, 86.802962],
    [48.822922, 86.801321],
    [48.82276, 86.800184],
    [48.82266, 86.799827],
    [48.822227, 86.798984],
    [48.821868, 86.79878],
    [48.821593, 86.798436],
    [48.821216, 86.797771],
    [48.820979, 86.797128],
    [48.820544, 86.795559],
    [48.820373, 86.7952],
    [48.820121, 86.794893],
    [48.819694, 86.794527],
    [48.818953, 86.794171],
    [48.818745, 86.793988],
    [48.8182, 86.793309],
    [48.817232, 86.791425],
    [48.816518, 86.789761],
    [48.816372, 86.789314],
    [48.816132, 86.788884],
    [48.815501, 86.78807],
    [48.815091, 86.787263],
    [48.814649, 86.786172],
    [48.814013, 86.784828],
    [48.813587, 86.783676],
    [48.812188, 86.780773],
    [48.811671, 86.779149],
    [48.811036, 86.776638],
    [48.810729, 86.776011],
    [48.810238, 86.77522],
    [48.809914, 86.774026],
    [48.809643, 86.772728],
    [48.809407, 86.770935],
    [48.808973, 86.76919],
    [48.808946, 86.768482],
    [48.808846, 86.768108],
    [48.808607, 86.767606],
    [48.808285, 86.767244],
    [48.807511, 86.76671],
    [48.807374, 86.766476],
    [48.80732, 86.766086],
    [48.807241, 86.765854],
    [48.806883, 86.765632],
    [48.806734, 86.765381],
    [48.806611, 86.764988],
    [48.806027, 86.763835],
    [48.805099, 86.762519],
    [48.80469, 86.761641],
    [48.8043, 86.761101],
    [48.804081, 86.760847],
    [48.803919, 86.760772],
    [48.803488, 86.760796],
    [48.803064, 86.761191],
    [48.802761, 86.761201],
    [48.802471, 86.76107],
    [48.802287, 86.760888],
    [48.802127, 86.760583],
    [48.801839, 86.760381],
    [48.801572, 86.760285],
    [48.801032, 86.760589],
    [48.800795, 86.760627],
    [48.800377, 86.760439],
    [48.799959, 86.760375],
    [48.799658, 86.760173],
    [48.799593, 86.759799],
    [48.799215, 86.759241],
    [48.799053, 86.759131],
    [48.79847, 86.759168],
    [48.797797, 86.758992],
    [48.797354, 86.758962],
    [48.797041, 86.758812],
    [48.796267, 86.75934],
    [48.79601, 86.759386],
    [48.795684, 86.759378],
    [48.795399, 86.759263],
    [48.79498, 86.759199],
    [48.794595, 86.759242],
    [48.794281, 86.759198],
    [48.79383, 86.758992],
    [48.793484, 86.758682],
    [48.792797, 86.757585],
    [48.792532, 86.757348],
    [48.792301, 86.757236],
    [48.791963, 86.757245],
    [48.791341, 86.757618],
    [48.790851, 86.75771],
    [48.789216, 86.75736],
    [48.788812, 86.757066],
    [48.788524, 86.756776],
    [48.788365, 86.756453],
    [48.787893, 86.754903],
    [48.787828, 86.754512],
    [48.787835, 86.75384],
    [48.787702, 86.753307],
    [48.787235, 86.753382],
    [48.786017, 86.753969],
    [48.785469, 86.754008],
    [48.785122, 86.753751],
    [48.784662, 86.753262],
    [48.783947, 86.752327],
    [48.78299, 86.751454],
    [48.782086, 86.751076],
    [48.781549, 86.751115],
    [48.781209, 86.751318],
    [48.780585, 86.751885],
    [48.780363, 86.75195],
    [48.779956, 86.75194],
    [48.7797, 86.751862],
    [48.778972, 86.751348],
    [48.778088, 86.751209],
    [48.777878, 86.751221],
    [48.777725, 86.751323],
    [48.776884, 86.752574],
    [48.776684, 86.752798],
    [48.775864, 86.753183],
    [48.77442, 86.754135],
    [48.772412, 86.756691],
    [48.771666, 86.757409],
    [48.771134, 86.7577],
    [48.76807, 86.758629],
    [48.766069, 86.760522],
    [48.765638, 86.760829],
    [48.765166, 86.761084],
    [48.764896, 86.761165],
    [48.764124, 86.761234],
    [48.763803, 86.761321],
    [48.76355, 86.761467],
    [48.761169, 86.764317],
    [48.759864, 86.766215],
    [48.758341, 86.767602],
    [48.757775, 86.767891],
    [48.756339, 86.768229],
    [48.755686, 86.768546],
    [48.754527, 86.768636],
    [48.753867, 86.769014],
    [48.753253, 86.769465],
    [48.7521, 86.769964],
    [48.751815, 86.770921],
    [48.75154, 86.771512],
    [48.7512, 86.772455],
    [48.75012, 86.773463],
    [48.749935, 86.773493],
    [48.749683, 86.773428],
    [48.7495, 86.773306],
    [48.749325, 86.773092],
    [48.749215, 86.772716],
    [48.748384, 86.772146],
    [48.748086, 86.772022],
    [48.747548, 86.771996],
    [48.747247, 86.772191],
    [48.746927, 86.772602],
    [48.746378, 86.772867],
    [48.745774, 86.773305],
    [48.745556, 86.773556],
    [48.745202, 86.774257],
    [48.743747, 86.774768],
    [48.742715, 86.775031],
    [48.739991, 86.775438],
    [48.738298, 86.776313],
    [48.737372, 86.776623],
    [48.736038, 86.777728],
    [48.735753, 86.777837],
    [48.735492, 86.777842],
    [48.734941, 86.77764],
    [48.73386, 86.776564],
    [48.733632, 86.776406],
    [48.733318, 86.776305],
    [48.732757, 86.776325],
    [48.731911, 86.777047],
    [48.731733, 86.777136],
    [48.731433, 86.777174],
    [48.730958, 86.777115],
    [48.730441, 86.776822],
    [48.729865, 86.776818],
    [48.729559, 86.77674],
    [48.72907, 86.776483],
    [48.728703, 86.776368],
    [48.728421, 86.776139],
    [48.728301, 86.775926],
    [48.728234, 86.775738],
    [48.728221, 86.775528],
    [48.727745, 86.774176],
    [48.72754, 86.77395],
    [48.727342, 86.773839],
    [48.727004, 86.773819],
    [48.726591, 86.773668],
    [48.726045, 86.773322],
    [48.72488, 86.772441],
    [48.724543, 86.772327],
    [48.724228, 86.772353],
    [48.723827, 86.772483],
    [48.723036, 86.773125],
    [48.722176, 86.773032],
    [48.721172, 86.773197],
    [48.720619, 86.773135],
    [48.720398, 86.773001],
    [48.720224, 86.772799],
    [48.719886, 86.772149],
    [48.719842, 86.771927],
    [48.71986, 86.771683],
    [48.720128, 86.771143],
    [48.720212, 86.770516],
    [48.72008, 86.769977],
    [48.719929, 86.769729],
    [48.719709, 86.769467],
    [48.718408, 86.769094],
    [48.717604, 86.768732],
    [48.71736, 86.768574],
    [48.717133, 86.768324],
    [48.716968, 86.767981],
    [48.716566, 86.766818],
    [48.716326, 86.766334],
    [48.716024, 86.765884],
    [48.715778, 86.765272],
    [48.714222, 86.762925],
    [48.714003, 86.762708],
    [48.713064, 86.762183],
    [48.71254, 86.761715],
    [48.7121, 86.761179],
    [48.711622, 86.759991],
    [48.711087, 86.759069],
    [48.710928, 86.758948],
    [48.710659, 86.758894],
    [48.7105, 86.758716],
    [48.710138, 86.758116],
    [48.709667, 86.757649],
    [48.709577, 86.757437],
    [48.709459, 86.756375],
    [48.709362, 86.756139],
    [48.708938, 86.755546],
    [48.708771, 86.755414],
    [48.708538, 86.754919],
    [48.70809, 86.754476],
    [48.707611, 86.754102],
    [48.706822, 86.752268],
    [48.706667, 86.751717],
    [48.706495, 86.751375],
    [48.706063, 86.75084],
    [48.70589, 86.750544],
    [48.705578, 86.75028],
    [48.705337, 86.749854],
    [48.705233, 86.749503],
    [48.704825, 86.74891],
    [48.704643, 86.748731],
    [48.704391, 86.748596],
    [48.704077, 86.748518],
    [48.703363, 86.748575],
    [48.70282, 86.748386],
    [48.702555, 86.74803],
    [48.702495, 86.747622],
    [48.701596, 86.746295],
    [48.701355, 86.745811],
    [48.700881, 86.745613],
    [48.700668, 86.745433],
    [48.700487, 86.745172],
    [48.70036, 86.744854],
    [48.700074, 86.744523],
    [48.699677, 86.744327],
    [48.699274, 86.743956],
    [48.698687, 86.743591],
    [48.698368, 86.743233],
    [48.698129, 86.742645],
    [48.698071, 86.742376],
    [48.698193, 86.740728],
    [48.698171, 86.739902],
    [48.697973, 86.739815],
    [48.697726, 86.739855],
    [48.697421, 86.739731],
    [48.696956, 86.739416],
    [48.696621, 86.739116],
    [48.695741, 86.738139],
    [48.694844, 86.737533],
    [48.694616, 86.737294],
    [48.694413, 86.736975],
    [48.694047, 86.735999],
    [48.693912, 86.735763],
    [48.693752, 86.735607],
    [48.693278, 86.735374],
    [48.693056, 86.735368],
    [48.692803, 86.735257],
    [48.692283, 86.735329],
    [48.69137, 86.7352],
    [48.690531, 86.73469],
    [48.689958, 86.733732],
    [48.689628, 86.732956],
    [48.689355, 86.732558],
    [48.688482, 86.731698],
    [48.687743, 86.731085],
    [48.687509, 86.73073],
    [48.687164, 86.730046],
    [48.687106, 86.729672],
    [48.687009, 86.729437],
    [48.686782, 86.729117],
    [48.686642, 86.728648],
    [48.686175, 86.727798],
    [48.685894, 86.727488],
    [48.685706, 86.727134],
    [48.68555, 86.7267],
    [48.685292, 86.726336],
    [48.685095, 86.726133],
    [48.684559, 86.72598],
    [48.683805, 86.72603],
    [48.683412, 86.726171],
    [48.683266, 86.726156],
    [48.68316, 86.726037],
    [48.683017, 86.725754],
    [48.682608, 86.724591],
    [48.682169, 86.723952],
    [48.681788, 86.723],
    [48.681321, 86.722173],
    [48.680731, 86.721353],
    [48.68049, 86.720905],
    [48.679449, 86.718516],
    [48.67928, 86.71786],
    [48.678198, 86.717018],
    [48.677676, 86.716283],
    [48.677322, 86.715558],
    [48.676734, 86.713902],
    [48.676579, 86.713259],
    [48.676156, 86.711922],
    [48.67617, 86.711375],
    [48.675874, 86.71039],
    [48.675745, 86.710271],
    [48.675223, 86.710222],
    [48.675065, 86.710146],
    [48.67463, 86.709851],
    [48.67406, 86.70919],
    [48.673302, 86.707462],
    [48.673074, 86.706798],
    [48.67293, 86.706142],
    [48.672783, 86.705768],
    [48.67253, 86.705409],
    [48.671712, 86.70463],
    [48.671342, 86.704123],
    [48.671021, 86.703827],
    [48.670215, 86.702769],
    [48.669798, 86.702657],
    [48.669639, 86.702322],
    [48.669579, 86.701744],
    [48.66933, 86.701047],
    [48.668937, 86.700475],
    [48.668259, 86.700464],
    [48.667981, 86.700307],
    [48.666957, 86.700008],
    [48.666644, 86.699754],
    [48.665918, 86.699449],
    [48.6652, 86.699423],
    [48.664666, 86.699286],
    [48.664385, 86.69935],
    [48.663847, 86.699601],
    [48.663401, 86.699942],
    [48.663051, 86.699986],
    [48.662819, 86.699857],
    [48.662613, 86.699569],
    [48.662436, 86.698736],
    [48.662275, 86.698503],
    [48.661791, 86.698032],
    [48.661178, 86.697699],
    [48.660968, 86.697747],
    [48.660709, 86.697935],
    [48.660544, 86.698177],
    [48.660269, 86.698787],
    [48.659962, 86.69915],
    [48.657981, 86.699267],
    [48.657221, 86.699221],
    [48.655781, 86.698954],
    [48.655191, 86.698765],
    [48.65447, 86.698372],
    [48.654005, 86.698039],
    [48.653061, 86.697801],
    [48.652656, 86.69759],
    [48.652734, 86.696915],
    [48.652555, 86.695936],
    [48.652158, 86.69473],
    [48.651608, 86.694207],
    [48.651344, 86.6942],
    [48.650796, 86.694873],
    [48.649662, 86.69418],
    [48.64946, 86.693887],
    [48.649378, 86.693353],
    [48.649415, 86.69269],
    [48.649376, 86.692179],
    [48.649205, 86.691754],
    [48.648772, 86.691234],
    [48.648615, 86.690831],
    [48.648726, 86.690058],
    [48.648514, 86.689344],
    [48.648328, 86.689007],
    [48.648152, 86.689047],
    [48.648091, 86.689289],
    [48.647871, 86.689328],
    [48.647637, 86.688947],
    [48.647347, 86.688752],
    [48.647179, 86.688708],
    [48.647011, 86.68877],
    [48.646553, 86.689362],
    [48.646188, 86.68962],
    [48.645548, 86.689885],
    [48.645325, 86.69004],
    [48.644957, 86.690513],
    [48.644546, 86.690877],
    [48.643195, 86.691774],
    [48.642957, 86.691688],
    [48.642764, 86.691509],
    [48.642065, 86.689899],
    [48.641308, 86.688836],
    [48.641089, 86.688603],
    [48.640826, 86.688395],
    [48.640516, 86.688387],
    [48.640329, 86.68853],
    [48.640157, 86.688927],
    [48.640085, 86.688992],
    [48.640015, 86.68895],
    [48.639624, 86.687535],
    [48.639524, 86.686617],
    [48.639041, 86.685534],
    [48.638831, 86.684472],
    [48.638912, 86.683564],
    [48.639039, 86.683245],
    [48.638882, 86.682925],
    [48.638969, 86.682212],
    [48.638932, 86.682003],
    [48.63847, 86.681584],
    [48.637952, 86.680985],
    [48.637523, 86.681243],
    [48.637332, 86.681258],
    [48.637249, 86.681087],
    [48.637338, 86.680732],
    [48.637295, 86.680413],
    [48.636841, 86.678831],
    [48.636675, 86.678571],
    [48.636307, 86.678217],
    [48.635824, 86.678353],
    [48.635679, 86.678596],
    [48.635286, 86.678803],
    [48.634547, 86.678113],
    [48.63418, 86.677663],
    [48.633719, 86.677009],
    [48.633598, 86.676725],
    [48.633483, 86.675049],
    [48.633508, 86.674367],
    [48.633707, 86.673127],
    [48.633853, 86.672783],
    [48.633919, 86.672357],
    [48.633971, 86.670888],
    [48.633858, 86.669949],
    [48.633367, 86.669344],
    [48.633042, 86.669283],
    [48.632776, 86.669383],
    [48.632082, 86.669953],
    [48.631906, 86.669922],
    [48.631731, 86.669811],
    [48.631566, 86.66954],
    [48.631374, 86.669348],
    [48.631279, 86.669159],
    [48.631144, 86.668662],
    [48.631017, 86.667338],
    [48.63112, 86.66674],
    [48.63111, 86.666019],
    [48.631034, 86.665737],
    [48.630663, 86.664941],
    [48.630492, 86.664456],
    [48.630436, 86.663907],
    [48.63051, 86.663726],
    [48.63047, 86.663342],
    [48.630463, 86.662308],
    [48.630263, 86.661731],
    [48.630103, 86.660618],
    [48.630022, 86.659639],
    [48.630232, 86.65845],
    [48.629699, 86.657516],
    [48.629584, 86.657108],
    [48.629479, 86.656116],
    [48.629462, 86.655585],
    [48.629522, 86.654176],
    [48.629715, 86.652872],
    [48.629381, 86.651994],
    [48.629243, 86.650932],
    [48.629149, 86.649284],
    [48.629329, 86.648421],
    [48.629375, 86.647566],
    [48.629624, 86.646375],
    [48.629692, 86.645555],
    [48.629647, 86.644911],
    [48.629544, 86.644751],
    [48.629426, 86.644703],
    [48.629196, 86.64383],
    [48.629209, 86.643323],
    [48.629094, 86.642205],
    [48.629004, 86.641617],
    [48.628902, 86.641251],
    [48.628823, 86.640333],
    [48.628528, 86.640089],
    [48.62834, 86.639499],
    [48.627935, 86.639061],
    [48.627524, 86.638845],
    [48.626985, 86.637739],
    [48.626857, 86.63715],
    [48.626871, 86.636531],
    [48.626783, 86.635627],
    [48.62646, 86.635018],
    [48.626061, 86.634814],
    [48.625734, 86.634502],
    [48.625537, 86.634129],
    [48.62444, 86.633495],
    [48.62404, 86.633355],
    [48.62306, 86.633189],
    [48.621785, 86.633093],
    [48.621496, 86.633281],
    [48.621207, 86.633578],
    [48.620976, 86.633659],
    [48.620762, 86.633611],
    [48.620467, 86.632997],
    [48.620222, 86.632741],
    [48.619876, 86.632733],
    [48.619098, 86.633113],
    [48.618663, 86.633751],
    [48.61753, 86.633349],
    [48.617066, 86.633613],
    [48.616559, 86.633301],
    [48.615867, 86.633285],
    [48.615511, 86.633811],
    [48.615165, 86.634019],
    [48.61461, 86.634167],
    [48.613304, 86.634331],
    [48.612701, 86.634338],
    [48.611459, 86.633875],
    [48.610304, 86.633262],
    [48.609976, 86.633015],
    [48.608444, 86.631461],
    [48.606914, 86.629427],
    [48.606642, 86.629377],
    [48.605781, 86.629443],
    [48.605366, 86.629412],
    [48.604953, 86.629142],
    [48.603903, 86.628076],
    [48.603749, 86.627704],
    [48.603391, 86.62622],
    [48.603167, 86.625695],
    [48.602572, 86.625008],
    [48.601836, 86.623875],
    [48.601197, 86.623253],
    [48.600764, 86.623151],
    [48.600436, 86.622711],
    [48.600195, 86.622336],
    [48.600037, 86.62188],
    [48.599967, 86.621427],
    [48.599653, 86.621206],
    [48.599297, 86.620575],
    [48.599017, 86.620543],
    [48.598545, 86.619409],
    [48.598276, 86.619063],
    [48.597816, 86.61822],
    [48.597896, 86.618114],
    [48.597947, 86.617891],
    [48.597989, 86.617095],
    [48.597927, 86.616645],
    [48.597751, 86.616192],
    [48.597534, 86.614792],
    [48.597523, 86.614293],
    [48.597792, 86.613801],
    [48.597795, 86.613528],
    [48.597912, 86.613306],
    [48.597816, 86.61303],
    [48.597136, 86.611918],
    [48.596463, 86.611603],
    [48.595822, 86.611488],
    [48.595491, 86.61123],
    [48.594767, 86.610925],
    [48.594113, 86.61053],
    [48.593869, 86.610462],
    [48.592032, 86.610579],
    [48.591435, 86.610514],
    [48.591212, 86.610416],
    [48.59101, 86.610237],
    [48.590729, 86.60982],
    [48.590252, 86.609687],
    [48.589485, 86.609721],
    [48.588508, 86.610133],
    [48.588161, 86.610174],
    [48.587866, 86.610093],
    [48.587463, 86.609187],
    [48.587283, 86.609058],
    [48.58697, 86.609051],
    [48.586856, 86.608948],
    [48.586794, 86.608598],
    [48.586666, 86.608221],
    [48.58609, 86.608108],
    [48.585845, 86.607903],
    [48.585635, 86.607475],
    [48.585374, 86.60727],
    [48.585114, 86.60694],
    [48.585012, 86.605542],
    [48.584857, 86.604592],
    [48.584894, 86.604195],
    [48.584711, 86.603097],
    [48.584759, 86.60241],
    [48.584729, 86.602039],
    [48.584518, 86.601377],
    [48.583085, 86.601383],
    [48.582938, 86.60128],
    [48.582049, 86.60116],
    [48.581773, 86.600804],
    [48.581563, 86.600326],
    [48.581102, 86.598672],
    [48.580847, 86.598574],
    [48.579874, 86.598601],
    [48.579382, 86.598366],
    [48.578987, 86.598381],
    [48.578407, 86.598642],
    [48.577292, 86.598118],
    [48.576914, 86.598084],
    [48.57616, 86.597718],
    [48.576036, 86.596919],
    [48.575842, 86.596565],
    [48.575401, 86.596132],
    [48.57524, 86.59578],
    [48.575267, 86.594734],
    [48.57519, 86.594259],
    [48.574974, 86.593918],
    [48.574675, 86.594185],
    [48.574344, 86.594326],
    [48.573932, 86.594292],
    [48.573424, 86.594081],
    [48.573292, 86.594128],
    [48.573191, 86.594275],
    [48.573085, 86.595019],
    [48.572628, 86.596154],
    [48.572523, 86.596774],
    [48.572323, 86.597019],
    [48.572011, 86.596962],
    [48.571554, 86.596478],
    [48.57057, 86.596032],
    [48.569684, 86.596202],
    [48.569396, 86.596483],
    [48.569029, 86.596556],
    [48.568736, 86.596236],
    [48.568414, 86.595482],
    [48.567755, 86.594926],
    [48.567186, 86.594728],
    [48.566438, 86.594772],
    [48.565566, 86.595018],
    [48.56521, 86.594896],
    [48.564749, 86.594941],
    [48.564319, 86.595683],
    [48.563584, 86.595554],
    [48.563118, 86.5961],
    [48.562538, 86.596976],
    [48.561675, 86.596762],
    [48.560797, 86.596241],
    [48.56071, 86.595738],
    [48.560382, 86.595425],
    [48.559851, 86.59519],
    [48.559461, 86.595515],
    [48.55882, 86.596498],
    [48.558597, 86.597088],
    [48.558417, 86.597351],
    [48.558267, 86.597429],
    [48.557564, 86.597085],
    [48.55547, 86.596613],
    [48.554754, 86.596226],
    [48.554025, 86.595696],
    [48.553528, 86.595213],
    [48.553246, 86.594816],
    [48.55247, 86.594396],
    [48.551742, 86.593573],
    [48.551263, 86.593645],
    [48.549729, 86.59425],
    [48.549213, 86.594321],
    [48.54879, 86.594283],
    [48.548185, 86.594075],
    [48.547844, 86.593645],
    [48.547346, 86.59369],
    [48.546799, 86.593232],
    [48.546364, 86.592555],
    [48.545943, 86.592295],
    [48.545693, 86.591511],
    [48.544803, 86.590322],
    [48.544517, 86.589482],
    [48.544061, 86.589138],
    [48.543489, 86.589235],
    [48.542773, 86.58908],
    [48.542257, 86.589096],
    [48.541948, 86.588783],
    [48.541748, 86.588473],
    [48.541659, 86.58822],
    [48.541456, 86.588113],
    [48.540882, 86.587252],
    [48.540539, 86.586633],
    [48.540488, 86.586159],
    [48.540495, 86.58552],
    [48.540302, 86.58457],
    [48.539782, 86.58434],
    [48.539271, 86.583856],
    [48.5391183, 86.5835243],
    [48.5399361, 86.5832655],
    [48.5067139, 86.4941177],
    [48.5038605, 86.4901657],
    [48.4828186, 86.4392929],
    [48.4814873, 86.4347916],
    [48.4799309, 86.4302826],
    [48.4765778, 86.421257],
    [48.4751282, 86.4153366],
    [48.4744987, 86.4112244],
    [48.4745865, 86.4081955],
    [48.4761276, 86.4040146],
    [48.4812927, 86.3959885],
    [48.4882774, 86.3872299],
    [48.4937134, 86.3829727],
    [48.4975433, 86.378418],
    [48.4998016, 86.3759537],
    [48.5009041, 86.3721466],
    [48.4965324, 86.3688049],
    [48.4933014, 86.365097],
    [48.4907265, 86.358963],
    [48.4897308, 86.3510742],
    [48.4928436, 86.3430862],
    [48.4920998, 86.3372574],
    [48.4899559, 86.3290558],
    [48.4878197, 86.3211899],
    [48.4872932, 86.3146744],
    [48.4872475, 86.3105469],
    [48.4885216, 86.3019104],
    [48.4837532, 86.3040924],
    [48.4787331, 86.3038712],
    [48.4745979, 86.3015671],
    [48.470417, 86.2944489],
    [48.4660034, 86.2876816],
    [48.460022, 86.28302],
    [48.4542503, 86.2769775],
    [48.4486694, 86.2678375],
    [48.4432716, 86.25457],
    [48.4399796, 86.2460632],
    [48.4373894, 86.2392578],
    [48.4354935, 86.2327805],
    [48.4339905, 86.221138],
    [48.4319687, 86.2137756],
    [48.4307671, 86.2045746],
    [48.430584, 86.1966324],
    [48.4306602, 86.1884689],
    [48.4307365, 86.178154],
    [48.4303513, 86.1678238],
    [48.4304161, 86.1581955],
    [48.4309235, 86.1499558],
    [48.431427, 86.143425],
    [48.4317055, 86.1362076],
    [48.432682, 86.1262512],
    [48.4334297, 86.1166306],
    [48.4334869, 86.1073456],
    [48.4333229, 86.0966797],
    [48.433651, 86.080864],
    [48.4344139, 86.0678101],
    [48.4351921, 86.051651],
    [48.4354935, 86.0396194],
    [48.4355545, 86.0289536],
    [48.4349327, 86.0169067],
    [48.4349823, 86.0076218],
    [48.4343681, 85.9948883],
    [48.4339714, 85.9828491],
    [48.4340286, 85.9718399],
    [48.4349937, 85.9618835],
    [48.4348145, 85.951561],
    [48.4339485, 85.9422607],
    [48.4333038, 85.9333115],
    [48.4333191, 85.9295273],
    [48.432888, 85.9243698],
    [48.4312935, 85.9088516],
    [48.4288826, 85.8999939],
    [48.4266815, 85.8897629],
    [48.4285316, 85.8759537],
    [48.4294662, 85.8618317],
    [48.4289093, 85.8549805],
    [48.4283066, 85.8453751],
    [48.4272881, 85.8381958],
    [48.4216347, 85.8267059],
    [48.4176521, 85.8048553],
    [48.4141769, 85.7867737],
    [48.4119911, 85.7782593],
    [48.408268, 85.7735825],
    [48.4033127, 85.7631073],
    [48.3981667, 85.755394],
    [48.3912621, 85.7515183],
    [48.3864098, 85.7479172],
    [48.3806, 85.7412567],
    [48.3766022, 85.7338486],
    [48.3712997, 85.7306061],
    [48.3650131, 85.7226028],
    [48.3644066, 85.7136993],
    [48.3560257, 85.7174377],
    [48.3512306, 85.7172699],
    [48.3479118, 85.7094955],
    [48.3403091, 85.7049713],
    [48.335495, 85.7037735],
    [48.3323898, 85.6953201],
    [48.325325, 85.6959229],
    [48.3196487, 85.6978455],
    [48.3122787, 85.6936569],
    [48.3018417, 85.6974716],
    [48.297123, 85.6880493],
    [48.2888451, 85.6842422],
    [48.277462, 85.6860352],
    [48.2729797, 85.6769485],
    [48.2670212, 85.6754608],
    [48.262188, 85.6728973],
    [48.2577629, 85.6675797],
    [48.2506676, 85.666481],
    [48.2458344, 85.6639175],
    [48.2422409, 85.6537857],
    [48.2366104, 85.6447525],
    [48.2312317, 85.637085],
    [48.2231445, 85.6312256],
    [48.2159958, 85.626709],
    [48.2100067, 85.6235199],
    [48.2042694, 85.6216812],
    [48.2104721, 85.6104965],
    [48.2051506, 85.6065979],
    [48.2000847, 85.603714],
    [48.1989708, 85.5921249],
    [48.1938934, 85.5889053],
    [48.1865845, 85.588501],
    [48.1792564, 85.5870743],
    [48.1712151, 85.5839691],
    [48.1658936, 85.5797348],
    [48.1603737, 85.5775604],
    [48.1574732, 85.5742718],
    [48.1570739, 85.5745272],
    [48.1567782, 85.5744756],
    [48.1566041, 85.5742413],
    [48.1566037, 85.5737981],
    [48.1566904, 85.5734329],
    [48.1570031, 85.5730673],
    [48.1573679, 85.5725452],
    [48.1574891, 85.5718932],
    [48.15768, 85.5713714],
    [48.1576274, 85.5709283],
    [48.1573489, 85.5706942],
    [48.1571752, 85.570851],
    [48.1571581, 85.5712421],
    [48.1571062, 85.5715029],
    [48.1569844, 85.571451],
    [48.1569319, 85.5711382],
    [48.1567927, 85.5710342],
    [48.1565488, 85.5705654],
    [48.1564616, 85.5702267],
    [48.1565655, 85.5697833],
    [48.1567567, 85.5696004],
    [48.1569133, 85.5696522],
    [48.1571743, 85.5698603],
    [48.1573307, 85.5697557],
    [48.1573826, 85.5694428],
    [48.1573649, 85.5690778],
    [48.1574862, 85.5685561],
    [48.1575207, 85.5682171],
    [48.1574857, 85.5679826],
    [48.157277, 85.5679048],
    [48.1570681, 85.5677227],
    [48.1568246, 85.5676971],
    [48.1565291, 85.5678802],
    [48.1562507, 85.5676982],
    [48.156146, 85.5673074],
    [48.1559023, 85.5671515],
    [48.1556242, 85.5672302],
    [48.1553807, 85.5672828],
    [48.1548413, 85.566945],
    [48.1542148, 85.5665031],
    [48.1534839, 85.5661135],
    [48.1531878, 85.5656448],
    [48.1531004, 85.5650975],
    [48.1530477, 85.564498],
    [48.1530817, 85.5637419],
    [48.1529978, 85.5630276],
    [48.152806, 85.5625327],
    [48.1524057, 85.5621686],
    [48.1519012, 85.5620653],
    [48.1515188, 85.5623268],
    [48.1513626, 85.562666],
    [48.1512066, 85.5631877],
    [48.1511722, 85.5636831],
    [48.1510682, 85.5639701],
    [48.1507379, 85.5641793],
    [48.150112, 85.5643891],
    [48.1496946, 85.5643639],
    [48.1493291, 85.56413],
    [48.1491898, 85.5638696],
    [48.1492937, 85.5634263],
    [48.1495018, 85.5628263],
    [48.1499708, 85.5621997],
    [48.1503178, 85.5612345],
    [48.1503171, 85.5604785],
    [48.1501079, 85.5600097],
    [48.1494464, 85.5593334],
    [48.1490463, 85.5592821],
    [48.1484205, 85.5595702],
    [48.1477079, 85.5600409],
    [48.1473431, 85.5604848],
    [48.1468569, 85.56132],
    [48.1467009, 85.5618938],
    [48.1466493, 85.5624934],
    [48.1466672, 85.5630668],
    [48.1465283, 85.5633017],
    [48.1463023, 85.5634325],
    [48.1461109, 85.5633286],
    [48.1460236, 85.5629899],
    [48.1457798, 85.5626255],
    [48.145484, 85.5624698],
    [48.1454142, 85.5622093],
    [48.145501, 85.5620787],
    [48.1453965, 85.5618965],
    [48.1450137, 85.5617409],
    [48.1445958, 85.5612205],
    [48.1445085, 85.5608558],
    [48.1447341, 85.5602297],
    [48.144908, 85.5602033],
    [48.1450647, 85.5603854],
    [48.1452907, 85.5603068],
    [48.1453684, 85.5601832],
    [48.1451591, 85.5595059],
    [48.1451584, 85.5588022],
    [48.1451752, 85.5582027],
    [48.1448268, 85.5575779],
    [48.1444439, 85.5573181],
    [48.1441134, 85.5572667],
    [48.1437828, 85.5570589],
    [48.1435909, 85.5564859],
    [48.1433125, 85.5563041],
    [48.142982, 85.5562787],
    [48.1424429, 85.5562538],
    [48.1419727, 85.5557075],
    [48.1411022, 85.5547712],
    [48.140197, 85.5539653],
    [48.1393966, 85.5536022],
    [48.1383529, 85.553396],
    [48.1377177, 85.5535463],
    [48.1370051, 85.5540692],
    [48.1363276, 85.5548787],
    [48.1359109, 85.5556093],
    [48.1355643, 85.5568349],
    [48.1355126, 85.5573302],
    [48.1356002, 85.5579554],
    [48.1356354, 85.5584244],
    [48.1356009, 85.5587633],
    [48.1353578, 85.5591026],
    [48.1349232, 85.5592599],
    [48.1345408, 85.5595734],
    [48.1342979, 85.5601994],
    [48.1340726, 85.5609816],
    [48.1337429, 85.5618162],
    [48.1335179, 85.5629111],
    [48.1331011, 85.5635635],
    [48.1328232, 85.564007],
    [48.1325623, 85.5639555],
    [48.1322664, 85.5637476],
    [48.1321442, 85.5632267],
    [48.1322479, 85.562549],
    [48.1322123, 85.5616109],
    [48.132142, 85.5609075],
    [48.1318809, 85.5605954],
    [48.1319783, 85.5596191],
    [48.1319257, 85.5591762],
    [48.1318732, 85.5588115],
    [48.1316816, 85.5585514],
    [48.1314381, 85.558578],
    [48.1311425, 85.5585786],
    [48.1309167, 85.5588657],
    [48.1306209, 85.5587361],
    [48.130325, 85.5585022],
    [48.1301164, 85.5586329],
    [48.1299599, 85.5586333],
    [48.1290209, 85.5587916],
    [48.1286904, 85.5586881],
    [48.1285514, 85.5588969],
    [48.1279779, 85.559341],
    [48.1277691, 85.5592373],
    [48.1275256, 85.5591857],
    [48.127508, 85.5589773],
    [48.1275422, 85.5583519],
    [48.1277854, 85.5581168],
    [48.1278024, 85.5576999],
    [48.1278195, 85.5573611],
    [48.1277496, 85.5569965],
    [48.1275582, 85.5569187],
    [48.1272801, 85.5571017],
    [48.1270196, 85.5575192],
    [48.1266894, 85.5577284],
    [48.1263768, 85.558198],
    [48.1260814, 85.5585114],
    [48.1258206, 85.558538],
    [48.1256813, 85.5583559],
    [48.125681, 85.5580693],
    [48.1256633, 85.5577827],
    [48.1257672, 85.5573135],
    [48.1258537, 85.5567662],
    [48.1257837, 85.5563234],
    [48.1258006, 85.5558543],
    [48.1260265, 85.5556975],
    [48.1261134, 85.555567],
    [48.1261304, 85.5552283],
    [48.1263036, 85.5545244],
    [48.1265645, 85.5545499],
    [48.1270169, 85.5547313],
    [48.1272952, 85.5547306],
    [48.1277648, 85.5547817],
    [48.1281476, 85.5549372],
    [48.1286866, 85.5548578],
    [48.1290167, 85.5545444],
    [48.1293293, 85.5540747],
    [48.1294332, 85.5536054],
    [48.1294154, 85.5531885],
    [48.1291366, 85.552668],
    [48.1287362, 85.552252],
    [48.1284927, 85.5522265],
    [48.128319, 85.5524875],
    [48.1281627, 85.5526963],
    [48.1279887, 85.5525925],
    [48.1280578, 85.5521494],
    [48.1281444, 85.5517844],
    [48.1284572, 85.5515231],
    [48.1287531, 85.551783],
    [48.1289965, 85.5517564],
    [48.1292222, 85.5513389],
    [48.1292392, 85.550922],
    [48.1291344, 85.5505053],
    [48.1288735, 85.5504538],
    [48.1285605, 85.5505848],
    [48.128386, 85.5499859],
    [48.1280551, 85.5494916],
    [48.1276026, 85.5492842],
    [48.1267503, 85.5491559],
    [48.1263204, 85.549208],
    [48.1254163, 85.5495229],
    [48.124495, 85.549968],
    [48.1230344, 85.5503361],
    [48.1219564, 85.5505471],
    [48.1211911, 85.5505488],
    [48.1204953, 85.5503681],
    [48.1199031, 85.5495358],
    [48.1192422, 85.5495373],
    [48.1191382, 85.5498502],
    [48.1189474, 85.5503456],
    [48.1184434, 85.5506594],
    [48.1181653, 85.5508424],
    [48.1176613, 85.5511822],
    [48.1169892, 85.5515026],
    [48.1164674, 85.5514777],
    [48.1158412, 85.5514271],
    [48.1153022, 85.5515325],
    [48.1148325, 85.5514033],
    [48.1146579, 85.5507786],
    [48.1146572, 85.5500752],
    [48.1148304, 85.5493715],
    [48.1148993, 85.5487201],
    [48.1149855, 85.5480165],
    [48.1151066, 85.547391],
    [48.114967, 85.5470006],
    [48.114706, 85.5468189],
    [48.1145147, 85.5468975],
    [48.1143933, 85.5472104],
    [48.114272, 85.5475754],
    [48.1138723, 85.547941],
    [48.1131426, 85.54862],
    [48.1126735, 85.54909],
    [48.1120654, 85.5496905],
    [48.1115266, 85.5499523],
    [48.1111092, 85.5499532],
    [48.110848, 85.5497194],
    [48.1106046, 85.54972],
    [48.1104135, 85.5499548],
    [48.1102513, 85.5503748],
    [48.1099909, 85.5508703],
    [48.10973, 85.550897],
    [48.109382, 85.5506634],
    [48.1092772, 85.550299],
    [48.1089287, 85.5496225],
    [48.1083889, 85.5489987],
    [48.1077802, 85.5490522],
    [48.1075718, 85.5493392],
    [48.1075722, 85.5496518],
    [48.1076594, 85.549886],
    [48.1078855, 85.5499375],
    [48.1080946, 85.5503017],
    [48.1081645, 85.5506141],
    [48.1079565, 85.5513699],
    [48.1078875, 85.551891],
    [48.1079402, 85.5523597],
    [48.1079232, 85.5528025],
    [48.1077845, 85.5532196],
    [48.1076456, 85.5534282],
    [48.1074198, 85.5537413],
    [48.1068641, 85.554576],
    [48.1065337, 85.5545768],
    [48.1061857, 85.5544734],
    [48.1058724, 85.5542136],
    [48.1058719, 85.5536666],
    [48.1061495, 85.553067],
    [48.1063229, 85.5525457],
    [48.1063391, 85.5513736],
    [48.1064778, 85.5509305],
    [48.1068423, 85.5502004],
    [48.1069286, 85.5496272],
    [48.1067889, 85.5490806],
    [48.1065275, 85.5485863],
    [48.1063015, 85.548665],
    [48.1060929, 85.5486915],
    [48.1058322, 85.5489005],
    [48.1055889, 85.5490834],
    [48.105346, 85.5496309],
    [48.1049635, 85.549762],
    [48.1045983, 85.5497368],
    [48.104285, 85.5494771],
    [48.1042324, 85.5490866],
    [48.1043537, 85.5487217],
    [48.1045274, 85.5485129],
    [48.1047185, 85.5482781],
    [48.1049268, 85.547939],
    [48.1050833, 85.5478605],
    [48.1053616, 85.5479119],
    [48.1054658, 85.5478075],
    [48.1055004, 85.547573],
    [48.1054999, 85.5471303],
    [48.1057083, 85.5468433],
    [48.1058817, 85.5464001],
    [48.1058291, 85.5459835],
    [48.1055153, 85.5452289],
    [48.1051845, 85.5449433],
    [48.1049237, 85.5449699],
    [48.10475, 85.5452047],
    [48.1046809, 85.5456477],
    [48.1044723, 85.5457003],
    [48.1042807, 85.5454663],
    [48.1040025, 85.5455451],
    [48.1036725, 85.5459626],
    [48.1032559, 85.5466929],
    [48.1030304, 85.5472663],
    [48.1028398, 85.5479439],
    [48.1025797, 85.5486217],
    [48.1023365, 85.5489608],
    [48.1019019, 85.5491441],
    [48.101641, 85.5491187],
    [48.1014842, 85.5488586],
    [48.1014663, 85.5483378],
    [48.1014114, 85.5480722],
    [48.1011848, 85.54763],
    [48.1006798, 85.5470062],
    [48.100227, 85.5464864],
    [48.0999136, 85.5461226],
    [48.0995125, 85.5451339],
    [48.0985194, 85.5435478],
    [48.0982235, 85.543262],
    [48.0979104, 85.5432368],
    [48.0975976, 85.5434459],
    [48.0973023, 85.5438372],
    [48.0971114, 85.5441762],
    [48.097164, 85.5445667],
    [48.096886, 85.5448799],
    [48.0964865, 85.5452975],
    [48.0960866, 85.5454547],
    [48.0958087, 85.5457419],
    [48.0957742, 85.5460284],
    [48.096018, 85.5463142],
    [48.096036, 85.546861],
    [48.0960547, 85.548137],
    [48.0960208, 85.5489703],
    [48.0958302, 85.5496478],
    [48.0955002, 85.5500913],
    [48.0952567, 85.5500918],
    [48.0950825, 85.5498058],
    [48.0946475, 85.5495724],
    [48.094369, 85.5493908],
    [48.0937951, 85.5494182],
    [48.093219, 85.5492636],
    [48.0930098, 85.5487693],
    [48.0929051, 85.548379],
    [48.092887, 85.5477801],
    [48.0929038, 85.5471552],
    [48.0928684, 85.5466345],
    [48.0926942, 85.5462964],
    [48.0924333, 85.5463491],
    [48.0923461, 85.546141],
    [48.0922588, 85.5457767],
    [48.0920674, 85.545699],
    [48.0915632, 85.5458565],
    [48.0913717, 85.5457268],
    [48.0911454, 85.5454409],
    [48.0909366, 85.5453633],
    [48.0904321, 85.5452604],
    [48.0898581, 85.5452097],
    [48.089284, 85.5450809],
    [48.0890058, 85.5451336],
    [48.0885193, 85.5455254],
    [48.0882241, 85.5459947],
    [48.0881027, 85.5462554],
    [48.0878939, 85.5462298],
    [48.08765, 85.5458399],
    [48.0873018, 85.5454502],
    [48.0868147, 85.5453733],
    [48.086032, 85.5453231],
    [48.0853548, 85.5454613],
    [48.0843283, 85.5451514],
    [48.0835452, 85.5447367],
    [48.083093, 85.5447378],
    [48.082554, 85.5449214],
    [48.0820674, 85.545261],
    [48.0815984, 85.5458088],
    [48.0812338, 85.5463303],
    [48.0807301, 85.5469563],
    [48.0805047, 85.5476337],
    [48.0804705, 85.5481804],
    [48.0800881, 85.5483375],
    [48.0797402, 85.5483384],
    [48.0793228, 85.5482873],
    [48.0789397, 85.5478717],
    [48.0788002, 85.5475076],
    [48.0787997, 85.546987],
    [48.0788515, 85.5467005],
    [48.0789034, 85.546414],
    [48.0787112, 85.5456335],
    [48.0786407, 85.5446966],
    [48.0785174, 85.5433433],
    [48.0783427, 85.5425888],
    [48.0780639, 85.5421209],
    [48.0778033, 85.5411657],
    [48.0775763, 85.5403593],
    [48.0772279, 85.5398916],
    [48.0769667, 85.5396319],
    [48.076619, 85.5397369],
    [48.0763063, 85.5400241],
    [48.0760459, 85.5404933],
    [48.0757508, 85.5409886],
    [48.0753339, 85.54138],
    [48.0749689, 85.5416152],
    [48.0745341, 85.5416163],
    [48.0742557, 85.5415129],
    [48.0741336, 85.5411488],
    [48.0739071, 85.540863],
    [48.0739066, 85.5403945],
    [48.0740105, 85.5400038],
    [48.0740798, 85.5397434],
    [48.0737303, 85.5382606],
    [48.0734688, 85.5377407],
    [48.0729641, 85.5374297],
    [48.0724239, 85.536494],
    [48.0719358, 85.5355583],
    [48.0717092, 85.5350904],
    [48.0713436, 85.534753],
    [48.0708216, 85.5345201],
    [48.0704216, 85.5345732],
    [48.0699351, 85.5349388],
    [48.0696226, 85.5354081],
    [48.0695534, 85.5357727],
    [48.0696584, 85.536319],
    [48.0696592, 85.5369957],
    [48.069521, 85.5377768],
    [48.0693478, 85.5384279],
    [48.0691047, 85.538819],
    [48.0686356, 85.5392366],
    [48.0683751, 85.5395756],
    [48.0681144, 85.5397064],
    [48.067836, 85.539629],
    [48.0674617, 85.539441],
    [48.0670611, 85.5389736],
    [48.0669042, 85.5386356],
    [48.0669037, 85.5382192],
    [48.0667468, 85.5378813],
    [48.066538, 85.5378038],
    [48.0661554, 85.5378048],
    [48.0660505, 85.5373626],
    [48.0659286, 85.5371807],
    [48.0656156, 85.5372596],
    [48.0652681, 85.5375208],
    [48.0649902, 85.5378858],
    [48.0648516, 85.5383546],
    [48.0648869, 85.5387709],
    [48.0650442, 85.5394732],
    [48.0651318, 85.5400715],
    [48.0651149, 85.5404359],
    [48.0650109, 85.5407745],
    [48.0648196, 85.540827],
    [48.0645591, 85.5411399],
    [48.0642635, 85.5411927],
    [48.063985, 85.5410113],
    [48.0636023, 85.5409081],
    [48.0634633, 85.5410646],
    [48.0634391, 85.5413291],
    [48.0634697, 85.5416347],
    [48.0634322, 85.5418951],
    [48.0632584, 85.5421219],
    [48.0630091, 85.5423375],
    [48.0627142, 85.5424967],
    [48.0625025, 85.5425312],
    [48.062336, 85.5424524],
    [48.0621785, 85.5422431],
    [48.062095, 85.541983],
    [48.0621022, 85.5416774],
    [48.0620413, 85.5412928],
    [48.062041, 85.5410664],
    [48.0620938, 85.5408739],
    [48.0621463, 85.5404889],
    [48.0621612, 85.5402625],
    [48.0620702, 85.5401156],
    [48.0617677, 85.5401956],
    [48.0615862, 85.5401734],
    [48.0614652, 85.5402303],
    [48.0613066, 85.5404005],
    [48.0612009, 85.540661],
    [48.0610952, 85.5407632],
    [48.0609817, 85.5407408],
    [48.0609436, 85.5405485],
    [48.0604744, 85.540312],
    [48.0603003, 85.5402106],
    [48.0602394, 85.5398373],
    [48.0601863, 85.5397355],
    [48.0601031, 85.5397244],
    [48.0600273, 85.5396114],
    [48.0599138, 85.5395212],
    [48.0597698, 85.5393291],
    [48.0594594, 85.5390696],
    [48.0593383, 85.539036],
    [48.0592474, 85.5389343],
    [48.0592473, 85.5387872],
    [48.0591942, 85.5386742],
    [48.0591412, 85.5386743],
    [48.059096, 85.5388102],
    [48.0589228, 85.5389128],
    [48.058711, 85.5389133],
    [48.0581362, 85.5389487],
    [48.0578337, 85.5390174],
    [48.0576371, 85.5390518],
    [48.0574706, 85.538973],
    [48.0572966, 85.5390187],
    [48.0571002, 85.5391777],
    [48.0569793, 85.5393477],
    [48.0569416, 85.5394497],
    [48.0570098, 85.5395401],
    [48.0569722, 85.5397325],
    [48.0568361, 85.5398234],
    [48.0567606, 85.5399481],
    [48.056723, 85.5401066],
    [48.0567534, 85.5402424],
    [48.0567007, 85.5404462],
    [48.0566327, 85.5404916],
    [48.0565569, 85.5404126],
    [48.0564434, 85.5403337],
    [48.0563298, 85.5402547],
    [48.0562008, 85.5398929],
    [48.0560645, 85.5397348],
    [48.0559887, 85.5395653],
    [48.0558525, 85.5395656],
    [48.0557846, 85.5397129],
    [48.0556789, 85.5398829],
    [48.0555959, 85.5401095],
    [48.0553845, 85.5404269],
    [48.0553168, 85.5407439],
    [48.0552337, 85.5408799],
    [48.0551429, 85.5408462],
    [48.0550447, 85.5409822],
    [48.0549202, 85.5411854],
    [48.0547843, 85.5414234],
    [48.0547012, 85.5415708],
    [48.0543763, 85.5418318],
    [48.0540208, 85.5418893],
    [48.0534611, 85.541902],
    [48.0532492, 85.5418233],
    [48.0531279, 85.5415747],
    [48.0530295, 85.541507],
    [48.0528854, 85.5412358],
    [48.0527869, 85.541055],
    [48.0527715, 85.5408513],
    [48.0527034, 85.5407496],
    [48.052552, 85.5406595],
    [48.0523553, 85.5406487],
    [48.0521512, 85.540785],
    [48.0519556, 85.5407838],
    [48.0516605, 85.5407166],
    [48.0511831, 85.539937],
    [48.0511145, 85.5394959],
    [48.0511294, 85.5393374],
    [48.0511215, 85.5389754],
    [48.0511439, 85.5387377],
    [48.0512266, 85.5383188],
    [48.0511731, 85.5378323],
    [48.0511124, 85.5376401],
    [48.050961, 85.5375387],
    [48.0507567, 85.5375279],
    [48.0506432, 85.5374942],
    [48.0505373, 85.5374945],
    [48.0504845, 85.5375738],
    [48.0504317, 85.5377663],
    [48.0503865, 85.5379249],
    [48.0504019, 85.5381172],
    [48.0503344, 85.5385926],
    [48.050289, 85.5386606],
    [48.0501528, 85.5386044],
    [48.049646, 85.5385717],
    [48.0493205, 85.5383689],
    [48.0491689, 85.5380864],
    [48.0491308, 85.5378602],
    [48.0491456, 85.5375886],
    [48.0492508, 85.5369433],
    [48.0493714, 85.5365583],
    [48.0496582, 85.5360597],
    [48.049726, 85.5357653],
    [48.0497405, 85.5352561],
    [48.0496494, 85.5349621],
    [48.0494525, 85.5347702],
    [48.0492442, 85.5345986],
    [48.0490627, 85.5345764],
    [48.0489267, 85.53469],
    [48.0487907, 85.534894],
    [48.0487229, 85.5351205],
    [48.0486175, 85.5355734],
    [48.0485345, 85.5357433],
    [48.0484137, 85.5358794],
    [48.0483078, 85.535925],
    [48.0481716, 85.5358574],
    [48.0480959, 85.535835],
    [48.0480203, 85.5359031],
    [48.0479751, 85.5359937],
    [48.0472418, 85.5364143],
    [48.0468336, 85.5365964],
    [48.0466143, 85.5366422],
    [48.0462965, 85.5365864],
    [48.0461528, 85.5366094],
    [48.0460242, 85.5366211],
    [48.0458274, 85.5364519],
    [48.0456521, 85.536165],
    [48.0454245, 85.5355999],
    [48.0451818, 85.5350009],
    [48.0450298, 85.5344242],
    [48.0448706, 85.5340852],
    [48.0446056, 85.5338822],
    [48.0444616, 85.5336563],
    [48.0444386, 85.5334301],
    [48.0445138, 85.5330792],
    [48.044468, 85.5326493],
    [48.0444904, 85.532423],
    [48.0445047, 85.5317215],
    [48.0447238, 85.5315172],
    [48.0448973, 85.5311094],
    [48.0449121, 85.5308831],
    [48.044783, 85.5304535],
    [48.0446238, 85.5301371],
    [48.0445103, 85.5300922],
    [48.0443363, 85.53007],
    [48.044215, 85.5298554],
    [48.0441543, 85.5296971],
    [48.0440785, 85.5295955],
    [48.0438514, 85.5294264],
    [48.0435527, 85.5292707],
    [48.043303, 85.5292262],
    [48.0431669, 85.529317],
    [48.0431066, 85.5294417],
    [48.0430467, 85.5299397],
    [48.0430091, 85.530166],
    [48.0429336, 85.5302681],
    [48.0427066, 85.5302461],
    [48.0425025, 85.5303371],
    [48.0420487, 85.5303723],
    [48.0416479, 85.5304865],
    [48.0414437, 85.5304871],
    [48.0412999, 85.530397],
    [48.0411483, 85.5301598],
    [48.0410119, 85.5299339],
    [48.0407468, 85.5296744],
    [48.0403306, 85.5295397],
    [48.0401593, 85.5295339],
    [48.0399854, 85.5296023],
    [48.0397511, 85.5298179],
    [48.0395021, 85.530305],
    [48.0393209, 85.5305883],
    [48.0393289, 85.530939],
    [48.0392611, 85.5311994],
    [48.039178, 85.5313015],
    [48.0390646, 85.5313131],
    [48.038974, 85.5314491],
    [48.0388454, 85.5314381],
    [48.0385198, 85.5311788],
    [48.0383305, 85.5309983],
    [48.0380577, 85.530637],
    [48.0378986, 85.5304338],
    [48.0375127, 85.5302878],
    [48.0372477, 85.5301075],
    [48.03696, 85.5298481],
    [48.0366349, 85.5295871],
    [48.0363018, 85.5293617],
    [48.0358855, 85.529114],
    [48.0353331, 85.5289684],
    [48.0351061, 85.5289238],
    [48.0349246, 85.5289809],
    [48.0346601, 85.5291513],
    [48.0341614, 85.5296164],
    [48.0339952, 85.5297979],
    [48.033935, 85.5300356],
    [48.0338903, 85.53059],
    [48.033921, 85.5309746],
    [48.0339139, 85.5313592],
    [48.0338163, 85.5319477],
    [48.0336653, 85.5321969],
    [48.033567, 85.5321972],
    [48.0334306, 85.5320618],
    [48.0333247, 85.5320282],
    [48.0330219, 85.5318706],
    [48.0328025, 85.5318146],
    [48.0324016, 85.5318496],
    [48.0321661, 85.5319199],
    [48.0319317, 85.5319884],
    [48.0315159, 85.5322496],
    [48.0312212, 85.5324766],
    [48.0308885, 85.5326133],
    [48.0305558, 85.532682],
    [48.0303363, 85.5325921],
    [48.0299501, 85.5322538],
    [48.0296321, 85.5319266],
    [48.0292686, 85.531577],
    [48.0289881, 85.5311366],
    [48.0287229, 85.5306849],
    [48.0286014, 85.5303572],
    [48.0280004, 85.5292592],
    [48.0277351, 85.5287849],
    [48.0275909, 85.5284007],
    [48.02706, 85.527305],
    [48.0268555, 85.5270342],
    [48.0265148, 85.5268428],
    [48.0263255, 85.526685],
    [48.0258791, 85.5265731],
    [48.0253497, 85.5266425],
    [48.0251322, 85.5265095],
    [48.0246631, 85.5264543],
    [48.0237527, 85.5280739],
    [48.0231354, 85.5295683],
    [48.0230992, 85.5308803],
    [48.0228968, 85.5324076],
    [48.0227694, 85.5332111],
    [48.0223926, 85.534377],
    [48.0220162, 85.5359726],
    [48.0219488, 85.536493],
    [48.021866, 85.5369117],
    [48.0217001, 85.5373531],
    [48.0205887, 85.5394803],
    [48.0201805, 85.5397075],
    [48.0200521, 85.5399114],
    [48.0198631, 85.5400023],
    [48.0194921, 85.539664],
    [48.0187132, 85.5398921],
    [48.0182057, 85.5401487],
    [48.0176889, 85.5404366],
    [48.0168163, 85.5414902],
    [48.0162839, 85.5419058],
    [48.0156883, 85.5428949],
    [48.015242, 85.5440429],
    [48.0149015, 85.5444579],
    [48.0144542, 85.5446502],
    [48.014071, 85.5450653],
    [48.0125162, 85.5459292],
    [48.0108974, 85.5466658],
    [48.0101734, 85.5471772],
    [48.0084677, 85.5464486],
    [48.0074873, 85.5464192],
    [48.0068936, 85.5462334],
    [48.0053157, 85.5455046],
    [48.0039939, 85.5450618],
    [48.0023744, 85.5451613],
    [48.0010311, 85.5445276],
    [48.0000069, 85.5433517],
    [47.9986165, 85.541273],
    [47.9965096, 85.5396895],
    [47.9953401, 85.5396925],
    [47.994649, 85.5396307],
    [47.9938516, 85.5396168],
    [47.9925925, 85.5396986],
    [47.9915402, 85.5399078],
    [47.9897759, 85.5404365],
    [47.9891598, 85.540851],
    [47.9889683, 85.5407721],
    [47.9883929, 85.5396775],
    [47.9879953, 85.5394191],
    [47.9876444, 85.5394041],
    [47.986826, 85.5395809],
    [47.9864113, 85.539582],
    [47.9859221, 85.5394085],
    [47.9856539, 85.5394152],
    [47.9852077, 85.5397181],
    [47.9846237, 85.5403391],
    [47.9840508, 85.5414207],
    [47.98386, 85.541993],
    [47.9837443, 85.5430575],
    [47.9837235, 85.5434864],
    [47.983607, 85.5439632],
    [47.9833736, 85.5443608],
    [47.9831932, 85.5446631],
    [47.9829493, 85.5452196],
    [47.9824616, 85.5465232],
    [47.982165, 85.5475246],
    [47.9819498, 85.548915],
    [47.9818218, 85.5506607],
    [47.9818228, 85.5516303],
    [47.9819481, 85.5530404],
    [47.9437073, 85.5610483],
    [47.9198129, 85.5660528],
  ],
});
mapRegions.push({
  id: 5,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Кокпектинский район",
  centerTitle: "село Кокпекты",
  centerTitleShort: "с. Кокпекты",
  centerGeometry: [48.7564151, 82.3830413],
  geometry: [
    [48.5097013, 81.6067591],
    [48.5101207, 81.6315607],
    [48.4830924, 81.671204],
    [48.4963114, 81.6945104],
    [48.4831471, 81.8074347],
    [48.4833682, 82.1012655],
    [48.4668125, 82.1264271],
    [48.4247739, 82.3631199],
    [48.3197331, 82.4171605],
    [48.1705021, 82.4345549],
    [48.1579213, 82.5119178],
    [48.1686925, 82.5987848],
    [48.1511614, 82.7768788],
    [48.1812323, 83.0086737],
    [48.1810112, 83.2111042],
    [48.1490623, 83.3092803],
    [48.2436646, 83.4232485],
    [48.2402277, 83.4811545],
    [48.2827792, 83.4997477],
    [48.2765662, 83.5299371],
    [48.3267391, 83.5321501],
    [48.3499681, 83.5423196],
    [48.3756836, 83.5404052],
    [48.3817073, 83.4901958],
    [48.4281835, 83.5105599],
    [48.4486113, 83.4930105],
    [48.4584504, 83.4441983],
    [48.5244838, 83.3664638],
    [48.541199, 83.3671042],
    [48.543076, 83.3853386],
    [48.552704, 83.4788822],
    [48.6915399, 83.4598468],
    [48.795979, 83.4656609],
    [48.9093865, 83.5556799],
    [48.9625883, 83.6723261],
    [49.0811194, 84.1983643],
    [49.1114774, 84.2377546],
    [49.1730301, 84.2514112],
    [49.2300791, 84.2040872],
    [49.2953444, 84.0945519],
    [49.3117724, 84.0556494],
    [49.4259366, 83.867844],
    [49.4716994, 83.6760639],
    [49.4944224, 83.6462163],
    [49.4575915, 83.6166833],
    [49.4254717, 83.6291433],
    [49.4043395, 83.5398103],
    [49.3824234, 83.5434729],
    [49.3726459, 83.5057213],
    [49.3096551, 83.5246427],
    [49.2975506, 83.4992143],
    [49.2584612, 83.4819111],
    [49.2460011, 83.4361575],
    [49.2831351, 83.4190822],
    [49.2999164, 83.3526226],
    [49.2725827, 83.3404384],
    [49.3041259, 83.1931082],
    [49.2824103, 83.1499254],
    [49.2633818, 83.1616356],
    [49.2377097, 83.0698936],
    [49.2387079, 83.0168467],
    [49.2202104, 83.0021212],
    [49.1639204, 83.0576933],
    [49.1509521, 83.0120628],
    [49.1690074, 82.8604364],
    [49.1921383, 82.8239361],
    [49.1877259, 82.7757939],
    [49.2026634, 82.76229],
    [49.2422451, 82.7525854],
    [49.2456023, 82.7077708],
    [49.2201238, 82.6838923],
    [49.2124363, 82.654357],
    [49.2244177, 82.6330425],
    [49.2086643, 82.6264877],
    [49.21085, 82.5880045],
    [49.2395717, 82.5670615],
    [49.2602913, 82.5690603],
    [49.2990298, 82.5128318],
    [49.2370829, 82.4025125],
    [49.2657544, 82.381508],
    [49.2213295, 82.3180842],
    [49.2311594, 82.2571264],
    [49.2242286, 82.2478936],
    [49.1906934, 82.2993702],
    [49.1362881, 82.2454367],
    [49.1124917, 82.2102742],
    [49.0997741, 82.2633052],
    [49.0570107, 82.16285],
    [49.0206995, 82.2321858],
    [48.9898423, 82.162385],
    [49.0035216, 82.1292806],
    [48.9767646, 82.1125563],
    [48.994225, 82.0554503],
    [48.9626886, 82.068863],
    [48.8275951, 81.9667577],
    [48.828304, 81.9354561],
    [48.7566593, 81.8834918],
    [48.7063246, 81.8022087],
    [48.7105638, 81.7475892],
    [48.6793283, 81.7050787],
    [48.6829384, 81.681533],
    [48.6335336, 81.6203154],
    [48.5968281, 81.6133116],
    [48.5853572, 81.5783542],
    [48.5136921, 81.5528029],
    [48.5097013, 81.6067591],
  ],
});
mapRegions.push({
  id: 6,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Жарминский район",
  centerTitle: "село Калбатау",
  centerTitleShort: "с. Калбатау",
  centerGeometry: [49.3316107, 81.572113],
  geometry: [
    [48.5097013, 81.6067591],
    [48.5136921, 81.5528029],
    [48.5853572, 81.5783542],
    [48.5968281, 81.6133116],
    [48.6335336, 81.6203154],
    [48.6829384, 81.681533],
    [48.6793283, 81.7050787],
    [48.7105638, 81.7475892],
    [48.7063246, 81.8022087],
    [48.7566593, 81.8834918],
    [48.828304, 81.9354561],
    [48.8275951, 81.9667577],
    [48.9626886, 82.068863],
    [48.994225, 82.0554503],
    [48.9767646, 82.1125563],
    [49.0035216, 82.1292806],
    [48.9898423, 82.162385],
    [49.0206995, 82.2321858],
    [49.0570107, 82.16285],
    [49.0997741, 82.2633052],
    [49.1124917, 82.2102742],
    [49.1362881, 82.2454367],
    [49.1906934, 82.2993702],
    [49.2242286, 82.2478936],
    [49.2311594, 82.2571264],
    [49.2213295, 82.3180842],
    [49.2657544, 82.381508],
    [49.2370829, 82.4025125],
    [49.2990298, 82.5128318],
    [49.3406719, 82.4643409],
    [49.337848, 82.4042127],
    [49.2963906, 82.3743219],
    [49.353745, 82.3322217],
    [49.3695554, 82.2603218],
    [49.391312, 82.255996],
    [49.4031612, 82.2346724],
    [49.3863116, 82.2084281],
    [49.3941837, 82.1855479],
    [49.3743826, 82.1776963],
    [49.409732, 82.0878687],
    [49.4579585, 82.0225669],
    [49.498714, 82.05793],
    [49.5094259, 82.0167323],
    [49.5617366, 81.9787893],
    [49.5711062, 81.8918676],
    [49.5909802, 81.899505],
    [49.6095438, 81.8614275],
    [49.6168598, 81.7869043],
    [49.6536132, 81.7944026],
    [49.6938855, 81.7516939],
    [49.6865968, 81.7167958],
    [49.7414283, 81.7438879],
    [49.7835398, 81.6888741],
    [49.7978779, 81.7329708],
    [49.8690918, 81.7081829],
    [49.8740626, 81.619675],
    [49.9035044, 81.5919789],
    [49.9156955, 81.5386654],
    [49.9273077, 81.5172575],
    [49.9623836, 81.367614],
    [50.0469213, 81.392395],
    [50.0996286, 81.40532],
    [50.22316, 81.3651958],
    [50.2238301, 81.3323375],
    [50.2508629, 81.2357409],
    [50.2198029, 81.1639937],
    [50.1722783, 81.1731877],
    [50.1789311, 81.0750573],
    [50.1550093, 80.9586641],
    [50.1529763, 80.8906091],
    [50.1866984, 80.8357093],
    [50.1707923, 80.8028783],
    [50.2531968, 80.7860424],
    [50.2630814, 80.6951368],
    [50.2362674, 80.7055273],
    [50.2184331, 80.7664805],
    [50.1519165, 80.7891762],
    [50.0782936, 80.6946034],
    [50.0187421, 80.726623],
    [49.9918551, 80.7101449],
    [49.9656337, 80.6614534],
    [49.9420742, 80.7057735],
    [49.9122244, 80.708497],
    [49.892952, 80.6691888],
    [49.9203176, 80.5430364],
    [49.9151577, 80.4647367],
    [49.9521754, 80.4705576],
    [49.936185, 80.3244695],
    [49.9638719, 80.2244178],
    [49.9273442, 80.159827],
    [49.751281, 80.1563263],
    [49.6729015, 80.1743702],
    [49.6217349, 80.2349861],
    [49.497604, 80.2499691],
    [49.3741615, 80.343849],
    [49.299317, 80.4218684],
    [49.2176441, 80.406311],
    [49.0440971, 80.3319861],
    [48.9446675, 80.3229744],
    [48.7522652, 80.3731929],
    [48.68336, 80.3291395],
    [48.4020737, 80.3288341],
    [48.395936, 80.5618438],
    [48.3232087, 80.5430979],
    [48.3180305, 80.6319956],
    [48.2626681, 80.664512],
    [48.2518125, 80.7634425],
    [48.3303675, 80.9109344],
    [48.2945668, 80.9809199],
    [48.3548477, 81.1912613],
    [48.3565821, 81.5094674],
  ],
});
mapRegions.push({
  id: 7,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Абайский район",
  centerTitle: "село Караул",
  centerTitleShort: "с. Караул",
  centerGeometry: [48.9445458, 79.2547702],
  geometry: [
    [48.4020737, 80.3288341],
    [48.68336, 80.3291395],
    [48.7522652, 80.3731929],
    [48.9446675, 80.3229744],
    [49.0440971, 80.3319861],
    [49.2176441, 80.406311],
    [49.299317, 80.4218684],
    [49.3741615, 80.343849],
    [49.497604, 80.2499691],
    [49.6217349, 80.2349861],
    [49.6729015, 80.1743702],
    [49.668464, 80.1199718],
    [49.7871933, 79.7989419],
    [49.7584238, 79.7357939],
    [49.7767685, 79.6960298],
    [49.7871432, 79.5939632],
    [49.8379702, 79.5920419],
    [49.8050002, 79.5275652],
    [49.8750038, 79.4197461],
    [49.8726837, 79.3461072],
    [49.8994635, 79.3355252],
    [49.8855243, 79.2843472],
    [49.8669972, 79.2052316],
    [49.8120199, 79.206321],
    [49.7925105, 79.1337807],
    [49.75756, 78.9576947],
    [49.6901363, 78.8635709],
    [49.6923334, 78.7531582],
    [49.6350747, 78.6762305],
    [49.5319164, 78.721656],
    [49.3984753, 78.4859387],
    [49.3375084, 78.3386381],
    [49.2919918, 78.3313973],
    [49.258762, 78.392036],
    [49.2400936, 78.4056698],
    [49.187416, 78.5180585],
    [49.1971661, 78.5402824],
    [49.141186, 78.6771923],
    [49.0933446, 78.6881185],
    [49.0706444, 78.6362841],
    [49.0446965, 78.6411524],
    [48.984721, 78.7123343],
    [48.9652663, 78.6999746],
    [48.9846982, 78.6484],
    [48.9578865, 78.5955286],
    [49.0048868, 78.5586932],
    [48.9715681, 78.5230931],
    [48.9043428, 78.5856941],
    [48.7896498, 78.4622721],
    [48.745079, 78.4807285],
    [48.744393, 78.518569],
    [48.7274545, 78.5515664],
    [48.6714174, 78.5284947],
    [48.5794679, 78.6844947],
    [48.5048878, 78.6747126],
    [48.4630998, 78.8010406],
    [48.3901675, 78.7789831],
    [48.3751024, 78.9257822],
    [48.3905253, 78.9683838],
    [48.342864, 79.0737072],
    [48.308914, 79.1074065],
    [48.2772705, 79.2492371],
    [48.2892428, 79.3162984],
    [48.3619335, 79.3075785],
    [48.3771445, 79.3504877],
    [48.404542, 79.3655551],
    [48.4143901, 79.5087281],
    [48.4398572, 79.5680084],
    [48.4294575, 79.6732407],
    [48.3903247, 79.6221083],
    [48.3266069, 79.6268762],
    [48.3284827, 79.7040478],
    [48.3652542, 79.7416923],
    [48.3687049, 79.806855],
    [48.4142807, 79.8412312],
    [48.4141007, 79.9311522],
    [48.3914256, 79.9428488],
    [48.357667, 80.1184926],
    [48.4020737, 80.3288341],
  ],
});
mapRegions.push({
  id: 8,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Семей Г.А.",
  centerTitle: "город Семей",
  centerTitleShort: "г. Семей",
  centerGeometry: [50.4078064, 80.2417373],
  geometry: [
    [48.7274545, 78.5515664],
    [48.744393, 78.518569],
    [48.745079, 78.4807285],
    [48.7896498, 78.4622721],
    [48.9043428, 78.5856941],
    [48.9715681, 78.5230931],
    [49.0048868, 78.5586932],
    [48.9578865, 78.5955286],
    [48.9846982, 78.6484],
    [48.9652663, 78.6999746],
    [48.984721, 78.7123343],
    [49.0446965, 78.6411524],
    [49.0706444, 78.6362841],
    [49.0933446, 78.6881185],
    [49.141186, 78.6771923],
    [49.1971661, 78.5402824],
    [49.187416, 78.5180585],
    [49.2400936, 78.4056698],
    [49.258762, 78.392036],
    [49.2919918, 78.3313973],
    [49.3375084, 78.3386381],
    [49.3984753, 78.4859387],
    [49.5319164, 78.721656],
    [49.6350747, 78.6762305],
    [49.6923334, 78.7531582],
    [49.6901363, 78.8635709],
    [49.75756, 78.9576947],
    [49.7925105, 79.1337807],
    [49.8120199, 79.206321],
    [49.8669972, 79.2052316],
    [49.8855243, 79.2843472],
    [49.8994635, 79.3355252],
    [49.8726837, 79.3461072],
    [49.8750038, 79.4197461],
    [49.8050002, 79.5275652],
    [49.8379702, 79.5920419],
    [49.7871432, 79.5939632],
    [49.7767685, 79.6960298],
    [49.7584238, 79.7357939],
    [49.7871933, 79.7989419],
    [49.668464, 80.1199718],
    [49.6729015, 80.1743702],
    [49.751281, 80.1563263],
    [49.9273442, 80.159827],
    [49.9638719, 80.2244178],
    [49.936185, 80.3244695],
    [49.9521754, 80.4705576],
    [49.9151577, 80.4647367],
    [49.9203176, 80.5430364],
    [49.892952, 80.6691888],
    [49.9122244, 80.708497],
    [49.9420742, 80.7057735],
    [49.9656337, 80.6614534],
    [49.9918551, 80.7101449],
    [50.0187421, 80.726623],
    [50.0782936, 80.6946034],
    [50.1519165, 80.7891762],
    [50.2184331, 80.7664805],
    [50.2362674, 80.7055273],
    [50.2630814, 80.6951368],
    [50.2531968, 80.7860424],
    [50.1707923, 80.8028783],
    [50.1866984, 80.8357093],
    [50.1529763, 80.8906091],
    [50.1550093, 80.9586641],
    [50.1789311, 81.0750573],
    [50.1722783, 81.1731877],
    [50.2198029, 81.1639937],
    [50.2508629, 81.2357409],
    [50.2238301, 81.3323375],
    [50.22316, 81.3651958],
    [50.2344828, 81.4023138],
    [50.3160143, 81.3330235],
    [50.3272846, 81.25724],
    [50.3841901, 81.1306294],
    [50.4098965, 81.0190133],
    [50.6476969, 80.6543653],
    [50.6100775, 80.5724782],
    [50.6002089, 80.527969],
    [50.6360095, 80.5410923],
    [50.660884, 80.5433646],
    [50.6976282, 80.5771869],
    [50.7283487, 80.6223366],
    [50.7472952, 80.6086208],
    [50.7395051, 80.5513142],
    [50.7723474, 80.5560388],
    [50.7615899, 80.4909057],
    [50.7367633, 80.4616165],
    [50.7199546, 80.4077674],
    [50.7607945, 80.4155734],
    [50.7708591, 80.3781956],
    [50.8741906, 80.3817602],
    [50.8666079, 80.2984395],
    [50.9153323, 80.2830634],
    [50.9147, 80.2478],
    [50.8984, 80.2381],
    [50.8963, 80.1926],
    [50.8584, 80.1933],
    [50.8566, 80.1834],
    [50.8226, 80.1882],
    [50.8253, 80.09],
    [50.8488, 80.0882],
    [50.8505, 80.0682],
    [50.8394, 80.0585],
    [50.8208423, 80.0626602],
    [50.8046, 80.0663],
    [50.7533, 80.0773],
    [50.7371, 80.0776],
    [50.7426, 80.069],
    [50.9012507, 79.9500153],
    [50.899799, 79.9493329],
    [50.8709952, 79.9357903],
    [50.6979108, 79.8509449],
    [50.7220583, 79.8018637],
    [50.7079003, 79.7835531],
    [50.6535224, 79.8090202],
    [50.6338626, 79.7424398],
    [50.6812481, 79.6208411],
    [50.6711196, 79.6035083],
    [50.6502313, 79.6021203],
    [50.6480821, 79.5878825],
    [50.6659095, 79.5813756],
    [50.6327253, 79.5483851],
    [50.6293453, 79.5133821],
    [50.6530255, 79.4955069],
    [50.6370351, 79.489319],
    [50.5404157, 79.4861533],
    [50.5162888, 79.3892741],
    [50.527958, 79.3665921],
    [50.428321, 78.9926908],
    [50.4416015, 78.8964315],
    [50.4839433, 78.8034815],
    [50.4377863, 78.8010406],
    [50.3070368, 78.8300562],
    [50.2614746, 78.7613221],
    [50.0606873, 78.5893248],
    [50.0199272, 78.4512479],
    [50.0456701, 78.2885974],
    [50.1610901, 77.8582146],
    [50.2279736, 77.7993695],
    [50.2026068, 77.7328644],
    [50.146221, 77.7098018],
    [50.0673036, 77.5627907],
    [49.9965935, 77.4839326],
    [49.9547531, 77.3420564],
    [49.9347651, 77.2975016],
    [49.9331697, 77.2061926],
    [49.8559389, 77.1784145],
    [49.8267545, 77.2092284],
    [49.7659449, 77.1860496],
    [49.7203668, 77.2122961],
    [49.6677712, 77.1914283],
    [49.5767242, 77.10228],
    [49.5143283, 76.8416904],
    [49.4401402, 76.6048199],
    [49.3824644, 76.5899964],
    [49.3222998, 76.6701649],
    [49.2886461, 76.6678539],
    [49.2703674, 76.7523118],
    [49.1891982, 76.9030539],
    [49.1253779, 76.9053809],
    [49.0947076, 76.986297],
    [49.0282548, 77.0830304],
    [49.0014499, 76.9870993],
    [49.0207678, 76.9668036],
    [48.9924245, 76.8832846],
    [48.9532849, 76.8920753],
    [48.8882475, 76.8688053],
    [48.7953727, 76.9393765],
    [48.83998, 77.3494499],
    [48.7589066, 77.3907091],
    [48.6497952, 77.3485953],
    [48.6073805, 77.349769],
    [48.64585, 77.4168554],
    [48.6763996, 77.4440546],
    [48.7563516, 77.724892],
    [48.8815354, 77.873993],
    [48.8337944, 78.0188138],
    [48.8555328, 78.278649],
    [48.6939352, 78.288406],
    [48.655452, 78.3909215],
    [48.6714174, 78.5284947],
  ],
});
mapRegions.push({
  id: 9,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Бескарагайский район",
  centerTitle: "село Бескарагай",
  centerTitleShort: "с. Бескарагай",
  centerGeometry: [50.8850589, 79.4805908],
  geometry: [
    [51.6923552, 79.352479],
    [51.6922453, 79.3510142],
    [51.6865931, 79.276001],
    [51.5888204, 79.249244],
    [51.5643812, 79.0952838],
    [51.5097344, 79.1219862],
    [51.4307282, 78.9925768],
    [51.4515344, 78.9652865],
    [51.4331349, 78.7643442],
    [51.3731412, 78.769103],
    [51.3786203, 78.8209146],
    [51.3384665, 78.8050701],
    [51.290534, 78.814606],
    [51.3020049, 78.7626713],
    [51.2202044, 78.5573417],
    [51.1951476, 78.4306651],
    [51.2279466, 78.4077142],
    [51.2108258, 78.3465125],
    [51.1743847, 78.3338542],
    [51.1381259, 78.2254859],
    [51.149754, 78.2020655],
    [51.1184797, 78.1506277],
    [51.0835328, 78.0817944],
    [50.9527598, 78.2323414],
    [50.8833903, 78.2719952],
    [50.8836069, 78.3270985],
    [50.8511054, 78.3715588],
    [50.8395077, 78.4572178],
    [50.80419899467614, 78.49456787109375],
    [50.800727337035184, 78.55087280273438],
    [50.770773582449536, 78.61473083496094],
    [50.72906686497805, 78.67927551269531],
    [50.7016324, 78.6905057],
    [50.6642166, 78.7287861],
    [50.4549481, 78.7062308],
    [50.4377863, 78.8010406],
    [50.4839433, 78.8034815],
    [50.4416015, 78.8964315],
    [50.428321, 78.9926908],
    [50.527958, 79.3665921],
    [50.5162888, 79.3892741],
    [50.5404157, 79.4861533],
    [50.6370351, 79.489319],
    [50.6530255, 79.4955069],
    [50.6293453, 79.5133821],
    [50.6327253, 79.5483851],
    [50.6659095, 79.5813756],
    [50.6480821, 79.5878825],
    [50.6502313, 79.6021203],
    [50.6711196, 79.6035083],
    [50.6812481, 79.6208411],
    [50.6338626, 79.7424398],
    [50.6535224, 79.8090202],
    [50.7079003, 79.7835531],
    [50.7220583, 79.8018637],
    [50.6979108, 79.8509449],
    [50.8709952, 79.9357903],
    [50.899799, 79.9493329],
    [50.9012507, 79.9500153],
    [50.9012507, 79.9500153],
    [51.0433906, 79.8430686],
    [51.1910062, 79.7316539],
    [51.3432984, 79.616335],
    [51.4558, 79.5309],
    [51.4657226, 79.5234345],
    [51.6923552, 79.352479],
  ],
});
mapRegions.push({
  id: 10,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Бородулихинский район",
  centerTitle: "село Бородулиха",
  centerTitleShort: "с. Бородулиха",
  centerGeometry: [50.7093953, 80.9370517],
  geometry: [
    [50.3160143, 81.3330235],
    [50.3272846, 81.25724],
    [50.3841901, 81.1306294],
    [50.4098965, 81.0190133],
    [50.6476969, 80.6543653],
    [50.6100775, 80.5724782],
    [50.6002089, 80.527969],
    [50.6360095, 80.5410923],
    [50.660884, 80.5433646],
    [50.6976282, 80.5771869],
    [50.7283487, 80.6223366],
    [50.7472952, 80.6086208],
    [50.7395051, 80.5513142],
    [50.7723474, 80.5560388],
    [50.7615899, 80.4909057],
    [50.7367633, 80.4616165],
    [50.7199546, 80.4077674],
    [50.7607945, 80.4155734],
    [50.7708591, 80.3781956],
    [50.8741906, 80.3817602],
    [50.8666079, 80.2984395],
    [50.9153323, 80.2830634],
    [50.916, 80.3203],
    [50.9268, 80.3287],
    [50.9252, 80.3486],
    [50.9253, 80.3657],
    [50.928, 80.3798],
    [50.9353, 80.3911],
    [50.9553, 80.4136],
    [50.9606, 80.4221],
    [50.9607, 80.4349],
    [50.9609, 80.452],
    [50.9628, 80.4648],
    [50.97, 80.4789],
    [50.9726, 80.4832],
    [51.1004, 80.4846],
    [51.1102, 80.4717],
    [51.1103, 80.4617],
    [51.1146, 80.4587],
    [51.1202, 80.4558],
    [51.1208, 80.433],
    [51.1812, 80.4365],
    [51.1929, 80.4435],
    [51.2036, 80.4506],
    [51.2055, 80.4691],
    [51.2052, 80.6366],
    [51.243, 80.632],
    [51.2672, 80.6332],
    [51.2771, 80.6417],
    [51.297, 80.6545],
    [51.3105, 80.6644],
    [51.3177, 80.6787],
    [51.3167, 80.6827],
    [51.2651, 80.8983],
    [51.2606, 80.9097],
    [51.2606, 80.9226],
    [51.2606, 80.9384],
    [51.2209, 80.9241],
    [51.1948, 81.0314],
    [51.1914, 81.0357],
    [51.1851, 81.0415],
    [51.1815, 81.0503],
    [51.1797, 81.0889],
    [51.2100463, 81.1125071],
    [51.2112991, 81.1182277],
    [51.2116298, 81.1259697],
    [51.2114739, 81.1390288],
    [51.2116298, 81.1428998],
    [51.2083525, 81.1519163],
    [51.1985, 81.1547],
    [51.194, 81.1604],
    [51.1886, 81.1661],
    [51.1846033, 81.1742065],
    [51.1796427, 81.1743696],
    [51.1743695, 81.1750391],
    [51.1654789, 81.1769488],
    [51.1598, 81.1789],
    [51.1483, 81.176],
    [51.1381, 81.1702],
    [51.1276, 81.1659],
    [51.1095, 81.1601],
    [51.0906, 81.1472],
    [51.0826, 81.1401],
    [51.0799, 81.1358],
    [51.0736, 81.1286],
    [51.07, 81.1215],
    [51.0664, 81.1101],
    [51.0583, 81.1015],
    [50.9949386, 81.0792357],
    [50.9521, 81.0642],
    [50.9449, 81.0657],
    [50.944, 81.0813],
    [50.9448, 81.1083],
    [50.9449, 81.1268],
    [50.9458, 81.151],
    [50.9484, 81.1667],
    [50.9522, 81.1838],
    [50.9511, 81.188],
    [50.9448, 81.2037],
    [50.9609, 81.2479],
    [50.9644, 81.2792],
    [50.9716, 81.2906],
    [50.9717, 81.2992],
    [50.9732, 81.3334],
    [50.9719, 81.4173],
    [50.9262, 81.4141],
    [50.9235, 81.434],
    [50.8857, 81.4421],
    [50.8803, 81.435],
    [50.8741, 81.4264],
    [50.866, 81.4263],
    [50.865, 81.4348],
    [50.8614, 81.4391],
    [50.8514, 81.4503],
    [50.8488, 81.4588],
    [50.847, 81.4645],
    [50.846, 81.4687],
    [50.8343, 81.4743],
    [50.8264, 81.4813],
    [50.8207, 81.4855],
    [50.8181, 81.4855],
    [50.8156, 81.4826],
    [50.8092, 81.4698],
    [50.8066, 81.4655],
    [50.8029, 81.4626],
    [50.7535, 81.4565],
    [50.7534, 81.4877],
    [50.7506, 81.516],
    [50.7459, 81.5442],
    [50.748, 81.598],
    [50.7483507, 81.6070687],
    [50.741105, 81.6077505],
    [50.6938745, 81.6144717],
    [50.5535663, 81.6913994],
    [50.515621, 81.7519834],
    [50.4336542, 81.6796573],
    [50.3864214, 81.715287],
    [50.3487017, 81.7141338],
    [50.3250511, 81.7317036],
    [50.2683143, 81.7164699],
    [50.2305718, 81.7152027],
    [50.2330925, 81.6699527],
    [50.2867776, 81.5913089],
    [50.3248141, 81.4740747],
    [50.3160143, 81.3330235],
  ],
});
mapRegions.push({
  id: 11,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Шемонаихинский район",
  centerTitle: "город Шемонаиха",
  centerTitleShort: "г. Шемонаиха",
  centerGeometry: [50.620499, 81.916809],
  geometry: [
    [50.7483507, 81.6070687],
    [50.741105, 81.6077505],
    [50.6938745, 81.6144717],
    [50.5535663, 81.6913994],
    [50.515621, 81.7519834],
    [50.4336542, 81.6796573],
    [50.3864214, 81.715287],
    [50.3487017, 81.7141338],
    [50.3250511, 81.7317036],
    [50.2683143, 81.7164699],
    [50.2305718, 81.7152027],
    [50.2340999, 81.7762151],
    [50.1979019, 81.8223929],
    [50.2335096, 82.0034495],
    [50.3111962, 82.0497752],
    [50.3359454, 82.0262978],
    [50.3818471, 82.0874721],
    [50.3769561, 82.119788],
    [50.4078794, 82.196702],
    [50.3730041, 82.2569806],
    [50.3987652, 82.3114861],
    [50.3748206, 82.3278434],
    [50.3579071, 82.3266446],
    [50.355751, 82.3675232],
    [50.3338463, 82.3708872],
    [50.3385504, 82.4219809],
    [50.3675159, 82.4011838],
    [50.3663148, 82.4355007],
    [50.3502606, 82.4555594],
    [50.375926, 82.4820634],
    [50.4109882, 82.4504632],
    [50.4441109, 82.5368037],
    [50.4895545, 82.5736459],
    [50.520806, 82.540505],
    [50.5252959, 82.4866627],
    [50.5645951, 82.639962],
    [50.5974511, 82.64994],
    [50.592029, 82.6820758],
    [50.6536341, 82.8292396],
    [50.6301909, 82.9133921],
    [50.6727332, 82.944671],
    [50.6916318, 82.9123984],
    [50.7957952, 82.9470436],
    [50.814263, 83.0136331],
    [50.8365393, 82.9892053],
    [50.8759113, 82.944687],
    [50.8842, 82.9825],
    [50.9006, 82.9164],
    [50.9082, 82.884],
    [50.9002, 82.8794],
    [50.8958, 82.8736],
    [50.9031, 82.8568],
    [50.9088, 82.8471],
    [50.9133, 82.8345],
    [50.9145, 82.8288],
    [50.9148, 82.8061],
    [50.9149, 82.7976],
    [50.9151, 82.789],
    [50.916, 82.7848],
    [50.9242, 82.778],
    [50.9261, 82.7738],
    [50.9263, 82.761],
    [50.9238, 82.7481],
    [50.924, 82.7353],
    [50.9242, 82.733],
    [50.9249, 82.7268],
    [50.9223, 82.7239],
    [50.9151, 82.7279],
    [50.9097, 82.7305],
    [50.907, 82.729],
    [50.9043, 82.7275],
    [50.899, 82.7202],
    [50.8937, 82.7171],
    [50.8838, 82.7182],
    [50.8638, 82.7288],
    [50.8528, 82.7327],
    [50.8475, 82.7325],
    [50.8423, 82.7294],
    [50.8326, 82.7121],
    [50.8272, 82.7062],
    [50.821, 82.7045],
    [50.8121, 82.7014],
    [50.8059, 82.6955],
    [50.8007, 82.6839],
    [50.7994401, 82.6682609],
    [50.7923, 82.6609],
    [50.7824, 82.6393],
    [50.7772, 82.6264],
    [50.7739, 82.6178],
    [50.751, 82.5815],
    [50.7429, 82.5699],
    [50.7403, 82.5613],
    [50.7503, 82.5532],
    [50.7452, 82.5318],
    [50.7419, 82.5132],
    [50.7385, 82.5046],
    [50.7358, 82.4932],
    [50.7412, 82.4849],
    [50.7478, 82.4737],
    [50.7571, 82.4485],
    [50.7618, 82.4302],
    [50.7602, 82.4132],
    [50.7621, 82.4033],
    [50.7686, 82.3908],
    [50.7769, 82.3669],
    [50.7779, 82.3598],
    [50.7551, 82.3167],
    [50.7489, 82.3066],
    [50.7562, 82.2969],
    [50.7607, 82.2899],
    [50.7672, 82.2787],
    [50.7664, 82.2674],
    [50.762, 82.2588],
    [50.7584, 82.2544],
    [50.7549, 82.2501],
    [50.7361, 82.2411],
    [50.7308, 82.2338],
    [50.7326, 82.2282],
    [50.7348, 82.2056],
    [50.7315, 82.1687],
    [50.739, 82.1321],
    [50.7502, 82.0898],
    [50.7495, 82.0728],
    [50.7596, 82.0645],
    [50.764, 82.0604],
    [50.7623, 82.0476],
    [50.7633, 82.0377],
    [50.7679, 82.0335],
    [50.7796, 82.0182],
    [50.7833, 82.0055],
    [50.7853, 81.9843],
    [50.7847, 81.9673],
    [50.7864, 81.9574],
    [50.7892, 81.9432],
    [50.7948, 81.9164],
    [50.7977, 81.8994],
    [50.7977, 81.8952],
    [50.7872, 81.8524],
    [50.7837, 81.8439],
    [50.7785, 81.8183],
    [50.7785, 81.8112],
    [50.7804, 81.8027],
    [50.7822, 81.7985],
    [50.8092, 81.7904],
    [50.8179, 81.7811],
    [50.8183, 81.7806],
    [50.8182, 81.7693],
    [50.7657, 81.7344],
    [50.7611, 81.7244],
    [50.7577, 81.7031],
    [50.7531, 81.6889],
    [50.7508, 81.6704],
    [50.7483507, 81.6070687],
  ],
});
mapRegions.push({
  id: 12,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Глубоковский район",
  centerTitle: "посёлок Глубокое",
  centerTitleShort: "п. Глубокое",
  centerGeometry: [50.1423659, 82.3157501],
  geometry: [
    [50.6349149, 84.073109],
    [50.6355174, 84.071918],
    [50.6620947, 84.0551222],
    [50.6779761, 84.0363584],
    [50.6897787, 84.0185799],
    [50.6988536, 84.003988],
    [50.7030358, 83.9968547],
    [50.7068405, 83.990834],
    [50.7126877, 83.9854133],
    [50.715292, 83.9771288],
    [50.7234646, 83.9535762],
    [50.7321417, 83.9455762],
    [50.7412546, 83.9395784],
    [50.7517307, 83.9395592],
    [50.7600977, 83.9436259],
    [50.7667362, 83.9509015],
    [50.7749048, 83.9579037],
    [50.778556, 83.9616185],
    [50.7862857, 83.9639274],
    [50.7902, 83.9717],
    [50.794, 83.9662],
    [50.797, 83.9607],
    [50.8025, 83.9497],
    [50.8063, 83.9401],
    [50.8101, 83.9318],
    [50.8142, 83.915],
    [50.8313, 83.8777],
    [50.8368, 83.8738],
    [50.8421, 83.8741],
    [50.847, 83.8645],
    [50.8507, 83.8534],
    [50.8537, 83.8436],
    [50.859, 83.8411],
    [50.8674, 83.8345],
    [50.8718, 83.8305],
    [50.8749, 83.8264],
    [50.8825, 83.8056],
    [50.8855, 83.793],
    [50.8858, 83.7802],
    [50.8871, 83.7632],
    [50.8876, 83.7419],
    [50.8889, 83.7247],
    [50.8893, 83.7062],
    [50.8949, 83.6966],
    [50.9052, 83.6787],
    [50.9217, 83.6654],
    [50.9345, 83.6562],
    [50.9482, 83.6485],
    [50.9477, 83.6313],
    [50.9467, 83.5929],
    [50.9479, 83.573],
    [50.9549, 83.5492],
    [50.9588, 83.5366],
    [50.9482, 83.5275],
    [50.9402, 83.5199],
    [50.9422, 83.5101],
    [50.9525, 83.495],
    [50.957, 83.4838],
    [50.9778, 83.4864],
    [50.9777, 83.4479],
    [51.0068, 83.4423],
    [51.0105, 83.4368],
    [51.0125, 83.4198],
    [51.0137, 83.4128],
    [51.0124, 83.3927],
    [51.0026, 83.3808],
    [51.0011, 83.3765],
    [50.9966, 83.3677],
    [51.0005, 83.3537],
    [51.0083, 83.3213],
    [51.0123, 83.3029],
    [51.0101, 83.2758],
    [51.0052, 83.2527],
    [51.0027, 83.2441],
    [51.0012, 83.2269],
    [50.9989, 83.2154],
    [50.9963, 83.1982],
    [50.9992, 83.1912],
    [51.0038, 83.1828],
    [51.011, 83.1746],
    [51.0103, 83.1219],
    [51.0085, 83.1214],
    [50.9637, 83.1098],
    [50.9576, 83.0995],
    [50.9445, 83.0733],
    [50.9411, 83.0646],
    [50.9359, 83.0516],
    [50.9293, 83.03],
    [50.9169, 83.0024],
    [50.9126, 82.9951],
    [50.8842, 82.9825],
    [50.8759113, 82.944687],
    [50.8365393, 82.9892053],
    [50.814263, 83.0136331],
    [50.7957952, 82.9470436],
    [50.6916318, 82.9123984],
    [50.6727332, 82.944671],
    [50.6301909, 82.9133921],
    [50.6536341, 82.8292396],
    [50.592029, 82.6820758],
    [50.5974511, 82.64994],
    [50.5645951, 82.639962],
    [50.5252959, 82.4866627],
    [50.520806, 82.540505],
    [50.4895545, 82.5736459],
    [50.4441109, 82.5368037],
    [50.4109882, 82.4504632],
    [50.375926, 82.4820634],
    [50.3502606, 82.4555594],
    [50.3663148, 82.4355007],
    [50.3675159, 82.4011838],
    [50.3385504, 82.4219809],
    [50.3338463, 82.3708872],
    [50.355751, 82.3675232],
    [50.3579071, 82.3266446],
    [50.3748206, 82.3278434],
    [50.3987652, 82.3114861],
    [50.3730041, 82.2569806],
    [50.4078794, 82.196702],
    [50.3769561, 82.119788],
    [50.3818471, 82.0874721],
    [50.3359454, 82.0262978],
    [50.3111962, 82.0497752],
    [50.2335096, 82.0034495],
    [50.2079468, 82.0872191],
    [50.2292066, 82.1830978],
    [50.2105063, 82.2115323],
    [50.1404525, 82.2479096],
    [50.0807802, 82.3032607],
    [50.0696014, 82.3584968],
    [50.0606007, 82.4029615],
    [50.0304843, 82.4647814],
    [50.0244025, 82.4905016],
    [50.0411047, 82.5173266],
    [50.021535, 82.5490381],
    [50.0242922, 82.5680925],
    [50.0125962, 82.5795551],
    [50.0126757, 82.5826187],
    [50.0235335, 82.6102998],
    [50.0300268, 82.6449968],
    [50.0419348, 82.7406122],
    [50.0377452, 82.7713396],
    [50.0305781, 82.7900507],
    [50.0189982, 82.7768327],
    [49.9632311, 82.7223327],
    [49.9016251, 82.7332375],
    [49.9016251, 82.7332375],
    [49.8778391, 82.817069],
    [49.9174733, 82.8830272],
    [49.9096649, 83.0017314],
    [49.9292632, 83.0108639],
    [49.9344141, 83.0539943],
    [49.9115954, 83.1111231],
    [49.9389951, 83.1239159],
    [49.9093892, 83.1709595],
    [49.9116204, 83.2057186],
    [49.8925213, 83.2641762],
    [49.9171519, 83.2688211],
    [49.9126278, 83.2935793],
    [49.9153058, 83.348388],
    [49.9400527, 83.3062217],
    [49.9551086, 83.3399347],
    [49.9381838, 83.3855903],
    [49.9892341, 83.3623363],
    [50.007278, 83.2636178],
    [50.0386093, 83.2312244],
    [50.0822525, 83.2248087],
    [50.1387864, 83.264869],
    [50.1550093, 83.1986237],
    [50.2020598, 83.2210094],
    [50.226638, 83.1316217],
    [50.3096077, 83.1446834],
    [50.3158046, 83.1814345],
    [50.3483279, 83.1904917],
    [50.3488886, 83.2109538],
    [50.3746565, 83.2570106],
    [50.4867558, 83.3225335],
    [50.5599069, 83.3003529],
    [50.5984266, 83.3024748],
    [50.5995456, 83.4759444],
    [50.5727931, 83.6116418],
    [50.6348221, 83.7934644],
    [50.6903988, 83.916344],
    [50.6518062, 84.0306244],
    [50.6505664, 84.0487983],
    [50.6477699, 84.0506056],
    [50.6461722, 84.0516358],
    [50.6416481, 84.05928],
    [50.6422726, 84.0614931],
    [50.6409712, 84.0622702],
    [50.6348791, 84.065926],
    [50.6332361, 84.0714845],
    [50.6349149, 84.073109],
  ],
});
mapRegions.push({
  id: 13,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Усть-Каменогорск Г.А.",
  centerTitle: "город Усть-Каменогорск",
  centerTitleShort: "г. Усть-Каменогорск",
  centerGeometry: [49.9568527, 82.6147842],
  geometry: [
    [49.9016251, 82.7332375],
    [49.9632311, 82.7223327],
    [50.0189982, 82.7768327],
    [50.0305781, 82.7900507],
    [50.0377452, 82.7713396],
    [50.0419348, 82.7406122],
    [50.0300268, 82.6449968],
    [50.0235335, 82.6102998],
    [50.0126757, 82.5826187],
    [50.0125962, 82.5795551],
    [50.0242922, 82.5680925],
    [50.021535, 82.5490381],
    [50.0411047, 82.5173266],
    [50.0244025, 82.4905016],
    [50.0023922, 82.4953539],
    [49.9251665, 82.4666944],
    [49.8891917, 82.5113637],
    [49.8760191, 82.629472],
    [49.8818726, 82.6900151],
    [49.8888263, 82.7130885],
    [49.9016251, 82.7332375],
  ],
});
mapRegions.push({
  id: 14,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Риддер Г.А.",
  centerTitle: "город Риддер",
  centerTitleShort: "г. Риддер",
  centerGeometry: [50.330998, 83.495922],
  geometry: [
    [50.6349149, 84.073109],
    [50.6332361, 84.0714845],
    [50.6348791, 84.065926],
    [50.6409712, 84.0622702],
    [50.6422726, 84.0614931],
    [50.6416481, 84.05928],
    [50.6461722, 84.0516358],
    [50.6477699, 84.0506056],
    [50.6505664, 84.0487983],
    [50.6518062, 84.0306244],
    [50.6903988, 83.916344],
    [50.6348221, 83.7934644],
    [50.5727931, 83.6116418],
    [50.5995456, 83.4759444],
    [50.5984266, 83.3024748],
    [50.5599069, 83.3003529],
    [50.4867558, 83.3225335],
    [50.3746565, 83.2570106],
    [50.3488886, 83.2109538],
    [50.3483279, 83.1904917],
    [50.3158046, 83.1814345],
    [50.3096077, 83.1446834],
    [50.226638, 83.1316217],
    [50.2020598, 83.2210094],
    [50.1550093, 83.1986237],
    [50.1387864, 83.264869],
    [50.1258478, 83.3590612],
    [50.1470027, 83.4287184],
    [50.143486, 83.493186],
    [50.1646843, 83.5161369],
    [50.1931985, 83.522735],
    [50.1944589, 83.6281519],
    [50.2124755, 83.7983167],
    [50.1910561, 83.8209371],
    [50.2008428, 83.8573372],
    [50.1833071, 83.8816601],
    [50.2227908, 83.9815911],
    [50.285679, 84.0942465],
    [50.2595601, 84.1404651],
    [50.3145055, 84.168519],
    [50.3082265, 84.2349239],
    [50.3025, 84.2587],
    [50.3143, 84.2651],
    [50.3279, 84.2643],
    [50.3352, 84.2667],
    [50.3395, 84.2678],
    [50.3576, 84.2668],
    [50.3673, 84.2578],
    [50.3727, 84.2512],
    [50.3767, 84.246],
    [50.3857, 84.2413],
    [50.3919, 84.2414],
    [50.3967, 84.2416],
    [50.4046, 84.2416],
    [50.4216, 84.2308],
    [50.4295, 84.2261],
    [50.4401, 84.217],
    [50.4514, 84.2093],
    [50.4639, 84.2041],
    [50.4685, 84.2039],
    [50.473, 84.2095],
    [50.4741, 84.2136],
    [50.4789, 84.2246],
    [50.4916, 84.2351],
    [50.5034, 84.2372],
    [50.5135, 84.2437],
    [50.5207, 84.2433],
    [50.5321, 84.2356],
    [50.5392, 84.2293],
    [50.5434, 84.2194],
    [50.5429, 84.1981],
    [50.5462, 84.1838],
    [50.5503, 84.1694],
    [50.5583, 84.1664],
    [50.5675, 84.167],
    [50.5745, 84.1553],
    [50.5842, 84.1519],
    [50.6053, 84.1365],
    [50.6077, 84.1307],
    [50.6087, 84.1278],
    [50.6119, 84.1177],
    [50.6164, 84.1104],
    [50.6287, 84.0998],
    [50.6252884, 84.0909682],
    [50.6349149, 84.073109],
  ],
});
mapRegions.push({
  id: 15,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Алтайский район",
  centerTitle: "город Зыряновск",
  centerTitleShort: "г. Зыряновск",
  centerGeometry: [49.725034, 84.2752647],
  geometry: [
    [50.3025, 84.2587],
    [50.3082265, 84.2349239],
    [50.3145055, 84.168519],
    [50.2595601, 84.1404651],
    [50.285679, 84.0942465],
    [50.2227908, 83.9815911],
    [50.1833071, 83.8816601],
    [50.2008428, 83.8573372],
    [50.1910561, 83.8209371],
    [50.2124755, 83.7983167],
    [50.1944589, 83.6281519],
    [50.1931985, 83.522735],
    [50.1646843, 83.5161369],
    [50.143486, 83.493186],
    [50.1470027, 83.4287184],
    [50.1258478, 83.3590612],
    [50.1387864, 83.264869],
    [50.0822525, 83.2248087],
    [50.0386093, 83.2312244],
    [50.007278, 83.2636178],
    [49.9892341, 83.3623363],
    [49.9381838, 83.3855903],
    [49.9551086, 83.3399347],
    [49.9400527, 83.3062217],
    [49.9153058, 83.348388],
    [49.9126278, 83.2935793],
    [49.9171519, 83.2688211],
    [49.8925213, 83.2641762],
    [49.9116204, 83.2057186],
    [49.9093892, 83.1709595],
    [49.9389951, 83.1239159],
    [49.9115954, 83.1111231],
    [49.9344141, 83.0539943],
    [49.9292632, 83.0108639],
    [49.9096649, 83.0017314],
    [49.9174733, 82.8830272],
    [49.8778391, 82.817069],
    [49.7910336, 82.8791732],
    [49.7403138, 82.903503],
    [49.7213833, 82.9152155],
    [49.7183771, 82.9815064],
    [49.6598512, 83.0495226],
    [49.6536793, 83.2019353],
    [49.6434551, 83.2579883],
    [49.6633405, 83.3342596],
    [49.6274692, 83.34491],
    [49.5948137, 83.383881],
    [49.591819, 83.4227379],
    [49.561862, 83.4695445],
    [49.5733261, 83.5213926],
    [49.5421247, 83.5741728],
    [49.5386285, 83.5925974],
    [49.4944224, 83.6462163],
    [49.4716994, 83.6760639],
    [49.4259366, 83.867844],
    [49.3117724, 84.0556494],
    [49.2953444, 84.0945519],
    [49.3455014, 84.2274392],
    [49.3126864, 84.3246535],
    [49.3513519, 84.3674009],
    [49.3390856, 84.4276818],
    [49.4005995, 84.4868778],
    [49.4052649, 84.5282965],
    [49.3899924, 84.5611571],
    [49.3817943, 84.6234208],
    [49.4217977, 84.6408311],
    [49.4382736, 84.6017986],
    [49.477477, 84.6452481],
    [49.4714783, 84.6757133],
    [49.5305558, 84.7081226],
    [49.5927853, 84.702149],
    [49.6532212, 84.6354364],
    [49.6968347, 84.6343834],
    [49.7296975, 84.668648],
    [49.7270469, 84.7075049],
    [49.7016687, 84.7031108],
    [49.6886184, 84.750633],
    [49.6210284, 84.7609096],
    [49.5996523, 84.7581792],
    [49.5921335, 84.7747645],
    [49.5649958, 84.7562648],
    [49.5510703, 84.7832406],
    [49.5463342, 84.8538141],
    [49.5615452, 84.9593198],
    [49.5160559, 85.011512],
    [49.4958765, 85.1399003],
    [49.4688641, 85.1409988],
    [49.4713484, 85.1746297],
    [49.5032358, 85.1952058],
    [49.501294, 85.2527152],
    [49.5352941, 85.2870481],
    [49.5375368, 85.2751168],
    [49.5545118, 85.25669],
    [49.5753294, 85.2658225],
    [49.5935, 85.2617],
    [49.5962, 85.2602],
    [49.6006, 85.2587],
    [49.6188, 85.2581],
    [49.6241, 85.2565],
    [49.6284, 85.2397],
    [49.6272, 85.2216],
    [49.6279, 85.2121],
    [49.6438, 85.2141],
    [49.6512, 85.2041],
    [49.6576, 85.2053],
    [49.6701, 85.2078],
    [49.6782, 85.2114],
    [49.6854, 85.2125],
    [49.6942, 85.2122],
    [49.7139, 85.209],
    [49.7176, 85.21],
    [49.7267, 85.2096],
    [49.7366, 85.2037],
    [49.7411, 85.2036],
    [49.7508, 85.1949],
    [49.7621, 85.1778],
    [49.7665, 85.1693],
    [49.771, 85.1622],
    [49.7709, 85.1553],
    [49.7744, 85.1524],
    [49.7798, 85.1453],
    [49.7922, 85.142],
    [49.7977, 85.1363],
    [49.8047, 85.1346],
    [49.8117, 85.1274],
    [49.8153, 85.1231],
    [49.8172, 85.1216],
    [49.8206, 85.1104],
    [49.8286, 85.1031],
    [49.8302, 85.1031],
    [49.8392, 85.1055],
    [49.8596, 85.0908],
    [49.8694, 85.0807],
    [49.8844, 85.0593],
    [49.8887, 85.0477],
    [49.8938, 85.0322],
    [49.8963, 85.0196],
    [49.9058, 84.9998],
    [49.9101, 84.9885],
    [49.9209, 84.988],
    [49.9336, 84.9833],
    [49.9522, 84.9784],
    [49.9658, 84.9889],
    [49.9758, 84.9857],
    [49.9774, 84.9829],
    [49.9817, 84.9827],
    [49.9854, 84.9839],
    [49.9875, 84.988],
    [49.9911, 84.9934],
    [49.9932, 85.0059],
    [49.9959, 85.0141],
    [49.9999, 85.0265],
    [50.0062, 85.0388],
    [50.0091, 85.0403],
    [50.0173, 85.0411],
    [50.0207, 85.0354],
    [50.0253, 85.0338],
    [50.0296, 85.0337],
    [50.0384, 85.0319],
    [50.0494, 85.0329],
    [50.0556, 85.0298],
    [50.0582, 85.0241],
    [50.0598, 85.0157],
    [50.0607, 85.0059],
    [50.0614, 85.0003],
    [50.063, 84.9891],
    [50.0663, 84.9763],
    [50.0725, 84.9663],
    [50.0831, 84.9575],
    [50.0912, 84.9516],
    [50.0928, 84.9501],
    [50.0965, 84.9458],
    [50.0956, 84.9374],
    [50.0942, 84.9249],
    [50.0933, 84.9194],
    [50.0974, 84.9064],
    [50.1018, 84.8939],
    [50.1044, 84.8868],
    [50.1034, 84.8812],
    [50.1014, 84.8755],
    [50.0922, 84.8564],
    [50.0939, 84.8507],
    [50.1013, 84.8383],
    [50.1051, 84.8335],
    [50.1141, 84.8275],
    [50.1219, 84.8145],
    [50.1291, 84.8128],
    [50.1354, 84.8125],
    [50.1463, 84.8204],
    [50.147, 84.812],
    [50.1477, 84.8008],
    [50.1447, 84.7856],
    [50.143, 84.78],
    [50.1391, 84.7718],
    [50.137, 84.758],
    [50.1404, 84.7492],
    [50.1492, 84.7378],
    [50.1641, 84.7201],
    [50.1773, 84.7041],
    [50.1834, 84.6968],
    [50.1878, 84.691],
    [50.1912, 84.6811],
    [50.1944, 84.6697],
    [50.2056, 84.6384],
    [50.2108, 84.6283],
    [50.2126, 84.6212],
    [50.2122, 84.6101],
    [50.2082, 84.5851],
    [50.2052, 84.5754],
    [50.2005, 84.5659],
    [50.1996, 84.5645],
    [50.1995, 84.5617],
    [50.2012, 84.5574],
    [50.2038, 84.5517],
    [50.2054, 84.5446],
    [50.2087, 84.5277],
    [50.2149, 84.5159],
    [50.2246, 84.5084],
    [50.2306, 84.5025],
    [50.2401, 84.4812],
    [50.2402, 84.481],
    [50.2413742, 84.4768814],
    [50.2467, 84.4582],
    [50.2466, 84.447],
    [50.243, 84.4416],
    [50.2356, 84.4322],
    [50.2273692, 84.4231526],
    [50.2235, 84.4189],
    [50.2169, 84.4122],
    [50.2113, 84.4055],
    [50.2176, 84.3926],
    [50.2182, 84.3821],
    [50.2171, 84.3744],
    [50.2087, 84.3635],
    [50.2123, 84.3593],
    [50.2174, 84.3476],
    [50.2243, 84.3332],
    [50.226, 84.3275],
    [50.2313, 84.3258],
    [50.2418, 84.3196],
    [50.2496, 84.3024],
    [50.2574, 84.2893],
    [50.2652, 84.2763],
    [50.2809, 84.2586],
    [50.2905, 84.251],
    [50.2924, 84.2509],
    [50.3025, 84.2587],
  ],
});
mapRegions.push({
  id: 16,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Катон-Карагайский район",
  centerTitle: "село Улкен Нарын",
  centerTitleShort: "с. Улкен Нарын",
  centerGeometry: [49.2091868, 84.5043468],
  geometry: [
    [49.0811194, 84.1983643],
    [49.0290981, 84.2213813],
    [48.9635479, 84.1664883],
    [48.9328274, 84.2703941],
    [48.9396443, 84.3385539],
    [48.9123197, 84.365532],
    [48.9203263, 84.4081564],
    [48.9674292, 84.409508],
    [49.0004311, 84.456718],
    [49.0046088, 84.4977037],
    [48.9889306, 84.5103232],
    [48.9773663, 84.5638966],
    [48.9946284, 84.6042396],
    [48.9709733, 84.613265],
    [48.9671329, 84.650734],
    [48.9250327, 84.7036372],
    [48.9567424, 84.7375485],
    [48.9356877, 84.7932734],
    [48.9232186, 84.9890215],
    [48.9425958, 85.0623937],
    [48.9996266, 85.1073155],
    [48.9827108, 85.2087484],
    [48.94152, 85.2986307],
    [48.9666862, 85.3289797],
    [48.9744079, 85.5648725],
    [48.9430356, 85.5880353],
    [48.9191252, 85.5216373],
    [48.8690458, 85.4993519],
    [48.8633776, 85.6020429],
    [48.9161692, 85.7164532],
    [48.9320707, 85.8082659],
    [48.9653438, 85.8231578],
    [48.9885751, 85.8701103],
    [48.9745971, 85.9002906],
    [49.0251666, 85.9416888],
    [49.031906, 86.0060425],
    [48.9823302, 86.0136252],
    [48.9451302, 86.0818716],
    [48.9154581, 86.1045764],
    [48.9012978, 86.1714713],
    [48.9680606, 86.2479864],
    [48.9542969, 86.317826],
    [48.982002, 86.3589256],
    [48.9463723, 86.4137799],
    [48.986125, 86.4703298],
    [48.9635342, 86.5197597],
    [49.0141287, 86.606964],
    [48.9862754, 86.6398929],
    [48.9529157, 86.6368867],
    [48.9386597, 86.7269741],
    [48.938382, 86.731482],
    [48.938771, 86.731397],
    [48.939353, 86.730821],
    [48.939556, 86.730708],
    [48.939762, 86.730358],
    [48.939919, 86.73022],
    [48.940168, 86.730179],
    [48.940477, 86.730353],
    [48.941247, 86.731036],
    [48.941634, 86.731188],
    [48.94193, 86.731077],
    [48.942636, 86.730528],
    [48.943013, 86.730376],
    [48.943432, 86.730435],
    [48.943974, 86.730662],
    [48.944374, 86.73098],
    [48.944896, 86.731609],
    [48.945314, 86.731738],
    [48.945518, 86.731625],
    [48.946135, 86.730623],
    [48.946434, 86.7303],
    [48.94681, 86.730025],
    [48.947184, 86.729988],
    [48.947633, 86.730118],
    [48.94839, 86.73054],
    [48.948541, 86.730899],
    [48.948661, 86.731352],
    [48.948876, 86.731523],
    [48.949202, 86.731579],
    [48.949452, 86.731514],
    [48.949626, 86.731211],
    [48.94974, 86.730788],
    [48.950258, 86.730281],
    [48.950571, 86.730123],
    [48.95073, 86.72982],
    [48.950733, 86.729559],
    [48.950464, 86.728535],
    [48.950539, 86.727424],
    [48.950636, 86.727072],
    [48.950935, 86.726771],
    [48.951153, 86.726706],
    [48.951659, 86.727043],
    [48.952166, 86.727621],
    [48.952676, 86.727894],
    [48.95299, 86.727816],
    [48.953572, 86.726746],
    [48.953889, 86.72645],
    [48.954316, 86.726299],
    [48.954929, 86.726419],
    [48.955336, 86.726637],
    [48.955896, 86.727518],
    [48.956278, 86.72791],
    [48.956505, 86.72795],
    [48.956714, 86.727574],
    [48.956858, 86.72692],
    [48.957045, 86.726474],
    [48.957343, 86.726239],
    [48.957843, 86.726356],
    [48.958339, 86.72675],
    [48.958875, 86.72763],
    [48.959283, 86.72778],
    [48.959785, 86.727689],
    [48.960152, 86.727421],
    [48.961065, 86.727202],
    [48.961473, 86.727282],
    [48.961946, 86.727779],
    [48.962867, 86.728355],
    [48.963387, 86.728527],
    [48.964012, 86.728596],
    [48.964653, 86.728799],
    [48.964978, 86.729337],
    [48.965094, 86.729843],
    [48.965247, 86.730165],
    [48.965806, 86.729888],
    [48.966092, 86.72918],
    [48.96634, 86.728737],
    [48.966726, 86.728455],
    [48.967056, 86.728543],
    [48.967261, 86.728787],
    [48.96813, 86.728836],
    [48.968702, 86.72901],
    [48.969253, 86.729501],
    [48.969767, 86.73015],
    [48.970147, 86.730372],
    [48.97056, 86.73078],
    [48.970833, 86.731263],
    [48.971178, 86.731458],
    [48.97156, 86.731521],
    [48.972348, 86.731231],
    [48.972748, 86.731242],
    [48.972923, 86.731114],
    [48.973416, 86.730544],
    [48.973696, 86.730393],
    [48.974252, 86.73046],
    [48.974949, 86.730319],
    [48.975508, 86.730069],
    [48.976331, 86.72956],
    [48.976504, 86.729591],
    [48.976697, 86.72949],
    [48.976943, 86.729259],
    [48.978233, 86.727384],
    [48.97892, 86.726607],
    [48.979148, 86.726428],
    [48.979356, 86.726434],
    [48.979649, 86.726679],
    [48.979989, 86.727403],
    [48.980265, 86.727596],
    [48.980612, 86.727658],
    [48.981664, 86.726917],
    [48.98222, 86.726879],
    [48.982434, 86.727042],
    [48.982679, 86.727623],
    [48.983097, 86.729613],
    [48.983364, 86.73013],
    [48.98378, 86.730333],
    [48.984283, 86.730346],
    [48.98481, 86.73004],
    [48.985378, 86.7298],
    [48.986229, 86.728641],
    [48.986673, 86.728333],
    [48.986976, 86.727447],
    [48.987529, 86.726695],
    [48.987993, 86.726388],
    [48.98887, 86.726698],
    [48.989076, 86.726959],
    [48.989131, 86.727695],
    [48.988647, 86.727874],
    [48.988643, 86.728225],
    [48.989266, 86.72872],
    [48.989538, 86.728791],
    [48.989594, 86.729335],
    [48.989423, 86.72965],
    [48.989546, 86.729908],
    [48.989901, 86.730013],
    [48.990575, 86.72968],
    [48.990845, 86.729768],
    [48.991272, 86.729094],
    [48.992184, 86.728605],
    [48.992672, 86.728516],
    [48.993007, 86.728601],
    [48.993394, 86.728586],
    [48.993945, 86.728907],
    [48.994148, 86.728784],
    [48.994394, 86.729303],
    [48.994537, 86.730024],
    [48.994531, 86.73051],
    [48.994812, 86.730927],
    [48.994759, 86.731207],
    [48.994973, 86.731571],
    [48.995957, 86.732211],
    [48.996501, 86.733095],
    [48.997005, 86.734593],
    [48.997436, 86.735142],
    [48.997611, 86.735218],
    [48.997991, 86.734864],
    [48.998313, 86.735055],
    [48.998568, 86.735113],
    [48.998945, 86.734915],
    [48.99915, 86.734947],
    [48.999398, 86.735655],
    [48.999322, 86.736329],
    [49.00006, 86.737467],
    [49.000349, 86.7375],
    [49.000641, 86.737352],
    [49.001015, 86.737518],
    [49.001791, 86.738292],
    [49.003296, 86.739476],
    [49.004399, 86.739973],
    [49.005785, 86.741288],
    [49.006238, 86.741976],
    [49.006568, 86.742947],
    [49.006762, 86.743914],
    [49.007143, 86.744913],
    [49.007338, 86.745776],
    [49.007588, 86.746303],
    [49.008345, 86.747181],
    [49.008942, 86.747197],
    [49.009432, 86.747626],
    [49.009597, 86.748099],
    [49.009213, 86.748869],
    [49.009192, 86.749232],
    [49.009323, 86.749756],
    [49.00956, 86.749866],
    [49.010108, 86.749673],
    [49.010951, 86.749071],
    [49.011225, 86.748974],
    [49.011616, 86.749114],
    [49.01203, 86.748966],
    [49.013038, 86.749194],
    [49.013397, 86.74955],
    [49.013762, 86.749456],
    [49.014231, 86.749156],
    [49.014537, 86.749164],
    [49.015031, 86.749645],
    [49.015276, 86.750119],
    [49.015432, 86.750313],
    [49.01568, 86.750459],
    [49.015904, 86.750742],
    [49.016147, 86.751388],
    [49.016352, 86.752329],
    [49.016375, 86.753281],
    [49.016855, 86.754498],
    [49.016775, 86.7555],
    [49.016915, 86.756213],
    [49.016943, 86.756681],
    [49.017498, 86.756817],
    [49.017794, 86.756721],
    [49.018266, 86.757201],
    [49.018639, 86.757367],
    [49.018739, 86.757543],
    [49.018789, 86.758081],
    [49.018751, 86.758443],
    [49.018804, 86.758774],
    [49.019191, 86.759736],
    [49.019435, 86.76021],
    [49.019648, 86.760458],
    [49.01994, 86.760629],
    [49.020435, 86.761075],
    [49.020647, 86.761375],
    [49.02078, 86.76169],
    [49.020799, 86.761985],
    [49.020655, 86.762691],
    [49.020755, 86.762884],
    [49.021061, 86.762962],
    [49.021251, 86.763157],
    [49.021626, 86.764171],
    [49.021783, 86.764297],
    [49.021939, 86.764578],
    [49.022405, 86.764504],
    [49.023614, 86.764034],
    [49.02401, 86.764131],
    [49.024201, 86.76431],
    [49.024412, 86.764748],
    [49.024762, 86.764913],
    [49.02561, 86.766269],
    [49.025773, 86.76688],
    [49.026005, 86.767405],
    [49.02651, 86.768943],
    [49.026728, 86.769843],
    [49.027003, 86.770595],
    [49.027166, 86.771205],
    [49.027243, 86.771415],
    [49.027535, 86.771752],
    [49.027679, 86.77205],
    [49.02771, 86.772259],
    [49.027684, 86.772605],
    [49.027564, 86.773104],
    [49.026993, 86.77437],
    [49.026738, 86.774865],
    [49.026542, 86.775102],
    [49.026506, 86.775309],
    [49.026525, 86.775569],
    [49.026871, 86.776115],
    [49.027169, 86.776833],
    [49.027539, 86.778676],
    [49.027528, 86.77961],
    [49.027444, 86.779954],
    [49.027235, 86.780434],
    [49.027232, 86.780659],
    [49.027309, 86.780886],
    [49.02871, 86.782448],
    [49.029354, 86.782742],
    [49.029995, 86.783279],
    [49.030386, 86.783775],
    [49.030485, 86.784089],
    [49.030526, 86.784454],
    [49.030485, 86.785041],
    [49.030374, 86.785748],
    [49.030551, 86.786203],
    [49.030959, 86.786266],
    [49.031264, 86.786413],
    [49.031533, 86.786663],
    [49.03189, 86.787192],
    [49.032401, 86.788392],
    [49.033171, 86.788621],
    [49.033436, 86.789217],
    [49.033799, 86.789261],
    [49.034245, 86.789914],
    [49.034408, 86.790611],
    [49.03453, 86.790788],
    [49.03464, 86.791137],
    [49.034967, 86.791319],
    [49.035731, 86.792033],
    [49.03612, 86.792298],
    [49.036421, 86.792739],
    [49.037913, 86.793352],
    [49.038352, 86.793624],
    [49.038473, 86.793974],
    [49.03872, 86.794223],
    [49.039097, 86.794943],
    [49.039196, 86.795275],
    [49.039144, 86.795828],
    [49.038911, 86.796606],
    [49.038783, 86.796879],
    [49.038588, 86.797982],
    [49.03858, 86.798727],
    [49.038641, 86.7993],
    [49.038836, 86.800147],
    [49.038931, 86.800773],
    [49.03904, 86.801088],
    [49.039229, 86.801422],
    [49.040315, 86.802751],
    [49.040678, 86.802813],
    [49.04089, 86.803113],
    [49.041194, 86.803277],
    [49.04135, 86.803559],
    [49.041459, 86.803995],
    [49.041437, 86.804826],
    [49.0411, 86.805526],
    [49.041276, 86.806969],
    [49.041643, 86.807637],
    [49.042057, 86.808134],
    [49.043085, 86.808894],
    [49.043486, 86.809563],
    [49.04356, 86.810033],
    [49.043592, 86.811125],
    [49.043751, 86.812065],
    [49.043789, 86.812655],
    [49.044251, 86.813915],
    [49.044706, 86.814355],
    [49.04488, 86.814856],
    [49.044933, 86.815596],
    [49.044686, 86.817224],
    [49.044756, 86.81741],
    [49.045069, 86.817851],
    [49.045885, 86.818647],
    [49.046074, 86.819032],
    [49.046157, 86.819045],
    [49.046276, 86.818869],
    [49.046461, 86.819001],
    [49.046692, 86.819366],
    [49.046736, 86.819747],
    [49.046817, 86.819939],
    [49.047037, 86.82004],
    [49.047118, 86.820148],
    [49.047428, 86.820948],
    [49.047465, 86.821179],
    [49.047682, 86.821548],
    [49.047703, 86.821779],
    [49.047397, 86.822158],
    [49.047117, 86.822344],
    [49.047146, 86.822599],
    [49.047262, 86.822832],
    [49.047763, 86.82344],
    [49.047984, 86.823555],
    [49.048142, 86.823547],
    [49.048486, 86.823339],
    [49.048597, 86.82339],
    [49.048753, 86.8236],
    [49.048959, 86.824296],
    [49.049076, 86.825098],
    [49.049282, 86.827188],
    [49.049266, 86.827853],
    [49.049114, 86.829289],
    [49.049134, 86.829629],
    [49.049273, 86.829887],
    [49.050051, 86.830551],
    [49.050414, 86.830694],
    [49.050933, 86.831132],
    [49.051033, 86.831329],
    [49.05138, 86.831569],
    [49.051684, 86.831715],
    [49.051891, 86.831721],
    [49.052087, 86.831847],
    [49.05217, 86.832249],
    [49.052087, 86.832513],
    [49.052046, 86.833275],
    [49.052468, 86.834461],
    [49.052736, 86.834663],
    [49.053052, 86.834793],
    [49.053473, 86.834756],
    [49.054019, 86.83488],
    [49.05481, 86.835661],
    [49.055748, 86.836136],
    [49.056058, 86.83675],
    [49.057427, 86.837733],
    [49.058313, 86.838605],
    [49.058944, 86.838914],
    [49.05982, 86.839075],
    [49.060436, 86.839347],
    [49.0606, 86.839533],
    [49.060845, 86.840291],
    [49.060872, 86.840704],
    [49.060794, 86.841286],
    [49.060812, 86.841807],
    [49.06088, 86.842112],
    [49.060876, 86.842439],
    [49.060951, 86.842755],
    [49.061068, 86.842953],
    [49.061257, 86.843019],
    [49.061399, 86.843156],
    [49.061518, 86.843764],
    [49.06165, 86.843986],
    [49.061988, 86.844299],
    [49.062488, 86.844955],
    [49.063188, 86.84534],
    [49.063367, 86.845624],
    [49.063363, 86.845951],
    [49.063808, 86.846545],
    [49.0653, 86.847502],
    [49.065514, 86.847533],
    [49.065761, 86.847394],
    [49.066002, 86.847159],
    [49.066434, 86.846929],
    [49.067206, 86.847338],
    [49.067444, 86.847369],
    [49.067818, 86.847247],
    [49.068045, 86.846902],
    [49.068459, 86.846732],
    [49.068834, 86.84693],
    [49.069183, 86.846952],
    [49.069415, 86.846777],
    [49.069609, 86.846503],
    [49.069717, 86.846034],
    [49.070198, 86.845697],
    [49.070343, 86.845507],
    [49.070736, 86.845191],
    [49.071141, 86.84513],
    [49.071346, 86.84522],
    [49.071529, 86.845213],
    [49.071804, 86.845015],
    [49.071965, 86.844813],
    [49.072171, 86.844226],
    [49.072291, 86.844108],
    [49.072546, 86.84403],
    [49.072824, 86.844063],
    [49.073313, 86.844246],
    [49.073691, 86.844511],
    [49.073942, 86.844785],
    [49.07433, 86.845438],
    [49.074519, 86.845612],
    [49.074882, 86.84578],
    [49.075317, 86.845853],
    [49.075727, 86.846071],
    [49.076038, 86.846084],
    [49.076318, 86.845946],
    [49.076602, 86.846027],
    [49.077001, 86.845893],
    [49.077242, 86.845657],
    [49.077923, 86.844429],
    [49.078107, 86.844277],
    [49.078481, 86.844251],
    [49.07889, 86.844529],
    [49.079175, 86.84455],
    [49.079424, 86.844375],
    [49.079633, 86.844114],
    [49.080081, 86.843824],
    [49.080571, 86.843651],
    [49.080922, 86.844109],
    [49.081232, 86.844118],
    [49.081989, 86.843836],
    [49.08237, 86.843786],
    [49.08302, 86.843211],
    [49.083334, 86.842808],
    [49.083558, 86.84273],
    [49.083856, 86.843004],
    [49.084046, 86.843034],
    [49.084325, 86.842933],
    [49.084637, 86.843155],
    [49.084898, 86.843247],
    [49.085192, 86.843219],
    [49.085928, 86.842731],
    [49.087069, 86.84223],
    [49.08757, 86.842111],
    [49.087806, 86.842156],
    [49.08797, 86.842318],
    [49.088272, 86.842993],
    [49.088859, 86.843592],
    [49.089029, 86.844009],
    [49.089163, 86.844085],
    [49.089354, 86.844054],
    [49.089951, 86.843211],
    [49.090191, 86.843036],
    [49.090391, 86.842944],
    [49.091441, 86.842732],
    [49.091816, 86.842573],
    [49.092208, 86.842615],
    [49.093067, 86.842086],
    [49.093359, 86.842134],
    [49.093734, 86.842395],
    [49.094811, 86.842399],
    [49.095311, 86.842282],
    [49.096135, 86.842555],
    [49.096357, 86.842693],
    [49.096846, 86.843744],
    [49.097235, 86.84432],
    [49.097406, 86.844417],
    [49.097813, 86.844297],
    [49.098225, 86.844374],
    [49.098423, 86.84434],
    [49.099484, 86.843476],
    [49.100035, 86.843532],
    [49.10038, 86.843462],
    [49.100629, 86.843535],
    [49.100909, 86.843872],
    [49.101038, 86.844361],
    [49.101051, 86.844651],
    [49.101343, 86.845409],
    [49.101531, 86.845585],
    [49.101784, 86.845974],
    [49.102185, 86.846287],
    [49.102366, 86.846253],
    [49.102557, 86.846127],
    [49.102789, 86.846226],
    [49.103126, 86.846091],
    [49.103514, 86.846062],
    [49.103878, 86.846533],
    [49.104396, 86.84785],
    [49.104625, 86.848119],
    [49.105128, 86.848528],
    [49.10528, 86.848867],
    [49.105403, 86.848953],
    [49.106131, 86.848962],
    [49.106353, 86.849099],
    [49.106586, 86.849697],
    [49.106837, 86.850071],
    [49.107128, 86.850339],
    [49.107784, 86.850523],
    [49.108184, 86.85076],
    [49.108986, 86.851646],
    [49.109706, 86.851998],
    [49.110348, 86.852749],
    [49.110571, 86.852827],
    [49.110745, 86.853175],
    [49.110904, 86.853721],
    [49.111078, 86.854022],
    [49.111228, 86.855067],
    [49.111338, 86.855543],
    [49.112085, 86.857209],
    [49.112425, 86.857857],
    [49.112745, 86.858293],
    [49.113251, 86.858662],
    [49.113639, 86.858626],
    [49.114064, 86.858834],
    [49.114401, 86.859163],
    [49.114764, 86.859872],
    [49.115243, 86.860584],
    [49.115396, 86.860683],
    [49.1159, 86.860685],
    [49.116154, 86.860823],
    [49.116835, 86.861446],
    [49.117162, 86.861964],
    [49.11756, 86.862307],
    [49.117704, 86.862536],
    [49.117875, 86.863692],
    [49.118002, 86.86411],
    [49.118282, 86.864639],
    [49.118519, 86.865462],
    [49.118652, 86.86601],
    [49.118648, 86.866329],
    [49.118698, 86.866638],
    [49.118877, 86.867247],
    [49.11902, 86.867535],
    [49.119447, 86.868103],
    [49.119786, 86.86891],
    [49.119931, 86.869731],
    [49.119839, 86.870912],
    [49.12002, 86.871343],
    [49.120508, 86.871925],
    [49.120515, 86.872599],
    [49.120393, 86.873684],
    [49.120424, 86.8743],
    [49.120522, 86.874529],
    [49.12077, 86.874774],
    [49.121799, 86.875457],
    [49.122417, 86.875678],
    [49.122596, 86.875527],
    [49.122674, 86.875353],
    [49.122866, 86.87524],
    [49.123277, 86.874702],
    [49.123621, 86.874766],
    [49.12386, 86.875199],
    [49.124369, 86.875246],
    [49.124508, 86.875365],
    [49.12526, 86.8753],
    [49.125502, 86.875402],
    [49.12617, 86.876289],
    [49.12628, 86.876973],
    [49.126571, 86.877231],
    [49.126762, 86.877215],
    [49.127078, 86.877648],
    [49.127256, 86.877593],
    [49.127433, 86.877829],
    [49.127941, 86.878013],
    [49.128484, 86.878704],
    [49.128647, 86.878999],
    [49.128709, 86.879273],
    [49.128887, 86.879393],
    [49.12896, 86.879764],
    [49.128895, 86.880055],
    [49.129394, 86.881465],
    [49.129558, 86.881682],
    [49.129758, 86.882153],
    [49.129824, 86.882396],
    [49.12982, 86.88296],
    [49.130019, 86.883684],
    [49.130144, 86.883959],
    [49.130232, 86.884136],
    [49.130385, 86.884138],
    [49.131197, 86.8846],
    [49.1315, 86.884858],
    [49.131791, 86.885214],
    [49.131915, 86.885703],
    [49.132165, 86.886252],
    [49.132202, 86.886486],
    [49.132303, 86.886605],
    [49.132301, 86.886819],
    [49.132579, 86.887155],
    [49.132616, 86.88737],
    [49.132548, 86.887836],
    [49.13253, 86.888556],
    [49.132741, 86.889378],
    [49.132739, 86.889572],
    [49.132878, 86.889828],
    [49.132902, 86.890023],
    [49.132785, 86.890274],
    [49.132745, 86.890526],
    [49.132904, 86.891366],
    [49.133132, 86.891643],
    [49.133169, 86.891819],
    [49.133384, 86.891998],
    [49.133724, 86.892549],
    [49.133797, 86.892939],
    [49.133757, 86.893231],
    [49.133652, 86.893541],
    [49.133884, 86.894032],
    [49.134387, 86.894878],
    [49.134587, 86.895427],
    [49.134822, 86.896307],
    [49.134858, 86.896697],
    [49.134776, 86.897397],
    [49.134697, 86.897726],
    [49.134232, 86.898633],
    [49.134176, 86.899236],
    [49.134352, 86.899589],
    [49.134426, 86.899883],
    [49.13459, 86.900119],
    [49.134701, 86.90053],
    [49.1347, 86.900744],
    [49.134404, 86.901051],
    [49.134348, 86.901638],
    [49.134393, 86.902064],
    [49.134525, 86.902389],
    [49.134522, 86.902712],
    [49.134443, 86.903004],
    [49.13439, 86.903664],
    [49.134552, 86.903901],
    [49.134753, 86.903978],
    [49.135001, 86.904247],
    [49.135122, 86.904675],
    [49.135187, 86.904984],
    [49.135118, 86.905232],
    [49.135115, 86.90554],
    [49.135276, 86.905866],
    [49.135231, 86.906716],
    [49.135134, 86.906905],
    [49.13521, 86.907039],
    [49.135296, 86.90704],
    [49.135482, 86.906588],
    [49.135588, 86.906546],
    [49.135701, 86.906857],
    [49.135427, 86.907512],
    [49.134637, 86.907924],
    [49.13451, 86.908127],
    [49.134313, 86.908828],
    [49.134296, 86.909027],
    [49.134352, 86.909263],
    [49.134532, 86.909501],
    [49.13455, 86.909721],
    [49.134419, 86.910512],
    [49.134235, 86.910714],
    [49.134195, 86.910904],
    [49.134556, 86.91138],
    [49.134488, 86.911599],
    [49.134178, 86.911916],
    [49.134087, 86.912516],
    [49.133882, 86.912938],
    [49.133899, 86.913217],
    [49.134071, 86.913367],
    [49.134348, 86.913489],
    [49.134376, 86.913666],
    [49.134306, 86.913973],
    [49.133988, 86.914114],
    [49.133901, 86.914244],
    [49.133927, 86.914567],
    [49.134125, 86.91507],
    [49.134315, 86.915264],
    [49.134381, 86.915412],
    [49.134273, 86.915718],
    [49.133981, 86.915892],
    [49.133894, 86.916081],
    [49.133912, 86.916243],
    [49.134026, 86.916392],
    [49.134071, 86.916789],
    [49.134096, 86.917244],
    [49.134016, 86.917878],
    [49.133924, 86.918151],
    [49.133712, 86.91833],
    [49.133679, 86.918719],
    [49.13375, 86.919224],
    [49.133569, 86.919473],
    [49.133268, 86.919651],
    [49.133086, 86.919945],
    [49.133325, 86.919995],
    [49.13336, 86.920253],
    [49.133286, 86.920381],
    [49.13292, 86.920671],
    [49.132858, 86.920874],
    [49.13288, 86.92119],
    [49.132769, 86.921355],
    [49.132792, 86.921578],
    [49.133008, 86.921878],
    [49.133066, 86.922158],
    [49.133015, 86.922491],
    [49.132867, 86.922859],
    [49.132685, 86.922837],
    [49.132565, 86.922705],
    [49.132431, 86.922758],
    [49.132294, 86.923108],
    [49.132123, 86.923346],
    [49.132035, 86.923697],
    [49.132235, 86.923861],
    [49.132368, 86.924081],
    [49.132303, 86.924785],
    [49.132401, 86.925133],
    [49.132501, 86.925225],
    [49.132645, 86.925138],
    [49.132761, 86.925255],
    [49.132834, 86.925628],
    [49.132917, 86.925758],
    [49.132705, 86.926011],
    [49.132245, 86.926037],
    [49.132133, 86.926191],
    [49.131993, 86.92671],
    [49.131855, 86.926987],
    [49.132076, 86.927228],
    [49.13205, 86.927494],
    [49.13201, 86.927581],
    [49.131652, 86.927597],
    [49.131542, 86.928127],
    [49.131294, 86.928249],
    [49.131264, 86.928388],
    [49.131241, 86.928817],
    [49.131288, 86.928988],
    [49.131272, 86.929194],
    [49.131455, 86.929367],
    [49.131596, 86.929252],
    [49.131654, 86.929297],
    [49.131706, 86.929394],
    [49.131754, 86.929846],
    [49.131545, 86.930027],
    [49.131365, 86.930097],
    [49.131277, 86.930251],
    [49.131299, 86.930529],
    [49.131413, 86.930753],
    [49.131468, 86.931161],
    [49.131421, 86.931566],
    [49.131435, 86.932269],
    [49.131273, 86.932561],
    [49.131263, 86.932672],
    [49.131559, 86.933128],
    [49.131549, 86.933194],
    [49.131155, 86.933424],
    [49.131048, 86.933562],
    [49.131069, 86.933925],
    [49.13115, 86.934059],
    [49.131186, 86.934481],
    [49.131082, 86.934775],
    [49.130838, 86.934893],
    [49.130774, 86.935175],
    [49.130817, 86.935331],
    [49.131208, 86.935561],
    [49.131207, 86.935689],
    [49.130946, 86.936339],
    [49.130809, 86.93689],
    [49.13089, 86.93729],
    [49.130977, 86.93748],
    [49.131251, 86.937982],
    [49.131352, 86.938031],
    [49.131404, 86.938154],
    [49.131378, 86.93926],
    [49.131498, 86.939654],
    [49.131565, 86.940714],
    [49.131383, 86.94146],
    [49.131169, 86.941986],
    [49.130848, 86.942324],
    [49.130436, 86.942579],
    [49.13023, 86.942947],
    [49.130107, 86.942877],
    [49.130085, 86.943423],
    [49.130021, 86.943692],
    [49.130164, 86.943978],
    [49.130485, 86.944207],
    [49.130616, 86.944398],
    [49.130752, 86.944414],
    [49.130884, 86.944551],
    [49.131046, 86.944757],
    [49.131162, 86.945049],
    [49.131487, 86.945491],
    [49.131489, 86.945714],
    [49.131385, 86.946103],
    [49.131332, 86.946581],
    [49.131327, 86.946709],
    [49.131461, 86.947029],
    [49.131397, 86.947331],
    [49.131405, 86.948027],
    [49.13135, 86.948775],
    [49.131427, 86.949181],
    [49.131719, 86.949578],
    [49.131868, 86.950084],
    [49.131844, 86.950286],
    [49.131563, 86.951179],
    [49.131111, 86.951926],
    [49.131066, 86.952094],
    [49.131023, 86.952464],
    [49.131068, 86.952911],
    [49.131197, 86.953311],
    [49.131155, 86.953567],
    [49.131418, 86.953801],
    [49.131518, 86.954019],
    [49.131785, 86.954334],
    [49.132039, 86.9545],
    [49.132117, 86.954683],
    [49.132192, 86.954732],
    [49.132474, 86.95471],
    [49.132549, 86.954746],
    [49.132632, 86.954902],
    [49.132634, 86.955233],
    [49.132717, 86.955808],
    [49.133005, 86.956232],
    [49.133048, 86.956402],
    [49.133033, 86.956665],
    [49.133145, 86.95693],
    [49.133156, 86.957592],
    [49.133244, 86.958024],
    [49.13325, 86.95831],
    [49.133385, 86.958836],
    [49.133375, 86.958975],
    [49.133256, 86.95927],
    [49.133177, 86.959931],
    [49.133106, 86.960129],
    [49.132659, 86.960705],
    [49.132711, 86.960854],
    [49.13281, 86.960932],
    [49.133444, 86.961163],
    [49.133619, 86.961385],
    [49.133691, 86.961748],
    [49.13379, 86.961865],
    [49.133902, 86.962271],
    [49.13382, 86.962741],
    [49.13385, 86.962879],
    [49.133968, 86.963315],
    [49.134058, 86.963377],
    [49.134094, 86.963506],
    [49.134064, 86.963758],
    [49.134001, 86.963838],
    [49.133943, 86.964094],
    [49.134042, 86.96461],
    [49.134037, 86.96481],
    [49.133902, 86.965688],
    [49.133384, 86.96758],
    [49.133441, 86.968806],
    [49.133811, 86.969766],
    [49.134032, 86.970008],
    [49.134201, 86.971134],
    [49.134356, 86.971204],
    [49.134639, 86.972027],
    [49.134612, 86.972536],
    [49.135049, 86.973633],
    [49.135099, 86.974347],
    [49.135596, 86.975124],
    [49.135745, 86.975453],
    [49.136033, 86.975598],
    [49.136531, 86.976166],
    [49.136888, 86.977197],
    [49.137063, 86.978201],
    [49.137227, 86.978623],
    [49.137491, 86.979769],
    [49.13782, 86.980567],
    [49.137893, 86.980894],
    [49.138271, 86.981204],
    [49.138466, 86.981649],
    [49.138537, 86.982233],
    [49.138546, 86.983024],
    [49.138786, 86.983378],
    [49.138996, 86.983825],
    [49.139224, 86.983829],
    [49.139556, 86.984254],
    [49.139748, 86.984979],
    [49.140049, 86.985358],
    [49.140287, 86.986038],
    [49.140282, 86.986666],
    [49.140171, 86.987222],
    [49.140176, 86.988349],
    [49.14022, 86.988629],
    [49.14064, 86.989382],
    [49.141111, 86.989531],
    [49.141707, 86.991125],
    [49.141837, 86.991896],
    [49.142075, 86.994485],
    [49.14264, 86.996055],
    [49.142682, 86.996521],
    [49.142674, 86.999315],
    [49.142606, 87.000058],
    [49.142462, 87.000963],
    [49.142152, 87.001629],
    [49.142045, 87.003443],
    [49.142243, 87.00354],
    [49.142256, 87.003773],
    [49.142163, 87.003981],
    [49.142176, 87.00426],
    [49.142033, 87.004863],
    [49.141631, 87.005646],
    [49.141381, 87.005764],
    [49.14115, 87.006039],
    [49.140948, 87.006524],
    [49.140853, 87.006987],
    [49.140846, 87.007825],
    [49.141009, 87.008387],
    [49.140967, 87.008944],
    [49.140747, 87.009731],
    [49.140469, 87.010191],
    [49.140404, 87.012471],
    [49.140324, 87.012982],
    [49.140373, 87.014058],
    [49.139773, 87.018306],
    [49.139648, 87.018722],
    [49.139278, 87.019157],
    [49.139074, 87.019968],
    [49.139146, 87.020388],
    [49.139077, 87.020796],
    [49.138817, 87.020977],
    [49.138564, 87.022065],
    [49.138315, 87.022666],
    [49.138294, 87.023294],
    [49.138017, 87.02366],
    [49.137921, 87.024171],
    [49.137673, 87.024701],
    [49.137547, 87.025211],
    [49.13713, 87.025924],
    [49.137158, 87.026227],
    [49.137064, 87.026434],
    [49.137001, 87.026759],
    [49.13718, 87.027158],
    [49.137207, 87.027601],
    [49.137113, 87.027832],
    [49.136589, 87.028543],
    [49.136572, 87.028822],
    [49.136431, 87.029261],
    [49.136261, 87.029537],
    [49.13594, 87.029717],
    [49.1358, 87.02997],
    [49.136053, 87.030697],
    [49.135787, 87.031529],
    [49.135844, 87.032019],
    [49.135643, 87.032317],
    [49.135534, 87.032931],
    [49.135372, 87.033078],
    [49.135292, 87.033885],
    [49.1351, 87.034689],
    [49.134764, 87.035115],
    [49.134701, 87.035339],
    [49.134527, 87.035505],
    [49.134426, 87.035784],
    [49.134253, 87.035969],
    [49.134057, 87.037206],
    [49.133783, 87.037614],
    [49.133619, 87.038061],
    [49.132843, 87.039643],
    [49.132878, 87.039851],
    [49.132716, 87.040054],
    [49.132702, 87.040299],
    [49.132528, 87.040483],
    [49.132502, 87.040708],
    [49.132176, 87.04134],
    [49.132019, 87.042408],
    [49.131611, 87.042701],
    [49.131348, 87.043278],
    [49.13122, 87.043765],
    [49.131124, 87.04432],
    [49.131195, 87.044604],
    [49.131143, 87.044885],
    [49.130957, 87.045125],
    [49.130933, 87.04644],
    [49.130869, 87.046702],
    [49.130815, 87.047303],
    [49.130637, 87.047938],
    [49.130645, 87.04839],
    [49.13052, 87.048612],
    [49.130054, 87.049824],
    [49.130063, 87.050257],
    [49.130146, 87.050597],
    [49.130305, 87.050751],
    [49.130247, 87.051217],
    [49.130269, 87.051855],
    [49.130168, 87.052512],
    [49.12999, 87.052905],
    [49.129838, 87.053642],
    [49.129733, 87.054444],
    [49.129421, 87.055472],
    [49.129263, 87.056337],
    [49.129459, 87.056783],
    [49.129906, 87.059485],
    [49.12992, 87.059835],
    [49.129829, 87.060264],
    [49.128764, 87.061802],
    [49.128009, 87.063079],
    [49.127809, 87.063316],
    [49.127364, 87.063465],
    [49.127203, 87.063595],
    [49.127085, 87.063831],
    [49.127058, 87.064046],
    [49.127255, 87.065328],
    [49.127413, 87.065351],
    [49.127496, 87.065458],
    [49.127726, 87.065367],
    [49.12791, 87.065491],
    [49.128112, 87.065994],
    [49.128358, 87.066159],
    [49.129182, 87.065705],
    [49.130173, 87.066881],
    [49.130934, 87.068814],
    [49.131443, 87.070483],
    [49.131704, 87.072159],
    [49.132218, 87.073928],
    [49.132325, 87.074789],
    [49.131734, 87.075763],
    [49.131349, 87.076908],
    [49.131276, 87.078447],
    [49.131017, 87.079383],
    [49.130878, 87.081006],
    [49.131013, 87.081611],
    [49.131059, 87.082474],
    [49.131518, 87.082621],
    [49.132738, 87.083851],
    [49.133795, 87.084459],
    [49.134937, 87.085553],
    [49.135894, 87.086814],
    [49.136305, 87.087026],
    [49.137171, 87.088284],
    [49.138035, 87.089729],
    [49.138345, 87.092696],
    [49.138726, 87.093815],
    [49.138485, 87.094785],
    [49.138964, 87.09571],
    [49.13895, 87.09679],
    [49.139226, 87.097894],
    [49.140051, 87.100575],
    [49.140353, 87.100954],
    [49.140729, 87.101028],
    [49.141347, 87.100542],
    [49.143336, 87.100095],
    [49.144685, 87.099938],
    [49.145459, 87.100156],
    [49.146811, 87.100072],
    [49.147541, 87.10032],
    [49.148674, 87.101348],
    [49.149544, 87.102807],
    [49.150112, 87.10349],
    [49.151124, 87.104137],
    [49.151452, 87.105973],
    [49.15178, 87.108271],
    [49.151617, 87.109588],
    [49.152261, 87.113175],
    [49.152496, 87.116241],
    [49.152586, 87.118057],
    [49.152438, 87.120284],
    [49.152659, 87.124018],
    [49.152641, 87.125252],
    [49.152182, 87.126485],
    [49.152018, 87.127433],
    [49.151537, 87.128737],
    [49.15118, 87.130314],
    [49.151032, 87.131936],
    [49.151064, 87.132857],
    [49.150883, 87.134384],
    [49.150801, 87.135952],
    [49.15077, 87.138489],
    [49.150952, 87.139581],
    [49.151676, 87.139921],
    [49.152049, 87.140431],
    [49.152304, 87.1411],
    [49.152374, 87.14183],
    [49.152272, 87.142509],
    [49.151966, 87.143494],
    [49.151639, 87.144913],
    [49.151317, 87.147796],
    [49.150753, 87.150028],
    [49.150749, 87.151514],
    [49.150148, 87.151433],
    [49.149758, 87.150925],
    [49.149588, 87.150792],
    [49.149427, 87.15091],
    [49.149361, 87.151135],
    [49.149024, 87.15101],
    [49.148384, 87.151461],
    [49.147656, 87.151843],
    [49.14657, 87.152168],
    [49.14642, 87.1524],
    [49.146101, 87.152321],
    [49.145611, 87.152684],
    [49.14521, 87.153263],
    [49.144753, 87.154194],
    [49.144276, 87.155367],
    [49.144107, 87.155582],
    [49.143724, 87.156569],
    [49.143235, 87.157608],
    [49.142829, 87.158727],
    [49.142371, 87.159325],
    [49.141902, 87.161077],
    [49.141673, 87.161704],
    [49.141419, 87.162755],
    [49.140846, 87.164134],
    [49.140271, 87.164777],
    [49.140207, 87.165347],
    [49.139923, 87.165857],
    [49.139698, 87.166543],
    [49.139519, 87.166815],
    [49.139134, 87.167786],
    [49.138877, 87.168671],
    [49.138947, 87.169219],
    [49.138909, 87.170013],
    [49.139009, 87.17072],
    [49.139113, 87.171009],
    [49.13931, 87.171169],
    [49.139483, 87.171626],
    [49.139563, 87.172093],
    [49.139545, 87.173136],
    [49.13976, 87.173406],
    [49.13982, 87.174107],
    [49.139631, 87.174657],
    [49.139511, 87.175356],
    [49.139448, 87.17608],
    [49.138963, 87.179019],
    [49.139073, 87.181579],
    [49.138897, 87.18291],
    [49.139018, 87.184534],
    [49.139481, 87.184953],
    [49.1401, 87.186256],
    [49.140709, 87.187237],
    [49.141385, 87.187945],
    [49.142265, 87.189139],
    [49.14228, 87.189898],
    [49.142154, 87.190793],
    [49.141921, 87.191547],
    [49.141885, 87.192459],
    [49.141927, 87.193296],
    [49.142318, 87.194816],
    [49.142353, 87.195308],
    [49.142576, 87.195868],
    [49.142732, 87.196563],
    [49.142879, 87.197516],
    [49.14315, 87.197961],
    [49.143187, 87.199166],
    [49.142945, 87.200004],
    [49.142323, 87.201312],
    [49.14186, 87.201895],
    [49.141567, 87.202964],
    [49.141247, 87.203723],
    [49.141015, 87.204023],
    [49.140752, 87.204116],
    [49.140584, 87.204105],
    [49.140365, 87.203923],
    [49.140323, 87.203808],
    [49.13992, 87.203725],
    [49.139609, 87.203532],
    [49.139479, 87.203826],
    [49.139128, 87.204257],
    [49.138771, 87.205155],
    [49.138345, 87.205904],
    [49.137475, 87.206739],
    [49.137428, 87.206964],
    [49.137221, 87.20716],
    [49.137122, 87.20707],
    [49.136771, 87.207326],
    [49.136193, 87.207242],
    [49.135882, 87.207267],
    [49.135678, 87.207395],
    [49.135255, 87.208267],
    [49.135219, 87.208611],
    [49.13431, 87.210402],
    [49.134016, 87.211213],
    [49.133788, 87.211591],
    [49.13358, 87.211797],
    [49.133428, 87.212136],
    [49.133314, 87.21256],
    [49.133005, 87.213037],
    [49.132841, 87.213247],
    [49.131923, 87.213622],
    [49.131615, 87.213606],
    [49.131399, 87.213723],
    [49.130815, 87.214491],
    [49.130468, 87.21472],
    [49.130341, 87.214986],
    [49.129509, 87.215371],
    [49.129316, 87.215642],
    [49.12835, 87.215834],
    [49.127904, 87.215382],
    [49.126253, 87.214488],
    [49.124902, 87.214198],
    [49.12409, 87.214127],
    [49.123828, 87.214026],
    [49.123499, 87.213694],
    [49.123266, 87.213567],
    [49.122777, 87.213546],
    [49.122464, 87.214117],
    [49.12215, 87.215074],
    [49.122031, 87.215562],
    [49.121835, 87.216096],
    [49.121473, 87.217528],
    [49.121089, 87.2184],
    [49.121073, 87.218706],
    [49.120758, 87.219704],
    [49.120434, 87.220551],
    [49.120126, 87.221944],
    [49.120027, 87.222112],
    [49.119676, 87.223614],
    [49.119588, 87.224337],
    [49.119603, 87.224928],
    [49.11969, 87.225642],
    [49.119665, 87.226293],
    [49.119592, 87.22695],
    [49.119458, 87.227372],
    [49.117985, 87.227724],
    [49.116316, 87.227827],
    [49.1153, 87.227995],
    [49.114916, 87.228973],
    [49.114684, 87.229755],
    [49.1144, 87.230303],
    [49.114375, 87.230665],
    [49.114471, 87.230879],
    [49.11451, 87.231448],
    [49.114475, 87.231955],
    [49.114261, 87.232685],
    [49.11375, 87.235233],
    [49.113225, 87.23651],
    [49.112954, 87.23705],
    [49.112789, 87.237652],
    [49.112872, 87.237946],
    [49.112809, 87.238785],
    [49.112736, 87.239138],
    [49.112811, 87.239581],
    [49.112766, 87.239964],
    [49.113041, 87.241026],
    [49.113066, 87.241853],
    [49.112964, 87.243025],
    [49.112951, 87.244546],
    [49.112811, 87.247464],
    [49.112705, 87.24822],
    [49.112696, 87.249076],
    [49.112456, 87.250002],
    [49.112456, 87.250678],
    [49.112248, 87.252189],
    [49.11171, 87.253524],
    [49.112014, 87.254072],
    [49.112443, 87.255265],
    [49.113619, 87.25754],
    [49.114152, 87.258687],
    [49.114627, 87.259035],
    [49.114839, 87.259993],
    [49.114909, 87.260823],
    [49.114885, 87.26159],
    [49.114573, 87.262802],
    [49.114422, 87.264699],
    [49.114488, 87.265875],
    [49.114341, 87.268203],
    [49.114344, 87.269437],
    [49.114184, 87.269982],
    [49.114046, 87.271312],
    [49.113825, 87.271762],
    [49.113807, 87.272044],
    [49.113887, 87.272364],
    [49.114139, 87.272689],
    [49.114608, 87.274115],
    [49.115553, 87.275214],
    [49.116285, 87.275651],
    [49.11673, 87.276117],
    [49.117131, 87.276335],
    [49.117416, 87.276769],
    [49.117631, 87.276945],
    [49.117732, 87.2775],
    [49.117763, 87.278729],
    [49.117057, 87.281435],
    [49.116077, 87.283593],
    [49.115872, 87.28579],
    [49.11624, 87.285818],
    [49.117449, 87.285545],
    [49.118595, 87.285468],
    [49.119092, 87.285519],
    [49.119756, 87.285685],
    [49.120791, 87.286276],
    [49.12289, 87.28826],
    [49.125685, 87.291643],
    [49.128939, 87.295137],
    [49.129451, 87.295501],
    [49.130281, 87.295969],
    [49.131085, 87.296279],
    [49.131834, 87.296766],
    [49.132452, 87.297042],
    [49.133679, 87.297231],
    [49.135694, 87.296892],
    [49.137571, 87.296474],
    [49.138503, 87.295043],
    [49.138937, 87.294495],
    [49.139432, 87.294135],
    [49.139913, 87.294057],
    [49.141013, 87.294327],
    [49.142264, 87.294876],
    [49.142763, 87.295181],
    [49.143583, 87.296011],
    [49.143993, 87.296553],
    [49.145043, 87.297654],
    [49.147356, 87.299029],
    [49.148135, 87.29933],
    [49.148658, 87.299459],
    [49.149129, 87.299413],
    [49.149459, 87.299173],
    [49.149913, 87.298668],
    [49.150624, 87.297706],
    [49.151663, 87.296515],
    [49.152029, 87.295961],
    [49.152498, 87.295405],
    [49.153244, 87.294258],
    [49.155277, 87.290759],
    [49.155999, 87.289965],
    [49.156729, 87.290678],
    [49.157286, 87.29104],
    [49.158518, 87.291679],
    [49.159174, 87.291895],
    [49.161439, 87.29235],
    [49.162728, 87.29284],
    [49.16316, 87.293127],
    [49.164021, 87.293976],
    [49.164462, 87.294525],
    [49.165432, 87.295933],
    [49.167062, 87.299428],
    [49.168457, 87.301656],
    [49.168823, 87.302131],
    [49.169557, 87.302731],
    [49.170707, 87.303356],
    [49.171171, 87.303575],
    [49.172181, 87.303812],
    [49.172727, 87.303814],
    [49.174606, 87.303603],
    [49.176312, 87.303156],
    [49.178554, 87.302753],
    [49.179154, 87.302562],
    [49.180276, 87.302069],
    [49.181337, 87.301932],
    [49.1826, 87.301517],
    [49.184046, 87.300761],
    [49.184987, 87.300018],
    [49.186191, 87.298439],
    [49.187227, 87.29696],
    [49.188865, 87.294406],
    [49.189836, 87.293446],
    [49.190919, 87.29261],
    [49.191356, 87.2924],
    [49.191859, 87.29224],
    [49.19289, 87.29219],
    [49.193728, 87.292246],
    [49.19598, 87.292238],
    [49.196618, 87.291934],
    [49.197339, 87.29149],
    [49.198285, 87.291111],
    [49.200509, 87.290683],
    [49.201323, 87.290735],
    [49.201764, 87.290897],
    [49.202185, 87.291201],
    [49.20399, 87.293056],
    [49.206646, 87.295469],
    [49.212535, 87.300469],
    [49.217096, 87.304083],
    [49.220992, 87.307261],
    [49.230774, 87.315415],
    [49.231007, 87.315384],
    [49.231233, 87.315235],
    [49.231361, 87.314888],
    [49.231707, 87.313427],
    [49.232012, 87.312386],
    [49.232255, 87.311952],
    [49.233592, 87.310199],
    [49.233885, 87.309671],
    [49.234373, 87.308242],
    [49.234709, 87.306703],
    [49.234892, 87.305646],
    [49.235247, 87.304182],
    [49.235279, 87.303657],
    [49.235192, 87.301964],
    [49.234952, 87.300296],
    [49.23459, 87.298755],
    [49.234445, 87.297277],
    [49.234111, 87.295667],
    [49.234003, 87.293123],
    [49.233924, 87.292251],
    [49.233694, 87.290773],
    [49.233343, 87.289325],
    [49.233193, 87.288096],
    [49.233527, 87.28746],
    [49.233741, 87.286744],
    [49.233822, 87.286278],
    [49.233874, 87.285377],
    [49.233865, 87.284692],
    [49.234223, 87.281474],
    [49.234207, 87.278907],
    [49.233733, 87.276677],
    [49.233201, 87.275311],
    [49.232769, 87.273755],
    [49.232549, 87.272168],
    [49.232531, 87.270737],
    [49.23257, 87.269325],
    [49.232705, 87.268871],
    [49.233316, 87.267665],
    [49.234102, 87.266604],
    [49.234518, 87.265812],
    [49.234791, 87.265393],
    [49.235732, 87.264353],
    [49.236056, 87.263747],
    [49.236422, 87.263203],
    [49.236916, 87.26263],
    [49.23818, 87.260096],
    [49.239216, 87.257792],
    [49.240168, 87.255265],
    [49.240321, 87.25455],
    [49.240328, 87.253962],
    [49.240264, 87.253314],
    [49.239859, 87.252137],
    [49.239217, 87.250787],
    [49.238103, 87.247463],
    [49.237796, 87.246156],
    [49.237733, 87.244855],
    [49.237779, 87.243989],
    [49.237869, 87.243298],
    [49.238045, 87.242595],
    [49.238526, 87.241205],
    [49.239165, 87.239949],
    [49.241202, 87.23747],
    [49.242382, 87.23637],
    [49.243643, 87.234754],
    [49.244311, 87.233481],
    [49.24483, 87.232023],
    [49.245525, 87.229786],
    [49.245784, 87.229084],
    [49.245909, 87.228578],
    [49.246026, 87.227721],
    [49.246189, 87.225533],
    [49.24618, 87.221726],
    [49.246228, 87.221285],
    [49.246329, 87.221],
    [49.24705, 87.219739],
    [49.24761, 87.218928],
    [49.247887, 87.218314],
    [49.248243, 87.217383],
    [49.249552, 87.213412],
    [49.250381, 87.211943],
    [49.250924, 87.210872],
    [49.251241, 87.209998],
    [49.251407, 87.209308],
    [49.251729, 87.207799],
    [49.251792, 87.207121],
    [49.25177, 87.206413],
    [49.251619, 87.205542],
    [49.25124, 87.204002],
    [49.25116, 87.203437],
    [49.251176, 87.202715],
    [49.251249, 87.20198],
    [49.251453, 87.201118],
    [49.252258, 87.199128],
    [49.252469, 87.198526],
    [49.252499, 87.198325],
    [49.251764, 87.196747],
    [49.251316, 87.195535],
    [49.250125, 87.191567],
    [49.249658, 87.190386],
    [49.249245, 87.18951],
    [49.248784, 87.187821],
    [49.248452, 87.186011],
    [49.248367, 87.184389],
    [49.248369, 87.182744],
    [49.248491, 87.181634],
    [49.24813, 87.181222],
    [49.247831, 87.180701],
    [49.247541, 87.180053],
    [49.247207, 87.178586],
    [49.246746, 87.175643],
    [49.246656, 87.174182],
    [49.246701, 87.173403],
    [49.246821, 87.172481],
    [49.247017, 87.171442],
    [49.247187, 87.170721],
    [49.24751, 87.169752],
    [49.248159, 87.168365],
    [49.248573, 87.167868],
    [49.248829, 87.167759],
    [49.249475, 87.16773],
    [49.24975, 87.167557],
    [49.249947, 87.167307],
    [49.250091, 87.166964],
    [49.250154, 87.166453],
    [49.25017, 87.165779],
    [49.250144, 87.165202],
    [49.249961, 87.16358],
    [49.249864, 87.161801],
    [49.24972, 87.160137],
    [49.24961, 87.157736],
    [49.249604, 87.156248],
    [49.249468, 87.154584],
    [49.249238, 87.15312],
    [49.248135, 87.148537],
    [49.247401, 87.145697],
    [49.246851, 87.144328],
    [49.245921, 87.142308],
    [49.245908, 87.141721],
    [49.24597, 87.141073],
    [49.246145, 87.140325],
    [49.246442, 87.139581],
    [49.246842, 87.138795],
    [49.247554, 87.137669],
    [49.24832, 87.136732],
    [49.249245, 87.135871],
    [49.250178, 87.135169],
    [49.251905, 87.133516],
    [49.254168, 87.130646],
    [49.25582, 87.128991],
    [49.255963, 87.12872],
    [49.256051, 87.128274],
    [49.255978, 87.126943],
    [49.255803, 87.125509],
    [49.255641, 87.124725],
    [49.254628, 87.121612],
    [49.25421, 87.1201],
    [49.253414, 87.116645],
    [49.253316, 87.116079],
    [49.253266, 87.115341],
    [49.253394, 87.114622],
    [49.253491, 87.11302],
    [49.253561, 87.112472],
    [49.253817, 87.110961],
    [49.25447, 87.107926],
    [49.254815, 87.105853],
    [49.254989, 87.104065],
    [49.254977, 87.103241],
    [49.254763, 87.101879],
    [49.2545, 87.100804],
    [49.253872, 87.098667],
    [49.25298, 87.096655],
    [49.252154, 87.095171],
    [49.252063, 87.094709],
    [49.25207, 87.092259],
    [49.252188, 87.089299],
    [49.252066, 87.087029],
    [49.252063, 87.086285],
    [49.252151, 87.08573],
    [49.252878, 87.08297],
    [49.253103, 87.081502],
    [49.253577, 87.076094],
    [49.253478, 87.074098],
    [49.253326, 87.072775],
    [49.253374, 87.072088],
    [49.253535, 87.070777],
    [49.254299, 87.067715],
    [49.254594, 87.065915],
    [49.254773, 87.064503],
    [49.254785, 87.0635],
    [49.254606, 87.060354],
    [49.254474, 87.059758],
    [49.25236, 87.054688],
    [49.251371, 87.051928],
    [49.250977, 87.050615],
    [49.250725, 87.049506],
    [49.250544, 87.048224],
    [49.250485, 87.047175],
    [49.250475, 87.04292],
    [49.250631, 87.039701],
    [49.250765, 87.038115],
    [49.250913, 87.037034],
    [49.251648, 87.034087],
    [49.252097, 87.032824],
    [49.253257, 87.030492],
    [49.254546, 87.02848],
    [49.255397, 87.027413],
    [49.256953, 87.025869],
    [49.258799, 87.024474],
    [49.260653, 87.023195],
    [49.267724, 87.019256],
    [49.268541, 87.01894],
    [49.269529, 87.018308],
    [49.271587, 87.017351],
    [49.272518, 87.016819],
    [49.277675, 87.014635],
    [49.278727, 87.014134],
    [49.28192, 87.012909],
    [49.284989, 87.011826],
    [49.289196, 87.010171],
    [49.292585, 87.008978],
    [49.296267, 87.007501],
    [49.297726, 87.006586],
    [49.298036, 87.006277],
    [49.298969, 87.005561],
    [49.299056, 87.004828],
    [49.299023, 87.003695],
    [49.298905, 87.003211],
    [49.298652, 87.002493],
    [49.297827, 87.000358],
    [49.297665, 87.000041],
    [49.297546, 86.999557],
    [49.297645, 86.998566],
    [49.298755, 86.992428],
    [49.299361, 86.98871],
    [49.299948, 86.985648],
    [49.30036, 86.983631],
    [49.300658, 86.98259],
    [49.30089, 86.982143],
    [49.301815, 86.981206],
    [49.302544, 86.980697],
    [49.303042, 86.980271],
    [49.303284, 86.979969],
    [49.303593, 86.978694],
    [49.304158, 86.975058],
    [49.30436, 86.974183],
    [49.304755, 86.973195],
    [49.305851, 86.970987],
    [49.306617, 86.970353],
    [49.307308, 86.969991],
    [49.307626, 86.969111],
    [49.308058, 86.968489],
    [49.308301, 86.968042],
    [49.308615, 86.967788],
    [49.30911, 86.967167],
    [49.309585, 86.96624],
    [49.310428, 86.96526],
    [49.311671, 86.96408],
    [49.312187, 86.963698],
    [49.312776, 86.963382],
    [49.314567, 86.96263],
    [49.315929, 86.961905],
    [49.317104, 86.960969],
    [49.317289, 86.960607],
    [49.317401, 86.960089],
    [49.317463, 86.959384],
    [49.317622, 86.958967],
    [49.317878, 86.958825],
    [49.318211, 86.958699],
    [49.318599, 86.958768],
    [49.323963, 86.962179],
    [49.325037, 86.962798],
    [49.325412, 86.962708],
    [49.3273, 86.961378],
    [49.327963, 86.9607],
    [49.328497, 86.960282],
    [49.329814, 86.959461],
    [49.331163, 86.957867],
    [49.333037, 86.955154],
    [49.333391, 86.954883],
    [49.334125, 86.95472],
    [49.334562, 86.954403],
    [49.335238, 86.954126],
    [49.335561, 86.95378],
    [49.335991, 86.953132],
    [49.336193, 86.95239],
    [49.336215, 86.951727],
    [49.336296, 86.951183],
    [49.336416, 86.950898],
    [49.33674, 86.950575],
    [49.3369665, 86.9502665],
    [49.3422, 86.9454],
    [49.3451, 86.9401],
    [49.3512, 86.9302],
    [49.353, 86.9275],
    [49.3611, 86.922],
    [49.3674, 86.9192],
    [49.3719, 86.922],
    [49.3728, 86.9247],
    [49.3755, 86.9343],
    [49.3774, 86.9371],
    [49.3808, 86.9384],
    [49.3853, 86.9439],
    [49.3918, 86.9439],
    [49.3972, 86.9398],
    [49.407, 86.937],
    [49.4088, 86.937],
    [49.4115, 86.9439],
    [49.4123, 86.9439],
    [49.4187, 86.9412],
    [49.4233, 86.937],
    [49.425, 86.9274],
    [49.4214, 86.915],
    [49.4266, 86.9095],
    [49.4313, 86.9026],
    [49.4357, 86.8847],
    [49.442, 86.8668],
    [49.4493, 86.8543],
    [49.4563, 86.8392],
    [49.4644, 86.835],
    [49.4762, 86.832],
    [49.4851, 86.8294],
    [49.4878, 86.8319],
    [49.4932, 86.8402],
    [49.4958, 86.8432],
    [49.5004, 86.8501],
    [49.503, 86.8514],
    [49.5095, 86.85],
    [49.512, 86.847],
    [49.5149, 86.8431],
    [49.5204, 86.8307],
    [49.5346, 86.8155],
    [49.54, 86.8193],
    [49.5454, 86.8223],
    [49.5506, 86.8165],
    [49.5543, 86.7986],
    [49.5641, 86.7723],
    [49.5686, 86.7557],
    [49.5739, 86.7405],
    [49.573, 86.7253],
    [49.573, 86.706],
    [49.5731, 86.6922],
    [49.5711, 86.6853],
    [49.5692, 86.6825],
    [49.5666, 86.6784],
    [49.563, 86.6644],
    [49.5683, 86.6315],
    [49.5745, 86.6188],
    [49.5791, 86.6176],
    [49.5942, 86.6075],
    [49.605, 86.6033],
    [49.614, 86.6005],
    [49.6167, 86.6032],
    [49.6267, 86.6128],
    [49.6321, 86.6169],
    [49.6338, 86.6252],
    [49.6358, 86.6379],
    [49.643, 86.65],
    [49.6459, 86.653],
    [49.652, 86.6585],
    [49.6571, 86.6584],
    [49.6619, 86.6653],
    [49.6682, 86.675],
    [49.67, 86.6775],
    [49.6772, 86.6901],
    [49.6818, 86.6984],
    [49.6881, 86.7094],
    [49.6838, 86.7233],
    [49.6808, 86.7275],
    [49.6827, 86.7316],
    [49.6836, 86.733],
    [49.688, 86.7385],
    [49.6899, 86.7468],
    [49.6927, 86.7606],
    [49.6936, 86.7676],
    [49.6981, 86.7758],
    [49.7008, 86.7772],
    [49.7302, 86.7632],
    [49.7421, 86.7604],
    [49.7501, 86.7604],
    [49.7602, 86.7672],
    [49.7672, 86.7728],
    [49.78, 86.7768],
    [49.7881, 86.781],
    [49.7918, 86.781],
    [49.7934, 86.7796],
    [49.7943, 86.7754],
    [49.7943, 86.7657],
    [49.7907, 86.7366],
    [49.7906, 86.7324],
    [49.7932, 86.7255],
    [49.8032, 86.7101],
    [49.8094, 86.699],
    [49.8129, 86.6752],
    [49.8137, 86.6599],
    [49.8136, 86.6557],
    [49.8101, 86.6391],
    [49.8065, 86.6128],
    [49.8078, 86.6066],
    [49.8091015, 86.5999071],
    [49.8066, 86.5961],
    [49.7964, 86.599],
    [49.7894, 86.6032],
    [49.7786, 86.6074],
    [49.7705, 86.6117],
    [49.7643, 86.6103],
    [49.7526, 86.6035],
    [49.7479, 86.6021],
    [49.741, 86.5897],
    [49.7326, 86.5787],
    [49.727, 86.5802],
    [49.7254, 86.5788],
    [49.7218, 86.5705],
    [49.7199, 86.565],
    [49.7054, 86.5319],
    [49.6973, 86.5195],
    [49.6911, 86.5113],
    [49.6865, 86.5085],
    [49.6803, 86.5058],
    [49.6721, 86.5004],
    [49.6639, 86.4878],
    [49.6558, 86.4754],
    [49.6486, 86.4633],
    [49.6404, 86.4465],
    [49.635, 86.4342],
    [49.6313, 86.4259],
    [49.6122, 86.4095],
    [49.6057, 86.3972],
    [49.6022, 86.3848],
    [49.5977, 86.3697],
    [49.5949, 86.3517],
    [49.5966, 86.3365],
    [49.5992, 86.3254],
    [49.6008, 86.3171],
    [49.6009, 86.3088],
    [49.5963, 86.2951],
    [49.5891, 86.2827],
    [49.5827, 86.2716],
    [49.5781, 86.2691],
    [49.5719, 86.2692],
    [49.5629, 86.2679],
    [49.5522, 86.2665],
    [49.5424, 86.2597],
    [49.533, 86.2488],
    [49.5231, 86.2393],
    [49.5141, 86.2367],
    [49.4925, 86.226],
    [49.4762, 86.2111],
    [49.4706, 86.2029],
    [49.4689, 86.1988],
    [49.4725, 86.1933],
    [49.476, 86.1863],
    [49.4805, 86.1807],
    [49.4903, 86.175],
    [49.501, 86.168],
    [49.51, 86.1582],
    [49.5135, 86.1498],
    [49.5207, 86.1331],
    [49.527, 86.1192],
    [49.5275, 86.1041],
    [49.5284, 86.0875],
    [49.5283, 86.0721],
    [49.5291, 86.0596],
    [49.5291, 86.0541],
    [49.5255, 86.0404],
    [49.5206, 86.0322],
    [49.5134, 86.0282],
    [49.5071, 86.0228],
    [49.4954, 85.9983],
    [49.4915, 85.9901],
    [49.4897, 85.9818],
    [49.4887, 85.9736],
    [49.4922, 85.9652],
    [49.5057, 85.9511],
    [49.5245, 85.9381],
    [49.5306, 85.9341],
    [49.5343, 85.931],
    [49.5397, 85.9325],
    [49.5426, 85.9366],
    [49.5497, 85.9433],
    [49.5569, 85.9501],
    [49.5631, 85.9458],
    [49.5649, 85.9278],
    [49.5665, 85.9195],
    [49.5645, 85.9],
    [49.5662, 85.8834],
    [49.5643, 85.8765],
    [49.5634, 85.8724],
    [49.5553, 85.856],
    [49.5505, 85.8437],
    [49.5476, 85.8341],
    [49.5476, 85.8272],
    [49.5486, 85.8216],
    [49.5502, 85.8175],
    [49.5582, 85.809],
    [49.5661, 85.8046],
    [49.5705, 85.7935],
    [49.575, 85.7851],
    [49.5769, 85.7726],
    [49.5784, 85.7602],
    [49.5774, 85.742],
    [49.5753, 85.7299],
    [49.5714, 85.7132],
    [49.568, 85.6981],
    [49.5661, 85.6898],
    [49.5604, 85.6817],
    [49.5559, 85.6777],
    [49.5603, 85.6693],
    [49.5673, 85.6553],
    [49.5816, 85.6355],
    [49.5886, 85.6284],
    [49.5993, 85.624],
    [49.608, 85.6155],
    [49.6161, 85.6028],
    [49.6196, 85.593],
    [49.6149, 85.5821],
    [49.6049, 85.5656],
    [49.6013, 85.5574],
    [49.601, 85.5479],
    [49.6004, 85.5104],
    [49.6012, 85.4897],
    [49.6055, 85.4785],
    [49.6289, 85.4128],
    [49.6342, 85.3958],
    [49.6349, 85.3833],
    [49.6274, 85.3697],
    [49.6173, 85.3549],
    [49.6099, 85.3413],
    [49.5951, 85.3197],
    [49.5934, 85.3156],
    [49.5942, 85.3087],
    [49.5937, 85.2714],
    [49.5935, 85.2617],
    [49.5753294, 85.2658225],
    [49.5545118, 85.25669],
    [49.5375368, 85.2751168],
    [49.5352941, 85.2870481],
    [49.501294, 85.2527152],
    [49.5032358, 85.1952058],
    [49.4713484, 85.1746297],
    [49.4688641, 85.1409988],
    [49.4958765, 85.1399003],
    [49.5160559, 85.011512],
    [49.5615452, 84.9593198],
    [49.5463342, 84.8538141],
    [49.5510703, 84.7832406],
    [49.5649958, 84.7562648],
    [49.5921335, 84.7747645],
    [49.5996523, 84.7581792],
    [49.6210284, 84.7609096],
    [49.6886184, 84.750633],
    [49.7016687, 84.7031108],
    [49.7270469, 84.7075049],
    [49.7296975, 84.668648],
    [49.6968347, 84.6343834],
    [49.6532212, 84.6354364],
    [49.5927853, 84.702149],
    [49.5305558, 84.7081226],
    [49.4714783, 84.6757133],
    [49.477477, 84.6452481],
    [49.4382736, 84.6017986],
    [49.4217977, 84.6408311],
    [49.3817943, 84.6234208],
    [49.3899924, 84.5611571],
    [49.4052649, 84.5282965],
    [49.4005995, 84.4868778],
    [49.3390856, 84.4276818],
    [49.3513519, 84.3674009],
    [49.3126864, 84.3246535],
    [49.3455014, 84.2274392],
    [49.2953444, 84.0945519],
    [49.2300791, 84.2040872],
    [49.1730301, 84.2514112],
    [49.1114774, 84.2377546],
    [49.0811194, 84.1983643],
  ],
});
mapRegions.push({
  id: 17,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Уланский район",
  centerTitle: "посёлок имени Касыма Кайсенова",
  centerTitleShort: "п. Касыма Кайсенова",
  centerGeometry: [49.8750571, 82.4788284],
  geometry: [
    [49.3406719, 82.4643409],
    [49.337848, 82.4042127],
    [49.2963906, 82.3743219],
    [49.353745, 82.3322217],
    [49.3695554, 82.2603218],
    [49.391312, 82.255996],
    [49.4031612, 82.2346724],
    [49.3863116, 82.2084281],
    [49.3941837, 82.1855479],
    [49.3743826, 82.1776963],
    [49.409732, 82.0878687],
    [49.4579585, 82.0225669],
    [49.498714, 82.05793],
    [49.5094259, 82.0167323],
    [49.5617366, 81.9787893],
    [49.5711062, 81.8918676],
    [49.5909802, 81.899505],
    [49.6095438, 81.8614275],
    [49.6168598, 81.7869043],
    [49.6536132, 81.7944026],
    [49.6938855, 81.7516939],
    [49.6865968, 81.7167958],
    [49.7414283, 81.7438879],
    [49.7835398, 81.6888741],
    [49.7978779, 81.7329708],
    [49.8690918, 81.7081829],
    [49.8740626, 81.619675],
    [49.9035044, 81.5919789],
    [49.9156955, 81.5386654],
    [49.9273077, 81.5172575],
    [49.9623836, 81.367614],
    [50.0469213, 81.392395],
    [50.0996286, 81.40532],
    [50.22316, 81.3651958],
    [50.2344828, 81.4023138],
    [50.3160143, 81.3330235],
    [50.3248141, 81.4740747],
    [50.2867776, 81.5913089],
    [50.2330925, 81.6699527],
    [50.2305718, 81.7152027],
    [50.2340999, 81.7762151],
    [50.1979019, 81.8223929],
    [50.2335096, 82.0034495],
    [50.2079468, 82.0872191],
    [50.2292066, 82.1830978],
    [50.2105063, 82.2115323],
    [50.1404525, 82.2479096],
    [50.0807802, 82.3032607],
    [50.0696014, 82.3584968],
    [50.0606007, 82.4029615],
    [50.0304843, 82.4647814],
    [50.0244025, 82.4905016],
    [50.0023922, 82.4953539],
    [49.9251665, 82.4666944],
    [49.8891917, 82.5113637],
    [49.8760191, 82.629472],
    [49.8818726, 82.6900151],
    [49.8888263, 82.7130885],
    [49.9016251, 82.7332375],
    [49.8778391, 82.817069],
    [49.7910336, 82.8791732],
    [49.7403138, 82.903503],
    [49.7213833, 82.9152155],
    [49.7183771, 82.9815064],
    [49.6598512, 83.0495226],
    [49.6536793, 83.2019353],
    [49.6434551, 83.2579883],
    [49.6633405, 83.3342596],
    [49.6274692, 83.34491],
    [49.5948137, 83.383881],
    [49.591819, 83.4227379],
    [49.561862, 83.4695445],
    [49.5733261, 83.5213926],
    [49.5421247, 83.5741728],
    [49.5386285, 83.5925974],
    [49.4944224, 83.6462163],
    [49.4575915, 83.6166833],
    [49.4254717, 83.6291433],
    [49.4043395, 83.5398103],
    [49.3824234, 83.5434729],
    [49.3726459, 83.5057213],
    [49.3096551, 83.5246427],
    [49.2975506, 83.4992143],
    [49.2584612, 83.4819111],
    [49.2460011, 83.4361575],
    [49.2831351, 83.4190822],
    [49.2999164, 83.3526226],
    [49.2725827, 83.3404384],
    [49.3041259, 83.1931082],
    [49.2824103, 83.1499254],
    [49.2633818, 83.1616356],
    [49.2377097, 83.0698936],
    [49.2387079, 83.0168467],
    [49.2202104, 83.0021212],
    [49.1639204, 83.0576933],
    [49.1509521, 83.0120628],
    [49.1690074, 82.8604364],
    [49.1921383, 82.8239361],
    [49.1877259, 82.7757939],
    [49.2026634, 82.76229],
    [49.2422451, 82.7525854],
    [49.2456023, 82.7077708],
    [49.2201238, 82.6838923],
    [49.2124363, 82.654357],
    [49.2244177, 82.6330425],
    [49.2086643, 82.6264877],
    [49.21085, 82.5880045],
    [49.2395717, 82.5670615],
    [49.2602913, 82.5690603],
    [49.2990298, 82.5128318],
  ],
});
mapRegions.push({
  id: 18,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Зайсанский район",
  centerTitle: "город Зайсан",
  centerTitleShort: "г. Зайсан",
  centerGeometry: [47.4780001, 84.8714447],
  geometry: [
    [47.4532388, 85.6499169],
    [47.4533638, 85.6498749],
    [47.4535172, 85.6500009],
    [47.4535798, 85.6501773],
    [47.4536253, 85.6503202],
    [47.4537389, 85.6503285],
    [47.453898, 85.6501436],
    [47.453915, 85.6497066],
    [47.454057, 85.64953],
    [47.4541934, 85.6495552],
    [47.454398, 85.6497652],
    [47.4545741, 85.6497147],
    [47.4546896, 85.649754],
    [47.4546534, 85.6499599],
    [47.454726, 85.6500136],
    [47.4548047, 85.649942],
    [47.4548894, 85.6499688],
    [47.4549863, 85.6501388],
    [47.4551255, 85.6502104],
    [47.455301, 85.6499418],
    [47.4554341, 85.6497716],
    [47.4556762, 85.6496283],
    [47.4556944, 85.6495388],
    [47.4556883, 85.6494134],
    [47.4558759, 85.6491269],
    [47.4561483, 85.6491626],
    [47.4562754, 85.6491178],
    [47.4564388, 85.6491177],
    [47.4566203, 85.6489834],
    [47.4569108, 85.64884],
    [47.4570258, 85.6486072],
    [47.4571166, 85.6484013],
    [47.4571286, 85.6481506],
    [47.4571613, 85.6480126],
    [47.4571371, 85.6478962],
    [47.4571915, 85.6476545],
    [47.4573247, 85.6476366],
    [47.4573549, 85.647547],
    [47.4574517, 85.64735],
    [47.4574759, 85.6472068],
    [47.4574759, 85.6470098],
    [47.4574516, 85.6469024],
    [47.4575, 85.6468129],
    [47.4575242, 85.6467323],
    [47.4575968, 85.6467323],
    [47.4576937, 85.646768],
    [47.4577656, 85.6465537],
    [47.4580001, 85.6466975],
    [47.4580443, 85.6464881],
    [47.4582434, 85.6464095],
    [47.458323, 85.6462982],
    [47.4583495, 85.6462197],
    [47.4584114, 85.6461804],
    [47.458699, 85.6460952],
    [47.4589866, 85.6461867],
    [47.4592122, 85.6461081],
    [47.4593139, 85.6460033],
    [47.4593537, 85.6458855],
    [47.4594908, 85.6456761],
    [47.4594465, 85.6453751],
    [47.4594002, 85.6451016],
    [47.4594488, 85.644709],
    [47.4594886, 85.644709],
    [47.459524, 85.6448268],
    [47.4597496, 85.6450099],
    [47.4598735, 85.6449967],
    [47.4598823, 85.6449247],
    [47.4598071, 85.6448266],
    [47.4598381, 85.6447677],
    [47.4600548, 85.6448069],
    [47.4601433, 85.6447545],
    [47.4601875, 85.6446563],
    [47.4601742, 85.6444993],
    [47.4600547, 85.6441656],
    [47.4599839, 85.6440283],
    [47.4599839, 85.6439563],
    [47.4600546, 85.6439235],
    [47.4601431, 85.644002],
    [47.4601741, 85.6440871],
    [47.4603201, 85.6441524],
    [47.4603732, 85.6440935],
    [47.4603555, 85.6439888],
    [47.460519, 85.6435045],
    [47.4606075, 85.6432231],
    [47.4607622, 85.6428893],
    [47.4608905, 85.6426799],
    [47.4610143, 85.6426078],
    [47.4611382, 85.6426143],
    [47.4612267, 85.6428498],
    [47.4612931, 85.6429348],
    [47.4613948, 85.6428795],
    [47.4615053, 85.6424149],
    [47.461576, 85.6423101],
    [47.461753, 85.6422446],
    [47.4619387, 85.6421856],
    [47.4620714, 85.642022],
    [47.4622749, 85.6419106],
    [47.4625845, 85.641701],
    [47.4627525, 85.6413541],
    [47.4627746, 85.6412363],
    [47.4629073, 85.6411708],
    [47.462987, 85.6412428],
    [47.4631241, 85.6411969],
    [47.4632258, 85.6409678],
    [47.4634027, 85.6406154],
    [47.4633894, 85.6404322],
    [47.463438, 85.6403733],
    [47.4634292, 85.6403013],
    [47.4634822, 85.6402162],
    [47.4634822, 85.6401573],
    [47.4634247, 85.640105],
    [47.4634335, 85.6399611],
    [47.463491, 85.6400134],
    [47.4635264, 85.6400003],
    [47.4635219, 85.6398498],
    [47.4635529, 85.6397908],
    [47.4636502, 85.6397188],
    [47.4637431, 85.6395944],
    [47.4638094, 85.6395224],
    [47.4639598, 85.6394896],
    [47.4641057, 85.6392867],
    [47.4641986, 85.6392342],
    [47.4642871, 85.6392865],
    [47.4643667, 85.639208],
    [47.4645127, 85.6392013],
    [47.4646056, 85.6390573],
    [47.4646763, 85.6389918],
    [47.4647471, 85.639018],
    [47.4647604, 85.639103],
    [47.4647472, 85.6391554],
    [47.4648091, 85.639332],
    [47.4648888, 85.6392862],
    [47.4648843, 85.6391815],
    [47.4649551, 85.6390571],
    [47.4649905, 85.6391487],
    [47.4649905, 85.6392207],
    [47.4650303, 85.6392534],
    [47.4650347, 85.6391618],
    [47.4651453, 85.6391682],
    [47.4651984, 85.6392467],
    [47.4652913, 85.63907],
    [47.46538, 85.639004],
    [47.4654419, 85.6389189],
    [47.4655083, 85.6389254],
    [47.4655879, 85.63903],
    [47.4656676, 85.6390365],
    [47.4657516, 85.6389841],
    [47.4658312, 85.6390037],
    [47.4659286, 85.6391018],
    [47.4660967, 85.639069],
    [47.4662427, 85.6391278],
    [47.4662958, 85.639095],
    [47.4663312, 85.639023],
    [47.4663887, 85.6389968],
    [47.466486, 85.6390818],
    [47.4664993, 85.6391604],
    [47.4663888, 85.6393895],
    [47.4663092, 85.6395204],
    [47.4662871, 85.6396709],
    [47.4663181, 85.6398214],
    [47.4663889, 85.6398672],
    [47.4664508, 85.6398737],
    [47.4665393, 85.639854],
    [47.4666189, 85.6399063],
    [47.4666765, 85.6399586],
    [47.4667119, 85.6399586],
    [47.4667472, 85.6399128],
    [47.4667782, 85.6398211],
    [47.4669728, 85.639592],
    [47.4671408, 85.6393825],
    [47.4672116, 85.6392646],
    [47.4672513, 85.6391141],
    [47.4672513, 85.6390225],
    [47.4672291, 85.6388393],
    [47.467238, 85.6386953],
    [47.4673087, 85.638584],
    [47.4674635, 85.6384923],
    [47.467583, 85.6385184],
    [47.4676582, 85.6386099],
    [47.4677247, 85.639068],
    [47.4677291, 85.6390811],
    [47.4677866, 85.6391072],
    [47.4678663, 85.639153],
    [47.468022, 85.6392204],
    [47.4680873, 85.6392022],
    [47.4681811, 85.6390814],
    [47.4684708, 85.6389786],
    [47.4686748, 85.6389664],
    [47.4687442, 85.6389181],
    [47.4687849, 85.6387973],
    [47.4688664, 85.6383989],
    [47.468948, 85.6382842],
    [47.468948, 85.638097],
    [47.4690294, 85.6376382],
    [47.4690824, 85.6375054],
    [47.4690824, 85.637276],
    [47.4691354, 85.6371009],
    [47.4692091, 85.637007],
    [47.4693314, 85.6369164],
    [47.4694824, 85.6368801],
    [47.4696211, 85.6368196],
    [47.4697476, 85.636723],
    [47.469768, 85.6366686],
    [47.4697883, 85.6364755],
    [47.4698862, 85.6363426],
    [47.470029, 85.6362701],
    [47.4702249, 85.6363182],
    [47.470384, 85.6361491],
    [47.4704492, 85.6360223],
    [47.4706736, 85.6357867],
    [47.4707919, 85.6357685],
    [47.4708491, 85.6358771],
    [47.470899, 85.6358861],
    [47.4709194, 85.6359102],
    [47.4709235, 85.6359826],
    [47.4709807, 85.6360792],
    [47.4709644, 85.6361939],
    [47.470997, 85.6362603],
    [47.4710827, 85.6362662],
    [47.4711807, 85.636399],
    [47.4712215, 85.6364895],
    [47.4712909, 85.6365076],
    [47.471397, 85.6366101],
    [47.4716133, 85.6367247],
    [47.471695, 85.6367427],
    [47.4717562, 85.6368453],
    [47.4718134, 85.6369298],
    [47.4718868, 85.6370384],
    [47.472042, 85.6373039],
    [47.4720868, 85.6372797],
    [47.4721521, 85.6371891],
    [47.4722173, 85.6369959],
    [47.4722132, 85.6368692],
    [47.4722744, 85.636688],
    [47.4722866, 85.636525],
    [47.4722783, 85.636193],
    [47.4721599, 85.6360301],
    [47.4721028, 85.6359094],
    [47.4720375, 85.6358491],
    [47.4720537, 85.6357525],
    [47.4720863, 85.6355895],
    [47.4720863, 85.6354204],
    [47.4721515, 85.6353359],
    [47.4722903, 85.6352452],
    [47.4724575, 85.6352149],
    [47.4725351, 85.6353054],
    [47.4726139, 85.6354556],
    [47.4726752, 85.6355159],
    [47.47272, 85.6355038],
    [47.4728302, 85.635377],
    [47.4728546, 85.6351717],
    [47.4728219, 85.6349966],
    [47.4728789, 85.6346465],
    [47.4729646, 85.6344834],
    [47.4730176, 85.6343264],
    [47.4730094, 85.634248],
    [47.47294, 85.6341574],
    [47.4728991, 85.6341031],
    [47.4729195, 85.6340186],
    [47.4730256, 85.6338616],
    [47.4731275, 85.6336864],
    [47.4732213, 85.6335173],
    [47.4733885, 85.6332697],
    [47.4734946, 85.6331429],
    [47.47367, 85.6330944],
    [47.4738251, 85.6331245],
    [47.4739475, 85.6330399],
    [47.4740821, 85.6330519],
    [47.4741556, 85.6329492],
    [47.47418, 85.632762],
    [47.4741596, 85.6327017],
    [47.474082, 85.6326293],
    [47.4740656, 85.6324361],
    [47.4741159, 85.6323664],
    [47.4742301, 85.6322999],
    [47.4743525, 85.6323059],
    [47.4744749, 85.6323299],
    [47.4745892, 85.6324506],
    [47.4746668, 85.6326558],
    [47.4747077, 85.6328972],
    [47.4747649, 85.63303],
    [47.4748547, 85.6330722],
    [47.4749526, 85.6330902],
    [47.4750953, 85.6328788],
    [47.4751361, 85.632746],
    [47.4752014, 85.6326312],
    [47.4753441, 85.6324983],
    [47.4753808, 85.6323956],
    [47.4753604, 85.6323232],
    [47.4752788, 85.632281],
    [47.4752012, 85.6322569],
    [47.4751726, 85.6321845],
    [47.4751726, 85.6321121],
    [47.4752868, 85.6319067],
    [47.4753847, 85.6318825],
    [47.4755276, 85.631991],
    [47.4755807, 85.6321118],
    [47.4756256, 85.6321781],
    [47.4756827, 85.6322022],
    [47.4757235, 85.6321962],
    [47.4757316, 85.6320935],
    [47.4757683, 85.6319245],
    [47.4758295, 85.6318218],
    [47.4759518, 85.6316708],
    [47.4760294, 85.6315922],
    [47.4761575, 85.6313066],
    [47.4763166, 85.6312944],
    [47.4765288, 85.6313787],
    [47.4767411, 85.6316382],
    [47.4768268, 85.6316864],
    [47.4769165, 85.631632],
    [47.4770144, 85.6315414],
    [47.4770185, 85.6314689],
    [47.4770022, 85.6314327],
    [47.4770103, 85.6313603],
    [47.4769695, 85.6312878],
    [47.4769735, 85.6312275],
    [47.4770143, 85.6311731],
    [47.4769495, 85.6309848],
    [47.4769699, 85.6308942],
    [47.4770637, 85.6308337],
    [47.4771494, 85.6308699],
    [47.4771964, 85.6309607],
    [47.4773023, 85.6310176],
    [47.4773697, 85.6309606],
    [47.4774403, 85.6308418],
    [47.4774884, 85.6305996],
    [47.4775525, 85.6304665],
    [47.4776359, 85.6303667],
    [47.4777065, 85.6303239],
    [47.4777483, 85.6303477],
    [47.4778061, 85.6304426],
    [47.4778639, 85.6304948],
    [47.4779121, 85.6305897],
    [47.4780084, 85.6307037],
    [47.4781432, 85.6307463],
    [47.4783345, 85.630801],
    [47.4785399, 85.6307819],
    [47.4786779, 85.6307865],
    [47.478755, 85.6307152],
    [47.4788287, 85.6305822],
    [47.4789186, 85.6304966],
    [47.4789314, 85.6304443],
    [47.478925, 85.6303921],
    [47.4789378, 85.6303209],
    [47.4789987, 85.6302353],
    [47.4790244, 85.6301498],
    [47.4790918, 85.6300215],
    [47.4791527, 85.6299027],
    [47.4791681, 85.6298344],
    [47.4791263, 85.6297442],
    [47.4790782, 85.6296778],
    [47.4790749, 85.629616],
    [47.4791198, 85.6295685],
    [47.4791712, 85.6296255],
    [47.4792065, 85.6296254],
    [47.4792194, 85.6295827],
    [47.4792514, 85.6295209],
    [47.4792963, 85.6294259],
    [47.4794696, 85.6293593],
    [47.479476, 85.6292928],
    [47.4794631, 85.6291408],
    [47.4795177, 85.6290315],
    [47.4795786, 85.6289792],
    [47.4796428, 85.6289317],
    [47.479723, 85.6288461],
    [47.4797423, 85.6287464],
    [47.4797936, 85.6286846],
    [47.4798257, 85.6286371],
    [47.4798449, 85.6285041],
    [47.4799159, 85.6284166],
    [47.4800603, 85.6283167],
    [47.480179, 85.6282121],
    [47.4803362, 85.6280838],
    [47.4804839, 85.6279982],
    [47.4806186, 85.6279126],
    [47.4807109, 85.6278667],
    [47.4807911, 85.6278334],
    [47.4809098, 85.6277241],
    [47.4810478, 85.6276385],
    [47.4811826, 85.6275671],
    [47.4813238, 85.6274387],
    [47.4814233, 85.6273484],
    [47.4815356, 85.6273293],
    [47.4816127, 85.6273435],
    [47.4817393, 85.6273025],
    [47.4819287, 85.6273213],
    [47.481964, 85.6274305],
    [47.4821021, 85.6275064],
    [47.4822145, 85.6276061],
    [47.4822915, 85.627625],
    [47.482391, 85.6275964],
    [47.4824455, 85.6275156],
    [47.4825996, 85.6274253],
    [47.4826734, 85.6273682],
    [47.4826824, 85.6272395],
    [47.4827498, 85.6272394],
    [47.4828332, 85.6271824],
    [47.482891, 85.6271063],
    [47.4829776, 85.6270635],
    [47.4831798, 85.6270633],
    [47.483244, 85.62703],
    [47.4833981, 85.6270917],
    [47.4834559, 85.6271819],
    [47.4835844, 85.627253],
    [47.4836614, 85.6271959],
    [47.4837352, 85.6271484],
    [47.4838058, 85.6270723],
    [47.4839245, 85.6270105],
    [47.4839855, 85.6269297],
    [47.4840849, 85.6267586],
    [47.4841386, 85.6267128],
    [47.4842964, 85.6268165],
    [47.4843577, 85.6267818],
    [47.4844366, 85.6267645],
    [47.4845563, 85.6266995],
    [47.4846703, 85.6267686],
    [47.4847988, 85.6267642],
    [47.4849219, 85.6267342],
    [47.4852203, 85.6265368],
    [47.485305, 85.6264719],
    [47.4853867, 85.6263249],
    [47.4854101, 85.6262168],
    [47.4853545, 85.6260958],
    [47.4853749, 85.6258969],
    [47.4854391, 85.6257239],
    [47.4854828, 85.6254948],
    [47.485547, 85.6254039],
    [47.4855976, 85.6253235],
    [47.4856533, 85.6251775],
    [47.4857151, 85.624804],
    [47.4857999, 85.6245935],
    [47.4858596, 85.6245696],
    [47.4859077, 85.6244576],
    [47.4860131, 85.624264],
    [47.4860842, 85.6242232],
    [47.4860888, 85.6242232],
    [47.4862357, 85.6242434],
    [47.4862862, 85.6244029],
    [47.4863138, 85.6245523],
    [47.4864285, 85.6245929],
    [47.4864951, 85.6247049],
    [47.4865502, 85.6247931],
    [47.4866236, 85.6247896],
    [47.4866626, 85.6247488],
    [47.4866786, 85.624647],
    [47.4867084, 85.6245723],
    [47.4867703, 85.6245281],
    [47.4868276, 85.6245043],
    [47.486846, 85.624416],
    [47.4868896, 85.624351],
    [47.4870502, 85.6243169],
    [47.4871442, 85.6242387],
    [47.4871603, 85.6242183],
    [47.4872016, 85.6241742],
    [47.4873162, 85.6241401],
    [47.4874149, 85.6240755],
    [47.4875157, 85.6239532],
    [47.4875799, 85.6238717],
    [47.4877091, 85.6238141],
    [47.4877958, 85.6237618],
    [47.4879083, 85.6237712],
    [47.4879757, 85.6237331],
    [47.4880046, 85.6236855],
    [47.4880271, 85.6235618],
    [47.4880302, 85.6233336],
    [47.4880558, 85.6231956],
    [47.4880976, 85.6230434],
    [47.4881168, 85.6230196],
    [47.4881457, 85.6230101],
    [47.4882004, 85.6230861],
    [47.4882422, 85.6231051],
    [47.4882872, 85.6230908],
    [47.4883193, 85.6230432],
    [47.4883283, 85.6229563],
    [47.4883547, 85.6228154],
    [47.4883547, 85.6227097],
    [47.4883547, 85.6226392],
    [47.4883876, 85.6225987],
    [47.4884643, 85.6225595],
    [47.4885886, 85.6226337],
    [47.4886653, 85.6226141],
    [47.4887129, 85.6225631],
    [47.4887155, 85.6224379],
    [47.4886969, 85.6221482],
    [47.4887206, 85.621929],
    [47.4887522, 85.6217058],
    [47.4888077, 85.6214592],
    [47.4888577, 85.6212646],
    [47.4889397, 85.6210688],
    [47.4889449, 85.6210649],
    [47.4890296, 85.6210061],
    [47.4891618, 85.6209864],
    [47.489421, 85.6210175],
    [47.4895824, 85.6210995],
    [47.4896591, 85.6210956],
    [47.489712, 85.6210329],
    [47.4897305, 85.6209428],
    [47.4897482, 85.6208386],
    [47.4897694, 85.6207368],
    [47.4897772, 85.6206193],
    [47.4897454, 85.6204823],
    [47.4897348, 85.6203023],
    [47.4897347, 85.62013],
    [47.4897717, 85.6199421],
    [47.4898695, 85.6198207],
    [47.4900017, 85.6197422],
    [47.4900628, 85.6196663],
    [47.490121, 85.6195371],
    [47.4901447, 85.6194822],
    [47.4903192, 85.6193098],
    [47.4904407, 85.6188243],
    [47.4904009, 85.6185307],
    [47.4904511, 85.6183741],
    [47.4904351, 85.6181432],
    [47.4904485, 85.6178201],
    [47.4904881, 85.6176517],
    [47.4905515, 85.6175851],
    [47.4906548, 85.6177494],
    [47.4907632, 85.6178159],
    [47.4909032, 85.6173695],
    [47.4910274, 85.6170914],
    [47.4910512, 85.6169661],
    [47.4910669, 85.6167116],
    [47.4911357, 85.6163875],
    [47.491176, 85.6163841],
    [47.4912543, 85.6165959],
    [47.4912991, 85.6167316],
    [47.4913372, 85.6167414],
    [47.4914713, 85.6165195],
    [47.4914667, 85.6163904],
    [47.4913906, 85.6161853],
    [47.491395, 85.6160794],
    [47.4914709, 85.6157714],
    [47.4915155, 85.6155728],
    [47.4915804, 85.61548],
    [47.4916162, 85.6154932],
    [47.4916877, 85.6155395],
    [47.4917191, 85.6155229],
    [47.4917235, 85.6154799],
    [47.4916676, 85.6154137],
    [47.4916496, 85.6153277],
    [47.4916965, 85.6151588],
    [47.4917524, 85.6151124],
    [47.4918128, 85.615119],
    [47.4918866, 85.6151983],
    [47.4919314, 85.6152877],
    [47.4920143, 85.6155193],
    [47.4920091, 85.6156167],
    [47.4919877, 85.6157294],
    [47.4919487, 85.6157323],
    [47.491894, 85.6156659],
    [47.4918471, 85.6156833],
    [47.4918316, 85.6157729],
    [47.4918492, 85.6158653],
    [47.4918219, 85.6159664],
    [47.4918317, 85.616056],
    [47.4918103, 85.616212],
    [47.4918455, 85.6162553],
    [47.4919079, 85.6162581],
    [47.4919645, 85.6161367],
    [47.4920055, 85.6161309],
    [47.4921695, 85.616252],
    [47.4922651, 85.616249],
    [47.4923919, 85.6161305],
    [47.4924778, 85.6161333],
    [47.4925794, 85.6162632],
    [47.4926257, 85.6162505],
    [47.4927036, 85.6160684],
    [47.4927249, 85.6159137],
    [47.4927425, 85.6156888],
    [47.4927804, 85.6155276],
    [47.492836, 85.6154039],
    [47.4929044, 85.6153813],
    [47.4929778, 85.615415],
    [47.4930614, 85.6155011],
    [47.4931374, 85.6155385],
    [47.4931981, 85.6154747],
    [47.493231, 85.6153698],
    [47.4933018, 85.6151786],
    [47.4933828, 85.6149986],
    [47.4934384, 85.6148336],
    [47.4935345, 85.6146012],
    [47.4936104, 85.6144512],
    [47.4936686, 85.6143612],
    [47.4937019, 85.6142738],
    [47.4937348, 85.6141463],
    [47.4937879, 85.6140526],
    [47.4938613, 85.6140562],
    [47.4939526, 85.6141948],
    [47.4939957, 85.6143259],
    [47.4940641, 85.614382],
    [47.4941324, 85.6143782],
    [47.4942084, 85.6143369],
    [47.4942514, 85.6142057],
    [47.4942969, 85.6140895],
    [47.49435, 85.614007],
    [47.4944057, 85.6140257],
    [47.4944716, 85.6140855],
    [47.4945374, 85.6140293],
    [47.494626, 85.613898],
    [47.4947272, 85.6138154],
    [47.4948132, 85.6137142],
    [47.4948656, 85.61363],
    [47.4949011, 85.6135116],
    [47.4949455, 85.6134294],
    [47.4950459, 85.6133502],
    [47.4951124, 85.6133829],
    [47.4952597, 85.6135021],
    [47.4953302, 85.613514],
    [47.4954794, 85.613475],
    [47.495662, 85.6134943],
    [47.4957803, 85.6134175],
    [47.4958727, 85.6133682],
    [47.495954, 85.6132039],
    [47.4960834, 85.6131544],
    [47.4962098, 85.6131648],
    [47.4963112, 85.6132471],
    [47.4963062, 85.6133858],
    [47.4963594, 85.6135769],
    [47.4964354, 85.6136143],
    [47.4965873, 85.6136366],
    [47.4967291, 85.6135727],
    [47.4968886, 85.6135126],
    [47.4971082, 85.6135162],
    [47.4971487, 85.6134525],
    [47.4972043, 85.6132912],
    [47.4972904, 85.6132499],
    [47.4973537, 85.6132836],
    [47.4974475, 85.6135158],
    [47.4975666, 85.6137743],
    [47.4976299, 85.613763],
    [47.4977235, 85.6136542],
    [47.4977893, 85.6135454],
    [47.4978146, 85.613463],
    [47.4978019, 85.6133506],
    [47.4978347, 85.6132081],
    [47.4978828, 85.6130507],
    [47.4978827, 85.6129495],
    [47.4979199, 85.612872],
    [47.497968, 85.6128795],
    [47.4980339, 85.6128869],
    [47.4980743, 85.6128456],
    [47.4980996, 85.6127669],
    [47.4981072, 85.612647],
    [47.4981274, 85.612602],
    [47.4981805, 85.6125532],
    [47.4982109, 85.6125644],
    [47.4982439, 85.6126281],
    [47.4982996, 85.6126655],
    [47.4984971, 85.6126053],
    [47.4985831, 85.6124515],
    [47.4986387, 85.612354],
    [47.4987273, 85.6123015],
    [47.4989552, 85.6122338],
    [47.4991146, 85.6121511],
    [47.4992648, 85.6121368],
    [47.4994344, 85.6121554],
    [47.4997914, 85.6121362],
    [47.4999661, 85.6121285],
    [47.5000674, 85.6121172],
    [47.5001206, 85.6121733],
    [47.5001915, 85.612192],
    [47.5002877, 85.6121731],
    [47.5004194, 85.612143],
    [47.5004852, 85.6120905],
    [47.5005914, 85.6119442],
    [47.5006496, 85.6118879],
    [47.5008066, 85.6118344],
    [47.5009965, 85.6118192],
    [47.5012219, 85.6118789],
    [47.5014473, 85.6118937],
    [47.5015181, 85.6118674],
    [47.5015763, 85.6117998],
    [47.5017383, 85.6115935],
    [47.5017838, 85.6114923],
    [47.5018521, 85.611346],
    [47.5019329, 85.6110049],
    [47.5020375, 85.610792],
    [47.5020881, 85.6107357],
    [47.5021337, 85.6107507],
    [47.5022122, 85.6107881],
    [47.5025034, 85.6107802],
    [47.502554, 85.6107539],
    [47.5025919, 85.6106827],
    [47.5026476, 85.6106751],
    [47.5026856, 85.6105889],
    [47.5026526, 85.6104952],
    [47.502731, 85.6103789],
    [47.502807, 85.6103526],
    [47.5029133, 85.610345],
    [47.5030662, 85.6104479],
    [47.503172, 85.6103679],
    [47.5036233, 85.6100332],
    [47.5040856, 85.6093477],
    [47.5042046, 85.609309],
    [47.5044544, 85.609321],
    [47.5047041, 85.6092714],
    [47.5048039, 85.6090864],
    [47.5049538, 85.6090123],
    [47.5051786, 85.609012],
    [47.5052537, 85.6092214],
    [47.5053704, 85.6096404],
    [47.5058119, 85.6098864],
    [47.5060117, 85.6099231],
    [47.5061366, 85.6097874],
    [47.5063945, 85.6094789],
    [47.5064719, 85.6093369],
    [47.5065169, 85.6093702],
    [47.5065845, 85.6094368],
    [47.5066239, 85.6093784],
    [47.5066239, 85.6092784],
    [47.5066069, 85.6091617],
    [47.5066293, 85.6089867],
    [47.5066856, 85.6089283],
    [47.5067982, 85.6089531],
    [47.5069108, 85.609053],
    [47.5070572, 85.6090528],
    [47.507136, 85.6090111],
    [47.5071922, 85.608861],
    [47.5071753, 85.6087027],
    [47.5072202, 85.608611],
    [47.5073328, 85.6085608],
    [47.5073947, 85.6084524],
    [47.507434, 85.608319],
    [47.5074903, 85.6082606],
    [47.5075804, 85.6083355],
    [47.50762, 85.6087022],
    [47.5077101, 85.6088437],
    [47.5078846, 85.6088352],
    [47.5081041, 85.6087349],
    [47.5082617, 85.6086847],
    [47.5083828, 85.6087258],
    [47.5084877, 85.6088312],
    [47.5084962, 85.6089491],
    [47.5085633, 85.6089863],
    [47.508601, 85.6090235],
    [47.5086472, 85.6089924],
    [47.5086681, 85.6089551],
    [47.5086303, 85.6089179],
    [47.5086303, 85.6088496],
    [47.5086596, 85.6087378],
    [47.508714, 85.6086074],
    [47.5087349, 85.6084832],
    [47.5087349, 85.608328],
    [47.5087392, 85.6082003],
    [47.5087811, 85.6080077],
    [47.5088649, 85.6078773],
    [47.5089193, 85.6078337],
    [47.5090493, 85.6078088],
    [47.5091207, 85.6078521],
    [47.5092213, 85.6079389],
    [47.5093304, 85.6079636],
    [47.509471, 85.6079315],
    [47.5096597, 85.6079003],
    [47.5097814, 85.6079374],
    [47.5098695, 85.6080304],
    [47.5099659, 85.6080427],
    [47.5101421, 85.6081667],
    [47.510289, 85.608241],
    [47.5103939, 85.6083526],
    [47.510501, 85.608583],
    [47.5106478, 85.6086573],
    [47.5107276, 85.6087689],
    [47.5110002, 85.6088245],
    [47.5111596, 85.6089236],
    [47.5112309, 85.6090105],
    [47.5112939, 85.6090477],
    [47.5113568, 85.609178],
    [47.5114953, 85.6093144],
    [47.5116212, 85.6094446],
    [47.5117513, 85.6096059],
    [47.5118489, 85.6095747],
    [47.5119873, 85.6095932],
    [47.5120043, 85.6098353],
    [47.5122266, 85.6100213],
    [47.5122433, 85.6098288],
    [47.5123355, 85.6096797],
    [47.5125662, 85.6097725],
    [47.5126752, 85.6097476],
    [47.512931, 85.6097349],
    [47.5131177, 85.6098027],
    [47.5133112, 85.6098796],
    [47.513382, 85.6100172],
    [47.5134862, 85.6100336],
    [47.513527, 85.6098959],
    [47.5136684, 85.6099067],
    [47.5138023, 85.6099231],
    [47.5139287, 85.6098348],
    [47.5141706, 85.6100053],
    [47.5142376, 85.6099887],
    [47.5143158, 85.6100602],
    [47.5145985, 85.610126],
    [47.5147436, 85.6100542],
    [47.5147742, 85.6099554],
    [47.5148486, 85.6099002],
    [47.5149081, 85.6099166],
    [47.5150459, 85.6101423],
    [47.515098, 85.6102414],
    [47.515165, 85.6102744],
    [47.515258, 85.6102633],
    [47.5153584, 85.6101254],
    [47.5153732, 85.6100318],
    [47.5154029, 85.6099546],
    [47.5154066, 85.6098279],
    [47.5154437, 85.6097728],
    [47.5155888, 85.6097176],
    [47.5157078, 85.6095742],
    [47.5158491, 85.6094914],
    [47.5158975, 85.6095244],
    [47.515905, 85.6097172],
    [47.5159497, 85.6097943],
    [47.5160836, 85.6098437],
    [47.5161952, 85.6098105],
    [47.5163254, 85.6097387],
    [47.5163514, 85.6096781],
    [47.5163291, 85.6096231],
    [47.5163662, 85.6094963],
    [47.5164368, 85.6093971],
    [47.5166005, 85.6093583],
    [47.5167841, 85.6093231],
    [47.5168586, 85.6094607],
    [47.5170111, 85.609433],
    [47.5170781, 85.6094825],
    [47.5171711, 85.6094878],
    [47.5173013, 85.6093941],
    [47.5174128, 85.6092342],
    [47.5175132, 85.6091845],
    [47.5176062, 85.6092119],
    [47.5178035, 85.609388],
    [47.5179412, 85.6095916],
    [47.5180826, 85.609663],
    [47.5182128, 85.6096188],
    [47.5184248, 85.6094643],
    [47.5186813, 85.6091556],
    [47.5187906, 85.6090323],
    [47.518905, 85.6090079],
    [47.5190196, 85.6091773],
    [47.5191286, 85.6092337],
    [47.5192921, 85.6091447],
    [47.5194284, 85.6091445],
    [47.5196138, 85.609225],
    [47.5198375, 85.6095961],
    [47.5199793, 85.6096605],
    [47.5200883, 85.6095797],
    [47.5201481, 85.6094585],
    [47.5201587, 85.608837],
    [47.5201368, 85.6086271],
    [47.5201912, 85.6084817],
    [47.520273, 85.6084897],
    [47.5205676, 85.608893],
    [47.5207094, 85.6090462],
    [47.520862, 85.6090056],
    [47.5209001, 85.6089329],
    [47.5207637, 85.6086344],
    [47.5207036, 85.608352],
    [47.5207961, 85.6080774],
    [47.5208179, 85.6080854],
    [47.5208561, 85.6082065],
    [47.5211125, 85.6085452],
    [47.5213088, 85.6085853],
    [47.5216046, 85.6083988],
    [47.5216607, 85.6081175],
    [47.5217802, 85.6079506],
    [47.5218998, 85.6079192],
    [47.5220195, 85.6079503],
    [47.5221532, 85.6081273],
    [47.5224558, 85.6081478],
    [47.5226739, 85.6080329],
    [47.5230607, 85.607824],
    [47.5231945, 85.6079802],
    [47.5233282, 85.6080425],
    [47.5233915, 85.6079487],
    [47.5234617, 85.6077923],
    [47.5235532, 85.6077714],
    [47.5237433, 85.6080733],
    [47.5238137, 85.6080732],
    [47.5238981, 85.6079585],
    [47.5239473, 85.6078855],
    [47.5239965, 85.6079063],
    [47.5242149, 85.6083957],
    [47.5243486, 85.608406],
    [47.5245177, 85.6087288],
    [47.5246303, 85.6088433],
    [47.5247429, 85.6089473],
    [47.5248274, 85.6090827],
    [47.524933, 85.6090825],
    [47.5250103, 85.6090512],
    [47.5250736, 85.6089365],
    [47.5251932, 85.6089259],
    [47.525503, 85.6092173],
    [47.5255838, 85.6094375],
    [47.5256825, 85.6097395],
    [47.525774, 85.6098749],
    [47.5260062, 85.6099059],
    [47.5260977, 85.6098433],
    [47.5262452, 85.6093742],
    [47.5263436, 85.6093428],
    [47.5265125, 85.6093739],
    [47.5265829, 85.609478],
    [47.5266323, 85.6096134],
    [47.526773, 85.6096132],
    [47.5268997, 85.6096548],
    [47.5270686, 85.609863],
    [47.5271812, 85.6098524],
    [47.5273007, 85.6097272],
    [47.5274062, 85.6095083],
    [47.5274554, 85.6094249],
    [47.5275398, 85.6094665],
    [47.5277933, 85.6097683],
    [47.5279693, 85.609914],
    [47.5280396, 85.6098723],
    [47.5281732, 85.6097158],
    [47.5282647, 85.6097261],
    [47.5283984, 85.6097989],
    [47.5284757, 85.609705],
    [47.5285249, 85.6095799],
    [47.5286445, 85.6095798],
    [47.5287571, 85.6095067],
    [47.5287569, 85.6092253],
    [47.5288483, 85.6090377],
    [47.5289468, 85.6089333],
    [47.5290242, 85.6089437],
    [47.5290946, 85.6091937],
    [47.5291018, 85.6094125],
    [47.5291538, 85.6094928],
    [47.5292307, 85.6094843],
    [47.5293728, 85.609397],
    [47.5294756, 85.6093606],
    [47.5295883, 85.609404],
    [47.5297109, 85.60952],
    [47.5297991, 85.6095271],
    [47.5298333, 85.6094546],
    [47.529843, 85.6093239],
    [47.5298626, 85.6091861],
    [47.5299213, 85.6090481],
    [47.530078, 85.6089319],
    [47.5302249, 85.6088809],
    [47.5304356, 85.6088806],
    [47.5305581, 85.6089385],
    [47.5306463, 85.6090836],
    [47.5307101, 85.6091851],
    [47.5307933, 85.6091197],
    [47.530857, 85.608989],
    [47.5309499, 85.6088147],
    [47.5310234, 85.6087929],
    [47.5311361, 85.6087855],
    [47.5312341, 85.6088071],
    [47.5312537, 85.6089014],
    [47.5313126, 85.6089667],
    [47.5313959, 85.6090028],
    [47.531538, 85.6090825],
    [47.5317109, 85.6091756],
    [47.5319314, 85.6092624],
    [47.5320147, 85.6092188],
    [47.5320881, 85.6091389],
    [47.5321616, 85.6090808],
    [47.5322302, 85.6090734],
    [47.5322694, 85.6091242],
    [47.5322302, 85.609204],
    [47.5322107, 85.6093492],
    [47.5321912, 85.6094726],
    [47.5321471, 85.6095887],
    [47.5321472, 85.6096613],
    [47.5321669, 85.6098064],
    [47.5322454, 85.6100385],
    [47.5322993, 85.6101472],
    [47.5324267, 85.6102414],
    [47.5325051, 85.6101978],
    [47.5325981, 85.6100018],
    [47.5325833, 85.6098567],
    [47.5325342, 85.6097116],
    [47.532539, 85.6094867],
    [47.5326516, 85.6093196],
    [47.532725, 85.6091962],
    [47.5328572, 85.609109],
    [47.5329161, 85.609167],
    [47.5330435, 85.6092103],
    [47.5331415, 85.6093191],
    [47.5332004, 85.6094859],
    [47.5332005, 85.6096238],
    [47.5331762, 85.6100519],
    [47.5332841, 85.6103855],
    [47.5333283, 85.6104943],
    [47.5333773, 85.6104943],
    [47.533436, 85.6103563],
    [47.5335437, 85.6102691],
    [47.5336711, 85.6103198],
    [47.5337348, 85.6103705],
    [47.533828, 85.6105808],
    [47.5341319, 85.6108054],
    [47.5342754, 85.6108039],
    [47.5343439, 85.610753],
    [47.5344075, 85.6105135],
    [47.5344515, 85.6104336],
    [47.5346034, 85.610506],
    [47.5347211, 85.6106292],
    [47.53478, 85.6108396],
    [47.5348095, 85.6110717],
    [47.5348341, 85.6112821],
    [47.5349126, 85.6115578],
    [47.5350009, 85.6117173],
    [47.5352214, 85.6118767],
    [47.5353978, 85.6119346],
    [47.5355595, 85.6118981],
    [47.5357407, 85.6118253],
    [47.5358387, 85.6118252],
    [47.5359073, 85.6119267],
    [47.5359075, 85.612217],
    [47.5360546, 85.6124853],
    [47.5361526, 85.612565],
    [47.5365053, 85.6125284],
    [47.536667, 85.6125645],
    [47.5367356, 85.612637],
    [47.5367308, 85.6127531],
    [47.5367162, 85.6129055],
    [47.5367125, 85.6131752],
    [47.5367959, 85.6134509],
    [47.5369773, 85.6136393],
    [47.5371978, 85.6137262],
    [47.5375553, 85.6135589],
    [47.5379667, 85.6134205],
    [47.5382803, 85.6133984],
    [47.5385155, 85.6134852],
    [47.538981, 85.6137605],
    [47.5394037, 85.6138361],
    [47.5396683, 85.614039],
    [47.5398692, 85.6141839],
    [47.5400064, 85.61422],
    [47.5401388, 85.6144086],
    [47.5402222, 85.6145899],
    [47.5403105, 85.6148438],
    [47.5404379, 85.6150541],
    [47.5405801, 85.6151701],
    [47.5407614, 85.6152352],
    [47.5409671, 85.6151552],
    [47.5411043, 85.6151478],
    [47.5412463, 85.6151476],
    [47.541462, 85.615278],
    [47.541511, 85.6153578],
    [47.5415259, 85.6157134],
    [47.5416337, 85.6159237],
    [47.5417402, 85.6160823],
    [47.5417941, 85.6160532],
    [47.5418872, 85.6159442],
    [47.5419508, 85.6159441],
    [47.542044, 85.6160384],
    [47.5421617, 85.6162923],
    [47.5423186, 85.6165897],
    [47.5424608, 85.6168726],
    [47.5425784, 85.6170031],
    [47.5427499, 85.6171045],
    [47.5428724, 85.6171116],
    [47.5429802, 85.6170752],
    [47.5432006, 85.6169371],
    [47.543323, 85.6169079],
    [47.5434161, 85.6169369],
    [47.5435779, 85.6171399],
    [47.5437054, 85.6174011],
    [47.543725, 85.6175389],
    [47.5437938, 85.6178364],
    [47.543894, 85.6181687],
    [47.5440165, 85.6183282],
    [47.5441537, 85.6183934],
    [47.5442958, 85.6183642],
    [47.5445015, 85.6182334],
    [47.5446829, 85.6179752],
    [47.5448101, 85.6177429],
    [47.5448591, 85.6176993],
    [47.5449277, 85.6177573],
    [47.5449963, 85.6178515],
    [47.5451041, 85.6178805],
    [47.5452756, 85.6178585],
    [47.5454617, 85.617793],
    [47.5456185, 85.6178146],
    [47.5459076, 85.6179305],
    [47.546035, 85.6179376],
    [47.546177, 85.6178794],
    [47.5462505, 85.6178793],
    [47.5463289, 85.6179445],
    [47.5465152, 85.6181331],
    [47.5466818, 85.6181764],
    [47.5467748, 85.6181691],
    [47.5467552, 85.618053],
    [47.5468139, 85.6179223],
    [47.5468874, 85.6178859],
    [47.5470638, 85.6178785],
    [47.5471715, 85.617813],
    [47.5472623, 85.6178394],
    [47.5473358, 85.6179337],
    [47.5474046, 85.6181949],
    [47.5474928, 85.6182965],
    [47.5475467, 85.6182964],
    [47.5476103, 85.6182383],
    [47.5476642, 85.6181003],
    [47.5478993, 85.6180057],
    [47.5480952, 85.6179112],
    [47.548247, 85.617882],
    [47.5484528, 85.6179181],
    [47.5485312, 85.6179906],
    [47.548664, 85.6191227],
    [47.5486641, 85.6193259],
    [47.5488063, 85.6196597],
    [47.5489387, 85.6197757],
    [47.5490808, 85.6198336],
    [47.5491885, 85.6197609],
    [47.549306, 85.6196519],
    [47.5493827, 85.619667],
    [47.5495396, 85.6197757],
    [47.5496033, 85.6198991],
    [47.5496279, 85.6201458],
    [47.549672, 85.6202764],
    [47.5497554, 85.6203489],
    [47.549917, 85.6203851],
    [47.5500444, 85.6203269],
    [47.5500836, 85.620276],
    [47.5501031, 85.6201381],
    [47.5501423, 85.6200655],
    [47.5502451, 85.6200073],
    [47.5503186, 85.6200508],
    [47.5504363, 85.6203555],
    [47.5505932, 85.6206385],
    [47.5507305, 85.6207835],
    [47.5508823, 85.6207906],
    [47.5511812, 85.6207976],
    [47.5513135, 85.620841],
    [47.5513919, 85.6209571],
    [47.551441, 85.621182],
    [47.5515734, 85.621494],
    [47.551696, 85.6217117],
    [47.5519647, 85.6219332],
    [47.5521019, 85.6220492],
    [47.5522391, 85.6221217],
    [47.5524499, 85.6224554],
    [47.5527292, 85.6224987],
    [47.5528811, 85.6226147],
    [47.5530036, 85.6228323],
    [47.5531115, 85.6229484],
    [47.5533173, 85.6232095],
    [47.5536163, 85.623456],
    [47.5539642, 85.6236081],
    [47.5544101, 85.623782],
    [47.5544836, 85.6238835],
    [47.5545277, 85.6239706],
    [47.5546152, 85.624065],
    [47.5549976, 85.6245728],
    [47.5551936, 85.6247251],
    [47.5554434, 85.6247611],
    [47.5556149, 85.6248118],
    [47.5556688, 85.6248843],
    [47.5556689, 85.6249714],
    [47.555664, 85.6251239],
    [47.555713, 85.6251674],
    [47.5559335, 85.6252761],
    [47.5560414, 85.6253994],
    [47.5561835, 85.62553],
    [47.5564334, 85.6257112],
    [47.5566344, 85.6259506],
    [47.5567913, 85.6262917],
    [47.5569846, 85.6267758],
    [47.5575149, 85.6270261],
    [47.5576729, 85.627285],
    [47.5578196, 85.6273601],
    [47.5580114, 85.627502],
    [47.5581637, 85.6273765],
    [47.5582878, 85.6274099],
    [47.5585021, 85.6274849],
    [47.5586545, 85.6276269],
    [47.5588011, 85.6276518],
    [47.5589364, 85.6275681],
    [47.5591, 85.6274593],
    [47.5592466, 85.6274174],
    [47.5594046, 85.6274507],
    [47.5595287, 85.6275342],
    [47.5596359, 85.627601],
    [47.5597882, 85.6276343],
    [47.5599179, 85.6276008],
    [47.5600367, 85.6275389],
    [47.5601608, 85.627497],
    [47.5602736, 85.6275303],
    [47.5603526, 85.6275052],
    [47.5604541, 85.6274048],
    [47.5605387, 85.6274131],
    [47.5606572, 85.6274882],
    [47.5608208, 85.6276218],
    [47.5608828, 85.6276301],
    [47.5610069, 85.6275882],
    [47.5610689, 85.6276132],
    [47.5611479, 85.6276967],
    [47.5612438, 85.6276716],
    [47.5612777, 85.627705],
    [47.5612382, 85.6277635],
    [47.5612326, 85.6278221],
    [47.5613003, 85.6278722],
    [47.5613793, 85.6278721],
    [47.5614583, 85.627964],
    [47.5615373, 85.6280391],
    [47.5615936, 85.6279639],
    [47.5616613, 85.6279638],
    [47.5617911, 85.6280724],
    [47.5618757, 85.6281141],
    [47.5619377, 85.628114],
    [47.562065, 85.6280533],
    [47.5622737, 85.6281868],
    [47.5624655, 85.6281449],
    [47.5626967, 85.6281865],
    [47.5627926, 85.62827],
    [47.5628322, 85.6283535],
    [47.5629337, 85.6285039],
    [47.5630183, 85.6285289],
    [47.563103, 85.6286208],
    [47.5631594, 85.6285957],
    [47.5632271, 85.6286625],
    [47.5633005, 85.628838],
    [47.5633457, 85.6289968],
    [47.5633457, 85.6291389],
    [47.5633965, 85.6292057],
    [47.5635319, 85.6292139],
    [47.5636615, 85.6290717],
    [47.5638533, 85.6289044],
    [47.5640845, 85.6288457],
    [47.5645526, 85.6287701],
    [47.5646993, 85.6287783],
    [47.56498, 85.628745],
    [47.5652225, 85.6286696],
    [47.5655215, 85.6289034],
    [47.5657641, 85.6288781],
    [47.5660629, 85.6286605],
    [47.5664522, 85.6288692],
    [47.5666157, 85.6287855],
    [47.5668978, 85.6288856],
    [47.5671629, 85.6289104],
    [47.5673981, 85.6289351],
    [47.5676227, 85.6287586],
    [47.5678213, 85.6287552],
    [47.5681187, 85.6287647],
    [47.5683103, 85.6286568],
    [47.5683763, 85.6285294],
    [47.5683894, 85.628118],
    [47.5686537, 85.6280395],
    [47.5688717, 85.627814],
    [47.5693939, 85.6280389],
    [47.5696848, 85.6283129],
    [47.5698897, 85.6283715],
    [47.5701475, 85.628528],
    [47.570326, 85.6287042],
    [47.5705507, 85.6287432],
    [47.5710004, 85.628745],
    [47.5714103, 85.6289993],
    [47.5720579, 85.6289204],
    [47.5722562, 85.6289888],
    [47.5726791, 85.6289101],
    [47.5727981, 85.6290178],
    [47.5730492, 85.6288804],
    [47.57334, 85.628841],
    [47.5734391, 85.6287234],
    [47.5741728, 85.6290461],
    [47.574484, 85.6290298],
    [47.5749399, 85.6288041],
    [47.5753695, 85.6288037],
    [47.5759298, 85.6285612],
    [47.5766658, 85.6285799],
    [47.5770435, 85.6285796],
    [47.5772975, 85.6286567],
    [47.577545, 85.6286178],
    [47.5778186, 85.6288204],
    [47.5782293, 85.6289683],
    [47.5783333, 85.6291558],
    [47.578487, 85.6294369],
    [47.5787806, 85.62943],
    [47.5790472, 85.6295169],
    [47.5796159, 85.6300107],
    [47.5801582, 85.6306264],
    [47.5807639, 85.6313694],
    [47.5813051, 85.6320087],
    [47.5815943, 85.6323634],
    [47.5819242, 85.6325909],
    [47.5823219, 85.6328652],
    [47.5833534, 85.6333786],
    [47.5842254, 85.6335521],
    [47.5846815, 85.6336719],
    [47.5854045, 85.6342207],
    [47.5859379, 85.635004],
    [47.5864033, 85.63587],
    [47.6451459, 85.6235689],
    [47.7004115, 85.6119959],
    [47.7537339, 85.6008299],
    [47.8077292, 85.5895229],
    [47.8535534, 85.5799271],
    [47.900561, 85.5700834],
    [47.9198129, 85.5660528],
    [47.918045, 85.5589218],
    [47.9150315, 85.5483465],
    [47.9125222, 85.5341794],
    [47.9005977, 85.4867619],
    [47.9277491, 85.4563902],
    [47.912978, 85.4344034],
    [47.8747455, 85.4317094],
    [47.8610798, 85.3645478],
    [47.8862415, 85.339409],
    [47.8933364, 85.24472],
    [47.8792582, 85.2044135],
    [47.9075309, 85.1674049],
    [47.8823282, 85.1345284],
    [47.8795932, 85.0683673],
    [47.8998525, 85.0272608],
    [47.8704767, 84.9927684],
    [47.8326567, 84.9732066],
    [47.8176759, 84.8697201],
    [47.8235402, 84.8153992],
    [47.784118, 84.719459],
    [47.7871013, 84.6576625],
    [47.7856336, 84.6126701],
    [47.747474, 84.5939037],
    [47.750389, 84.5133887],
    [47.7395586, 84.4703746],
    [47.7617482, 84.4496527],
    [47.744224, 84.4160764],
    [47.7951695, 84.2018901],
    [47.7229277, 84.2181244],
    [47.6157878, 84.3104636],
    [47.5381969, 84.2605368],
    [47.4745361, 84.2788611],
    [47.3786005, 84.2558372],
    [47.2799184, 84.2667611],
    [47.2056346, 84.2100517],
    [47.1730794, 84.2655486],
    [47.0245778, 84.4184878],
    [46.999939, 84.3699265],
    [46.9998322, 84.3751831],
    [46.9997253, 84.3804398],
    [46.9996872, 84.3823471],
    [47.0001488, 84.3875656],
    [47.0006065, 84.3927841],
    [47.0008316, 84.39534],
    [47.0023613, 84.4001007],
    [47.0031433, 84.4025421],
    [47.0051231, 84.4069366],
    [47.007103, 84.4113312],
    [47.0081139, 84.4135742],
    [47.0084076, 84.4188156],
    [47.0087051, 84.424057],
    [47.0087166, 84.424263],
    [47.0087471, 84.4295197],
    [47.0087585, 84.4319687],
    [47.008728, 84.4372253],
    [47.0086937, 84.4424896],
    [47.0086899, 84.4433746],
    [47.0066185, 84.4476776],
    [47.0048447, 84.4513626],
    [47.0022163, 84.4549561],
    [46.9995918, 84.4585571],
    [46.9991531, 84.4591599],
    [46.9964943, 84.4626999],
    [46.99543, 84.464119],
    [46.9936905, 84.4687271],
    [46.9919586, 84.4733353],
    [46.9910164, 84.4758377],
    [46.9893723, 84.4805145],
    [46.9887199, 84.4823685],
    [46.9882851, 84.487587],
    [46.9880676, 84.4901276],
    [46.9867744, 84.4950333],
    [46.9864464, 84.4962769],
    [46.9842224, 84.500412],
    [46.982132, 84.5042953],
    [46.9811325, 84.509346],
    [46.9801788, 84.5141525],
    [46.9815559, 84.5190125],
    [46.9827843, 84.5233459],
    [46.984005, 84.5282974],
    [46.9852219, 84.5332413],
    [46.9857063, 84.535202],
    [46.987114, 84.5400467],
    [46.9875221, 84.5414429],
    [46.98946, 84.5458755],
    [46.9905052, 84.5482712],
    [46.9923325, 84.5527954],
    [46.9930038, 84.5544586],
    [46.9945793, 84.5591888],
    [46.9953003, 84.5613327],
    [46.9966011, 84.5662384],
    [46.9978981, 84.5711441],
    [46.9979172, 84.5712051],
    [46.9980927, 84.5764618],
    [46.9982758, 84.5817108],
    [46.9983253, 84.5832443],
    [46.9985008, 84.588501],
    [46.9986763, 84.59375],
    [46.9987144, 84.5949478],
    [46.9991531, 84.600174],
    [46.9995995, 84.6053848],
    [46.9998436, 84.6082764],
    [46.9998894, 84.6135406],
    [46.9999275, 84.6176605],
    [46.99963, 84.6229019],
    [46.9993362, 84.6281433],
    [46.9992065, 84.6304474],
    [46.9996338, 84.6356659],
    [46.9999695, 84.6397781],
    [47.0009003, 84.6448593],
    [47.0013657, 84.6473923],
    [47.0018768, 84.6525955],
    [47.0020561, 84.6543808],
    [47.0024223, 84.6596146],
    [47.0027847, 84.6648483],
    [47.0029182, 84.6667252],
    [47.0033073, 84.6719513],
    [47.0036964, 84.6771851],
    [47.0037346, 84.6777344],
    [47.0047073, 84.6827927],
    [47.005111, 84.684887],
    [47.0056839, 84.6878586],
    [47.0062141, 84.6906357],
    [47.0072937, 84.6956558],
    [47.0075645, 84.6969147],
    [47.008831, 84.7018356],
    [47.0099297, 84.7061386],
    [47.0115471, 84.7108383],
    [47.0119553, 84.7120361],
    [47.0134811, 84.7167969],
    [47.0150108, 84.72155],
    [47.0154877, 84.7222977],
    [47.0149574, 84.7275009],
    [47.0144234, 84.7327042],
    [47.0140686, 84.7362061],
    [47.011795, 84.7402802],
    [47.0099754, 84.7435455],
    [47.0088921, 84.7453003],
    [47.0084839, 84.746666],
    [47.0049076, 84.7522945],
    [47.0043739, 84.7528425],
    [47.0038026, 84.7534541],
    [47.0031314, 84.7533624],
    [47.0021373, 84.7531933],
    [47.0014933, 84.7533446],
    [47.0010562, 84.7536629],
    [47.0006687, 84.7541565],
    [47.0001591, 84.7543823],
    [46.999566, 84.7548089],
    [46.9990714, 84.7548551],
    [46.9988808, 84.7559376],
    [46.998695, 84.7563389],
    [46.998873, 84.7568976],
    [46.9988878, 84.7578793],
    [46.9990546, 84.7585222],
    [46.9990612, 84.7592463],
    [46.9992028, 84.7600415],
    [46.9989988, 84.7609448],
    [46.9989479, 84.7617932],
    [46.9987343, 84.7625206],
    [46.9983584, 84.7633293],
    [46.9979654, 84.7639094],
    [46.9975157, 84.7649863],
    [46.9973185, 84.7654202],
    [46.9970531, 84.7657586],
    [46.9969115, 84.7661269],
    [46.9968197, 84.7664812],
    [46.9967602, 84.7668096],
    [46.9967361, 84.7672017],
    [46.9963346, 84.7677375],
    [46.9961382, 84.7681161],
    [46.9959655, 84.7684415],
    [46.9957662, 84.7688993],
    [46.9954492, 84.7690542],
    [46.9951985, 84.7691695],
    [46.9949144, 84.7691722],
    [46.9947263, 84.7691865],
    [46.9944833, 84.7692994],
    [46.9941147, 84.769412],
    [46.9939215, 84.7694725],
    [46.9937991, 84.7697174],
    [46.9935315, 84.770074],
    [46.993374, 84.7706941],
    [46.99318, 84.7716391],
    [46.9929085, 84.7723188],
    [46.9926233, 84.7728217],
    [46.9924253, 84.7731354],
    [46.9923102, 84.7733666],
    [46.9922914, 84.7736287],
    [46.9921958, 84.773932],
    [46.9919564, 84.7741359],
    [46.9918495, 84.77434],
    [46.9918121, 84.7744712],
    [46.9918337, 84.7748097],
    [46.9918737, 84.7751552],
    [46.9918357, 84.7756841],
    [46.9911971, 84.7758894],
    [46.9906699, 84.7760352],
    [46.9902132, 84.776266],
    [46.989748, 84.7765251],
    [46.9893621, 84.776694],
    [46.9886663, 84.7767962],
    [46.9881019, 84.7766436],
    [46.9876466, 84.7767389],
    [46.9874241, 84.7764799],
    [46.9873546, 84.7764036],
    [46.9872393, 84.7763607],
    [46.987054, 84.7763408],
    [46.9867342, 84.776426],
    [46.9864362, 84.7765625],
    [46.9859923, 84.7764913],
    [46.9856149, 84.7764249],
    [46.9852357, 84.7764412],
    [46.9848374, 84.7766897],
    [46.9841952, 84.7776311],
    [46.9834589, 84.778877],
    [46.9827306, 84.7800261],
    [46.9823283, 84.7804658],
    [46.9818847, 84.7806474],
    [46.9810423, 84.7806824],
    [46.9791644, 84.7807361],
    [46.9773593, 84.7808098],
    [46.9767282, 84.7806651],
    [46.9763481, 84.7811826],
    [46.9760612, 84.781795],
    [46.9756351, 84.7824487],
    [46.9750957, 84.7826327],
    [46.9744051, 84.7823807],
    [46.9741193, 84.7822931],
    [46.9736877, 84.7823139],
    [46.9734511, 84.7827629],
    [46.973352, 84.7837114],
    [46.9736531, 84.7855805],
    [46.97358, 84.7859611],
    [46.972828, 84.787234],
    [46.9722407, 84.788059],
    [46.971448, 84.7895549],
    [46.9707314, 84.7908868],
    [46.9701147, 84.7919567],
    [46.9696495, 84.7925448],
    [46.9689787, 84.7933297],
    [46.9682933, 84.7942761],
    [46.9677951, 84.7949315],
    [46.9668388, 84.7959586],
    [46.9665678, 84.7966123],
    [46.9659937, 84.7987632],
    [46.9656163, 84.8000991],
    [46.9651533, 84.801365],
    [46.9648234, 84.8021767],
    [46.964371, 84.8030256],
    [46.963941, 84.8039131],
    [46.963433, 84.8052378],
    [46.9630848, 84.8061104],
    [46.9627997, 84.8066111],
    [46.9623998, 84.8072808],
    [46.9619707, 84.8078916],
    [46.9615903, 84.8086038],
    [46.9612226, 84.8094046],
    [46.9609744, 84.8101003],
    [46.9607736, 84.8105002],
    [46.9601817, 84.8112319],
    [46.959813, 84.8116165],
    [46.9593834, 84.8119507],
    [46.9585056, 84.8125492],
    [46.9578876, 84.813087],
    [46.9575932, 84.8134556],
    [46.9573441, 84.81384],
    [46.9574119, 84.8141913],
    [46.9578756, 84.8156918],
    [46.9589809, 84.8169843],
    [46.9595612, 84.8177273],
    [46.9596966, 84.8180456],
    [46.9596975, 84.8183669],
    [46.9596271, 84.8191221],
    [46.95946, 84.8207976],
    [46.9595119, 84.8210881],
    [46.9602571, 84.8231368],
    [46.9603605, 84.8236438],
    [46.9603345, 84.8241472],
    [46.9601256, 84.8255178],
    [46.9598288, 84.8275129],
    [46.9596782, 84.8280641],
    [46.9590652, 84.8288833],
    [46.9583146, 84.8307132],
    [46.957811, 84.8320992],
    [46.9573418, 84.8334225],
    [46.9573589, 84.8337243],
    [46.9573088, 84.8340904],
    [46.9571565, 84.834525],
    [46.9571158, 84.8347882],
    [46.957184, 84.8358039],
    [46.957099, 84.8360737],
    [46.956865, 84.8362784],
    [46.9566446, 84.8364334],
    [46.9566166, 84.8367064],
    [46.9567764, 84.8378393],
    [46.9571047, 84.8385349],
    [46.9571833, 84.8394531],
    [46.9571103, 84.8400854],
    [46.9566507, 84.840413],
    [46.9560788, 84.8406728],
    [46.9556964, 84.8410029],
    [46.9554245, 84.841451],
    [46.9552614, 84.8425988],
    [46.9551071, 84.8432124],
    [46.954965, 84.843562],
    [46.9548896, 84.8440628],
    [46.9547862, 84.8443765],
    [46.9546974, 84.8452447],
    [46.9540576, 84.8468556],
    [46.95382, 84.8472673],
    [46.9532198, 84.8471926],
    [46.9525292, 84.8472855],
    [46.9516215, 84.8477331],
    [46.951433, 84.8485468],
    [46.9511758, 84.849507],
    [46.9510147, 84.8499121],
    [46.950653, 84.8502348],
    [46.9502685, 84.8503288],
    [46.9499321, 84.8505465],
    [46.9496091, 84.8506814],
    [46.9488689, 84.8506224],
    [46.9482885, 84.85063],
    [46.9476022, 84.8508626],
    [46.9476424, 84.8520083],
    [46.9476871, 84.8538324],
    [46.9475552, 84.8544487],
    [46.9472794, 84.8552747],
    [46.9471067, 84.8562301],
    [46.9469439, 84.858121],
    [46.9463955, 84.8586052],
    [46.945684, 84.859393],
    [46.9451131, 84.8601128],
    [46.9445432, 84.8610303],
    [46.9440668, 84.8612452],
    [46.9429723, 84.8609789],
    [46.9418669, 84.8604932],
    [46.9408595, 84.8598489],
    [46.9400768, 84.8592454],
    [46.9395389, 84.8585914],
    [46.9390989, 84.858335],
    [46.9387087, 84.8583844],
    [46.9384218, 84.8586599],
    [46.9376658, 84.8588889],
    [46.9369393, 84.8590317],
    [46.9363467, 84.8590341],
    [46.9359223, 84.8590121],
    [46.9348431, 84.8589282],
    [46.9345734, 84.8586627],
    [46.9342228, 84.8586123],
    [46.9333515, 84.858903],
    [46.9328398, 84.8589564],
    [46.9325089, 84.8587833],
    [46.9321161, 84.858725],
    [46.9318466, 84.8584249],
    [46.9314764, 84.8586473],
    [46.931214, 84.8587636],
    [46.9309809, 84.8588055],
    [46.9306841, 84.8584427],
    [46.9303967, 84.8579849],
    [46.9299461, 84.8580949],
    [46.9295236, 84.8581878],
    [46.9290915, 84.8581688],
    [46.9285889, 84.8580133],
    [46.928332, 84.8580086],
    [46.9278512, 84.8584897],
    [46.9275312, 84.8591703],
    [46.9274353, 84.8599077],
    [46.9273684, 84.8606151],
    [46.9272435, 84.8609083],
    [46.9268125, 84.8611827],
    [46.9264545, 84.8613451],
    [46.9262179, 84.8615771],
    [46.9261527, 84.8622023],
    [46.9260284, 84.8633142],
    [46.9258744, 84.8636774],
    [46.9254537, 84.8640941],
    [46.9250144, 84.8644727],
    [46.9246316, 84.8645203],
    [46.9243682, 84.8646583],
    [46.9241396, 84.8651299],
    [46.9239118, 84.8655212],
    [46.9237342, 84.8657955],
    [46.9232904, 84.8668963],
    [46.9232283, 84.8672529],
    [46.9229668, 84.8675104],
    [46.9227338, 84.867723],
    [46.9226442, 84.8681323],
    [46.9224135, 84.8683975],
    [46.9222695, 84.8688389],
    [46.9216659, 84.8689638],
    [46.9214242, 84.8691101],
    [46.9210596, 84.8696827],
    [46.9208349, 84.8699736],
    [46.9206652, 84.8701714],
    [46.9204112, 84.8702603],
    [46.9202563, 84.8703301],
    [46.9200485, 84.8701068],
    [46.9198285, 84.8700529],
    [46.9196367, 84.8701123],
    [46.9191612, 84.8703812],
    [46.9187979, 84.8706254],
    [46.9184356, 84.8709151],
    [46.9180785, 84.871217],
    [46.9176547, 84.8715356],
    [46.9173548, 84.8718371],
    [46.917112, 84.8722144],
    [46.9167771, 84.8726937],
    [46.9165346, 84.8728583],
    [46.9163503, 84.8729732],
    [46.9161982, 84.8731967],
    [46.916062, 84.8736518],
    [46.9159328, 84.8739526],
    [46.9157282, 84.8741852],
    [46.9152978, 84.8746013],
    [46.9148883, 84.8749278],
    [46.9143765, 84.8754067],
    [46.9142537, 84.8755907],
    [46.9139984, 84.8760226],
    [46.9137636, 84.876325],
    [46.9135177, 84.8764936],
    [46.913183, 84.87667],
    [46.9124079, 84.8769872],
    [46.9119721, 84.8772346],
    [46.9116029, 84.877489],
    [46.9114784, 84.8776816],
    [46.9111637, 84.8782073],
    [46.9106458, 84.8791476],
    [46.9102983, 84.879794],
    [46.9099379, 84.880503],
    [46.9097138, 84.880988],
    [46.9096327, 84.8813192],
    [46.9095098, 84.8820409],
    [46.9092192, 84.8838733],
    [46.9086983, 84.887427],
    [46.907742, 84.8881293],
    [46.907115, 84.8885533],
    [46.9063532, 84.8891618],
    [46.9062031, 84.8901076],
    [46.9060333, 84.891241],
    [46.9055747, 84.8927436],
    [46.9047327, 84.8954617],
    [46.9039558, 84.895529],
    [46.9031914, 84.8955387],
    [46.9028676, 84.8955752],
    [46.9023286, 84.8956546],
    [46.9018575, 84.8957178],
    [46.9014124, 84.8957735],
    [46.9008831, 84.8958637],
    [46.900397, 84.8977215],
    [46.9005508, 84.8994556],
    [46.8998502, 84.8998963],
    [46.8991371, 84.9024994],
    [46.899136, 84.903567],
    [46.8987732, 84.9049316],
    [46.8984707, 84.9056568],
    [46.8978238, 84.9063725],
    [46.8960181, 84.9062334],
    [46.8951089, 84.9075917],
    [46.8945388, 84.9085398],
    [46.8938914, 84.9092004],
    [46.893227, 84.910227],
    [46.891052, 84.9131195],
    [46.890312, 84.91378],
    [46.8894324, 84.9142049],
    [46.8883764, 84.9144699],
    [46.8880031, 84.9145586],
    [46.8869674, 84.914406],
    [46.8864549, 84.9142193],
    [46.8858108, 84.9139097],
    [46.8854491, 84.9138511],
    [46.8851448, 84.913833],
    [46.8847276, 84.9139458],
    [46.883837, 84.9143408],
    [46.8835723, 84.9143968],
    [46.8831624, 84.9144507],
    [46.8816539, 84.9148847],
    [46.8810226, 84.9150127],
    [46.8803736, 84.9151808],
    [46.8800309, 84.9155341],
    [46.8796331, 84.9162819],
    [46.8793276, 84.9172723],
    [46.8790244, 84.9183035],
    [46.8783499, 84.9196567],
    [46.8777699, 84.9207431],
    [46.8772161, 84.9214294],
    [46.876495, 84.9221698],
    [46.8757374, 84.9228985],
    [46.8756931, 84.9233631],
    [46.8756027, 84.9268895],
    [46.8756456, 84.9284442],
    [46.8756592, 84.9301127],
    [46.8753289, 84.9305978],
    [46.8733398, 84.9309685],
    [46.872796, 84.9312407],
    [46.8719916, 84.9319117],
    [46.8710811, 84.9328877],
    [46.8704399, 84.9339135],
    [46.8693636, 84.9355066],
    [46.8685767, 84.9365743],
    [46.8672479, 84.9380603],
    [46.8663285, 84.9389169],
    [46.865767, 84.9393313],
    [46.8650488, 84.94001],
    [46.86452, 84.9408588],
    [46.8635759, 84.9425036],
    [46.8632624, 84.9430086],
    [46.862966, 84.94386],
    [46.8628509, 84.944232],
    [46.8631269, 84.9448692],
    [46.8633878, 84.945753],
    [46.8634291, 84.9464666],
    [46.8633759, 84.946851],
    [46.8634055, 84.9478571],
    [46.8633933, 84.9481897],
    [46.8634128, 84.9488397],
    [46.8635527, 84.9494858],
    [46.8633773, 84.9499917],
    [46.8633283, 84.9510745],
    [46.8633227, 84.9519999],
    [46.8638627, 84.9528202],
    [46.8642243, 84.9535216],
    [46.8643247, 84.9545372],
    [46.8646509, 84.955633],
    [46.864935, 84.9568802],
    [46.8651377, 84.9578313],
    [46.8654832, 84.9594017],
    [46.8658866, 84.9601151],
    [46.8662053, 84.9616521],
    [46.8663738, 84.9631078],
    [46.8668559, 84.9639611],
    [46.8673038, 84.9644821],
    [46.867533, 84.9653196],
    [46.8680058, 84.9657432],
    [46.8688047, 84.965644],
    [46.8696859, 84.9643795],
    [46.8702877, 84.9636799],
    [46.8708824, 84.9636897],
    [46.871511, 84.9641151],
    [46.8722854, 84.9651276],
    [46.8726692, 84.9658387],
    [46.8728034, 84.9671737],
    [46.8726292, 84.9690179],
    [46.8726023, 84.9699559],
    [46.8726491, 84.9709196],
    [46.8725338, 84.9713445],
    [46.8721426, 84.9720215],
    [46.8720982, 84.9725066],
    [46.8722223, 84.9733462],
    [46.872359, 84.9740378],
    [46.8723395, 84.97478],
    [46.8722012, 84.9755379],
    [46.8721794, 84.9763294],
    [46.8722229, 84.9770671],
    [46.872233, 84.9779582],
    [46.8722365, 84.9782949],
    [46.8727061, 84.9787467],
    [46.8730053, 84.9790421],
    [46.8735525, 84.9795496],
    [46.8739751, 84.9796555],
    [46.874441, 84.9795502],
    [46.8748578, 84.9791158],
    [46.8755798, 84.9779395],
    [46.8763217, 84.9772917],
    [46.8770915, 84.9766779],
    [46.877482, 84.9764162],
    [46.8779179, 84.9763469],
    [46.8783347, 84.9763689],
    [46.8792223, 84.9765338],
    [46.8804636, 84.9766954],
    [46.8806827, 84.9768229],
    [46.8809529, 84.9770828],
    [46.8812848, 84.9772958],
    [46.8815418, 84.9772238],
    [46.8817366, 84.9769848],
    [46.882005, 84.9766699],
    [46.8826451, 84.9762858],
    [46.8834034, 84.9755113],
    [46.8839714, 84.974818],
    [46.8844981, 84.9741386],
    [46.8847984, 84.9739413],
    [46.8852345, 84.9739043],
    [46.8856375, 84.9738906],
    [46.8861524, 84.9737079],
    [46.8867278, 84.9733907],
    [46.8870367, 84.9733037],
    [46.8873595, 84.9733385],
    [46.8878374, 84.9735396],
    [46.8884075, 84.974011],
    [46.8891521, 84.974494],
    [46.8895812, 84.9750718],
    [46.8899475, 84.9756071],
    [46.8899948, 84.9763088],
    [46.8902023, 84.9768662],
    [46.8906655, 84.9776353],
    [46.8912573, 84.9784999],
    [46.8916294, 84.9789705],
    [46.8920164, 84.9791396],
    [46.8923305, 84.9791899],
    [46.892697, 84.9790128],
    [46.8931063, 84.9786114],
    [46.8934657, 84.9783227],
    [46.8941457, 84.9781622],
    [46.8946481, 84.9782227],
    [46.8952947, 84.9783544],
    [46.8958134, 84.9786433],
    [46.8962288, 84.9791654],
    [46.8965357, 84.9799497],
    [46.8970941, 84.9801485],
    [46.8978818, 84.9801681],
    [46.8983284, 84.9800334],
    [46.898886, 84.980125],
    [46.8993213, 84.9800697],
    [46.899738, 84.97998],
    [46.9001017, 84.9800027],
    [46.9004731, 84.980237],
    [46.9007209, 84.9801054],
    [46.9011314, 84.9796492],
    [46.9014974, 84.9793087],
    [46.9018731, 84.9793161],
    [46.902173, 84.9795099],
    [46.9028662, 84.9793224],
    [46.9033508, 84.9790856],
    [46.9036042, 84.9791368],
    [46.9041557, 84.9793972],
    [46.9045948, 84.9797304],
    [46.9049652, 84.9799169],
    [46.9053288, 84.979986],
    [46.905778, 84.9799262],
    [46.90647, 84.9798076],
    [46.9067267, 84.9798467],
    [46.9074135, 84.9799739],
    [46.9079785, 84.9799881],
    [46.9082841, 84.9800496],
    [46.9086949, 84.9804593],
    [46.9091878, 84.9809237],
    [46.9096978, 84.9811562],
    [46.9103763, 84.9812398],
    [46.9107295, 84.9812613],
    [46.9109926, 84.9813356],
    [46.9115466, 84.9815478],
    [46.9119758, 84.9818574],
    [46.9125641, 84.9823454],
    [46.913016, 84.9825921],
    [46.9134312, 84.9826004],
    [46.9137372, 84.9825786],
    [46.9140194, 84.9826708],
    [46.9144701, 84.9828224],
    [46.9148024, 84.9828586],
    [46.9151082, 84.9828792],
    [46.9152646, 84.9829871],
    [46.9156185, 84.9833544],
    [46.9157781, 84.9836481],
    [46.916018, 84.9843389],
    [46.9160233, 84.9843741],
    [46.9161881, 84.984826],
    [46.9163026, 84.9851106],
    [46.9165562, 84.9854811],
    [46.9167398, 84.9857906],
    [46.9170152, 84.9863351],
    [46.9174234, 84.9871892],
    [46.9177482, 84.9878134],
    [46.9180124, 84.9880433],
    [46.918408, 84.9882689],
    [46.9185869, 84.9884783],
    [46.9187477, 84.9888927],
    [46.9188461, 84.9896948],
    [46.9189534, 84.9903381],
    [46.9190365, 84.9907918],
    [46.9191022, 84.9910514],
    [46.919207, 84.9912138],
    [46.9198459, 84.9915776],
    [46.9199984, 84.991665],
    [46.9200889, 84.9918561],
    [46.9201885, 84.9922474],
    [46.920218, 84.9926689],
    [46.920251, 84.9930648],
    [46.9203195, 84.9933327],
    [46.9204474, 84.9936062],
    [46.9206926, 84.9945349],
    [46.9207993, 84.9948557],
    [46.9210111, 84.9954111],
    [46.9211703, 84.9959727],
    [46.9212435, 84.9965816],
    [46.9213375, 84.9973131],
    [46.9213894, 84.9978432],
    [46.9214027, 84.9985504],
    [46.9213506, 84.9991476],
    [46.9212878, 84.9995803],
    [46.9210573, 85.0000408],
    [46.9209228, 85.0003378],
    [46.9209094, 85.0004575],
    [46.9209181, 85.00057],
    [46.9211469, 85.0012638],
    [46.9213068, 85.0015105],
    [46.9214385, 85.0016591],
    [46.9217255, 85.0018054],
    [46.9219932, 85.0018996],
    [46.9221497, 85.0020733],
    [46.9223443, 85.0023293],
    [46.9225181, 85.002412],
    [46.9228121, 85.0024159],
    [46.9231232, 85.0022462],
    [46.9234788, 85.0020293],
    [46.9237078, 85.0019082],
    [46.9238487, 85.0019275],
    [46.9240825, 85.0020406],
    [46.924323, 85.0020902],
    [46.9246728, 85.0020732],
    [46.9249822, 85.0019553],
    [46.9251042, 85.0020146],
    [46.9253765, 85.0024017],
    [46.9255613, 85.0027176],
    [46.9256652, 85.0030921],
    [46.9257143, 85.0034709],
    [46.925821, 85.003884],
    [46.926101, 85.0047014],
    [46.9263004, 85.0054483],
    [46.9264562, 85.0061471],
    [46.9261223, 85.0068041],
    [46.9258774, 85.0073027],
    [46.9255352, 85.0076729],
    [46.9253471, 85.0079859],
    [46.9251896, 85.0084771],
    [46.9251633, 85.0090015],
    [46.9250227, 85.0099333],
    [46.924892, 85.0104252],
    [46.9248854, 85.0115001],
    [46.9248383, 85.0118908],
    [46.9246678, 85.0122212],
    [46.9241596, 85.0126753],
    [46.9238075, 85.0130652],
    [46.9234922, 85.0135888],
    [46.9234908, 85.0137756],
    [46.9236006, 85.0144515],
    [46.9237586, 85.0155284],
    [46.9240097, 85.01615],
    [46.924503, 85.0173291],
    [46.924554, 85.0176614],
    [46.9244886, 85.018215],
    [46.9242997, 85.0193308],
    [46.9239147, 85.0203938],
    [46.9236881, 85.0213048],
    [46.9234466, 85.0222001],
    [46.9231635, 85.0227786],
    [46.9228861, 85.0232444],
    [46.9227587, 85.0234244],
    [46.9229578, 85.0241353],
    [46.9231909, 85.0248984],
    [46.923698, 85.0259597],
    [46.9233691, 85.0278044],
    [46.9232399, 85.0280193],
    [46.923064, 85.028475],
    [46.923065, 85.0289406],
    [46.923199, 85.0300679],
    [46.9231107, 85.0307648],
    [46.9226983, 85.0311801],
    [46.9223637, 85.0318923],
    [46.9218692, 85.032943],
    [46.921729, 85.0335787],
    [46.9220768, 85.0343903],
    [46.9226287, 85.0351104],
    [46.9230651, 85.0360506],
    [46.9232285, 85.037596],
    [46.9238344, 85.0393808],
    [46.9242685, 85.0410677],
    [46.9244242, 85.041968],
    [46.9250823, 85.04352],
    [46.9252962, 85.044161],
    [46.9254455, 85.0447968],
    [46.925824, 85.0454861],
    [46.9263231, 85.0464676],
    [46.9266587, 85.0470677],
    [46.9268808, 85.0473157],
    [46.9272298, 85.0476061],
    [46.9278532, 85.0478223],
    [46.9281141, 85.0479816],
    [46.9282666, 85.0482962],
    [46.928572, 85.0492474],
    [46.9285659, 85.049498],
    [46.9284207, 85.049981],
    [46.9281442, 85.0506412],
    [46.927936, 85.051244],
    [46.9277649, 85.0521298],
    [46.9275366, 85.0526124],
    [46.9273158, 85.0531102],
    [46.9272511, 85.0534351],
    [46.9272509, 85.0539075],
    [46.9272503, 85.0547747],
    [46.92724, 85.0556447],
    [46.9272595, 85.0563581],
    [46.9273304, 85.0566762],
    [46.927558, 85.0574008],
    [46.9279763, 85.0590287],
    [46.9280584, 85.0599554],
    [46.9279457, 85.0606439],
    [46.9277565, 85.0610815],
    [46.9271139, 85.061868],
    [46.9267194, 85.0624208],
    [46.9260595, 85.0634541],
    [46.9257029, 85.0639796],
    [46.9257933, 85.0648067],
    [46.9256637, 85.0663279],
    [46.9261042, 85.0666915],
    [46.9268806, 85.0671192],
    [46.9273437, 85.0674157],
    [46.9278901, 85.0675866],
    [46.9283691, 85.0677708],
    [46.9286585, 85.0679746],
    [46.9287899, 85.0692636],
    [46.9291757, 85.0703911],
    [46.9298041, 85.0723913],
    [46.930345, 85.0734657],
    [46.9306511, 85.0739131],
    [46.9307581, 85.0742442],
    [46.9308246, 85.0745926],
    [46.931207, 85.0750744],
    [46.9311922, 85.0759917],
    [46.9310793, 85.0768002],
    [46.930953, 85.0774453],
    [46.9309091, 85.0778709],
    [46.9309561, 85.0781001],
    [46.9311845, 85.078261],
    [46.9324213, 85.0782235],
    [46.9336638, 85.0781074],
    [46.9343269, 85.0782233],
    [46.9347025, 85.0780749],
    [46.9351666, 85.077742],
    [46.9354855, 85.0776666],
    [46.9366503, 85.0776696],
    [46.9373954, 85.0775862],
    [46.937591, 85.0776323],
    [46.937831, 85.0779097],
    [46.9380965, 85.0783192],
    [46.9389824, 85.0802348],
    [46.9395691, 85.0813887],
    [46.9399927, 85.0818166],
    [46.9402595, 85.0821716],
    [46.9404773, 85.0826021],
    [46.9407183, 85.0826561],
    [46.9418274, 85.0826999],
    [46.9429195, 85.0827258],
    [46.9441195, 85.0828958],
    [46.9445443, 85.0829372],
    [46.9450295, 85.0831116],
    [46.945861, 85.0836965],
    [46.9465343, 85.0840836],
    [46.9474317, 85.0845802],
    [46.9480175, 85.0848279],
    [46.9485022, 85.0849856],
    [46.9487577, 85.085049],
    [46.9488816, 85.0851104],
    [46.9490139, 85.0851938],
    [46.9496307, 85.0852346],
    [46.950413, 85.0840366],
    [46.950819, 85.082948],
    [46.9510872, 85.0825957],
    [46.9514836, 85.0816651],
    [46.9515943, 85.0814331],
    [46.9518321, 85.0812261],
    [46.952244, 85.0810889],
    [46.9525556, 85.0809158],
    [46.9529904, 85.0808493],
    [46.9533353, 85.0807119],
    [46.9535983, 85.0805383],
    [46.9537591, 85.0803204],
    [46.9538634, 85.0800217],
    [46.9539961, 85.0796081],
    [46.9541548, 85.0793595],
    [46.9543662, 85.0791929],
    [46.9547139, 85.0790393],
    [46.9548829, 85.0789226],
    [46.9550767, 85.0786091],
    [46.9553007, 85.0784769],
    [46.9555799, 85.0784956],
    [46.955725, 85.0786604],
    [46.9559262, 85.0788633],
    [46.9563405, 85.078997],
    [46.956999, 85.0789399],
    [46.9574094, 85.0789787],
    [46.9578936, 85.0788428],
    [46.9580378, 85.0792222],
    [46.958059, 85.0800727],
    [46.9580159, 85.080581],
    [46.9580577, 85.0812314],
    [46.9580445, 85.0816802],
    [46.9579217, 85.0824331],
    [46.9578786, 85.0831495],
    [46.9579342, 85.0832742],
    [46.9581433, 85.0835726],
    [46.9583006, 85.0838822],
    [46.9585343, 85.0843287],
    [46.958666, 85.0847249],
    [46.9587143, 85.0848711],
    [46.9586821, 85.0850036],
    [46.9585534, 85.0855348],
    [46.9584541, 85.0858924],
    [46.9582367, 85.0865994],
    [46.9581391, 85.0868494],
    [46.9580457, 85.0870293],
    [46.957996, 85.0872045],
    [46.9578746, 85.0878766],
    [46.9578042, 85.0884089],
    [46.9577541, 85.0886439],
    [46.9576965, 85.0888645],
    [46.9576773, 85.0890711],
    [46.9576777, 85.0894341],
    [46.9577278, 85.0899258],
    [46.9577551, 85.0900119],
    [46.9579499, 85.0903172],
    [46.9583594, 85.0908379],
    [46.9584778, 85.0910249],
    [46.9587367, 85.0920513],
    [46.9587569, 85.0922107],
    [46.9587834, 85.0924467],
    [46.958844, 85.0925823],
    [46.9589441, 85.0927634],
    [46.9592925, 85.0931255],
    [46.959701, 85.0938136],
    [46.960051, 85.094234],
    [46.9602479, 85.094549],
    [46.9605519, 85.0949599],
    [46.9609786, 85.0951085],
    [46.961163, 85.0952642],
    [46.9615184, 85.0956819],
    [46.9616468, 85.0957805],
    [46.9617662, 85.0957881],
    [46.9619289, 85.0957414],
    [46.9621159, 85.095661],
    [46.9622406, 85.0956505],
    [46.9625304, 85.095679],
    [46.9628047, 85.0956679],
    [46.9630846, 85.0955716],
    [46.9636939, 85.0954604],
    [46.9639587, 85.0954851],
    [46.9641925, 85.0955945],
    [46.9643439, 85.0957504],
    [46.9644908, 85.0959313],
    [46.9646805, 85.0961866],
    [46.9647442, 85.0963434],
    [46.9648083, 85.0965516],
    [46.965086, 85.0970572],
    [46.9652003, 85.0971716],
    [46.9654134, 85.0973343],
    [46.965401, 85.0977524],
    [46.9653721, 85.0980091],
    [46.9652726, 85.0984251],
    [46.9652368, 85.0987043],
    [46.9651986, 85.099404],
    [46.9651407, 85.0998647],
    [46.9650776, 85.1006509],
    [46.9650991, 85.1008187],
    [46.965159, 85.1009754],
    [46.9653934, 85.1012057],
    [46.9654688, 85.1014095],
    [46.9654848, 85.1016834],
    [46.9654222, 85.1022939],
    [46.9654481, 85.1025525],
    [46.9655326, 85.1028198],
    [46.9656305, 85.102936],
    [46.9657687, 85.103029],
    [46.9660007, 85.1030985],
    [46.9661434, 85.103146],
    [46.9662315, 85.1032134],
    [46.966295, 85.1033404],
    [46.9663243, 85.1034563],
    [46.9664005, 85.1037138],
    [46.9664608, 85.1038986],
    [46.9666024, 85.104143],
    [46.96667, 85.1043478],
    [46.9667676, 85.1046123],
    [46.9669365, 85.1050158],
    [46.9670156, 85.1051721],
    [46.9671102, 85.1054992],
    [46.9673572, 85.1061671],
    [46.96742, 85.1064319],
    [46.9674271, 85.1066806],
    [46.9673917, 85.1069242],
    [46.9673139, 85.10731],
    [46.967285, 85.1077683],
    [46.9672326, 85.1080948],
    [46.967261, 85.1083267],
    [46.9673934, 85.1087445],
    [46.9674946, 85.10891],
    [46.9677406, 85.1091359],
    [46.9680139, 85.1091992],
    [46.9681129, 85.1092497],
    [46.9682115, 85.1093477],
    [46.9683084, 85.1094165],
    [46.9686034, 85.1095142],
    [46.9686589, 85.1095971],
    [46.9688513, 85.1098161],
    [46.9689556, 85.1098514],
    [46.9690675, 85.1100512],
    [46.9691844, 85.1103584],
    [46.9694307, 85.1105705],
    [46.9697773, 85.1108823],
    [46.9700739, 85.111089],
    [46.9702545, 85.1111655],
    [46.9704549, 85.1112053],
    [46.9705745, 85.1112307],
    [46.97071, 85.1113403],
    [46.9708031, 85.1115277],
    [46.9708728, 85.1117527],
    [46.9709625, 85.112317],
    [46.9711594, 85.1131232],
    [46.9711875, 85.1133885],
    [46.9711601, 85.113721],
    [46.9710924, 85.1140816],
    [46.9710088, 85.1145627],
    [46.9709686, 85.1149845],
    [46.9710376, 85.1155549],
    [46.9711405, 85.1163513],
    [46.971125, 85.1164534],
    [46.9710718, 85.1166874],
    [46.9709909, 85.1169151],
    [46.9707273, 85.1170819],
    [46.9703287, 85.1173074],
    [46.9701851, 85.1174318],
    [46.9700702, 85.1175872],
    [46.9700254, 85.1176943],
    [46.969986, 85.1178462],
    [46.9699209, 85.1182035],
    [46.9698893, 85.1183637],
    [46.9698453, 85.1185206],
    [46.9698229, 85.1186604],
    [46.9698284, 85.1187539],
    [46.9698645, 85.1189291],
    [46.969935, 85.1191217],
    [46.97002, 85.1192535],
    [46.9702721, 85.1195133],
    [46.970541, 85.1197614],
    [46.9707076, 85.1199387],
    [46.9708138, 85.1201326],
    [46.9710372, 85.120578],
    [46.9713051, 85.1209881],
    [46.9715078, 85.1212254],
    [46.9717043, 85.1213433],
    [46.9718589, 85.1214561],
    [46.9719676, 85.1216236],
    [46.9720687, 85.1219097],
    [46.9722474, 85.1222646],
    [46.9723265, 85.1225884],
    [46.9724711, 85.1230203],
    [46.9724881, 85.123039],
    [46.9727038, 85.1234433],
    [46.9729114, 85.1238876],
    [46.9730651, 85.1244125],
    [46.9732396, 85.1248167],
    [46.9734591, 85.1250949],
    [46.9737544, 85.1254335],
    [46.9744941, 85.126325],
    [46.9746536, 85.1265984],
    [46.9749633, 85.127269],
    [46.9752114, 85.1278164],
    [46.9752979, 85.1279621],
    [46.9757089, 85.1282245],
    [46.976176, 85.1282136],
    [46.9767673, 85.1280633],
    [46.9772237, 85.127866],
    [46.9775166, 85.1276812],
    [46.9778741, 85.1272215],
    [46.9779961, 85.1270408],
    [46.9781084, 85.1269523],
    [46.9782724, 85.1269482],
    [46.9792689, 85.1270575],
    [46.9795044, 85.1271271],
    [46.9796946, 85.1272896],
    [46.9800859, 85.1277001],
    [46.9805487, 85.1279935],
    [46.9808971, 85.1282286],
    [46.9810621, 85.1284232],
    [46.9811572, 85.1286217],
    [46.9812391, 85.1299097],
    [46.9812646, 85.1307336],
    [46.9813516, 85.1334035],
    [46.981373, 85.1340743],
    [46.9814494, 85.134295],
    [46.9815605, 85.1345447],
    [46.9817274, 85.1347621],
    [46.9831486, 85.1367676],
    [46.9836674, 85.1374922],
    [46.9848898, 85.1392572],
    [46.9854878, 85.1401961],
    [46.9861231, 85.1411377],
    [46.9875982, 85.1431122],
    [46.9884748, 85.1442959],
    [46.9892856, 85.1454993],
    [46.9865586, 85.1531214],
    [46.9862801, 85.1533565],
    [46.9861803, 85.1536978],
    [46.9861942, 85.154764],
    [46.9862741, 85.1553899],
    [46.9865089, 85.1564033],
    [46.9867005, 85.157189],
    [46.9868342, 85.1579898],
    [46.9868013, 85.1585569],
    [46.9868398, 85.1589361],
    [46.9869993, 85.1593301],
    [46.9870323, 85.1594903],
    [46.9870347, 85.1597026],
    [46.9869634, 85.1601845],
    [46.9869416, 85.1605152],
    [46.9867744, 85.1616784],
    [46.9866772, 85.1621677],
    [46.9866533, 85.1625187],
    [46.9867011, 85.1632458],
    [46.9867556, 85.1636266],
    [46.9867421, 85.1638228],
    [46.9866237, 85.1644618],
    [46.9865581, 85.1654205],
    [46.9864777, 85.1662271],
    [46.986551, 85.1668413],
    [46.9870279, 85.1673939],
    [46.987345, 85.1676974],
    [46.9875815, 85.168156],
    [46.9877734, 85.1682622],
    [46.9878129, 85.1685505],
    [46.9878444, 85.1687809],
    [46.988209, 85.1687879],
    [46.9883564, 85.168816],
    [46.9886182, 85.1689195],
    [46.9889454, 85.1694351],
    [46.9891484, 85.1697384],
    [46.989327, 85.1700102],
    [46.9894794, 85.1703938],
    [46.9896929, 85.1708144],
    [46.9897887, 85.1709997],
    [46.9900105, 85.1714575],
    [46.9903199, 85.1722943],
    [46.9904584, 85.1728211],
    [46.9906665, 85.1733352],
    [46.9908545, 85.1736784],
    [46.9913746, 85.1743775],
    [46.9914577, 85.1745218],
    [46.9916737, 85.1749844],
    [46.9918993, 85.1754005],
    [46.9921661, 85.1759709],
    [46.9923816, 85.1761726],
    [46.9926794, 85.1763804],
    [46.9929524, 85.1765517],
    [46.9933981, 85.1766522],
    [46.9935396, 85.1768257],
    [46.9936109, 85.1770252],
    [46.9936841, 85.1773166],
    [46.9937776, 85.1775231],
    [46.9938962, 85.1776483],
    [46.9942445, 85.1778815],
    [46.9946411, 85.178003],
    [46.9948745, 85.1780008],
    [46.9950471, 85.1780232],
    [46.9953723, 85.1781486],
    [46.9957904, 85.1784092],
    [46.9961949, 85.1785572],
    [46.9964611, 85.1786167],
    [46.9967371, 85.1786117],
    [46.9971668, 85.1785461],
    [46.9977407, 85.1784272],
    [46.9981491, 85.1782466],
    [46.9985594, 85.1778545],
    [46.998744, 85.1777233],
    [46.9990255, 85.1775359],
    [46.9992419, 85.1772976],
    [46.9993687, 85.1770698],
    [47.0000723, 85.1765517],
    [47.0005453, 85.1760623],
    [47.0008475, 85.175853],
    [47.0021471, 85.1756054],
    [47.0034542, 85.175607],
    [47.0037115, 85.1758553],
    [47.0039727, 85.1762275],
    [47.0042714, 85.1769479],
    [47.0043308, 85.1772843],
    [47.0043205, 85.1776435],
    [47.0042597, 85.178361],
    [47.0040996, 85.1791246],
    [47.004002, 85.1799382],
    [47.0039525, 85.1806062],
    [47.0041695, 85.1820342],
    [47.0049091, 85.1834671],
    [47.0055311, 85.1847512],
    [47.0061291, 85.1861937],
    [47.0064497, 85.1870272],
    [47.0066844, 85.1877866],
    [47.0072102, 85.1879117],
    [47.0077582, 85.187938],
    [47.0083119, 85.1880812],
    [47.0086765, 85.1882479],
    [47.0089444, 85.188454],
    [47.0090525, 85.1884933],
    [47.0094661, 85.1884795],
    [47.0097396, 85.1884595],
    [47.0098365, 85.1884327],
    [47.0099342, 85.1883901],
    [47.0100394, 85.1883906],
    [47.0101114, 85.1884154],
    [47.010091, 85.1888497],
    [47.0101458, 85.1896309],
    [47.0102173, 85.1904115],
    [47.01048, 85.1910447],
    [47.0106154, 85.1914124],
    [47.0107543, 85.1916598],
    [47.0109573, 85.192012],
    [47.0112243, 85.1924385],
    [47.0113749, 85.1926287],
    [47.0115337, 85.1927683],
    [47.0116619, 85.192896],
    [47.0117867, 85.1930651],
    [47.0118846, 85.1932101],
    [47.0119208, 85.1932513],
    [47.0120067, 85.1933915],
    [47.0121109, 85.1934935],
    [47.0122277, 85.1935938],
    [47.0123321, 85.1937013],
    [47.0124436, 85.1938443],
    [47.0125702, 85.1940254],
    [47.0126442, 85.194095],
    [47.0127957, 85.1941559],
    [47.0129669, 85.1942268],
    [47.0130538, 85.1942768],
    [47.0132114, 85.1943971],
    [47.0133356, 85.1944708],
    [47.013534, 85.194576],
    [47.0136403, 85.1946028],
    [47.0137464, 85.1946033],
    [47.0139454, 85.1946058],
    [47.0141033, 85.1945609],
    [47.0142899, 85.1944899],
    [47.0144867, 85.1944025],
    [47.0147829, 85.1942434],
    [47.0150951, 85.1940799],
    [47.0154697, 85.1939285],
    [47.0157319, 85.1938043],
    [47.0159438, 85.1937384],
    [47.0160919, 85.1937254],
    [47.0162433, 85.193897],
    [47.0163752, 85.1939615],
    [47.0165127, 85.1940407],
    [47.016649, 85.1941413],
    [47.0168457, 85.1943067],
    [47.017093, 85.1945162],
    [47.017417, 85.1948176],
    [47.0176591, 85.1949954],
    [47.017883, 85.1951686],
    [47.0181278, 85.1953997],
    [47.0183632, 85.1956905],
    [47.0185895, 85.1959671],
    [47.0187723, 85.1961252],
    [47.0189456, 85.1962228],
    [47.0193839, 85.1965206],
    [47.0195443, 85.1966848],
    [47.0196638, 85.1967697],
    [47.0198598, 85.196924],
    [47.020002, 85.1970798],
    [47.0201734, 85.197267],
    [47.0203216, 85.1973885],
    [47.020474, 85.1974441],
    [47.0206084, 85.1974763],
    [47.0206592, 85.1976696],
    [47.0206853, 85.1979429],
    [47.020705, 85.1981921],
    [47.0207302, 85.1984125],
    [47.0208546, 85.1985573],
    [47.0209929, 85.1986644],
    [47.0211987, 85.1988404],
    [47.0212753, 85.1989406],
    [47.0214663, 85.1991003],
    [47.0215559, 85.1991771],
    [47.0216072, 85.1993205],
    [47.0217012, 85.1994116],
    [47.0219964, 85.1995036],
    [47.0222266, 85.1995306],
    [47.0225378, 85.1995552],
    [47.0228891, 85.1995441],
    [47.0230525, 85.1996001],
    [47.0236859, 85.1997662],
    [47.0241453, 85.199924],
    [47.0244885, 85.2000641],
    [47.0245822, 85.2001451],
    [47.0250457, 85.2004511],
    [47.0255006, 85.2007513],
    [47.0259131, 85.2009913],
    [47.0263192, 85.2011964],
    [47.0266831, 85.2014998],
    [47.0270072, 85.201887],
    [47.0274186, 85.2025399],
    [47.0277281, 85.2030256],
    [47.0279427, 85.2032857],
    [47.0282185, 85.2035272],
    [47.0284278, 85.2036299],
    [47.0287475, 85.2036953],
    [47.0290693, 85.2037457],
    [47.0293577, 85.203793],
    [47.029608, 85.2038384],
    [47.0299575, 85.2038871],
    [47.0302183, 85.2039229],
    [47.0305025, 85.2040017],
    [47.0308602, 85.2041229],
    [47.0313614, 85.2043049],
    [47.0316443, 85.2044057],
    [47.0319372, 85.2045179],
    [47.0320622, 85.2045135],
    [47.0322147, 85.2044931],
    [47.0323668, 85.2045295],
    [47.0325125, 85.2046518],
    [47.0326456, 85.2048162],
    [47.0329003, 85.2050064],
    [47.0331357, 85.2052226],
    [47.0333761, 85.2054855],
    [47.0336104, 85.205745],
    [47.0337423, 85.2059178],
    [47.0338756, 85.2061948],
    [47.0340025, 85.2064278],
    [47.0341948, 85.2066972],
    [47.0343323, 85.2068636],
    [47.0345029, 85.2071655],
    [47.0347765, 85.2076369],
    [47.0348622, 85.2078694],
    [47.0350497, 85.2082073],
    [47.0352533, 85.2085975],
    [47.0354824, 85.2089614],
    [47.0357924, 85.2092887],
    [47.0359945, 85.2093896],
    [47.0362454, 85.2094664],
    [47.0364937, 85.2095553],
    [47.0367192, 85.2097153],
    [47.0369628, 85.2099805],
    [47.0375001, 85.2102568],
    [47.0378647, 85.2105154],
    [47.0380589, 85.210699],
    [47.0383262, 85.2110408],
    [47.0385159, 85.2112347],
    [47.038779, 85.2114616],
    [47.0389353, 85.2115588],
    [47.0390856, 85.2116152],
    [47.0391956, 85.2117002],
    [47.039275, 85.2117828],
    [47.0393796, 85.2118161],
    [47.0394627, 85.2118142],
    [47.0395969, 85.2117942],
    [47.0398117, 85.21168],
    [47.040081, 85.2115413],
    [47.040243, 85.211478],
    [47.040457, 85.211386],
    [47.040642, 85.2112639],
    [47.040868, 85.2112886],
    [47.0410168, 85.2113053],
    [47.0418992, 85.2111045],
    [47.0430181, 85.2106839],
    [47.0433069, 85.2106327],
    [47.0434806, 85.2105702],
    [47.0438525, 85.2105731],
    [47.044153, 85.2106767],
    [47.0445116, 85.2109332],
    [47.0448213, 85.2111803],
    [47.0451424, 85.2114549],
    [47.0455492, 85.2118078],
    [47.0459938, 85.2122246],
    [47.0464109, 85.2126387],
    [47.046864, 85.213079],
    [47.0471984, 85.213432],
    [47.0473942, 85.2135665],
    [47.0477244, 85.213664],
    [47.0483503, 85.2137837],
    [47.0484904, 85.2138497],
    [47.0487039, 85.2140081],
    [47.0490531, 85.2141056],
    [47.0494444, 85.2141265],
    [47.0500539, 85.2141259],
    [47.0505712, 85.2141959],
    [47.0510675, 85.2144819],
    [47.0513747, 85.2147665],
    [47.0518181, 85.2154916],
    [47.0521179, 85.2161213],
    [47.0523679, 85.2168977],
    [47.0525284, 85.217727],
    [47.0526861, 85.2187718],
    [47.0528136, 85.2200198],
    [47.0527397, 85.2208828],
    [47.0525488, 85.2216683],
    [47.0524615, 85.2222767],
    [47.0522895, 85.22363],
    [47.0521698, 85.2242934],
    [47.0519742, 85.2247943],
    [47.0516225, 85.2250743],
    [47.0513709, 85.2250725],
    [47.0510875, 85.2249527],
    [47.0507221, 85.2250559],
    [47.0502763, 85.2251098],
    [47.050033, 85.2251324],
    [47.0498119, 85.2255656],
    [47.0496748, 85.2258622],
    [47.0495812, 85.2260693],
    [47.0496421, 85.2266417],
    [47.0499078, 85.2276321],
    [47.0499834, 85.2282582],
    [47.0499267, 85.2290991],
    [47.0496963, 85.230227],
    [47.0495637, 85.2316086],
    [47.0495324, 85.2323476],
    [47.0492313, 85.2333135],
    [47.0492259, 85.2338099],
    [47.0493284, 85.2340775],
    [47.0494739, 85.2345337],
    [47.0495455, 85.2351027],
    [47.0496962, 85.2354837],
    [47.0498199, 85.2355972],
    [47.0502201, 85.2357668],
    [47.0502839, 85.2359368],
    [47.0503463, 85.2361839],
    [47.0504631, 85.2366029],
    [47.0505029, 85.2369399],
    [47.0505533, 85.2371726],
    [47.0505816, 85.2374921],
    [47.0507233, 85.2376937],
    [47.0510847, 85.2380248],
    [47.0514107, 85.2384102],
    [47.0516377, 85.2387537],
    [47.0519985, 85.2390912],
    [47.0521838, 85.2393097],
    [47.0523998, 85.2399727],
    [47.052883, 85.2402956],
    [47.0533337, 85.2403713],
    [47.0535752, 85.240637],
    [47.0537355, 85.240839],
    [47.0531889, 85.2434108],
    [47.0532266, 85.2443069],
    [47.0530905, 85.2449873],
    [47.0528779, 85.2456762],
    [47.0527152, 85.2463378],
    [47.0525567, 85.2473797],
    [47.0524825, 85.2481856],
    [47.0526118, 85.2499518],
    [47.0528751, 85.2512222],
    [47.0529014, 85.2519919],
    [47.0529377, 85.2525857],
    [47.0530664, 85.2534699],
    [47.0530468, 85.2538153],
    [47.0529558, 85.2541049],
    [47.0522442, 85.2550866],
    [47.0521109, 85.2555178],
    [47.0515303, 85.2567297],
    [47.0513148, 85.2578002],
    [47.0512376, 85.2587437],
    [47.0509411, 85.2596241],
    [47.0502776, 85.2607801],
    [47.0498754, 85.2614626],
    [47.0501205, 85.2619018],
    [47.0505868, 85.262918],
    [47.0509746, 85.264417],
    [47.0514614, 85.2657524],
    [47.053058, 85.2674799],
    [47.0535292, 85.267787],
    [47.054129, 85.2681788],
    [47.0545859, 85.2701839],
    [47.0548684, 85.2715943],
    [47.054891, 85.2724391],
    [47.0546548, 85.2734328],
    [47.0551334, 85.2736512],
    [47.0555303, 85.2739329],
    [47.0563955, 85.2748211],
    [47.0572321, 85.2753897],
    [47.0579132, 85.2748347],
    [47.058427, 85.2747951],
    [47.0595243, 85.275002],
    [47.0600009, 85.2743842],
    [47.0611313, 85.2737464],
    [47.0623702, 85.2731308],
    [47.063949, 85.2723965],
    [47.064985, 85.2726604],
    [47.066295, 85.2728596],
    [47.0668936, 85.2735814],
    [47.0676605, 85.2742811],
    [47.068053, 85.2749407],
    [47.0684733, 85.2758031],
    [47.0687742, 85.2763604],
    [47.0685718, 85.2767468],
    [47.0684164, 85.2770875],
    [47.0674027, 85.2767685],
    [47.0666647, 85.2769814],
    [47.0657525, 85.2773309],
    [47.0652074, 85.2778583],
    [47.0647563, 85.278575],
    [47.0642727, 85.2798254],
    [47.0637495, 85.2808043],
    [47.0634736, 85.2823351],
    [47.0640209, 85.2831556],
    [47.0646386, 85.2840192],
    [47.0643831, 85.28521],
    [47.0640158, 85.285831],
    [47.0636296, 85.2874157],
    [47.0634983, 85.2881386],
    [47.0631008, 85.2889042],
    [47.0630384, 85.289612],
    [47.0633177, 85.2908272],
    [47.0633043, 85.2923484],
    [47.0637193, 85.2934101],
    [47.0639237, 85.2939305],
    [47.0641224, 85.2948341],
    [47.0643261, 85.2964625],
    [47.0643529, 85.2966962],
    [47.064366, 85.2967544],
    [47.064634, 85.2976686],
    [47.0648232, 85.2986894],
    [47.0649837, 85.2993583],
    [47.06432, 85.2994636],
    [47.0631246, 85.2998376],
    [47.0625131, 85.3001525],
    [47.0615538, 85.3004797],
    [47.0607269, 85.3008356],
    [47.0598118, 85.3012442],
    [47.0591828, 85.3016272],
    [47.0586615, 85.3022247],
    [47.0582689, 85.3027515],
    [47.0575713, 85.3036304],
    [47.0572802, 85.3043118],
    [47.0568774, 85.3049017],
    [47.0564844, 85.3045627],
    [47.0563613, 85.304247],
    [47.05624, 85.3040996],
    [47.0558897, 85.304084],
    [47.0550373, 85.3042432],
    [47.0548272, 85.304473],
    [47.0543045, 85.3046651],
    [47.053577, 85.3050864],
    [47.0528227, 85.3054977],
    [47.0524078, 85.3060156],
    [47.0517734, 85.3060538],
    [47.0515872, 85.30636],
    [47.0514622, 85.3064522],
    [47.0513651, 85.3066844],
    [47.0508575, 85.3069613],
    [47.0504085, 85.3077314],
    [47.050337, 85.3079449],
    [47.0499517, 85.3083306],
    [47.0498447, 85.3087066],
    [47.0496495, 85.3091402],
    [47.0495759, 85.3097614],
    [47.0493741, 85.3105462],
    [47.049325, 85.3111277],
    [47.0491512, 85.3117074],
    [47.049189, 85.3119734],
    [47.0491457, 85.3122486],
    [47.0490794, 85.3124671],
    [47.0489194, 85.3127543],
    [47.0487103, 85.3128884],
    [47.0484777, 85.3130235],
    [47.0480393, 85.3136279],
    [47.0475947, 85.3138794],
    [47.047472, 85.3142273],
    [47.0474124, 85.3143522],
    [47.0473849, 85.3144458],
    [47.0471478, 85.3143519],
    [47.0470012, 85.3143598],
    [47.0468609, 85.3145345],
    [47.0467859, 85.3149504],
    [47.0467069, 85.3152186],
    [47.0464705, 85.3155036],
    [47.0462683, 85.3159447],
    [47.0459555, 85.3164625],
    [47.0459297, 85.3165045],
    [47.0457141, 85.3169244],
    [47.045398, 85.3173807],
    [47.0452573, 85.3177062],
    [47.0450832, 85.3180282],
    [47.0448933, 85.3182807],
    [47.0448183, 85.3184668],
    [47.044577, 85.318742],
    [47.0442341, 85.3196065],
    [47.0441722, 85.3198574],
    [47.043996, 85.3202125],
    [47.0438537, 85.3206935],
    [47.0435638, 85.3211876],
    [47.0434816, 85.3216652],
    [47.0431607, 85.3228985],
    [47.0430469, 85.3230947],
    [47.0429462, 85.3234335],
    [47.0429154, 85.3235233],
    [47.0429413, 85.3238375],
    [47.0431287, 85.324083],
    [47.0434095, 85.3247908],
    [47.0436937, 85.3255292],
    [47.0437889, 85.3260704],
    [47.0439228, 85.3263522],
    [47.0440514, 85.3265607],
    [47.0441938, 85.3269711],
    [47.0443977, 85.3273286],
    [47.0445327, 85.3274397],
    [47.0446338, 85.3275976],
    [47.0447702, 85.3277894],
    [47.045196, 85.3284755],
    [47.0453404, 85.3287715],
    [47.0455164, 85.3289475],
    [47.0457423, 85.329086],
    [47.0459568, 85.3291576],
    [47.0464514, 85.3296039],
    [47.0465895, 85.3299034],
    [47.0468542, 85.3301974],
    [47.0469079, 85.33023],
    [47.0470138, 85.330926],
    [47.0473719, 85.331593],
    [47.0473344, 85.3319911],
    [47.0470881, 85.3339228],
    [47.0469966, 85.3347063],
    [47.0470027, 85.335207],
    [47.0469304, 85.3362004],
    [47.0472572, 85.3374212],
    [47.047569, 85.3382256],
    [47.0478085, 85.3388442],
    [47.0479642, 85.3392875],
    [47.0480943, 85.3395604],
    [47.0483419, 85.3395389],
    [47.0485674, 85.3395303],
    [47.0488035, 85.3395204],
    [47.0491215, 85.3396638],
    [47.0493884, 85.3398619],
    [47.049735, 85.3398432],
    [47.050727, 85.3403688],
    [47.0509687, 85.3404086],
    [47.0512189, 85.3405146],
    [47.0512837, 85.3405947],
    [47.0516635, 85.3407558],
    [47.0517901, 85.3409433],
    [47.0520527, 85.3410081],
    [47.0521296, 85.3410894],
    [47.0522828, 85.3411167],
    [47.0525037, 85.3411963],
    [47.0527838, 85.3412473],
    [47.052898, 85.3413501],
    [47.0530311, 85.3414871],
    [47.0535282, 85.3414631],
    [47.0540841, 85.3418672],
    [47.0541712, 85.3428425],
    [47.0542455, 85.3440747],
    [47.0542467, 85.3443972],
    [47.054327, 85.344688],
    [47.0544991, 85.3449431],
    [47.0546732, 85.3452963],
    [47.0548428, 85.3457082],
    [47.0548566, 85.3461071],
    [47.0548602, 85.3464144],
    [47.054794, 85.3465679],
    [47.0546536, 85.3467781],
    [47.0546169, 85.347064],
    [47.0545091, 85.347313],
    [47.0543598, 85.3474354],
    [47.0542126, 85.3477845],
    [47.0540902, 85.3480668],
    [47.0540559, 85.3483434],
    [47.0539313, 85.3485168],
    [47.0538695, 85.3486597],
    [47.0538593, 85.3489],
    [47.053721, 85.3490061],
    [47.0535169, 85.3490608],
    [47.0532705, 85.3491499],
    [47.0530326, 85.3496409],
    [47.0528802, 85.3501071],
    [47.0527878, 85.3501149],
    [47.0527996, 85.3502676],
    [47.0525665, 85.3504741],
    [47.0524165, 85.3506284],
    [47.0523275, 85.3509119],
    [47.0522923, 85.35146],
    [47.0521624, 85.3521407],
    [47.0522643, 85.3536189],
    [47.0521469, 85.354851],
    [47.0519733, 85.355829],
    [47.0519921, 85.3567167],
    [47.0524722, 85.3592047],
    [47.0524867, 85.3596323],
    [47.0527555, 85.3610714],
    [47.0528882, 85.3620326],
    [47.0528504, 85.3627874],
    [47.0527268, 85.3642397],
    [47.0526433, 85.3649405],
    [47.0526192, 85.3656223],
    [47.0524865, 85.3660666],
    [47.0524407, 85.3664567],
    [47.0522865, 85.3670429],
    [47.0520174, 85.3678262],
    [47.0517805, 85.3684794],
    [47.0515863, 85.3689481],
    [47.0512404, 85.3696314],
    [47.0510573, 85.3700824],
    [47.0508673, 85.3704355],
    [47.0506171, 85.3709448],
    [47.0504088, 85.3716516],
    [47.0502537, 85.3718274],
    [47.0501914, 85.3723108],
    [47.0498142, 85.3728727],
    [47.0492514, 85.3733158],
    [47.0484518, 85.3745712],
    [47.0474152, 85.3759103],
    [47.0470287, 85.3772905],
    [47.0470057, 85.3780068],
    [47.0466695, 85.3796764],
    [47.0466908, 85.3801431],
    [47.0464409, 85.3812113],
    [47.0462617, 85.3819379],
    [47.0460355, 85.3824177],
    [47.0461896, 85.3829835],
    [47.0463141, 85.3841759],
    [47.0465637, 85.384676],
    [47.0469827, 85.3854497],
    [47.0474846, 85.386085],
    [47.047721, 85.3868179],
    [47.0477644, 85.3869825],
    [47.0483424, 85.3888496],
    [47.0485689, 85.3906483],
    [47.0486661, 85.3917425],
    [47.048584, 85.3923477],
    [47.0484214, 85.3928542],
    [47.0482787, 85.3932766],
    [47.0482158, 85.3937082],
    [47.0482558, 85.3942704],
    [47.0484921, 85.3948748],
    [47.04911, 85.3962297],
    [47.0493423, 85.3970017],
    [47.0495164, 85.397917],
    [47.0495677, 85.3986722],
    [47.0498826, 85.3997988],
    [47.0502016, 85.4010549],
    [47.0504373, 85.4015414],
    [47.0505492, 85.40195],
    [47.0510124, 85.4026973],
    [47.0513176, 85.4031119],
    [47.0517359, 85.4039408],
    [47.0518587, 85.4042288],
    [47.0518376, 85.404413],
    [47.0518097, 85.404809],
    [47.0517735, 85.4050836],
    [47.0516371, 85.40539],
    [47.0516737, 85.4059322],
    [47.0520356, 85.4064408],
    [47.052375, 85.406724],
    [47.0528574, 85.4073831],
    [47.0531926, 85.4079143],
    [47.0540775, 85.4083428],
    [47.0550911, 85.4086324],
    [47.0559346, 85.4091631],
    [47.0570244, 85.4097073],
    [47.0581135, 85.4110302],
    [47.0586077, 85.4114117],
    [47.0589243, 85.4120353],
    [47.0592003, 85.412178],
    [47.0598086, 85.4124203],
    [47.0599526, 85.4125171],
    [47.0598848, 85.4129727],
    [47.0602751, 85.4143703],
    [47.0605462, 85.4160371],
    [47.0604792, 85.4176188],
    [47.0603826, 85.4197862],
    [47.0604057, 85.4212792],
    [47.0604868, 85.4223715],
    [47.0606247, 85.4240605],
    [47.060194, 85.4244203],
    [47.0595089, 85.4252797],
    [47.0590715, 85.4255999],
    [47.0578784, 85.4265195],
    [47.0573106, 85.4273754],
    [47.0572672, 85.428723],
    [47.057211, 85.430099],
    [47.0575718, 85.4326746],
    [47.0584068, 85.4344147],
    [47.0591566, 85.4360529],
    [47.0594756, 85.4362708],
    [47.0599878, 85.4371399],
    [47.0605062, 85.4383196],
    [47.060572, 85.4406284],
    [47.0605474, 85.4431112],
    [47.0607254, 85.4443244],
    [47.0604166, 85.445269],
    [47.0602198, 85.4472467],
    [47.0601542, 85.4509015],
    [47.0603675, 85.4533527],
    [47.0608022, 85.4548269],
    [47.0617447, 85.4566749],
    [47.0624033, 85.4580642],
    [47.063487, 85.4595909],
    [47.0639884, 85.4603513],
    [47.0639603, 85.4614795],
    [47.0636669, 85.4624883],
    [47.0635169, 85.4627322],
    [47.0631042, 85.4628261],
    [47.0626133, 85.4628947],
    [47.0619005, 85.4633712],
    [47.0618653, 85.463807],
    [47.0607559, 85.4651948],
    [47.0599832, 85.4662839],
    [47.0598624, 85.4675788],
    [47.0589687, 85.4703868],
    [47.0583908, 85.472137],
    [47.0582986, 85.472668],
    [47.0577562, 85.4739213],
    [47.0573377, 85.4750374],
    [47.0566483, 85.4763287],
    [47.0561548, 85.4770704],
    [47.0554184, 85.478206],
    [47.0547797, 85.4789932],
    [47.0542094, 85.4792528],
    [47.05383, 85.4794196],
    [47.0535689, 85.4799477],
    [47.0533679, 85.4802334],
    [47.0531249, 85.481099],
    [47.0531086, 85.4824728],
    [47.0525324, 85.485282],
    [47.0526246, 85.4880105],
    [47.0529688, 85.4895835],
    [47.0529326, 85.4903294],
    [47.0532145, 85.4910619],
    [47.0533647, 85.4921797],
    [47.0532632, 85.4931037],
    [47.0530519, 85.4945481],
    [47.0529579, 85.4952869],
    [47.0529521, 85.4959614],
    [47.0533019, 85.4969935],
    [47.0538178, 85.4977074],
    [47.0543724, 85.4987644],
    [47.0552966, 85.5002112],
    [47.0553798, 85.5009666],
    [47.0555733, 85.5012537],
    [47.0558489, 85.5021785],
    [47.0561854, 85.5030771],
    [47.0562499, 85.5043651],
    [47.0562482, 85.5052592],
    [47.0561328, 85.5061824],
    [47.0559419, 85.5070973],
    [47.0557588, 85.5086663],
    [47.0555414, 85.5103183],
    [47.0554343, 85.510918],
    [47.055122, 85.5115141],
    [47.0548692, 85.5126989],
    [47.0547421, 85.5133126],
    [47.054252, 85.5142691],
    [47.0545342, 85.5164742],
    [47.0541623, 85.5171683],
    [47.0537771, 85.5184155],
    [47.053415, 85.5206409],
    [47.0530232, 85.5215899],
    [47.0525093, 85.5241889],
    [47.051582, 85.525356],
    [47.0514048, 85.5260467],
    [47.0513762, 85.5271342],
    [47.0516384, 85.5287214],
    [47.0516429, 85.529359],
    [47.0512864, 85.5297844],
    [47.0506909, 85.5302723],
    [47.0502161, 85.53059],
    [47.0497571, 85.5312671],
    [47.0493963, 85.5316172],
    [47.0490779, 85.5319474],
    [47.0489806, 85.5336059],
    [47.0488716, 85.5339395],
    [47.0489345, 85.535614],
    [47.0488632, 85.536445],
    [47.0485643, 85.5376029],
    [47.0487085, 85.5379511],
    [47.0494809, 85.5386203],
    [47.0498702, 85.5394198],
    [47.0510574, 85.5405135],
    [47.0520728, 85.5426092],
    [47.0526975, 85.5449347],
    [47.0534082, 85.5469926],
    [47.0528385, 85.5486668],
    [47.0520491, 85.5505087],
    [47.0519228, 85.5515314],
    [47.0513345, 85.5519923],
    [47.0503969, 85.5526496],
    [47.050288, 85.5534687],
    [47.0504135, 85.5547855],
    [47.0504224, 85.559008],
    [47.0599137, 85.5608335],
    [47.0631978, 85.5623276],
    [47.065633, 85.5652443],
    [47.071628, 85.5669314],
    [47.0765134, 85.569433],
    [47.0801732, 85.5714179],
    [47.0826225, 85.572106],
    [47.0855932, 85.5741017],
    [47.0860007, 85.5739848],
    [47.0872487, 85.5741669],
    [47.0880275, 85.5745393],
    [47.0892855, 85.5759893],
    [47.0907106, 85.5763632],
    [47.0927133, 85.5764825],
    [47.095082, 85.5776112],
    [47.0965914, 85.5771776],
    [47.0981532, 85.5765963],
    [47.099895, 85.5768506],
    [47.1013321, 85.5771921],
    [47.1017722, 85.5769057],
    [47.1020766, 85.5770972],
    [47.1031437, 85.5778225],
    [47.1042147, 85.5777773],
    [47.1074413, 85.5785916],
    [47.1088116, 85.5786599],
    [47.1096256, 85.57851],
    [47.1098913, 85.5786845],
    [47.1103545, 85.5791038],
    [47.1107141, 85.5793041],
    [47.1111621, 85.579431],
    [47.1115298, 85.5797227],
    [47.1117668, 85.5802336],
    [47.1118741, 85.5807066],
    [47.1121361, 85.5812111],
    [47.1126836, 85.5820395],
    [47.112751, 85.5829134],
    [47.1123948, 85.5836396],
    [47.1125317, 85.5847692],
    [47.1127787, 85.5854025],
    [47.1132049, 85.5858518],
    [47.1138488, 85.5863138],
    [47.1142029, 85.5862704],
    [47.1145732, 85.585838],
    [47.114767, 85.5855841],
    [47.1150849, 85.5854297],
    [47.1156391, 85.5853538],
    [47.1160392, 85.5849853],
    [47.1167022, 85.584835],
    [47.1172175, 85.5849642],
    [47.1178313, 85.5848572],
    [47.1181096, 85.5844796],
    [47.1182037, 85.5841539],
    [47.1182053, 85.5838807],
    [47.1183888, 85.5838303],
    [47.1185687, 85.5839169],
    [47.1185748, 85.5836139],
    [47.1187955, 85.5833734],
    [47.1190047, 85.5832328],
    [47.119293, 85.5830266],
    [47.1196323, 85.5830381],
    [47.1198751, 85.5827406],
    [47.1199168, 85.5822102],
    [47.1201616, 85.5809303],
    [47.1200331, 85.5801498],
    [47.1207921, 85.5799662],
    [47.121257, 85.5794838],
    [47.1219707, 85.578446],
    [47.1229131, 85.577044],
    [47.1232318, 85.5751873],
    [47.1235985, 85.5746733],
    [47.1242535, 85.5745628],
    [47.1247232, 85.5748903],
    [47.1251958, 85.5747946],
    [47.1258302, 85.5743389],
    [47.1270462, 85.5750118],
    [47.1277498, 85.5755608],
    [47.1284195, 85.5758858],
    [47.1290281, 85.5759093],
    [47.1298707, 85.5765143],
    [47.1306868, 85.5763573],
    [47.1316287, 85.5756448],
    [47.1326797, 85.5760728],
    [47.1331315, 85.5759729],
    [47.134101, 85.5762598],
    [47.1344818, 85.5768044],
    [47.1351229, 85.5770619],
    [47.1356686, 85.5771255],
    [47.1366253, 85.5762945],
    [47.1370279, 85.5762801],
    [47.1373977, 85.576507],
    [47.137718, 85.5766557],
    [47.1380927, 85.5774206],
    [47.1388466, 85.5782265],
    [47.1391851, 85.578929],
    [47.1398951, 85.5791279],
    [47.1407306, 85.5787285],
    [47.1416344, 85.5795851],
    [47.1428029, 85.5813793],
    [47.1430766, 85.5821891],
    [47.1439285, 85.5833512],
    [47.1446864, 85.5857071],
    [47.1450447, 85.5859608],
    [47.1455578, 85.586639],
    [47.1463175, 85.5872274],
    [47.1469544, 85.5872558],
    [47.1471527, 85.5875863],
    [47.1469269, 85.5880562],
    [47.1469015, 85.5884032],
    [47.147428, 85.5890559],
    [47.1476329, 85.589574],
    [47.1475556, 85.5901279],
    [47.1479177, 85.5906134],
    [47.1485427, 85.5911102],
    [47.1485822, 85.5912157],
    [47.1490343, 85.5911998],
    [47.1496466, 85.5918992],
    [47.1499966, 85.5922268],
    [47.1507859, 85.5926303],
    [47.151416, 85.5928381],
    [47.1517294, 85.5931558],
    [47.151942, 85.5939644],
    [47.1524269, 85.5953954],
    [47.1531014, 85.5963233],
    [47.1534048, 85.597336],
    [47.1539282, 85.5981597],
    [47.1544051, 85.5992884],
    [47.1550425, 85.6003526],
    [47.1555648, 85.6005864],
    [47.1561363, 85.6012654],
    [47.1567295, 85.602167],
    [47.1571784, 85.603289],
    [47.1573894, 85.6041057],
    [47.1580117, 85.6050322],
    [47.1582272, 85.6055951],
    [47.1586566, 85.6065758],
    [47.1590716, 85.6071223],
    [47.1592726, 85.6078868],
    [47.159371, 85.6084006],
    [47.1596191, 85.6088448],
    [47.1598846, 85.6090458],
    [47.1600172, 85.6093174],
    [47.1602217, 85.609641],
    [47.1603105, 85.6097892],
    [47.1604865, 85.6101954],
    [47.1608612, 85.6103715],
    [47.1612567, 85.6107515],
    [47.1615538, 85.6112481],
    [47.1618379, 85.6116032],
    [47.1623867, 85.6117215],
    [47.1625816, 85.6116613],
    [47.1627551, 85.6119555],
    [47.1630173, 85.611975],
    [47.1631774, 85.612235],
    [47.1634463, 85.6123792],
    [47.163591, 85.6125983],
    [47.1639403, 85.6126406],
    [47.1642781, 85.6125636],
    [47.1649501, 85.6128275],
    [47.1650972, 85.6131886],
    [47.1655723, 85.6132731],
    [47.1657621, 85.6135154],
    [47.1659955, 85.6140165],
    [47.1659407, 85.6145027],
    [47.1662341, 85.6148842],
    [47.1664232, 85.6153448],
    [47.166694, 85.6160496],
    [47.1670092, 85.6160089],
    [47.1672402, 85.616594],
    [47.1672794, 85.6172352],
    [47.1674023, 85.6176588],
    [47.1676094, 85.6179012],
    [47.1676783, 85.6182458],
    [47.1682739, 85.6184335],
    [47.1685643, 85.618823],
    [47.1687937, 85.6192447],
    [47.1689598, 85.6200797],
    [47.1690412, 85.620571],
    [47.1694432, 85.6211467],
    [47.1695959, 85.6214551],
    [47.1697486, 85.6218663],
    [47.1699735, 85.622054],
    [47.1704695, 85.6223914],
    [47.1707746, 85.6228139],
    [47.1710735, 85.6234942],
    [47.1715647, 85.6241667],
    [47.172016, 85.6244519],
    [47.1724873, 85.6244818],
    [47.172832, 85.6244139],
    [47.1730401, 85.6243412],
    [47.1733448, 85.6245191],
    [47.1739923, 85.6252063],
    [47.1741059, 85.6254644],
    [47.17409, 85.6260889],
    [47.1738847, 85.626392],
    [47.1738221, 85.6269663],
    [47.1739265, 85.6273261],
    [47.1740249, 85.6275092],
    [47.1745954, 85.6277026],
    [47.1749462, 85.6278755],
    [47.1750681, 85.6282577],
    [47.175241, 85.6285254],
    [47.1755293, 85.6286823],
    [47.1758404, 85.6286026],
    [47.1761407, 85.6283975],
    [47.1763606, 85.6282839],
    [47.1766698, 85.6283209],
    [47.1768295, 85.6285048],
    [47.1773506, 85.6287092],
    [47.177984, 85.628697],
    [47.1781845, 85.6289864],
    [47.1783198, 85.6292542],
    [47.1784623, 85.6292571],
    [47.1786044, 85.6292079],
    [47.1788688, 85.6295336],
    [47.1791328, 85.6300578],
    [47.179433, 85.6305356],
    [47.1797976, 85.6313754],
    [47.1802454, 85.6318831],
    [47.1811414, 85.6323249],
    [47.1813649, 85.6331341],
    [47.1814743, 85.6338604],
    [47.1814287, 85.6343577],
    [47.1818626, 85.635635],
    [47.1822418, 85.6361083],
    [47.1827935, 85.6374488],
    [47.1834597, 85.6383758],
    [47.1838023, 85.6393535],
    [47.1840212, 85.6399052],
    [47.184607, 85.6405611],
    [47.1851752, 85.6408154],
    [47.1857714, 85.6407891],
    [47.1862585, 85.6407525],
    [47.1865341, 85.6408405],
    [47.1869293, 85.641313],
    [47.1873277, 85.6417024],
    [47.1877793, 85.6419193],
    [47.1880372, 85.6422917],
    [47.1886749, 85.6421762],
    [47.1894348, 85.6424284],
    [47.1909687, 85.6427238],
    [47.1912732, 85.6430583],
    [47.191749, 85.6432503],
    [47.192094, 85.6435293],
    [47.1925834, 85.6436602],
    [47.1929335, 85.6440403],
    [47.1932932, 85.6441902],
    [47.1939053, 85.645742],
    [47.1943398, 85.6464727],
    [47.1962363, 85.6465775],
    [47.1966555, 85.6470398],
    [47.1976287, 85.6469561],
    [47.1984772, 85.6465304],
    [47.1988993, 85.646436],
    [47.199287, 85.64709],
    [47.1998318, 85.6476192],
    [47.2003846, 85.6479341],
    [47.2007891, 85.6480284],
    [47.2013267, 85.6477232],
    [47.2019306, 85.6480448],
    [47.2024641, 85.6479153],
    [47.2027789, 85.6480996],
    [47.2030857, 85.6488338],
    [47.2039123, 85.6492658],
    [47.2043088, 85.6494596],
    [47.2050739, 85.6496084],
    [47.205569, 85.6499343],
    [47.207178, 85.653706],
    [47.2088674, 85.6553531],
    [47.2110557, 85.6586111],
    [47.2118037, 85.6589282],
    [47.2126047, 85.6586841],
    [47.2129404, 85.6590297],
    [47.2135916, 85.6605515],
    [47.2143899, 85.6617885],
    [47.2145147, 85.6622374],
    [47.214584, 85.6630537],
    [47.2150554, 85.6632169],
    [47.2152356, 85.6637067],
    [47.2154297, 85.664523],
    [47.2157625, 85.6654822],
    [47.2161229, 85.6658087],
    [47.216317, 85.6668086],
    [47.2162893, 85.6679515],
    [47.2161507, 85.6691148],
    [47.2162478, 85.6695842],
    [47.2165666, 85.6700128],
    [47.2167884, 85.6704822],
    [47.2168161, 85.6713394],
    [47.216927, 85.6716251],
    [47.2169548, 85.6720945],
    [47.2175508, 85.6724619],
    [47.2179092, 85.6725934],
    [47.218131, 85.673022],
    [47.2182142, 85.6737976],
    [47.218436, 85.6742874],
    [47.2188102, 85.6750018],
    [47.2188518, 85.6758182],
    [47.2186715, 85.6765937],
    [47.2186299, 85.677104],
    [47.2187824, 85.6773489],
    [47.2190042, 85.6775122],
    [47.2191982, 85.6781449],
    [47.2194339, 85.6782062],
    [47.2198774, 85.6783287],
    [47.2201408, 85.6787165],
    [47.2203903, 85.6786349],
    [47.2208062, 85.6789819],
    [47.2211804, 85.680125],
    [47.2215823, 85.680472],
    [47.2219566, 85.6806354],
    [47.2221229, 85.6809824],
    [47.2221229, 85.6816152],
    [47.2222892, 85.6818397],
    [47.2226496, 85.6820439],
    [47.2228298, 85.6822685],
    [47.2231486, 85.682289],
    [47.2235645, 85.6825748],
    [47.2246319, 85.682963],
    [47.225121, 85.6831247],
    [47.2254121, 85.6833697],
    [47.2256478, 85.6832473],
    [47.2258973, 85.6832882],
    [47.2260359, 85.683574],
    [47.2263824, 85.6837578],
    [47.2267983, 85.6840845],
    [47.2270616, 85.6840845],
    [47.2272834, 85.6843703],
    [47.2275467, 85.6847991],
    [47.2278655, 85.6850237],
    [47.2281289, 85.6852687],
    [47.2285447, 85.6853913],
    [47.2287249, 85.6856976],
    [47.2289883, 85.6857181],
    [47.229224, 85.6855956],
    [47.2294735, 85.6857386],
    [47.2299447, 85.6862082],
    [47.2302358, 85.6862287],
    [47.2311368, 85.6866985],
    [47.232135, 85.6862293],
    [47.2324843, 85.6865619],
    [47.2330526, 85.6868887],
    [47.233413, 85.6868071],
    [47.233718, 85.6865623],
    [47.2339953, 85.6862357],
    [47.234231, 85.6861337],
    [47.2343973, 85.6862154],
    [47.2345914, 85.68644],
    [47.2347161, 85.6864401],
    [47.2348548, 85.6862359],
    [47.2352429, 85.6859502],
    [47.2357559, 85.6857462],
    [47.2359222, 85.6855013],
    [47.2360609, 85.6854809],
    [47.2362688, 85.6854605],
    [47.2365599, 85.6853177],
    [47.2367817, 85.685379],
    [47.2374746, 85.6867471],
    [47.2377796, 85.6865226],
    [47.2383341, 85.6864615],
    [47.2386529, 85.6869924],
    [47.2389163, 85.6870538],
    [47.2393737, 85.6873602],
    [47.2398173, 85.6874216],
    [47.2402886, 85.6877688],
    [47.2403092, 85.6885304],
    [47.2404339, 85.6889388],
    [47.2408637, 85.6886939],
    [47.2413627, 85.6886328],
    [47.2414182, 85.6884899],
    [47.2415153, 85.6881224],
    [47.2417233, 85.6879183],
    [47.2417649, 85.6876937],
    [47.2421531, 85.6871221],
    [47.2424165, 85.6872651],
    [47.2426937, 85.6875511],
    [47.2435253, 85.6879801],
    [47.2437887, 85.6879802],
    [47.2442462, 85.687572],
    [47.2445373, 85.6875312],
    [47.2451334, 85.6876539],
    [47.2452721, 85.6874498],
    [47.2455634, 85.6860204],
    [47.2454665, 85.6851219],
    [47.2456329, 85.6846522],
    [47.2459517, 85.684346],
    [47.2464807, 85.6826996],
    [47.2466332, 85.6824546],
    [47.2470353, 85.6822097],
    [47.2472987, 85.6818217],
    [47.247368, 85.6815358],
    [47.2476453, 85.6813113],
    [47.2479087, 85.6807191],
    [47.2480473, 85.6806375],
    [47.2483107, 85.68076],
    [47.2485048, 85.6809847],
    [47.2487681, 85.6809643],
    [47.2489622, 85.6810256],
    [47.2492533, 85.680944],
    [47.2494058, 85.680944],
    [47.2494751, 85.6807602],
    [47.2495306, 85.6806173],
    [47.2498633, 85.6802906],
    [47.2501683, 85.6801273],
    [47.2505703, 85.6802499],
    [47.2507089, 85.6801682],
    [47.2508753, 85.6800457],
    [47.2510416, 85.6801683],
    [47.2524081, 85.6816722],
    [47.2527547, 85.6817744],
    [47.2528794, 85.681897],
    [47.2529071, 85.682142],
    [47.2531428, 85.6824689],
    [47.2535586, 85.6825915],
    [47.2538636, 85.6824894],
    [47.2546398, 85.6827347],
    [47.2548755, 85.6830819],
    [47.2550556, 85.6838172],
    [47.2553467, 85.6841236],
    [47.2555268, 85.6845321],
    [47.2558595, 85.6846956],
    [47.256012, 85.6848386],
    [47.2562754, 85.6848387],
    [47.2564971, 85.685043],
    [47.2567466, 85.6853086],
    [47.2570932, 85.6853495],
    [47.25762, 85.6854722],
    [47.2585782, 85.6858627],
    [47.2590773, 85.6858424],
    [47.2593268, 85.685577],
    [47.2598813, 85.6857814],
    [47.2602694, 85.686047],
    [47.2604219, 85.6862922],
    [47.2609763, 85.6865783],
    [47.2612812, 85.6869665],
    [47.2617803, 85.6869666],
    [47.2624317, 85.687314],
    [47.2627228, 85.6876614],
    [47.2628337, 85.6876001],
    [47.2630277, 85.6877636],
    [47.2631386, 85.68807],
    [47.2631524, 85.6887441],
    [47.2633047, 85.6896021],
    [47.263471, 85.689929],
    [47.2637067, 85.6899903],
    [47.2640394, 85.689827],
    [47.2642473, 85.6901948],
    [47.2643026, 85.6906851],
    [47.2644274, 85.6908894],
    [47.2649324, 85.6912399],
    [47.2651957, 85.6915669],
    [47.2654868, 85.691567],
    [47.2658056, 85.6921186],
    [47.2662491, 85.6921801],
    [47.2666928, 85.6917921],
    [47.2669562, 85.691874],
    [47.2672333, 85.6926503],
    [47.2674828, 85.6926913],
    [47.2677739, 85.6923033],
    [47.2679542, 85.6921603],
    [47.2682037, 85.6921604],
    [47.2686056, 85.6926713],
    [47.2688828, 85.6926714],
    [47.2691601, 85.6925694],
    [47.2694512, 85.6926104],
    [47.2698392, 85.6931621],
    [47.2702689, 85.6934075],
    [47.2706848, 85.6933872],
    [47.2709204, 85.6938572],
    [47.2713559, 85.6937393],
    [47.2717579, 85.6935965],
    [47.2720212, 85.693903],
    [47.2720628, 85.6941278],
    [47.2720073, 85.6944342],
    [47.2717438, 85.694904],
    [47.2717715, 85.6951696],
    [47.2721873, 85.6952923],
    [47.2724922, 85.6955377],
    [47.2727418, 85.6953539],
    [47.2731854, 85.6953541],
    [47.2734072, 85.6950886],
    [47.2736708, 85.6942715],
    [47.2739341, 85.6942103],
    [47.2743639, 85.6938632],
    [47.2749045, 85.6939656],
    [47.2753342, 85.6940883],
    [47.2755976, 85.6942315],
    [47.2757778, 85.694252],
    [47.2760134, 85.6943542],
    [47.2768451, 85.6947019],
    [47.27715, 85.6949473],
    [47.2773441, 85.6949065],
    [47.2774689, 85.6946],
    [47.2776353, 85.6944367],
    [47.2778849, 85.6943346],
    [47.2781067, 85.6941712],
    [47.2787202, 85.6941232],
    [47.278942, 85.6943276],
    [47.2790805, 85.694675],
    [47.2791082, 85.6949611],
    [47.2790943, 85.6952472],
    [47.2791497, 85.6954107],
    [47.2792883, 85.6955129],
    [47.2794408, 85.6955334],
    [47.2794963, 85.6953086],
    [47.2797597, 85.6949205],
    [47.2799399, 85.6949206],
    [47.2800507, 85.6956154],
    [47.2801477, 85.6958402],
    [47.2802863, 85.6959629],
    [47.2804942, 85.6959425],
    [47.2807714, 85.696004],
    [47.2810763, 85.6962902],
    [47.2812288, 85.6965559],
    [47.2813534, 85.6969442],
    [47.2814643, 85.6971486],
    [47.2818386, 85.697067],
    [47.2822406, 85.6971285],
    [47.2825291, 85.6974233],
    [47.2830004, 85.6976892],
    [47.2835963, 85.6981391],
    [47.2839984, 85.6980984],
    [47.2845113, 85.6978739],
    [47.2847054, 85.6976288],
    [47.2851213, 85.697629],
    [47.2855649, 85.6975066],
    [47.285856, 85.6977724],
    [47.2861748, 85.6978134],
    [47.2864382, 85.6976705],
    [47.28666, 85.6977319],
    [47.2868402, 85.6977115],
    [47.287256, 85.6981],
    [47.2875766, 85.6983265],
    [47.2876597, 85.6985309],
    [47.2880062, 85.698715],
    [47.2884082, 85.6986539],
    [47.2887132, 85.698654],
    [47.2888103, 85.6983884],
    [47.2891292, 85.698082],
    [47.2892817, 85.6978777],
    [47.2895174, 85.6977961],
    [47.2896837, 85.6979392],
    [47.2898778, 85.6979393],
    [47.2901274, 85.6974489],
    [47.2905157, 85.697163],
    [47.2908068, 85.6969792],
    [47.2913533, 85.6966954],
    [47.2915336, 85.6961641],
    [47.2915892, 85.6957758],
    [47.2920051, 85.6954285],
    [47.292213, 85.6954286],
    [47.2927952, 85.6957968],
    [47.2930448, 85.6954699],
    [47.2931696, 85.6951225],
    [47.2933637, 85.6949182],
    [47.2935855, 85.6949387],
    [47.2936964, 85.6947753],
    [47.2937103, 85.6945504],
    [47.2937936, 85.6942848],
    [47.2939461, 85.6941213],
    [47.2942788, 85.694101],
    [47.2943897, 85.6939785],
    [47.2943759, 85.6938149],
    [47.2943205, 85.6935901],
    [47.2944453, 85.6933858],
    [47.2946255, 85.6933245],
    [47.2948889, 85.6933451],
    [47.2950691, 85.693386],
    [47.2953325, 85.6930795],
    [47.295388, 85.6927525],
    [47.2954435, 85.692446],
    [47.2956099, 85.6923643],
    [47.2960119, 85.6924053],
    [47.2962198, 85.6922419],
    [47.2963446, 85.6919558],
    [47.2966219, 85.6918333],
    [47.2970655, 85.6921809],
    [47.2973982, 85.6917314],
    [47.2978454, 85.691682],
    [47.2981011, 85.6913958],
    [47.2982097, 85.690965],
    [47.2984967, 85.6907601],
    [47.2987895, 85.6904408],
    [47.2991865, 85.6903029],
    [47.299407, 85.6898445],
    [47.3001132, 85.6898772],
    [47.3001081, 85.6892488],
    [47.3002703, 85.6886757],
    [47.3007941, 85.6881022],
    [47.3007942, 85.6878504],
    [47.3007755, 85.6874014],
    [47.3009637, 85.6871511],
    [47.3012279, 85.6872959],
    [47.3015325, 85.6873284],
    [47.3018198, 85.6870492],
    [47.302069, 85.6867074],
    [47.3022862, 85.686549],
    [47.3023607, 85.6863177],
    [47.3026157, 85.6859087],
    [47.3028463, 85.6858776],
    [47.3030394, 85.6855843],
    [47.303348, 85.6854437],
    [47.3036392, 85.6854324],
    [47.3041833, 85.6850905],
    [47.3045459, 85.684608],
    [47.3046175, 85.6844209],
    [47.3049213, 85.6840822],
    [47.3051466, 85.6838022],
    [47.3053637, 85.6837799],
    [47.305531, 85.6839828],
    [47.3060176, 85.6842424],
    [47.3061857, 85.6845549],
    [47.3064191, 85.6846383],
    [47.3067342, 85.6845607],
    [47.3070509, 85.6847245],
    [47.3073785, 85.6848916],
    [47.3074993, 85.6850365],
    [47.3077648, 85.6848991],
    [47.3079588, 85.6848992],
    [47.3083054, 85.6847357],
    [47.3085272, 85.684838],
    [47.3087767, 85.6846745],
    [47.3089431, 85.684327],
    [47.3090679, 85.683775],
    [47.3092621, 85.6830595],
    [47.3092621, 85.6826915],
    [47.309179, 85.6823235],
    [47.3092622, 85.6820169],
    [47.3093592, 85.6817102],
    [47.3093038, 85.6811786],
    [47.3092069, 85.6807084],
    [47.3093039, 85.6805449],
    [47.3095118, 85.6806676],
    [47.3097891, 85.6808312],
    [47.3099693, 85.6806676],
    [47.3100663, 85.680361],
    [47.3100525, 85.6800952],
    [47.3099139, 85.6796249],
    [47.3099947, 85.6790951],
    [47.3102697, 85.6783349],
    [47.3103041, 85.6774396],
    [47.3104415, 85.6771017],
    [47.3108196, 85.6764767],
    [47.3109456, 85.6759699],
    [47.3110601, 85.6757165],
    [47.3112091, 85.6750914],
    [47.3113923, 85.6748549],
    [47.3118391, 85.6746353],
    [47.3121483, 85.6743143],
    [47.3126065, 85.6741454],
    [47.3127325, 85.6741961],
    [47.3129043, 85.6744495],
    [47.3131678, 85.6743651],
    [47.3135343, 85.6742975],
    [47.3137634, 85.6742975],
    [47.313962, 85.6746232],
    [47.3140422, 85.6747752],
    [47.3144775, 85.674336],
    [47.3147753, 85.674184],
    [47.3156344, 85.6736265],
    [47.3158291, 85.6732885],
    [47.3164477, 85.6731027],
    [47.3165966, 85.6732379],
    [47.3167111, 85.6733393],
    [47.3168944, 85.6733055],
    [47.3170891, 85.6733562],
    [47.3174327, 85.6729676],
    [47.3176642, 85.6728666],
    [47.3179277, 85.6726639],
    [47.318088, 85.6727315],
    [47.3182369, 85.6727653],
    [47.3184431, 85.6729849],
    [47.3185347, 85.6732891],
    [47.3186493, 85.6732891],
    [47.3188211, 85.6732046],
    [47.3189815, 85.6732384],
    [47.3194167, 85.6734412],
    [47.319852, 85.6735426],
    [47.3200582, 85.6737454],
    [47.3202414, 85.6737454],
    [47.3204934, 85.6733061],
    [47.3205393, 85.6730695],
    [47.3206767, 85.6729343],
    [47.32086, 85.6729343],
    [47.3211921, 85.6736102],
    [47.3215472, 85.6735764],
    [47.3221543, 85.6731202],
    [47.3223491, 85.6731371],
    [47.322525, 85.6732095],
    [47.3227083, 85.6734461],
    [47.32288, 85.6752034],
    [47.3233038, 85.6754569],
    [47.3237162, 85.6757105],
    [47.3240827, 85.6766399],
    [47.3243117, 85.677113],
    [47.3246783, 85.6774003],
    [47.3249302, 85.677958],
    [47.325228, 85.6778905],
    [47.3256289, 85.6780257],
    [47.3260985, 85.6790059],
    [47.3262818, 85.6792425],
    [47.326339, 85.6794622],
    [47.3262474, 85.6797157],
    [47.3264306, 85.6799861],
    [47.3266712, 85.680172],
    [47.3268659, 85.6804762],
    [47.3270433, 85.6808055],
    [47.3273296, 85.6813632],
    [47.3274098, 85.681735],
    [47.327719, 85.6820223],
    [47.3279366, 85.6821914],
    [47.3281657, 85.6820731],
    [47.3283833, 85.6822929],
    [47.3286697, 85.6823098],
    [47.3289331, 85.6825127],
    [47.3288644, 85.6829858],
    [47.3290476, 85.683138],
    [47.3292538, 85.6832225],
    [47.3295516, 85.6832733],
    [47.3296432, 85.6833747],
    [47.3296661, 85.6837634],
    [47.3298264, 85.6841014],
    [47.3300898, 85.6842029],
    [47.3302044, 85.6843719],
    [47.3301814, 85.6846592],
    [47.3299523, 85.6851662],
    [47.3299293, 85.6854028],
    [47.330021, 85.6856394],
    [47.3304104, 85.6856564],
    [47.3308572, 85.685403],
    [47.3313501, 85.6852837],
    [47.3316593, 85.6858584],
    [47.3318196, 85.6864669],
    [47.3320029, 85.6863825],
    [47.3321977, 85.6861966],
    [47.3323695, 85.6860952],
    [47.3325413, 85.6858925],
    [47.3327475, 85.6858925],
    [47.3329422, 85.6860785],
    [47.3331712, 85.6865518],
    [47.333343, 85.6866702],
    [47.333595, 85.6864336],
    [47.3337783, 85.6862985],
    [47.3338814, 85.6860788],
    [47.334122, 85.6858591],
    [47.3343855, 85.6858254],
    [47.3346374, 85.6861804],
    [47.3348436, 85.686265],
    [47.3350841, 85.6864171],
    [47.3352788, 85.6864003],
    [47.3355194, 85.6861637],
    [47.3357027, 85.6859948],
    [47.3359204, 85.6859272],
    [47.3360921, 85.686147],
    [47.3362754, 85.6864344],
    [47.3365017, 85.6865028],
    [47.3366621, 85.6863169],
    [47.3367309, 85.6860465],
    [47.3369256, 85.6859958],
    [47.3370286, 85.6863508],
    [47.337189, 85.6863508],
    [47.3373837, 85.6863002],
    [47.3379908, 85.6863849],
    [47.3382543, 85.6863173],
    [47.3384032, 85.6861315],
    [47.338472, 85.6855399],
    [47.338598, 85.6854216],
    [47.3389302, 85.6855231],
    [47.3393425, 85.685912],
    [47.3394914, 85.685743],
    [47.3396404, 85.6854726],
    [47.339858, 85.685405],
    [47.34011, 85.6855741],
    [47.3403161, 85.6858615],
    [47.3407284, 85.6862504],
    [47.3408888, 85.6862505],
    [47.3411523, 85.6861491],
    [47.3415755, 85.6859686],
    [47.3419764, 85.6860532],
    [47.3420909, 85.6863237],
    [47.3421023, 85.686628],
    [47.342297, 85.6869154],
    [47.3424688, 85.6869155],
    [47.342801, 85.686831],
    [47.3431102, 85.6869833],
    [47.3433164, 85.6868988],
    [47.3434883, 85.6867467],
    [47.3438205, 85.6861044],
    [47.3440382, 85.6858002],
    [47.3443475, 85.6857834],
    [47.3449431, 85.6859357],
    [47.345218, 85.6861386],
    [47.3454699, 85.6861894],
    [47.3455845, 85.6861387],
    [47.3456533, 85.6858852],
    [47.3457678, 85.6857162],
    [47.3459396, 85.6857162],
    [47.3462718, 85.6859868],
    [47.3470018, 85.6860267],
    [47.347082, 85.6858915],
    [47.3472881, 85.6859422],
    [47.3481013, 85.6865004],
    [47.3481815, 85.686754],
    [47.3484907, 85.6869231],
    [47.3487656, 85.6868218],
    [47.3489833, 85.6865344],
    [47.3491322, 85.6864161],
    [47.3493155, 85.6864669],
    [47.3495331, 85.686822],
    [47.3497736, 85.6870419],
    [47.3500141, 85.6869912],
    [47.3502432, 85.6871603],
    [47.3504035, 85.6875154],
    [47.3505753, 85.6876676],
    [47.350667, 85.6876],
    [47.3508503, 85.6873972],
    [47.3511595, 85.6872789],
    [47.3514574, 85.6869409],
    [47.3516751, 85.6868564],
    [47.3520416, 85.6869918],
    [47.3523624, 85.6876077],
    [47.35258, 85.6876754],
    [47.3528091, 85.6875233],
    [47.3529466, 85.6873543],
    [47.3532444, 85.6875911],
    [47.3534276, 85.6879124],
    [47.3535422, 85.6879801],
    [47.3536453, 85.6878449],
    [47.3538171, 85.6874729],
    [47.3540119, 85.6874223],
    [47.3541379, 85.6874899],
    [47.3542524, 85.6877098],
    [47.35447, 85.687676],
    [47.3547678, 85.6879128],
    [47.3548365, 85.6882341],
    [47.3549166, 85.6887414],
    [47.355054, 85.6888936],
    [47.3552258, 85.6889613],
    [47.3553404, 85.688843],
    [47.3554893, 85.688657],
    [47.3557871, 85.6885557],
    [47.3559823, 85.6885068],
    [47.3561802, 85.6884538],
    [47.3563151, 85.6883343],
    [47.356495, 85.6883344],
    [47.3566029, 85.6884406],
    [47.3567108, 85.6886797],
    [47.3569086, 85.6888656],
    [47.3570166, 85.6888656],
    [47.3571245, 85.6888126],
    [47.3571785, 85.6887064],
    [47.3572055, 85.6885338],
    [47.3573225, 85.6882948],
    [47.3574304, 85.6881488],
    [47.3575563, 85.6881621],
    [47.3576732, 85.6882816],
    [47.3578171, 85.688441],
    [47.3584108, 85.6880827],
    [47.3586267, 85.688189],
    [47.3589416, 85.6876049],
    [47.3591664, 85.6874854],
    [47.3594722, 85.687605],
    [47.3596431, 85.6874989],
    [47.3597061, 85.6873263],
    [47.3597601, 85.6871005],
    [47.3599024, 85.6868361],
    [47.3603341, 85.6868495],
    [47.360442, 85.6869956],
    [47.360478, 85.6871683],
    [47.3607298, 85.6872878],
    [47.3611345, 85.6875801],
    [47.3612244, 85.6875137],
    [47.3614584, 85.6869694],
    [47.3616113, 85.6868499],
    [47.362079, 85.6868766],
    [47.3622499, 85.6870493],
    [47.3624027, 85.6874477],
    [47.3626275, 85.6876071],
    [47.3627803, 85.6885499],
    [47.3629692, 85.6885367],
    [47.3632031, 85.6883907],
    [47.36332, 85.6881783],
    [47.363419, 85.6879526],
    [47.3635359, 85.6878198],
    [47.3636349, 85.6877933],
    [47.3637518, 85.6878597],
    [47.3639676, 85.6880723],
    [47.3641025, 85.6881387],
    [47.3642284, 85.6883911],
    [47.3643363, 85.6886301],
    [47.3644713, 85.6886036],
    [47.3645882, 85.6884708],
    [47.3648039, 85.6881843],
    [47.3650108, 85.687932],
    [47.3651907, 85.6879188],
    [47.3653166, 85.6880516],
    [47.3653885, 85.6882907],
    [47.3653525, 85.6885695],
    [47.3652895, 85.6888749],
    [47.3653614, 85.689114],
    [47.3655053, 85.689207],
    [47.3655143, 85.6894195],
    [47.3656132, 85.6894195],
    [47.3657842, 85.6892204],
    [47.365955, 85.6892204],
    [47.3661529, 85.6894595],
    [47.3660989, 85.6898313],
    [47.3661438, 85.6902563],
    [47.3661077, 85.6907742],
    [47.3660176, 85.6914514],
    [47.3662515, 85.6916374],
    [47.3663234, 85.6919296],
    [47.3662694, 85.692142],
    [47.3660984, 85.6922349],
    [47.3656398, 85.6920887],
    [47.3655228, 85.6922214],
    [47.3654598, 85.692633],
    [47.3654777, 85.6929119],
    [47.3656485, 85.6932572],
    [47.3657384, 85.6936291],
    [47.3655134, 85.6942266],
    [47.3655853, 85.6944258],
    [47.3657472, 85.6946251],
    [47.3658281, 85.6947712],
    [47.3657831, 85.6949305],
    [47.3657471, 85.6951297],
    [47.3657808, 85.6956858],
    [47.3657358, 85.695885],
    [47.3656368, 85.6962036],
    [47.3656278, 85.6963895],
    [47.3656817, 85.6965755],
    [47.3657986, 85.6966685],
    [47.3660235, 85.6966022],
    [47.3664641, 85.6970805],
    [47.3665899, 85.697386],
    [47.3666708, 85.6976383],
    [47.3670035, 85.6980767],
    [47.3673363, 85.6980503],
    [47.3675701, 85.6982496],
    [47.3680287, 85.698635],
    [47.3681547, 85.6985023],
    [47.3683255, 85.6986484],
    [47.3684699, 85.6990328],
    [47.3685688, 85.6991258],
    [47.3687218, 85.6990064],
    [47.3689196, 85.6991393],
    [47.3691085, 85.6989933],
    [47.3693694, 85.6988208],
    [47.3695223, 85.6990201],
    [47.3697201, 85.6990335],
    [47.3698371, 85.6987281],
    [47.3697922, 85.6985156],
    [47.3697834, 85.697918],
    [47.3699454, 85.6974532],
    [47.3700443, 85.6974134],
    [47.3701612, 85.697533],
    [47.370215, 85.698144],
    [47.3705118, 85.6983832],
    [47.3707726, 85.6985958],
    [47.3709434, 85.698888],
    [47.3710422, 85.6992334],
    [47.3710278, 85.6996474],
    [47.3711537, 85.6998334],
    [47.3712706, 85.69986],
    [47.3713336, 85.6997538],
    [47.3713337, 85.6995413],
    [47.3712708, 85.699342],
    [47.3715226, 85.6992891],
    [47.3718014, 85.6995017],
    [47.3723051, 85.6995551],
    [47.372431, 85.699688],
    [47.3726826, 85.7003256],
    [47.3728175, 85.700671],
    [47.3729434, 85.700764],
    [47.3730423, 85.7007641],
    [47.3731323, 85.7006446],
    [47.3734111, 85.7005784],
    [47.3737169, 85.7006715],
    [47.3742295, 85.7010038],
    [47.3743284, 85.700964],
    [47.3744363, 85.7010438],
    [47.3745172, 85.7012962],
    [47.3746162, 85.7013095],
    [47.374922, 85.7012034],
    [47.3751378, 85.7012567],
    [47.3752728, 85.7012036],
    [47.3752728, 85.7009247],
    [47.3753718, 85.7007521],
    [47.3754438, 85.7007256],
    [47.375643, 85.7009001],
    [47.3757869, 85.7010463],
    [47.3758678, 85.7012322],
    [47.3760387, 85.7013519],
    [47.3762186, 85.701352],
    [47.3763714, 85.7014982],
    [47.3764703, 85.7016045],
    [47.3766052, 85.7016577],
    [47.3767491, 85.7017109],
    [47.3769201, 85.7015118],
    [47.3769382, 85.7010868],
    [47.3770551, 85.7009673],
    [47.377208, 85.7009807],
    [47.3774958, 85.7012199],
    [47.3777836, 85.7012333],
    [47.3779095, 85.7013397],
    [47.3781703, 85.7014726],
    [47.3783592, 85.7013798],
    [47.3785661, 85.7011807],
    [47.378764, 85.7011409],
    [47.3789439, 85.7013137],
    [47.3790427, 85.701606],
    [47.3790786, 85.7019381],
    [47.3792405, 85.7020843],
    [47.3793262, 85.702094],
    [47.3794791, 85.7022535],
    [47.3796319, 85.7023466],
    [47.3800725, 85.7029578],
    [47.3802524, 85.7029181],
    [47.3805493, 85.7025995],
    [47.3806843, 85.702387],
    [47.3807652, 85.7023605],
    [47.38099, 85.7024404],
    [47.381152, 85.7023608],
    [47.381287, 85.7019889],
    [47.3816648, 85.7018032],
    [47.3820245, 85.7021089],
    [47.3821144, 85.7020691],
    [47.3823574, 85.7017106],
    [47.3824204, 85.7014449],
    [47.3824295, 85.7011793],
    [47.3824835, 85.7010465],
    [47.3828084, 85.7010219],
    [47.3830603, 85.7006102],
    [47.3832673, 85.7003978],
    [47.3835913, 85.6996541],
    [47.3837262, 85.6993885],
    [47.3839871, 85.6992026],
    [47.384158, 85.6991894],
    [47.3844188, 85.6993091],
    [47.3844861, 85.6993109],
    [47.384577, 85.6988382],
    [47.3846344, 85.6985791],
    [47.3847708, 85.6982848],
    [47.3847395, 85.6980047],
    [47.3848648, 85.6977899],
    [47.3851252, 85.6976932],
    [47.3851965, 85.6975603],
    [47.3851118, 85.6969865],
    [47.385205, 85.6968345],
    [47.3856835, 85.6969251],
    [47.3858605, 85.6966374],
    [47.3860698, 85.6960526],
    [47.3860628, 85.6956913],
    [47.3859479, 85.6953167],
    [47.3859508, 85.6950919],
    [47.3861555, 85.694945],
    [47.3862155, 85.6948404],
    [47.3862398, 85.6946689],
    [47.386309, 85.6945688],
    [47.3864497, 85.6945683],
    [47.3866391, 85.6948045],
    [47.3867454, 85.6947868],
    [47.3868459, 85.6943846],
    [47.3870908, 85.6943027],
    [47.3871908, 85.6941053],
    [47.387171, 85.6938781],
    [47.3870442, 85.6935327],
    [47.3871019, 85.6931763],
    [47.3872892, 85.692935],
    [47.3875811, 85.6928161],
    [47.387892, 85.6928479],
    [47.3880025, 85.6927392],
    [47.3879796, 85.6921479],
    [47.3880756, 85.6919563],
    [47.388363, 85.691885],
    [47.3886886, 85.6920291],
    [47.3890887, 85.6922426],
    [47.3891944, 85.692207],
    [47.3892641, 85.6919037],
    [47.3892767, 85.6916905],
    [47.3894412, 85.6915986],
    [47.3895707, 85.6914523],
    [47.3897862, 85.6915057],
    [47.389956, 85.6918152],
    [47.3901033, 85.6919151],
    [47.3902972, 85.6918778],
    [47.3904142, 85.6917184],
    [47.3904952, 85.691466],
    [47.3905223, 85.6906689],
    [47.3907832, 85.6903103],
    [47.3909632, 85.6898719],
    [47.391224, 85.6897126],
    [47.391332, 85.6895797],
    [47.3913617, 85.6892475],
    [47.3915776, 85.6890748],
    [47.3919014, 85.6890218],
    [47.3921263, 85.688889],
    [47.3921893, 85.6886233],
    [47.3923152, 85.6882912],
    [47.3924412, 85.6879325],
    [47.392774, 85.6876403],
    [47.3930889, 85.6874278],
    [47.3936555, 85.6872952],
    [47.3938084, 85.6873351],
    [47.3940243, 85.6874414],
    [47.3942221, 85.6875743],
    [47.3943121, 85.6875212],
    [47.3943571, 85.6874149],
    [47.3944381, 85.6868436],
    [47.394564, 85.6868171],
    [47.3947169, 85.6868703],
    [47.3948968, 85.6867508],
    [47.3950947, 85.6865117],
    [47.3952117, 85.6863257],
    [47.3952477, 85.6861131],
    [47.3954006, 85.6859006],
    [47.3955805, 85.6859006],
    [47.3957964, 85.6860335],
    [47.3960212, 85.6859804],
    [47.3962461, 85.6860602],
    [47.3964169, 85.6862862],
    [47.3965339, 85.6862862],
    [47.3966814, 85.6859647],
    [47.3969513, 85.6858186],
    [47.3969963, 85.6856857],
    [47.3969424, 85.6853535],
    [47.3971762, 85.6850878],
    [47.3974101, 85.6850746],
    [47.3981026, 85.685221],
    [47.3986872, 85.685633],
    [47.399029, 85.6856066],
    [47.3993798, 85.6854472],
    [47.3996183, 85.685366],
    [47.3998939, 85.68539],
    [47.4000965, 85.6852583],
    [47.4003397, 85.6852584],
    [47.4004613, 85.6851028],
    [47.4007126, 85.685007],
    [47.400972, 85.685043],
    [47.4012719, 85.6849114],
    [47.4014665, 85.6847198],
    [47.4016286, 85.6845761],
    [47.4018313, 85.6844924],
    [47.4021312, 85.6842529],
    [47.4024311, 85.6840614],
    [47.4027149, 85.6837261],
    [47.4029256, 85.6836423],
    [47.403239, 85.6834318],
    [47.403393, 85.683336],
    [47.4035794, 85.683348],
    [47.4039604, 85.6832403],
    [47.4042117, 85.6832883],
    [47.4043981, 85.6832883],
    [47.4045359, 85.6833243],
    [47.4047466, 85.6832884],
    [47.4048925, 85.6832764],
    [47.4051033, 85.6834561],
    [47.4052735, 85.6834562],
    [47.4054275, 85.6835161],
    [47.4056383, 85.6834922],
    [47.4058838, 85.6833017],
    [47.4059891, 85.6833736],
    [47.406054, 85.6835293],
    [47.406289, 85.6836491],
    [47.4064511, 85.6836252],
    [47.4066133, 85.6834935],
    [47.4067186, 85.6835894],
    [47.4068726, 85.6836373],
    [47.4070347, 85.6838529],
    [47.4070915, 85.6840685],
    [47.4071076, 85.6846195],
    [47.4071724, 85.6848231],
    [47.407294, 85.6848111],
    [47.4073913, 85.6846794],
    [47.4074967, 85.6847034],
    [47.4076668, 85.6850148],
    [47.4077965, 85.6850867],
    [47.4079181, 85.6850269],
    [47.4081046, 85.6850629],
    [47.408218, 85.6851827],
    [47.4083639, 85.6853504],
    [47.408608, 85.6853925],
    [47.408916, 85.6860993],
    [47.4090376, 85.6862071],
    [47.4091105, 85.6862311],
    [47.4091592, 85.6860634],
    [47.4092402, 85.6859796],
    [47.409378, 85.6860275],
    [47.409532, 85.6861354],
    [47.4096131, 85.6862552],
    [47.4098319, 85.6863271],
    [47.4101156, 85.6860517],
    [47.4102535, 85.685896],
    [47.4104886, 85.6854769],
    [47.4105859, 85.685405],
    [47.410829, 85.6856087],
    [47.41121, 85.6856687],
    [47.41121, 85.6857885],
    [47.4112747, 85.6861718],
    [47.411372, 85.6862676],
    [47.4115754, 85.6861468],
    [47.4117051, 85.6859072],
    [47.4118186, 85.6858234],
    [47.4119483, 85.6858474],
    [47.4121104, 85.6859073],
    [47.4122968, 85.6858595],
    [47.4125157, 85.6856918],
    [47.4126373, 85.6855122],
    [47.412686, 85.6853804],
    [47.4126292, 85.6852008],
    [47.4127346, 85.685069],
    [47.4128562, 85.6849133],
    [47.4131399, 85.6849254],
    [47.4133345, 85.6848775],
    [47.413529, 85.68465],
    [47.4136587, 85.6845422],
    [47.4140235, 85.6843986],
    [47.4141208, 85.6844585],
    [47.4143327, 85.684821],
    [47.4146083, 85.6850247],
    [47.4149649, 85.6852404],
    [47.4151432, 85.685492],
    [47.4152648, 85.68554],
    [47.4153296, 85.68554],
    [47.4153377, 85.6854681],
    [47.4152729, 85.6853244],
    [47.4153378, 85.6852046],
    [47.4154463, 85.6852258],
    [47.4155355, 85.6853696],
    [47.4156408, 85.6854535],
    [47.415811, 85.6856931],
    [47.4160623, 85.6858489],
    [47.4160866, 85.685813],
    [47.4160542, 85.6857052],
    [47.4160947, 85.6856093],
    [47.4162001, 85.6855255],
    [47.4162974, 85.6853578],
    [47.4163218, 85.6851542],
    [47.4163218, 85.6849506],
    [47.4163218, 85.6847589],
    [47.4164353, 85.6846152],
    [47.4166542, 85.6845314],
    [47.4168001, 85.6844835],
    [47.4169703, 85.6844835],
    [47.4172945, 85.6846753],
    [47.4173026, 85.6848071],
    [47.4173836, 85.6849029],
    [47.4174728, 85.6850347],
    [47.4175944, 85.6850587],
    [47.4177484, 85.6849749],
    [47.4177646, 85.6846634],
    [47.4178457, 85.6843041],
    [47.418016, 85.6842801],
    [47.4181294, 85.684388],
    [47.4183483, 85.6843281],
    [47.4183726, 85.6842084],
    [47.4184861, 85.6841605],
    [47.4186482, 85.6842204],
    [47.4187941, 85.6842803],
    [47.4188833, 85.6842444],
    [47.4190373, 85.6841247],
    [47.4191913, 85.6840888],
    [47.4193534, 85.6841247],
    [47.4195723, 85.6843764],
    [47.4197431, 85.6846674],
    [47.419948, 85.685004],
    [47.4201529, 85.685105],
    [47.4203426, 85.6850826],
    [47.4204488, 85.6849368],
    [47.420502, 85.6847574],
    [47.4205627, 85.6844321],
    [47.4206083, 85.6842751],
    [47.420669, 85.6841629],
    [47.4207904, 85.6841293],
    [47.4209422, 85.6844322],
    [47.4211319, 85.6846005],
    [47.4213292, 85.6846005],
    [47.4214734, 85.6845108],
    [47.42161, 85.6843763],
    [47.4217542, 85.6843202],
    [47.4218301, 85.6843539],
    [47.4219515, 85.6845783],
    [47.4220729, 85.6846232],
    [47.4221792, 85.6846008],
    [47.422422, 85.6848812],
    [47.4230518, 85.6852964],
    [47.4232415, 85.6852964],
    [47.4237728, 85.6850722],
    [47.423884, 85.6850667],
    [47.4240965, 85.6847863],
    [47.4242635, 85.6846294],
    [47.4244381, 85.6844051],
    [47.4247037, 85.6842593],
    [47.4248024, 85.684035],
    [47.4249086, 85.6838555],
    [47.4251287, 85.6836873],
    [47.4252426, 85.6836874],
    [47.4255385, 85.6836986],
    [47.4257662, 85.6836538],
    [47.4259484, 85.6835193],
    [47.4262595, 85.6833847],
    [47.4263734, 85.6832389],
    [47.4265555, 85.6831492],
    [47.4267756, 85.6830932],
    [47.426914, 85.6830847],
    [47.4270126, 85.6831184],
    [47.4272783, 85.6829166],
    [47.4274073, 85.6829951],
    [47.4276729, 85.6829503],
    [47.4277488, 85.6828045],
    [47.4278408, 85.6828129],
    [47.4280836, 85.6831046],
    [47.4281216, 85.6829925],
    [47.4283493, 85.682757],
    [47.4286073, 85.682656],
    [47.429055, 85.6828581],
    [47.4293434, 85.6828806],
    [47.4296014, 85.6830601],
    [47.4296545, 85.6832396],
    [47.429738, 85.6833182],
    [47.4298366, 85.6834191],
    [47.4299125, 85.6834079],
    [47.4299808, 85.6832621],
    [47.4302465, 85.6831837],
    [47.4302616, 85.6830715],
    [47.4302161, 85.6829593],
    [47.4302541, 85.6827798],
    [47.4304818, 85.6827799],
    [47.4307246, 85.682735],
    [47.4308612, 85.6826004],
    [47.4308005, 85.6823088],
    [47.4309068, 85.6821854],
    [47.4309675, 85.6819722],
    [47.4310055, 85.6814113],
    [47.431127, 85.6811309],
    [47.4315431, 85.6812071],
    [47.4318543, 85.6810726],
    [47.4319681, 85.680938],
    [47.432044, 85.6806351],
    [47.4322338, 85.6804556],
    [47.4324083, 85.6803771],
    [47.4325981, 85.6798162],
    [47.432932, 85.6797153],
    [47.4332204, 85.6798612],
    [47.4333797, 85.6801305],
    [47.433577, 85.6803773],
    [47.433873, 85.6803325],
    [47.4341041, 85.6802541],
    [47.4342255, 85.6799512],
    [47.4342104, 85.6798053],
    [47.4342559, 85.6796146],
    [47.4342939, 85.6793454],
    [47.4344381, 85.6791547],
    [47.4346354, 85.6794576],
    [47.4347189, 85.6794127],
    [47.4347416, 85.6792332],
    [47.4348934, 85.678863],
    [47.4352046, 85.6786724],
    [47.4354095, 85.6787734],
    [47.4355537, 85.6787734],
    [47.4356372, 85.6786612],
    [47.4355537, 85.6785153],
    [47.4355765, 85.6782236],
    [47.4355537, 85.6780666],
    [47.4354399, 85.6778422],
    [47.435493, 85.67773],
    [47.4355537, 85.6776963],
    [47.4356069, 85.6774832],
    [47.4356297, 85.6771803],
    [47.4357207, 85.6769222],
    [47.4358725, 85.6767315],
    [47.4361533, 85.6765857],
    [47.4362368, 85.6764511],
    [47.4362141, 85.6761145],
    [47.4361609, 85.6758228],
    [47.4362141, 85.6756208],
    [47.4363051, 85.6754862],
    [47.4365101, 85.675475],
    [47.4367225, 85.6758565],
    [47.4368288, 85.6758565],
    [47.4370716, 85.6756209],
    [47.4371475, 85.6754526],
    [47.4372538, 85.6753853],
    [47.4373069, 85.6754414],
    [47.4374883, 85.6757092],
    [47.4376704, 85.6757317],
    [47.4377387, 85.675698],
    [47.4377387, 85.6753839],
    [47.437678, 85.6751034],
    [47.437678, 85.6749126],
    [47.4377387, 85.6748117],
    [47.4378601, 85.674778],
    [47.4381485, 85.6749127],
    [47.4383155, 85.6749463],
    [47.4384141, 85.6748902],
    [47.4385735, 85.6747781],
    [47.4387025, 85.6747893],
    [47.438915, 85.6751932],
    [47.4390061, 85.6753279],
    [47.4391654, 85.6753503],
    [47.4393172, 85.675283],
    [47.4395145, 85.6752942],
    [47.4396283, 85.6752045],
    [47.4397877, 85.6750586],
    [47.4400002, 85.674823],
    [47.4400989, 85.6745201],
    [47.4401824, 85.6743406],
    [47.4402962, 85.6742508],
    [47.4404753, 85.6743681],
    [47.4405967, 85.6744017],
    [47.4407789, 85.674514],
    [47.4408851, 85.6744915],
    [47.4408927, 85.6743569],
    [47.4410217, 85.674211],
    [47.4411431, 85.674211],
    [47.441257, 85.6742447],
    [47.4413177, 85.6741549],
    [47.4413253, 85.6740652],
    [47.4412494, 85.6738744],
    [47.4413556, 85.6735378],
    [47.441386, 85.6732236],
    [47.4416288, 85.6729656],
    [47.4419324, 85.672887],
    [47.4421432, 85.6729422],
    [47.4422722, 85.6728973],
    [47.4424088, 85.6727066],
    [47.4426365, 85.6725046],
    [47.4426213, 85.6722016],
    [47.4426744, 85.6719099],
    [47.4427351, 85.671764],
    [47.4428869, 85.6717304],
    [47.4432133, 85.6718875],
    [47.443365, 85.6723027],
    [47.4434257, 85.6726842],
    [47.4435699, 85.6728861],
    [47.4436686, 85.6729198],
    [47.4437369, 85.6728413],
    [47.4437596, 85.6727178],
    [47.4437293, 85.6725046],
    [47.4437445, 85.67237],
    [47.443957, 85.6719997],
    [47.4440556, 85.6719099],
    [47.4441846, 85.6717416],
    [47.4442985, 85.6715845],
    [47.4444503, 85.6715396],
    [47.4446324, 85.6716294],
    [47.4448297, 85.6715172],
    [47.4450802, 85.6711693],
    [47.4452319, 85.670698],
    [47.4452375, 85.6704519],
    [47.4453134, 85.670306],
    [47.4454045, 85.670306],
    [47.4454804, 85.6703397],
    [47.4455259, 85.670306],
    [47.4455942, 85.6701601],
    [47.4456777, 85.6697786],
    [47.4457612, 85.6696215],
    [47.4459357, 85.6694532],
    [47.446004, 85.6692063],
    [47.4461103, 85.6690043],
    [47.4462393, 85.6688921],
    [47.4464518, 85.6688921],
    [47.4471727, 85.6691165],
    [47.4472714, 85.6689706],
    [47.4472638, 85.6687461],
    [47.4472789, 85.6683983],
    [47.4472713, 85.6679045],
    [47.4472865, 85.6676689],
    [47.44737, 85.6675117],
    [47.447499, 85.6673546],
    [47.4475749, 85.6671526],
    [47.4476204, 85.6669506],
    [47.4478936, 85.666109],
    [47.4479922, 85.6659518],
    [47.4480984, 85.665862],
    [47.4299808, 85.6832621],
    [47.4302465, 85.6831837],
    [47.4302616, 85.6830715],
    [47.4302161, 85.6829593],
    [47.4302541, 85.6827798],
    [47.4304818, 85.6827799],
    [47.4307246, 85.682735],
    [47.4308612, 85.6826004],
    [47.4308005, 85.6823088],
    [47.4309068, 85.6821854],
    [47.4309675, 85.6819722],
    [47.4310055, 85.6814113],
    [47.431127, 85.6811309],
    [47.4315431, 85.6812071],
    [47.4318543, 85.6810726],
    [47.4319681, 85.680938],
    [47.432044, 85.6806351],
    [47.4322338, 85.6804556],
    [47.4324083, 85.6803771],
    [47.4325981, 85.6798162],
    [47.432932, 85.6797153],
    [47.4332204, 85.6798612],
    [47.4333797, 85.6801305],
    [47.433577, 85.6803773],
    [47.433873, 85.6803325],
    [47.4341041, 85.6802541],
    [47.4342255, 85.6799512],
    [47.4342104, 85.6798053],
    [47.4342559, 85.6796146],
    [47.4342939, 85.6793454],
    [47.4344381, 85.6791547],
    [47.4346354, 85.6794576],
    [47.4347189, 85.6794127],
    [47.4347416, 85.6792332],
    [47.4348934, 85.678863],
    [47.4352046, 85.6786724],
    [47.4354095, 85.6787734],
    [47.4355537, 85.6787734],
    [47.4356372, 85.6786612],
    [47.4355537, 85.6785153],
    [47.4355765, 85.6782236],
    [47.4355537, 85.6780666],
    [47.4354399, 85.6778422],
    [47.435493, 85.67773],
    [47.4355537, 85.6776963],
    [47.4356069, 85.6774832],
    [47.4356297, 85.6771803],
    [47.4357207, 85.6769222],
    [47.4358725, 85.6767315],
    [47.4361533, 85.6765857],
    [47.4362368, 85.6764511],
    [47.4362141, 85.6761145],
    [47.4361609, 85.6758228],
    [47.4362141, 85.6756208],
    [47.4363051, 85.6754862],
    [47.4365101, 85.675475],
    [47.4367225, 85.6758565],
    [47.4368288, 85.6758565],
    [47.4370716, 85.6756209],
    [47.4371475, 85.6754526],
    [47.4372538, 85.6753853],
    [47.4373069, 85.6754414],
    [47.4374883, 85.6757092],
    [47.4376704, 85.6757317],
    [47.4377387, 85.675698],
    [47.4377387, 85.6753839],
    [47.437678, 85.6751034],
    [47.437678, 85.6749126],
    [47.4377387, 85.6748117],
    [47.4378601, 85.674778],
    [47.4381485, 85.6749127],
    [47.4383155, 85.6749463],
    [47.4384141, 85.6748902],
    [47.4385735, 85.6747781],
    [47.4387025, 85.6747893],
    [47.438915, 85.6751932],
    [47.4390061, 85.6753279],
    [47.4391654, 85.6753503],
    [47.4393172, 85.675283],
    [47.4395145, 85.6752942],
    [47.4396283, 85.6752045],
    [47.4397877, 85.6750586],
    [47.4400002, 85.674823],
    [47.4400989, 85.6745201],
    [47.4401824, 85.6743406],
    [47.4402962, 85.6742508],
    [47.4404753, 85.6743681],
    [47.4405967, 85.6744017],
    [47.4407789, 85.674514],
    [47.4408851, 85.6744915],
    [47.4408927, 85.6743569],
    [47.4410217, 85.674211],
    [47.4411431, 85.674211],
    [47.441257, 85.6742447],
    [47.4413177, 85.6741549],
    [47.4413253, 85.6740652],
    [47.4412494, 85.6738744],
    [47.4413556, 85.6735378],
    [47.441386, 85.6732236],
    [47.4416288, 85.6729656],
    [47.4419324, 85.672887],
    [47.4421432, 85.6729422],
    [47.4422722, 85.6728973],
    [47.4424088, 85.6727066],
    [47.4426365, 85.6725046],
    [47.4426213, 85.6722016],
    [47.4426744, 85.6719099],
    [47.4427351, 85.671764],
    [47.4428869, 85.6717304],
    [47.4432133, 85.6718875],
    [47.443365, 85.6723027],
    [47.4434257, 85.6726842],
    [47.4435699, 85.6728861],
    [47.4436686, 85.6729198],
    [47.4437369, 85.6728413],
    [47.4437596, 85.6727178],
    [47.4437293, 85.6725046],
    [47.4437445, 85.67237],
    [47.443957, 85.6719997],
    [47.4440556, 85.6719099],
    [47.4441846, 85.6717416],
    [47.4442985, 85.6715845],
    [47.4444503, 85.6715396],
    [47.4446324, 85.6716294],
    [47.4448297, 85.6715172],
    [47.4450802, 85.6711693],
    [47.4452319, 85.670698],
    [47.4452375, 85.6704519],
    [47.4453134, 85.670306],
    [47.4454045, 85.670306],
    [47.4454804, 85.6703397],
    [47.4455259, 85.670306],
    [47.4455942, 85.6701601],
    [47.4456777, 85.6697786],
    [47.4457612, 85.6696215],
    [47.4459357, 85.6694532],
    [47.446004, 85.6692063],
    [47.4461103, 85.6690043],
    [47.4462393, 85.6688921],
    [47.4464518, 85.6688921],
    [47.4471727, 85.6691165],
    [47.4472714, 85.6689706],
    [47.4472638, 85.6687461],
    [47.4472789, 85.6683983],
    [47.4472713, 85.6679045],
    [47.4472865, 85.6676689],
    [47.44737, 85.6675117],
    [47.447499, 85.6673546],
    [47.4475749, 85.6671526],
    [47.4476204, 85.6669506],
    [47.4478936, 85.666109],
    [47.4479922, 85.6659518],
    [47.4480984, 85.665862],
    [47.4533638, 85.6498749],
    [47.4535172, 85.6500009],
    [47.4535798, 85.6501773],
    [47.4536253, 85.6503202],
    [47.4537389, 85.6503285],
    [47.453898, 85.6501436],
    [47.453915, 85.6497066],
    [47.454057, 85.64953],
    [47.4541934, 85.6495552],
    [47.454398, 85.6497652],
    [47.4545741, 85.6497147],
    [47.4546896, 85.649754],
    [47.4546534, 85.6499599],
    [47.454726, 85.6500136],
    [47.4548047, 85.649942],
    [47.4548894, 85.6499688],
    [47.4549863, 85.6501388],
    [47.4551255, 85.6502104],
    [47.455301, 85.6499418],
    [47.4554341, 85.6497716],
    [47.4556762, 85.6496283],
    [47.4556944, 85.6495388],
    [47.4556883, 85.6494134],
    [47.4558759, 85.6491269],
    [47.4561483, 85.6491626],
    [47.4562754, 85.6491178],
    [47.4564388, 85.6491177],
    [47.4566203, 85.6489834],
    [47.4569108, 85.64884],
    [47.4570258, 85.6486072],
    [47.4571166, 85.6484013],
    [47.4571286, 85.6481506],
    [47.4571613, 85.6480126],
    [47.4571371, 85.6478962],
    [47.4571915, 85.6476545],
    [47.4573247, 85.6476366],
    [47.4573549, 85.647547],
    [47.4574517, 85.64735],
    [47.4574759, 85.6472068],
    [47.4574759, 85.6470098],
    [47.4574516, 85.6469024],
    [47.4575, 85.6468129],
    [47.4575242, 85.6467323],
    [47.4575968, 85.6467323],
    [47.4576937, 85.646768],
    [47.4577656, 85.6465537],
    [47.4580001, 85.6466975],
    [47.4580443, 85.6464881],
    [47.4582434, 85.6464095],
    [47.458323, 85.6462982],
    [47.4583495, 85.6462197],
    [47.4584114, 85.6461804],
    [47.458699, 85.6460952],
    [47.4589866, 85.6461867],
    [47.4592122, 85.6461081],
    [47.4593139, 85.6460033],
    [47.4593537, 85.6458855],
    [47.4594908, 85.6456761],
    [47.4594465, 85.6453751],
    [47.4594002, 85.6451016],
    [47.4594488, 85.644709],
    [47.4594886, 85.644709],
    [47.459524, 85.6448268],
    [47.4597496, 85.6450099],
    [47.4598735, 85.6449967],
    [47.4598823, 85.6449247],
    [47.4598071, 85.6448266],
    [47.4598381, 85.6447677],
    [47.4600548, 85.6448069],
    [47.4601433, 85.6447545],
    [47.4601875, 85.6446563],
    [47.4601742, 85.6444993],
    [47.4600547, 85.6441656],
    [47.4599839, 85.6440283],
    [47.4599839, 85.6439563],
    [47.4600546, 85.6439235],
    [47.4601431, 85.644002],
    [47.4601741, 85.6440871],
    [47.4603201, 85.6441524],
    [47.4603732, 85.6440935],
    [47.4603555, 85.6439888],
    [47.460519, 85.6435045],
    [47.4606075, 85.6432231],
    [47.4607622, 85.6428893],
    [47.4608905, 85.6426799],
    [47.4610143, 85.6426078],
    [47.4611382, 85.6426143],
    [47.4612267, 85.6428498],
    [47.4612931, 85.6429348],
    [47.4613948, 85.6428795],
    [47.4615053, 85.6424149],
    [47.461576, 85.6423101],
    [47.461753, 85.6422446],
    [47.4619387, 85.6421856],
    [47.4620714, 85.642022],
    [47.4622749, 85.6419106],
    [47.4625845, 85.641701],
    [47.4627525, 85.6413541],
    [47.4627746, 85.6412363],
    [47.4629073, 85.6411708],
    [47.462987, 85.6412428],
    [47.4631241, 85.6411969],
    [47.4632258, 85.6409678],
    [47.4634027, 85.6406154],
    [47.4633894, 85.6404322],
    [47.463438, 85.6403733],
    [47.4634292, 85.6403013],
    [47.4634822, 85.6402162],
    [47.4634822, 85.6401573],
    [47.4634247, 85.640105],
    [47.4634335, 85.6399611],
    [47.463491, 85.6400134],
    [47.4635264, 85.6400003],
    [47.4635219, 85.6398498],
    [47.4635529, 85.6397908],
    [47.4636502, 85.6397188],
    [47.4637431, 85.6395944],
    [47.4638094, 85.6395224],
    [47.4639598, 85.6394896],
    [47.4641057, 85.6392867],
    [47.4641986, 85.6392342],
    [47.4642871, 85.6392865],
    [47.4643667, 85.639208],
    [47.4645127, 85.6392013],
    [47.4646056, 85.6390573],
    [47.4646763, 85.6389918],
    [47.4647471, 85.639018],
    [47.4647604, 85.639103],
    [47.4647472, 85.6391554],
    [47.4648091, 85.639332],
    [47.4648888, 85.6392862],
    [47.4648843, 85.6391815],
    [47.4649551, 85.6390571],
    [47.4649905, 85.6391487],
    [47.4649905, 85.6392207],
    [47.4650303, 85.6392534],
    [47.4650347, 85.6391618],
    [47.4651453, 85.6391682],
    [47.4651984, 85.6392467],
    [47.4652913, 85.63907],
    [47.46538, 85.639004],
    [47.4654419, 85.6389189],
    [47.4655083, 85.6389254],
    [47.4655879, 85.63903],
    [47.4656676, 85.6390365],
    [47.4657516, 85.6389841],
    [47.4658312, 85.6390037],
    [47.4659286, 85.6391018],
    [47.4660967, 85.639069],
    [47.4662427, 85.6391278],
    [47.4662958, 85.639095],
    [47.4663312, 85.639023],
    [47.4663887, 85.6389968],
    [47.466486, 85.6390818],
    [47.4664993, 85.6391604],
    [47.4663888, 85.6393895],
    [47.4663092, 85.6395204],
    [47.4662871, 85.6396709],
    [47.4663181, 85.6398214],
    [47.4663889, 85.6398672],
    [47.4664508, 85.6398737],
    [47.4665393, 85.639854],
    [47.4666189, 85.6399063],
    [47.4666765, 85.6399586],
    [47.4667119, 85.6399586],
    [47.4667472, 85.6399128],
    [47.4667782, 85.6398211],
    [47.4669728, 85.639592],
    [47.4671408, 85.6393825],
    [47.4672116, 85.6392646],
    [47.4672513, 85.6391141],
    [47.4672513, 85.6390225],
    [47.4672291, 85.6388393],
    [47.467238, 85.6386953],
    [47.4673087, 85.638584],
    [47.4674635, 85.6384923],
    [47.467583, 85.6385184],
    [47.4676582, 85.6386099],
    [47.4677247, 85.639068],
    [47.4677291, 85.6390811],
    [47.4677866, 85.6391072],
    [47.4678663, 85.639153],
    [47.468022, 85.6392204],
    [47.4680873, 85.6392022],
    [47.4681811, 85.6390814],
    [47.4684708, 85.6389786],
    [47.4686748, 85.6389664],
    [47.4687442, 85.6389181],
    [47.4687849, 85.6387973],
    [47.4688664, 85.6383989],
    [47.468948, 85.6382842],
    [47.468948, 85.638097],
    [47.4690294, 85.6376382],
    [47.4690824, 85.6375054],
    [47.4690824, 85.637276],
    [47.4691354, 85.6371009],
    [47.4692091, 85.637007],
    [47.4693314, 85.6369164],
    [47.4694824, 85.6368801],
    [47.4696211, 85.6368196],
    [47.4697476, 85.636723],
    [47.469768, 85.6366686],
    [47.4697883, 85.6364755],
    [47.4698862, 85.6363426],
    [47.470029, 85.6362701],
    [47.4702249, 85.6363182],
    [47.470384, 85.6361491],
    [47.4704492, 85.6360223],
    [47.4706736, 85.6357867],
    [47.4707919, 85.6357685],
    [47.4708491, 85.6358771],
    [47.470899, 85.6358861],
    [47.4709194, 85.6359102],
    [47.4709235, 85.6359826],
    [47.4709807, 85.6360792],
    [47.4709644, 85.6361939],
    [47.470997, 85.6362603],
    [47.4710827, 85.6362662],
    [47.4711807, 85.636399],
    [47.4712215, 85.6364895],
    [47.4712909, 85.6365076],
    [47.471397, 85.6366101],
    [47.4716133, 85.6367247],
    [47.471695, 85.6367427],
    [47.4717562, 85.6368453],
    [47.4718134, 85.6369298],
    [47.4718868, 85.6370384],
    [47.472042, 85.6373039],
    [47.4720868, 85.6372797],
    [47.472042, 85.6373039],
    [47.4718868, 85.6370384],
    [47.4718134, 85.6369298],
    [47.4717562, 85.6368453],
    [47.471695, 85.6367427],
    [47.4716133, 85.6367247],
    [47.471397, 85.6366101],
    [47.4712909, 85.6365076],
    [47.4712215, 85.6364895],
    [47.4711807, 85.636399],
    [47.4710827, 85.6362662],
    [47.470997, 85.6362603],
    [47.4709644, 85.6361939],
    [47.4709807, 85.6360792],
    [47.4709235, 85.6359826],
    [47.4709194, 85.6359102],
    [47.470899, 85.6358861],
    [47.4708491, 85.6358771],
    [47.4707919, 85.6357685],
    [47.4706736, 85.6357867],
    [47.4704492, 85.6360223],
    [47.470384, 85.6361491],
    [47.4702249, 85.6363182],
    [47.470029, 85.6362701],
    [47.4698862, 85.6363426],
    [47.4697883, 85.6364755],
    [47.469768, 85.6366686],
    [47.4697476, 85.636723],
    [47.4696211, 85.6368196],
    [47.4694824, 85.6368801],
    [47.4693314, 85.6369164],
    [47.4692091, 85.637007],
    [47.4691354, 85.6371009],
    [47.4690824, 85.637276],
    [47.4690824, 85.6375054],
    [47.4690294, 85.6376382],
    [47.468948, 85.638097],
    [47.468948, 85.6382842],
    [47.4688664, 85.6383989],
    [47.4687849, 85.6387973],
    [47.4687442, 85.6389181],
    [47.4686748, 85.6389664],
    [47.4684708, 85.6389786],
    [47.4681811, 85.6390814],
    [47.4680873, 85.6392022],
    [47.468022, 85.6392204],
    [47.4678663, 85.639153],
    [47.4677866, 85.6391072],
    [47.4677291, 85.6390811],
    [47.4677247, 85.639068],
    [47.4676582, 85.6386099],
    [47.467583, 85.6385184],
    [47.4674635, 85.6384923],
    [47.4673087, 85.638584],
    [47.467238, 85.6386953],
    [47.4672291, 85.6388393],
    [47.4672513, 85.6390225],
    [47.4672513, 85.6391141],
    [47.4672116, 85.6392646],
    [47.4671408, 85.6393825],
    [47.4669728, 85.639592],
    [47.4667782, 85.6398211],
    [47.4667472, 85.6399128],
    [47.4667119, 85.6399586],
    [47.4666765, 85.6399586],
    [47.4666189, 85.6399063],
    [47.4665393, 85.639854],
    [47.4664508, 85.6398737],
    [47.4663889, 85.6398672],
    [47.4663181, 85.6398214],
    [47.4662871, 85.6396709],
    [47.4663092, 85.6395204],
    [47.4663888, 85.6393895],
    [47.4664993, 85.6391604],
    [47.466486, 85.6390818],
    [47.4663887, 85.6389968],
    [47.4663312, 85.639023],
    [47.4662958, 85.639095],
    [47.4662427, 85.6391278],
    [47.4660967, 85.639069],
    [47.4659286, 85.6391018],
    [47.4658312, 85.6390037],
    [47.4657516, 85.6389841],
    [47.4656676, 85.6390365],
    [47.4655879, 85.63903],
    [47.4655083, 85.6389254],
    [47.4654419, 85.6389189],
    [47.46538, 85.639004],
    [47.4652913, 85.63907],
    [47.4651984, 85.6392467],
    [47.4651453, 85.6391682],
    [47.4650347, 85.6391618],
    [47.4650303, 85.6392534],
    [47.4649905, 85.6392207],
    [47.4649905, 85.6391487],
    [47.4649551, 85.6390571],
    [47.4648843, 85.6391815],
    [47.4648888, 85.6392862],
    [47.4648091, 85.639332],
    [47.4647472, 85.6391554],
    [47.4647604, 85.639103],
    [47.4647471, 85.639018],
    [47.4646763, 85.6389918],
    [47.4646056, 85.6390573],
    [47.4645127, 85.6392013],
    [47.4643667, 85.639208],
    [47.4642871, 85.6392865],
    [47.4641986, 85.6392342],
    [47.4641057, 85.6392867],
    [47.4639598, 85.6394896],
    [47.4638094, 85.6395224],
    [47.4637431, 85.6395944],
    [47.4636502, 85.6397188],
    [47.4635529, 85.6397908],
    [47.4635219, 85.6398498],
    [47.4635264, 85.6400003],
    [47.463491, 85.6400134],
    [47.4634335, 85.6399611],
    [47.4634247, 85.640105],
    [47.4634822, 85.6401573],
    [47.4634822, 85.6402162],
    [47.4634292, 85.6403013],
    [47.463438, 85.6403733],
    [47.4633894, 85.6404322],
    [47.4634027, 85.6406154],
    [47.4632258, 85.6409678],
    [47.4631241, 85.6411969],
    [47.462987, 85.6412428],
    [47.4629073, 85.6411708],
    [47.4627746, 85.6412363],
    [47.4627525, 85.6413541],
    [47.4625845, 85.641701],
    [47.4622749, 85.6419106],
    [47.4620714, 85.642022],
    [47.4619387, 85.6421856],
    [47.461753, 85.6422446],
    [47.461576, 85.6423101],
    [47.4615053, 85.6424149],
    [47.4613948, 85.6428795],
    [47.4612931, 85.6429348],
    [47.4612267, 85.6428498],
    [47.4611382, 85.6426143],
    [47.4610143, 85.6426078],
    [47.4608905, 85.6426799],
    [47.4607622, 85.6428893],
    [47.4606075, 85.6432231],
    [47.460519, 85.6435045],
    [47.4603555, 85.6439888],
    [47.4603732, 85.6440935],
    [47.4603201, 85.6441524],
    [47.4601741, 85.6440871],
    [47.4601431, 85.644002],
    [47.4600546, 85.6439235],
    [47.4599839, 85.6439563],
    [47.4599839, 85.6440283],
    [47.4600547, 85.6441656],
    [47.4601742, 85.6444993],
    [47.4601875, 85.6446563],
    [47.4601433, 85.6447545],
    [47.4600548, 85.6448069],
    [47.4598381, 85.6447677],
    [47.4598071, 85.6448266],
    [47.4598823, 85.6449247],
    [47.4598735, 85.6449967],
    [47.4597496, 85.6450099],
    [47.459524, 85.6448268],
    [47.4594886, 85.644709],
    [47.4594488, 85.644709],
    [47.4594002, 85.6451016],
    [47.4594465, 85.6453751],
    [47.4594908, 85.6456761],
    [47.4593537, 85.6458855],
    [47.4593139, 85.6460033],
    [47.4592122, 85.6461081],
    [47.4589866, 85.6461867],
    [47.458699, 85.6460952],
    [47.4584114, 85.6461804],
    [47.4583495, 85.6462197],
    [47.458323, 85.6462982],
    [47.4582434, 85.6464095],
    [47.4580443, 85.6464881],
    [47.4580001, 85.6466975],
    [47.4577656, 85.6465537],
    [47.4576937, 85.646768],
    [47.4575968, 85.6467323],
    [47.4575242, 85.6467323],
    [47.4575, 85.6468129],
    [47.4574516, 85.6469024],
    [47.4574759, 85.6470098],
    [47.4574759, 85.6472068],
    [47.4574517, 85.64735],
    [47.4573549, 85.647547],
    [47.4573247, 85.6476366],
    [47.4571915, 85.6476545],
    [47.4571371, 85.6478962],
    [47.4571613, 85.6480126],
    [47.4571286, 85.6481506],
    [47.4571166, 85.6484013],
    [47.4570258, 85.6486072],
    [47.4569108, 85.64884],
    [47.4566203, 85.6489834],
    [47.4564388, 85.6491177],
    [47.4562754, 85.6491178],
    [47.4561483, 85.6491626],
    [47.4558759, 85.6491269],
    [47.4556883, 85.6494134],
    [47.4556944, 85.6495388],
    [47.4556762, 85.6496283],
    [47.4554341, 85.6497716],
    [47.455301, 85.6499418],
    [47.4551255, 85.6502104],
    [47.4549863, 85.6501388],
    [47.4548894, 85.6499688],
    [47.4548047, 85.649942],
    [47.454726, 85.6500136],
    [47.4546534, 85.6499599],
    [47.4546896, 85.649754],
    [47.4545741, 85.6497147],
    [47.454398, 85.6497652],
    [47.4541934, 85.6495552],
    [47.454057, 85.64953],
    [47.453915, 85.6497066],
    [47.453898, 85.6501436],
    [47.4537389, 85.6503285],
    [47.4536253, 85.6503202],
    [47.4535798, 85.6501773],
    [47.4535172, 85.6500009],
    [47.4533638, 85.6498749],
    [47.4532388, 85.6499169],
  ],
});
mapRegions.push({
  id: 19,
  type: MapObjectType.DistrictInfo,
  regionType: REGION_TYPE_DISTRICT,
  title: "Курчатов Г.А.",
  centerTitle: "город Курчатов",
  centerTitleShort: "г. Курчатов",
  centerGeometry: [50.75470351914962, 78.54400634765624],
  geometry: [
    [50.80419899467614, 78.49388122558594],
    [50.78336517951859, 78.47122192382812],
    [50.77858945556799, 78.47328186035156],
    [50.7686022747888, 78.45268249511719],
    [50.75948168204403, 78.45680236816406],
    [50.73688970495706, 78.45611572265625],
    [50.680797145321655, 78.5247802734375],
    [50.68471275767546, 78.60443115234375],
    [50.68427770577119, 78.60923767089844],
    [50.69471783819287, 78.68339538574219],
    [50.700371939869676, 78.69094848632812],
    [50.7295015014743, 78.67927551269531],
    [50.77033932897997, 78.61541748046875],
    [50.8002933616934, 78.55224609374999],
    [50.802029238878184, 78.53096008300781],
    [50.80419899467614, 78.49388122558594],
  ],
});

const mapLocality = [];

mapLocality.push({
  id: 1,
  title: "с. Урджар",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [47.0786562, 81.6347694],
});
mapLocality.push({
  id: 2,
  title: "с. Аксуат",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [47.7509818, 82.8073024],
});
mapLocality.push({
  id: 3,
  title: "г. Аягоз",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [47.94348672, 80.4233551],
});
mapLocality.push({
  id: 4,
  title: "с. Курчум",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [48.5670659, 83.6536788],
});
mapLocality.push({
  id: 5,
  title: "с. Кокпекты",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [48.7564151, 82.3830413],
});
mapLocality.push({
  id: 6,
  title: "с. Калбатау",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [49.3316107, 81.572113],
});
mapLocality.push({
  id: 7,
  title: "с. Караул",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [48.9445458, 79.2547702],
});
mapLocality.push({
  id: 8,
  title: "г. Семей",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.4078064, 80.2417373],
});
mapLocality.push({
  id: 9,
  title: "с. Бескарагай",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.8850589, 79.4805908],
});
mapLocality.push({
  id: 10,
  title: "с. Бородулиха",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.7093953, 80.9370517],
});
mapLocality.push({
  id: 11,
  title: "г. Шемонаиха",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.620499, 81.916809],
});
mapLocality.push({
  id: 12,
  title: "п. Глубокое",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.1423659, 82.3157501],
});
mapLocality.push({
  id: 13,
  title: "г. Усть-Каменогорск",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [49.9568527, 82.6147842],
});
mapLocality.push({
  id: 14,
  title: "г. Риддер",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.330998, 83.495922],
});
mapLocality.push({
  id: 15,
  title: "г. Зыряновск",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [49.725034, 84.2752647],
});
mapLocality.push({
  id: 16,
  title: "с. Улкен Нарын",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [49.2091868, 84.5043468],
});
mapLocality.push({
  id: 17,
  katoId: 48133,
  title: "п. Касыма Кайсенова",
  isCity: false,
  isDistrictCenter: true,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.8750571, 82.4788284],
});
mapLocality.push({
  id: 18,
  title: "г. Зайсан",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [47.4780001, 84.8714447],
});
mapLocality.push({
  id: 19,
  title: "г. Курчатов",
  isCity: true,
  isDistrictCenter: true,
  type: MapObjectType.DistrictInfo,
  geometry: [50.75470351914962, 78.54400634765624],
});

mapLocality.push({
  id: 101,
  katoId: 48404,
  title: "с. Айыртау",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.8342, 82.279],
});
mapLocality.push({
  id: 102,
  katoId: 48233,
  title: "с. Ново-Азовое",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.1969, 81.7386],
});
mapLocality.push({
  id: 103,
  katoId: 48250,
  title: "с. Алмасай",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.7878, 82.4575],
});
mapLocality.push({
  id: 104,
  katoId: 48730,
  title: "с. Герасимовка",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.9674, 82.4436],
});
mapLocality.push({
  id: 105,
  katoId: 48612,
  title: "с. Таврическое",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.1638, 82.0702],
});
mapLocality.push({
  id: 106,
  katoId: 48682,
  title: "с. Таргын",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.4917, 82.8559],
});
mapLocality.push({
  id: 107,
  katoId: 48473,
  title: "с. Бозанбай",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.5279, 82.4278],
});
mapLocality.push({
  id: 108,
  katoId: 48451,
  title: "с. Каменка",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.9208, 81.8893],
});
mapLocality.push({
  id: 109,
  katoId: 48369,
  title: "с. Уланское",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.7382, 82.2361],
});
mapLocality.push({
  id: 110,
  katoId: 48272,
  title: "п. Асубулак",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.5368, 83.0102],
});
mapLocality.push({
  id: 111,
  katoId: 48518,
  title: "п. Огневка",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.6807, 83.0318],
});
mapLocality.push({
  id: 112,
  katoId: 48314,
  title: "с. Привольное",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.101, 81.5626],
});
mapLocality.push({
  id: 113,
  katoId: 48175,
  title: "с. Сагыр",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.7131, 82.6788],
});
mapLocality.push({
  id: 114,
  katoId: 48589,
  title: "с. Саратовка",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.0552, 82.3357],
});
mapLocality.push({
  id: 115,
  katoId: 48545,
  title: "с. Восточное",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.1336, 82.215],
});

mapLocality.push({
  id: 116,
  katoId: 50702,
  title: "ВЕРХ-УБИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.47617, 82.415496],
});
mapLocality.push({
  id: 117,
  katoId: 50587,
  title: "ВОЛЧАНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.567417, 82.143117],
});
mapLocality.push({
  id: 118,
  katoId: 50652,
  title: "ВЫДРИХИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.518279, 82.173427],
});
mapLocality.push({
  id: 119,
  katoId: 50757,
  title: "ЗЕВАКИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.224745, 81.856627],
});
mapLocality.push({
  id: 120,
  katoId: 50796,
  title: "КАМЕНЕВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.480985, 81.833695],
});
mapLocality.push({
  id: 121,
  katoId: 50857,
  title: "ОКТЯБРЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.65008, 81.866095],
});
mapLocality.push({
  id: 122,
  katoId: 50899,
  title: "РАЗИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.680945, 81.916958],
});
mapLocality.push({
  id: 123,
  katoId: 50938,
  title: "ПЕРВОМАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.266135, 82.000941],
});
mapLocality.push({
  id: 124,
  katoId: 51023,
  title: "УСТЬ-ТАЛОВКА",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.558187, 81.859717],
});
mapLocality.push({
  id: 125,
  katoId: 36560,
  title: "АЙГЫЗСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.72, 79.483056],
});
mapLocality.push({
  id: 126,
  katoId: 36670,
  title: "АКШИЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.934722, 80.926944],
});
mapLocality.push({
  id: 127,
  katoId: 36588,
  title: "АКШАТАУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.085, 80.0204],
});
mapLocality.push({
  id: 128,
  katoId: 36645,
  title: "АКШАУЛИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.2756, 80.5421],
});
mapLocality.push({
  id: 129,
  katoId: 36743,
  title: "БАРШАТАССКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.173318, 78.665766],
});
mapLocality.push({
  id: 130,
  katoId: 36775,
  title: "БИДАЙЫКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.896523, 79.432064],
});
mapLocality.push({
  id: 131,
  katoId: 36807,
  title: "ЕМЕЛТАУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.726979, 77.4585254],
});
mapLocality.push({
  id: 132,
  katoId: 36839,
  title: "КАРАГАШСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.4333, 77.275],
});
mapLocality.push({
  id: 133,
  katoId: 36846,
  title: "КОПИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.1480934, 79.5759852],
});
mapLocality.push({
  id: 134,
  katoId: 36915,
  title: "МАДЕНИЕТСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.875941, 78.622242],
});
mapLocality.push({
  id: 135,
  katoId: 37052,
  title: "ОРКЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.3406, 79.0424],
});
mapLocality.push({
  id: 136,
  katoId: 37057,
  title: "САРЫАРКИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.0849, 81.0544],
});
mapLocality.push({
  id: 137,
  katoId: 37160,
  title: "ТАРБАГАТАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.803947, 81.278669],
});
mapLocality.push({
  id: 138,
  katoId: 36709,
  title: "БАЙКОШКАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.39489, 78.752175],
});
mapLocality.push({
  id: 139,
  katoId: 36868,
  title: "КОСАГАШСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.009133, 78.604977],
});
mapLocality.push({
  id: 140,
  katoId: 36976,
  title: "МАЛГЕЛЬДИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.446898, 78.187817],
});
mapLocality.push({
  id: 141,
  katoId: 37018,
  title: "МЫНБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.3331, 80.4926],
});
mapLocality.push({
  id: 142,
  katoId: 37082,
  title: "МАМЫРСУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.951147, 80.382033],
});
mapLocality.push({
  id: 143,
  katoId: 47055,
  title: "АКСУАТСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.761529, 82.806056],
});
mapLocality.push({
  id: 144,
  katoId: 47134,
  title: "АКЖАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.577986, 83.684016],
});
mapLocality.push({
  id: 145,
  katoId: 47386,
  title: "КАБАНБАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.8622183, 83.5669263],
});
mapLocality.push({
  id: 146,
  katoId: 47039,
  title: "ЫРГЫЗБАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.1296634, 82.1517298],
});
mapLocality.push({
  id: 147,
  katoId: 47529,
  title: "КАРАСУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.741246, 83.43679],
});
mapLocality.push({
  id: 148,
  katoId: 47993,
  title: "САТПАЕВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.888268, 82.607717],
});
mapLocality.push({
  id: 149,
  katoId: 47452,
  title: "КОКЖИРИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.4912, 82.3616],
});
mapLocality.push({
  id: 150,
  katoId: 47630,
  title: "КУЙГАНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.416667, 83.583333],
});
mapLocality.push({
  id: 151,
  katoId: 47261,
  title: "ЖАНААУЛСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.220762, 84.113886],
});
mapLocality.push({
  id: 152,
  katoId: 47855,
  title: "МАНЫРАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.430245, 83.862017],
});
mapLocality.push({
  id: 153,
  katoId: 47912,
  title: "ОЙШИЛИКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.54371, 83.004799],
});
mapLocality.push({
  id: 154,
  katoId: 47600,
  title: "КИНДИКТИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.2013, 81.3925],
});
mapLocality.push({
  id: 155,
  katoId: 47319,
  title: "ЖЕТЫАРАЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.48210255, 83.7367211984683],
});
mapLocality.push({
  id: 156,
  katoId: 47684,
  title: "КУМГОЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.740525, 82.840417],
});
mapLocality.push({
  id: 157,
  katoId: 47218,
  title: "ЕКПИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.777814, 82.73809],
});
mapLocality.push({
  id: 158,
  katoId: 47753,
  title: "КЫЗЫЛ КЕСИКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.877871, 82.055065],
});
mapLocality.push({
  id: 159,
  katoId: 46946,
  title: "АККАЙНАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.1321, 85.5312],
});
mapLocality.push({
  id: 160,
  katoId: 46912,
  title: "АКСУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.2154, 85.2441],
});

mapLocality.push({
  id: 162,
  katoId: 46415,
  title: "ЖАМБЫЛСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.239935, 86.308632],
});

mapLocality.push({
  id: 164,
  katoId: 46439,
  title: "КАТОН-КАРАГАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.1725, 85.600454],
});
mapLocality.push({
  id: 165,
  katoId: 46661,
  title: "НОВОХАЙРУЗОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.257595, 84.346074],
});
mapLocality.push({
  id: 166,
  katoId: 46749,
  title: "СОЛДАТОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.215531, 85.075407],
});
mapLocality.push({
  id: 167,
  katoId: 46763,
  title: "СОЛОНОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.30259, 84.747944],
});
mapLocality.push({
  id: 168,
  katoId: 46810,
  title: "УЛКЕН НАРЫНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.213089, 84.510708],
});

mapLocality.push({
  id: 169,
  katoId: 46717,
  title: "УРЫЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.241423, 86.338735],
});

mapLocality.push({
  id: 170,
  katoId: 46524,
  title: "БЕЛКАРАГАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.184815, 85.299204],
});
mapLocality.push({
  id: 171,
  katoId: 46489,
  title: "КОРОБИХИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.460374, 85.059175],
});
mapLocality.push({
  id: 172,
  katoId: 46636,
  title: "НОВОПОЛЯКОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.482953, 84.692895],
});
mapLocality.push({
  id: 173,
  katoId: 46578,
  title: "АЛТЫНБЕЛ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.207758, 84.856255],
});
mapLocality.push({
  id: 174,
  katoId: 41990,
  title: "КАРАБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.551434, 84.682376],
});
mapLocality.push({
  id: 175,
  katoId: 41894,
  title: "КЕНСАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.478818, 84.771956],
});
mapLocality.push({
  id: 176,
  katoId: 42427,
  title: "ШИЛИКТИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.154566, 84.467947],
});

mapLocality.push({
  id: 177,
  katoId: 42244,
  title: "АЙНАБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.504262, 84.993553],
});

mapLocality.push({
  id: 178,
  katoId: 41701,
  title: "БИРЖАНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.4321, 84.5609],
});

mapLocality.push({
  id: 179,
  katoId: 42147,
  title: "САРЫТЕРЕКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.2956, 85.1028],
});
mapLocality.push({
  id: 180,
  katoId: 41770,
  title: "КАРАТАЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.612743, 85.173791],
});
mapLocality.push({
  id: 181,
  katoId: 42357,
  title: "ДАЙЫРОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.3724, 84.5917],
});
mapLocality.push({
  id: 182,
  katoId: 39823,
  title: "УШАНОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.964982, 82.739106],
});
mapLocality.push({
  id: 183,
  katoId: 40102,
  title: "ЧЕРЕМШАНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.275675, 83.051152],
});
mapLocality.push({
  id: 184,
  katoId: 39958,
  title: "ФРУНЗЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.112376, 82.951601],
});

mapLocality.push({
  id: 185,
  katoId: 39788,
  title: "СЕКИСОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.329764, 82.591188],
});

mapLocality.push({
  id: 186,
  katoId: 39731,
  title: "ОПЫТНОПОЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.039933, 82.547701],
});
mapLocality.push({
  id: 187,
  katoId: 39697,
  title: "МАЛОУБИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.450745, 82.663925],
});
mapLocality.push({
  id: 188,
  katoId: 39658,
  title: "КУЙБЫШЕВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.374618, 82.722917],
});
mapLocality.push({
  id: 189,
  katoId: 39447,
  title: "КРАСНОЯРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.250724, 82.250044],
});
mapLocality.push({
  id: 190,
  katoId: 39394,
  title: "КОЖОХОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.196844, 82.293477],
});
mapLocality.push({
  id: 191,
  katoId: 39285,
  title: "КИРОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.042751, 82.467463],
});
mapLocality.push({
  id: 192,
  katoId: 39239,
  title: "КАЛИНИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.2324386, 82.12422],
});

mapLocality.push({
  id: 193,
  katoId: 39201,
  title: "ВЕСЕЛОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.3067864, 82.33149],
});
mapLocality.push({
  id: 194,
  katoId: 39121,
  title: "БОБРОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.164615, 82.718102],
});

mapLocality.push({
  id: 195,
  katoId: 37919,
  title: "АНДРЕЕВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.6439, 81.135253],
});

mapLocality.push({
  id: 196,
  katoId: 38028,
  title: "БАКИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [51.046239, 80.996849],
});

mapLocality.push({
  id: 197,
  katoId: 37942,
  title: "БЕЛЬ-АГАЧСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.768119, 80.665955],
});
mapLocality.push({
  id: 144,
  katoId: 37830,
  title: "БОРОДУЛИХИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.713574, 80.936249],
});
mapLocality.push({
  id: 198,
  katoId: 38068,
  title: "ДМИТРИЕВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.759402, 80.831676],
});
mapLocality.push({
  id: 199,
  katoId: 38144,
  title: "ЖЕРНОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.520661, 81.121149],
});
mapLocality.push({
  id: 200,
  katoId: 38170,
  title: "ЗУБАИРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.810154, 80.297133],
});
mapLocality.push({
  id: 201,
  katoId: 38194,
  title: "КРАСНОЯРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.500423, 81.675652],
});

mapLocality.push({
  id: 202,
  katoId: 38262,
  title: "НОВОДВОРОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.89524, 81.050173],
});

mapLocality.push({
  id: 203,
  katoId: 38298,
  title: "НОВОПОКРОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.669511, 80.463816],
});
mapLocality.push({
  id: 204,
  katoId: 38329,
  title: "НОВОШУЛЬБИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.541881, 81.327735],
});
mapLocality.push({
  id: 205,
  katoId: 38396,
  title: "ПЕРЕМЕНОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.844718, 81.275444],
});
mapLocality.push({
  id: 206,
  katoId: 38433,
  title: "ПЕТРОПАВЛОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.745138, 80.287656],
});
mapLocality.push({
  id: 207,
  katoId: 38483,
  title: "ПОДБОРНЫЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.704414, 80.745501],
});
mapLocality.push({
  id: 208,
  katoId: 38511,
  title: "СТЕПНОЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.800574, 80.49549],
});
mapLocality.push({
  id: 209,
  katoId: 38556,
  title: "ТАВРИЧЕСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.743343, 81.5208],
});
mapLocality.push({
  id: 210,
  katoId: 38098,
  title: "ЖЕЗКЕНТ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.920259, 81.39377],
});
mapLocality.push({
  id: 211,
  katoId: 50528,
  title: "ВАВИЛОНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.569934, 81.802863],
});

mapLocality.push({
  id: 211,
  katoId: 35458,
  title: "АРХАТСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.0342026, 80.0723091],
});
mapLocality.push({
  id: 149,
  katoId: 35256,
  title: "КАРАУЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.5646, 79.1509],
});
mapLocality.push({
  id: 150,
  katoId: 35652,
  title: "КАСКАБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.5657712, 79.8698673],
});
mapLocality.push({
  id: 151,
  katoId: 35332,
  title: "КЕНГИРБАЙБИЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.90322, 79.244901],
});
mapLocality.push({
  id: 152,
  katoId: 36057,
  title: "КОКБАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.939545, 79.25878],
});
mapLocality.push({
  id: 153,
  katoId: 35823,
  title: "КУНДЫЗДИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.5638, 79.1558],
});
mapLocality.push({
  id: 154,
  katoId: 35979,
  title: "МЕДЕУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.2504109, 78.8726728],
});
mapLocality.push({
  id: 155,
  katoId: 36389,
  title: "САРЖАЛСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.6000366, 78.7388449],
});
mapLocality.push({
  id: 156,
  katoId: 36290,
  title: "ТОКТАМЫССКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.217773, 78.347969],
});
mapLocality.push({
  id: 157,
  katoId: 37329,
  title: "БАСКОЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [51.184444, 79.295],
});
mapLocality.push({
  id: 158,
  katoId: 37373,
  title: "БЕГЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [51.1841762, 79.0452681],
});
mapLocality.push({
  id: 159,
  katoId: 37252,
  title: "БЕСКАРАГАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [51.06266275, 78.9802431782024],
});
mapLocality.push({
  id: 160,
  katoId: 37409,
  title: "ГЛУХОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.4958955, 79.8700189],
});

mapLocality.push({
  id: 162,
  katoId: 37470,
  title: "ДОЛОНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.6639711, 79.3106964],
});

mapLocality.push({
  id: 164,
  katoId: 37738,
  title: "ЕРНАЗАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [51.2701, 79.2943],
});
mapLocality.push({
  id: 165,
  katoId: 37672,
  title: "ЖЕТИЖАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.8936147, 78.3127821],
});
mapLocality.push({
  id: 166,
  katoId: 37522,
  title: "КАНОНЕРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.7236701, 79.6888664],
});
mapLocality.push({
  id: 167,
  katoId: 37605,
  title: "КАРАБАССКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [51.044002, 79.09923],
});
mapLocality.push({
  id: 168,
  katoId: 37564,
  title: "МАЛОВЛАДИМИРОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.9502332, 79.4030867],
});

mapLocality.push({
  id: 169,
  katoId: 40389,
  title: "АКЖАЛСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.218333, 81.421944],
});

mapLocality.push({
  id: 170,
  katoId: 40865,
  title: "АРШАЛИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.6710553, 80.8039775],
});
mapLocality.push({
  id: 171,
  katoId: 41517,
  title: "АУЭЗОВА",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.7108938, 81.579474],
});
mapLocality.push({
  id: 172,
  katoId: 40431,
  title: "БЕЛТЕРЕКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.2172372, 80.7301053],
});
mapLocality.push({
  id: 173,
  katoId: 40550,
  title: "БИРЛИКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.060278, 81.645278],
});
mapLocality.push({
  id: 174,
  katoId: 40599,
  title: "БИРЛИКШИЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.55, 81.7],
});
mapLocality.push({
  id: 175,
  katoId: 40528,
  title: "БОЖЫГУРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.766667, 80.616667],
});
mapLocality.push({
  id: 176,
  katoId: 40156,
  title: "ЖАНГИЗ-ТОБЕ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.2110729, 81.2170862],
});

mapLocality.push({
  id: 177,
  katoId: 40186,
  title: "ЖАРМА",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.7823103, 80.8783224],
});

mapLocality.push({
  id: 178,
  katoId: 40811,
  title: "ЖАРЫКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.8095273, 81.5355411],
});

mapLocality.push({
  id: 179,
  katoId: 41312,
  title: "КАЛБАТАУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.3302263, 81.5707116],
});
mapLocality.push({
  id: 180,
  katoId: 40942,
  title: "КАПАНБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.9307211, 80.8942163],
});
mapLocality.push({
  id: 181,
  katoId: 41033,
  title: "КАРАСУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.9662462, 81.124413],
});
mapLocality.push({
  id: 182,
  katoId: 41101,
  title: "КАРАТОБИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.05, 82.1],
});
mapLocality.push({
  id: 183,
  katoId: 41133,
  title: "КЫЗЫЛАГАШСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.6175, 81.221944],
});
mapLocality.push({
  id: 184,
  katoId: 41540,
  title: "СУЫКБУЛАК",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.7087186, 81.0464],
});

mapLocality.push({
  id: 185,
  katoId: 41183,
  title: "УШБИИКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.4655442, 80.5753203],
});

mapLocality.push({
  id: 186,
  katoId: 41256,
  title: "ШАЛАБАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.716667, 81.516667],
});
mapLocality.push({
  id: 187,
  katoId: 40240,
  title: "ШАР",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.5832221, 81.0506253],
});
mapLocality.push({
  id: 188,
  katoId: 33379,
  title: "АБРАЛИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.257222, 77.621944],
});
mapLocality.push({
  id: 189,
  katoId: 33404,
  title: "АЙНАБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.033333, 79.9],
});
mapLocality.push({
  id: 190,
  katoId: 33410,
  title: "АКБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.166667, 77.950278],
});
mapLocality.push({
  id: 191,
  katoId: 33433,
  title: "АЛГАБАССКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.783333, 77.35],
});
mapLocality.push({
  id: 192,
  katoId: 33480,
  title: "ДОСТЫКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.4521486, 79.8193645],
});

mapLocality.push({
  id: 193,
  katoId: 33608,
  title: "ЖИЕНАЛИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.3099746, 80.2864831],
});
mapLocality.push({
  id: 194,
  katoId: 33631,
  title: "ЗНАМЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.0802945, 79.580019],
});

mapLocality.push({
  id: 195,
  katoId: 33524,
  title: "ИРТЫШСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.4413375, 79.9253918],
});

mapLocality.push({
  id: 196,
  katoId: 33719,
  title: "КАРАОЛЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.466667, 77.533333],
});

mapLocality.push({
  id: 197,
  katoId: 33664,
  title: "НОВОБАЖЕНОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.3832122, 81.0282617],
});
mapLocality.push({
  id: 144,
  katoId: 33736,
  title: "ОЗЕРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.3775797, 80.488583],
});
mapLocality.push({
  id: 198,
  katoId: 33795,
  title: "ПРИРЕЧНЫЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.3589619, 80.417591],
});
mapLocality.push({
  id: 199,
  katoId: 33360,
  title: "ЧАГАН",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.1950903, 79.3426014],
});
mapLocality.push({
  id: 200,
  katoId: 33338,
  title: "ШУЛЬБИНСК",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [50.3909906, 81.0675968],
});
mapLocality.push({
  id: 201,
  katoId: 48997,
  title: "АКЖАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.0783418, 81.5895798],
});

mapLocality.push({
  id: 202,
  katoId: 49040,
  title: "АКШОКИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.9341768, 82.8601002],
});

mapLocality.push({
  id: 203,
  katoId: 49075,
  title: "АЛТЫНШОКИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.1728764, 81.0644765],
});
mapLocality.push({
  id: 204,
  katoId: 50132,
  title: "БАРКЫТБЕЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.916667, 81.766667],
});
mapLocality.push({
  id: 205,
  katoId: 49122,
  title: "БАХТИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.6590081, 82.7345911],
});
mapLocality.push({
  id: 206,
  katoId: 49158,
  title: "БЕСТЕРЕКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.1658879, 81.6196624],
});
mapLocality.push({
  id: 207,
  katoId: 49232,
  title: "ЕГИНСУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.9561214, 81.4735469],
});
mapLocality.push({
  id: 208,
  katoId: 49275,
  title: "ЕЛТАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.8848841, 81.5987604],
});
mapLocality.push({
  id: 209,
  katoId: 50215,
  title: "ЖАНА ТИЛЕКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.1248198, 81.2671053],
});
mapLocality.push({
  id: 210,
  katoId: 50108,
  title: "ЖОГАРГЫ ЕГИНСУСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.0238385, 81.3938912],
});
mapLocality.push({
  id: 211,
  katoId: 49307,
  title: "КАБАНБАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.0772597, 82.0891172],
});

mapLocality.push({
  id: 144,
  katoId: 49512,
  title: "КАРАБУЛАКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.1427525, 82.1675253],
});
mapLocality.push({
  id: 198,
  katoId: 49477,
  title: "КАРАБУТИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.346667, 81.187778],
});
mapLocality.push({
  id: 199,
  katoId: 49587,
  title: "КАРАКОЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.433333, 80.966667],
});
mapLocality.push({
  id: 200,
  katoId: 49653,
  title: "КАРАТАЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.061215, 81.359253],
});
mapLocality.push({
  id: 201,
  katoId: 49401,
  title: "КАРАТУМИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.103451, 81.5439864],
});

mapLocality.push({
  id: 202,
  katoId: 49192,
  title: "КЕЛДИМУРАТСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.0331007, 82.148744],
});

mapLocality.push({
  id: 203,
  katoId: 49380,
  title: "КОКОЗЕКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.916667, 82.1],
});
mapLocality.push({
  id: 204,
  katoId: 49421,
  title: "КОКТАЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.4572593, 81.851397],
});
mapLocality.push({
  id: 205,
  katoId: 49442,
  title: "КОКТЕРЕКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.6, 82.15],
});
mapLocality.push({
  id: 206,
  katoId: 48787,
  title: "КОЛДЕНЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [45.5925735, 82.1767079],
});
mapLocality.push({
  id: 207,
  katoId: 49727,
  title: "КОНЫРШАУЛИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.6998887, 82.5349097],
});
mapLocality.push({
  id: 208,
  katoId: 49787,
  title: "МАКАНЧИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [46.7903132, 82.0192496],
});
mapLocality.push({
  id: 209,
  katoId: 50042,
  title: "НАУАЛИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.0024236, 81.7513829],
});
mapLocality.push({
  id: 210,
  katoId: 50163,
  title: "САЛКЫНБЕЛЬСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.280278, 81.155556],
});
mapLocality.push({
  id: 211,
  katoId: 48803,
  title: "УРДЖАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.0997599, 81.6355739],
});
mapLocality.push({
  id: 211,
  katoId: 50193,
  title: "ШОЛПАНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [47.1728764, 81.0644765],
});

mapLocality.push({
  id: 144,
  katoId: 45664,
  title: "БЕЛЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.860558, 82.98333],
});
mapLocality.push({
  id: 198,
  katoId: 43819,
  title: "БАСТАУШИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.87751, 83.257103],
});
mapLocality.push({
  id: 199,
  katoId: 45076,
  title: "БИГАШСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.641109, 82.010002],
});
mapLocality.push({
  id: 200,
  katoId: 44431,
  title: "КОКЖАЙЫКСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.556389, 82.796944],
});
mapLocality.push({
  id: 201,
  katoId: 44301,
  title: "КУЛЫНЖОНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.9473569, 83.1634531],
});

mapLocality.push({
  id: 202,
  katoId: 43547,
  title: "КОКПЕКТИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.755928, 82.382706],
});

mapLocality.push({
  id: 203,
  katoId: 44600,
  title: "КОЙГЕЛЬДЫ АУХАДИЕВА",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.0588016, 83.5132108],
});
mapLocality.push({
  id: 204,
  katoId: 44665,
  title: "МАРИНОГОРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.9456201, 83.0389388780637],
});
mapLocality.push({
  id: 205,
  katoId: 44733,
  title: "МИРОЛЮБОВСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.026569, 83.564377],
});
mapLocality.push({
  id: 206,
  katoId: 44933,
  title: "ПАЛАТЦЫНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.1398796, 83.7240973],
});
mapLocality.push({
  id: 207,
  katoId: 45094,
  title: "САМАРСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.0588016, 83.5132108],
});
mapLocality.push({
  id: 208,
  katoId: 44635,
  title: "САРЫБЕЛСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [49.10815405, 84.0424207131938],
});
mapLocality.push({
  id: 209,
  katoId: 45426,
  title: "ТАССАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.4018859, 82.8358593],
});
mapLocality.push({
  id: 210,
  katoId: 45481,
  title: "ТЕРЕКТИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.7932967, 82.7670076],
});
mapLocality.push({
  id: 211,
  katoId: 45533,
  title: "УЛЬГУЛИМАЛШИНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.5809879, 83.0987934],
});
mapLocality.push({
  id: 211,
  katoId: 43997,
  title: "УЛКЕН БОКЕНСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.7811984, 82.6962859],
});
mapLocality.push({
  id: 211,
  katoId: 45754,
  title: "ШУГЫЛБАЙСКИЙ",
  isCity: false,
  isDistrictCenter: false,
  type: MapObjectType.RuralDistrictInfo,
  geometry: [48.6, 82.683333],
});

export const regionPolygons = mapRegions.map((el) => ({
  title: el.title,
  coords: [el.geometry.map((coord) => [coord[1], coord[0]])],
}));
