import { localStore, localStoreKeys } from "./storage";

export function getAuthority() {
  const authority = localStore.get(localStoreKeys.Authority);

  if (typeof authority === "string") {
    return [authority];
  }

  return authority || ["GUEST"];
}

export function setAuthority(authority) {
  const userAuthority = typeof authority === "string" ? [authority] : authority;
  localStore.set(localStoreKeys.Authority, userAuthority);
}

export function resetAuthority() {
  localStore.set(localStoreKeys.RouteList, []);
  localStore.set(localStoreKeys.RoleIds, null);
  localStore.set(localStoreKeys.UserId, null);
}
