import React from "react";

export default function () {
  return (
    <div className="row-container">
      <iframe
        title="Business map"
        src="https://bicloud.smartkokshetau.kz/public/dashboards/12"
        className="second-row"
      ></iframe>
    </div>
  );
}
