import React, { Component } from "react";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

const COLORS = [
  "#FFAA00",
  "#FAAD14",
  "#2F3243",
  "#7CB305",
  "#4B9A25",
  "#E6F7FF",
  "#1890FF",
  "#0050B3",
  "#A05ACC",
  "#F759AB",
  "#FF99CC",
  "#A61E25",
];

export default class BarChart extends Component {
  componentDidMount() {
    am4core.options.commercialLicense = true;
    const {
      id,
      data,
      category,
      categoryText,
      values,
      valueText,
      dateAxis,
      inputDateFormat,
      dateFormat,
      stacked,
      colorArr,
    } = this.props;

    // Create chart instance
    const chart = am4core.create(id, am4charts.XYChart);
    chart.language.locale = am4lang_ru_RU;

    // Add data
    chart.data = data;

    // legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.fontSize = 11;
    chart.legend.position = "bottom";
    chart.legend.labels.template.text = "{name}[/]";
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 11;
    markerTemplate.height = 11;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    // colors
    const colors = values[0].color
      ? values.map((value) => value.color)
      : colorArr;
    chart.colors.list = colors.map((color) => am4core.color(color));

    // Create axes
    if (!dateAxis) {
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = category;
      if (categoryText) categoryAxis.title.text = categoryText;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.fontSize = 12;
      categoryAxis.renderer.labels.template.horizontalCenter = "left";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.rotation = 60;
      categoryAxis.renderer.grid.template.disabled = true;

      if (values.length > 1 && !stacked) {
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.8;
      }
    } else {
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      if (categoryText) dateAxis.title.text = categoryText;
      dateAxis.renderer.minGridDistance = 10;
      dateAxis.renderer.labels.template.fontSize = 12;
      // dateAxis.parseDates = false;

      // Set input format for the dates
      chart.dateFormatter.inputDateFormat = inputDateFormat;
      // chart.dataDateFormat = "YYYY-MM-DDTJJ:NN:SS";
      // chart.dateFormatter.dateFormat = "dd";
      // dateAxis.dateFormatter = new am4core.DateFormatter();
      // dateAxis.dateFormatter.dateFormat = "dd";
      dateAxis.dateFormats.setKey("day", dateFormat);
      dateAxis.periodChangeDateFormats.setKey("day", dateFormat);

      if (values.length > 1 && !stacked) {
        dateAxis.renderer.cellStartLocation = 0.1;
        dateAxis.renderer.cellEndLocation = 0.9;
      }
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueText) valueAxis.title.text = valueText;
    valueAxis.renderer.labels.template.fontSize = 12;

    // Create series
    values.forEach((value, i) => {
      const { key, title } = value;
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = key;
      series.dataFields[dateAxis ? "dateX" : "categoryX"] = category;
      series.name = title;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      if (values.length > 1 && !stacked) {
        series.columns.template.width = am4core.percent(100);
      } else {
        series.columns.template.width = am4core.percent(94);
      }
      series.stacked = stacked;

      // colors
      // series.columns.template.stroke = am4core.color(
      //   value.color ? value.color : COLORS[i]
      // );
      // series.columns.template.fill = am4core.color(
      //   value.color ? value.color : COLORS[i]
      // );
    });

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { id, height } = this.props;
    return <div id={id} style={{ width: "100%", height }} />;
  }
}

BarChart.defaultProps = {
  id: "bar-chart",
  category: "category",
  dateAxis: false,
  inputDateFormat: "yyyy-MM-ddT:HH:mm:ss.000",
  dateFormat: "dd MMM",
  stacked: false,
  height: "460px",
  colorArr: COLORS,
};

BarChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  categoryText: PropTypes.string,
  values: PropTypes.array.isRequired,
  valueText: PropTypes.string,
  dateAxis: PropTypes.bool,
  inputDateFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  stacked: PropTypes.bool,
  height: PropTypes.string,
  colorArr: PropTypes.array,
};
