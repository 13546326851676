import i18n from "../i18n";

const Localize = (value) => {
  const { language } = i18n;
  const t = i18n.t.bind(i18n);
  return value?.map((data) => {
    if (language === "ru") return data;
    else if (language === "kz") {
      let word = "";
      switch (data.category) {
        case "Кража":
          word = t("mainPage.theft");
          break;
        case "Грабеж":
          word = t("mainPage.robbery");
          break;
        case "Хулиганство":
          word = t("mainPage.hooliganism");
          break;
        case "Мошенничество":
          word = t("mainPage.fraud");
          break;
        case "Доведение до самоубийства":
          word = t("mainPage.inducement_to_suicide");
          break;
        case "Умышленное причинение средней тяжести вреда здоровью":
          word = t("mainPage.intentional_injury");
          break;
        case "Незаконное обращение с наркотическими средствами, психотропными веществами, их аналогами, прекурсора":
          word = t("mainPage.illegal_drug_handling");
          break;
        case `ГКП на ПХВ "Ерейментауская РБ" при УЗ Акмолинской области`:
          word = t("mainPage.GKPErementau");
          break;
        case `ГКП на ПХВ "Буландынская РБ" при УЗ Акмолинской области`:
          word = t("mainPage.GKPBuldanskaya");
          break;
        case `ГКП на ПХВ "Атбасарская ММБ" при УЗ Акмолинской области`:
          word = t("mainPage.GKPAtbasarskaya");
          break;
        case `Зерендинская ЦРБ`:
          word = t("mainPage.zerendinskayaCRB");
          break;
        default:
          break;
      }

      return {
        ...data,
        category: word || data.category,
      };
    }
  });
};

export default Localize;
