import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Col, Modal, Row, Spin, Table, Tooltip } from "antd";
import moment from "moment";

import { categoryFields, fields } from "./utils";

import ExportExcel from "../../../components/ExportExcelNew";
import Loader from "../../../components/Loader";
import { ChartCard } from "../../../components/ChartCard/ChartCard";
import { DatePickerR } from "../../../components/DatePickerR";
import { FilterItem } from "../../../components/FilterItemNew/FilterItem";
import { FilterWrapper } from "../../../components/FilterWrapper/FilterWrapper";
import { MainWrapper } from "../../../components/MainWrapper/MainWrapper";
import { SelectList } from "../../../components/SelectList/SelectList";
import cubejs from "../../../cube";

const cubejsApi = cubejs({ appId: 1 });

export default class AppealsMonitoring extends Component {
  state = {
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    records: [],
    visible: false,
    title: null,
    titleCount: null,
    modalLoading: false,
    modalFilter: [],
    currentPage: 1,
    pageSize: 10,
    regions: [],
    districts: [],
    selectedRegions: [],
    selectedDistricts: [],
    disabledRegion: false,
    disabledDistrict: false,
  };

  handleRange = (val) => {
    this.setState({ range: val }, this.getData);
  };

  calculateReplyDays = (replyDate, plannedDate, expired) => {
    if (expired === "Просрочено") {
      if (replyDate === null) {
        return moment().diff(moment(plannedDate), "days");
      } else {
        return moment(replyDate).diff(moment(plannedDate), "days");
      }
    } else if (expired === "Не просрочено") {
      return 0;
    }
  };

  hideModal = () => {
    this.setState({ visible: false });
  };

  renderRowColor(text, record) {
    return {
      props: {
        style: { color: record.color },
      },
      children: <div>{text}</div>,
    };
  }

  renderRowColorAndWordBreak(text, record, maxWidth) {
    return {
      props: {
        style: { color: record.color },
      },
      children: (
        <Tooltip title={text}>
          <div
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: maxWidth,
              wordWrap: "break-word",
              maxHeight: "1.8em",
              lineHeight: "1.8em",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    };
  }

  handleColumn = (column, record, filters) => {
    this.setState({ modalLoading: true });
    let modalFilter = [];
    let segments = [];
    if (column === "На исполнении") {
      modalFilter = [
        {
          dimension: "Appeals.status",
          operator: "equals",
          values: ["Зарегистрировано"],
        },
      ];
    } else if (column === "Завершено") {
      modalFilter = [
        {
          dimension: "Appeals.status",
          operator: "equals",
          values: ["Завершено"],
        },
      ];
    } else if (column === "Общее количество обращений") {
      modalFilter = [];
    } else if (column === "Исполнено с нарушением срока") {
      segments = ["Appeals.outOfTime"];
    } else if (column === "Просрочено") {
      segments = ["Appeals.expiredForOperator"];
    }
    const { range } = this.state;
    cubejsApi
      .load({
        dimensions: [
          "Appeals.appealNum",
          "Appeals.source",
          "Appeals.appealType",
          "Appeals.category",
          "Appeals.subCategory",
          "Appeals.appealText",
          "Appeals.submissionDate",
          "Appeals.replyDate",
          "Appeals.plannedDate",
          "Appeals.expired",
          "Appeals.theStatus",
          "Appeals.fio",
          "Appeals.phoneNumber",
          "Appeals.answer",
          "Appeals.region",
          "Appeals.district",
        ],
        filters: [
          {
            dimension: "Appeals.category",
            operator:
              record["Appeals.category"] === "ИТОГО" ? "notEquals" : "equals",
            values: [record["Appeals.category"]],
          },
          {
            member: "Appeals.submissionDate",
            operator: "inDateRange",
            values: range,
          },
          ...modalFilter,
          ...filters,
        ],
        segments,
        renewQuery: true,
      })
      .then((r) => {
        let records = r.rawData().map((element, index) => ({
          ...element,
          key: index + 1,
          "Appeals.replyDays": this.calculateReplyDays(
            element["Appeals.replyDate"],
            element["Appeals.plannedDate"],
            element["Appeals.expired"]
          ),
          "Appeals.submissionDate":
            element["Appeals.submissionDate"] !== null
              ? moment(element["Appeals.submissionDate"]).format("YYYY-MM-DD")
              : "",
          "Appeals.submissionTime":
            element["Appeals.submissionDate"] !== null
              ? moment(element["Appeals.submissionDate"]).format("HH:mm")
              : "",
          "Appeals.replyDate":
            element["Appeals.replyDate"] !== null
              ? moment(element["Appeals.replyDate"]).format("YYYY-MM-DD")
              : "",
          "Appeals.plannedDate":
            element["Appeals.plannedDate"] !== null
              ? moment(element["Appeals.plannedDate"]).format("YYYY-MM-DD")
              : "",
          color: element["Appeals.expired"] === "Просрочено" ? "#ff4a4a" : "",
        }));
        this.setState({
          modalLoading: false,
          records,
          visible: true,
          title: record["Appeals.category"],
          titleCount: records.length,
        });
      });
  };

  async componentDidMount() {
    this.loadDictionaries();
    this.checkForRegion();
  }

  checkForRegion = () => {
    let role = JSON.parse(localStorage.getItem("user_params"));
    if (role?.roles?.[0]?.region?.length) {
      const selectedRegions = role.roles[0].region
        .filter((region) => !region?.parentId)
        .map((region) => region.regionName);

      /*
       * Эта часть кода была создана специально
       * для учетных записей user-krg-bkh и
       * user-krg-kzb. Данный кейс является
       * уникальным, поэтому логика
       * прописывалась исключительно под них.
       */

      let krgDistrict = [];

      if (role.userId === 44) {
        krgDistrict = [role.roles[0].region[0].child[0].regionName];
      } else if (role.userId === 45) {
        krgDistrict = [role.roles[0].region[0].child[1].regionName];
      }

      this.setState({
        selectedRegions,
        selectedDistricts: krgDistrict,
        disabledRegion: true,
        disabledDistrict: krgDistrict.length || false,
      });
    }
  };

  applyFilters = (filter, values) => {
    const { regions, districts, selectedRegions } = this.state;
    if (filter === "selectedRegions" && !values.length) {
      this.setState({
        selectedDistricts: [],
      });
    }
    if (filter === "selectedDistricts" && selectedRegions.length === 0) {
      const districtObject = districts.find(
        (district) => district["AppealsRegion.nameRu"] === values[0]
      );
      const regionObject = regions.find(
        (region) =>
          region["AppealsRegion.id"] ===
          districtObject["AppealsRegion.parentId"]
      );
      this.setState({
        selectedRegions: [regionObject["AppealsRegion.nameRu"]],
      });
    }
    this.setState({
      [filter]: values,
    });
  };

  loadDictionaries = async () => {
    const regions = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "notSet",
        },
      ],
    });

    const districts = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "set",
        },
      ],
    });
    this.setState({
      regions: regions.rawData(),
      districts: districts.rawData(),
    });
  };

  // Pagination
  onChange = (page) => this.setState({ ...this.state, currentPage: page });

  onShowSizeChange = (current, pageSize) =>
    this.setState({ ...this.state, pageSize, currentPage: 1 });

  sortHandler = (a, b, key, compare = false) => {
    if (a["Appeals.category"] !== "ИТОГО") {
      if (compare) return a[key].localeCompare(b[key]);
      else return a[key] - b[key];
    } else return false;
  };

  filteredDistricts = () => {
    const { districts, regions, selectedRegions } = this.state;
    return districts
      .filter((district) => {
        if (!selectedRegions.length) {
          return true;
        }
        const ids = [];
        selectedRegions.forEach((region) => {
          const regionObj = regions.find(
            (it) => region === it["AppealsRegion.nameRu"]
          );
          ids.push(regionObj?.["AppealsRegion.id"]);
        });
        return ids.includes(district["AppealsRegion.parentId"]);
      })
      .map((it) => it["AppealsRegion.nameRu"]);
  };

  render() {
    const {
      range,
      visible,
      records,
      title,
      titleCount,
      selectedRegions,
      selectedDistricts,
      regions,
      districts,
      disabledRegion,
      disabledDistrict,
    } = this.state;
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
      {
        member: "Appeals.region",
        operator: "equals",
        values: selectedRegions.length
          ? selectedRegions
          : regions.map((it) => it["AppealsRegion.nameRu"]),
      },
      {
        member: "Appeals.district",
        operator: "equals",
        values: selectedDistricts.length
          ? selectedDistricts
          : districts.map((it) => it["AppealsRegion.nameRu"]),
      },
    ];
    return (
      <MainWrapper>
        {this.state.modalLoading && (
          <div className="loading-wrap">
            <Loader />
          </div>
        )}
        <FilterWrapper>
          <FilterItem>
            <SelectList
              label="Регион:"
              mode="multiple"
              value={selectedRegions}
              disabled={disabledRegion}
              onChange={(value) => this.applyFilters("selectedRegions", value)}
              defaultValue={[]}
              size="small"
              allowClear
              list={regions.map((it) => it["AppealsRegion.nameRu"])}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label="Населенный пункт:"
              mode="multiple"
              value={selectedDistricts}
              onChange={(value) =>
                this.applyFilters("selectedDistricts", value)
              }
              defaultValue={[]}
              disabled={disabledDistrict}
              size="small"
              allowClear
              list={this.filteredDistricts()}
            />
          </FilterItem>

          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              showTime={{
                secondStep: 60,
                minuteStep: 15,
              }}
              allowClear={false}
              value={this.state.range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <QueryRenderer
              query={{
                dimensions: ["Appeals.category"],
                measures: [
                  "Appeals.appealsNum",
                  "Appeals.finishedAppeals",
                  "Appeals.expiredAppeals",
                  "Appeals.registredAppeals",
                  "Appeals.efficiencyCoef",
                  "Appeals.expiredForOperatorAppeals",
                  "Appeals.outOfTimeForOperator",
                ],
                filters,
                renewQuery: true,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Spin />;
                let data = resultSet.rawData();
                data = data.map((el, id) => {
                  return { ...el, key: ++id };
                });
                data.push({
                  "Appeals.appealsNum": data.reduce(
                    (a, b) => a + +b["Appeals.appealsNum"],
                    0
                  ),
                  "Appeals.category": "ИТОГО",
                  "Appeals.efficiencyCoef": parseInt(
                    data.reduce((a, b) => a + +b["Appeals.efficiencyCoef"], 0) /
                      data.length
                  ),
                  "Appeals.expiredAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.expiredAppeals"],
                    0
                  ),
                  "Appeals.expiredForOperatorAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.expiredForOperatorAppeals"],
                    0
                  ),
                  "Appeals.finishedAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.finishedAppeals"],
                    0
                  ),
                  "Appeals.outOfTimeForOperator": data.reduce(
                    (a, b) => a + +b["Appeals.outOfTimeForOperator"],
                    0
                  ),
                  "Appeals.registredAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.registredAppeals"],
                    0
                  ),
                });
                return (
                  <ExportExcel
                    filename={`Мониторинг исполнения (${
                      selectedRegions.length !== 0 ? selectedRegions : ""
                    }, ${
                      selectedDistricts.length !== 0 ? selectedDistricts : ""
                    }) за ${moment(range[0]).format(
                      "DD.MM.YY HH:mm:ss"
                    )} – ${moment(range[1]).format("DD.MM.YY HH:mm:ss")}`}
                    data={data}
                    fields={fields}
                  />
                );
              }}
            />
          </FilterItem>
        </FilterWrapper>
        <QueryRenderer
          query={{
            dimensions: ["Appeals.category"],
            measures: [
              "Appeals.appealsNum",
              "Appeals.finishedAppeals",
              "Appeals.expiredAppeals",
              "Appeals.registredAppeals",
              "Appeals.efficiencyCoef",
              "Appeals.expiredForOperatorAppeals",
              "Appeals.outOfTimeForOperator",
            ],
            filters,
            renewQuery: true,
          }}
          cubejsApi={cubejsApi}
          render={({ resultSet }) => {
            if (!resultSet) return <Spin />;
            let data = resultSet.rawData();
            data = data.map((el, id) => {
              return { ...el, key: ++id };
            });
            data.push({
              "Appeals.appealsNum": data.reduce(
                (a, b) => a + +b["Appeals.appealsNum"],
                0
              ),
              "Appeals.category": "ИТОГО",
              "Appeals.efficiencyCoef": parseInt(
                data.reduce((a, b) => a + +b["Appeals.efficiencyCoef"], 0) /
                  data.length
              ),
              "Appeals.expiredAppeals": data.reduce(
                (a, b) => a + +b["Appeals.expiredAppeals"],
                0
              ),
              "Appeals.expiredForOperatorAppeals": data.reduce(
                (a, b) => a + +b["Appeals.expiredForOperatorAppeals"],
                0
              ),
              "Appeals.finishedAppeals": data.reduce(
                (a, b) => a + +b["Appeals.finishedAppeals"],
                0
              ),
              "Appeals.outOfTimeForOperator": data.reduce(
                (a, b) => a + +b["Appeals.outOfTimeForOperator"],
                0
              ),
              "Appeals.registredAppeals": data.reduce(
                (a, b) => a + +b["Appeals.registredAppeals"],
                0
              ),
            });
            return (
              <div>
                <Row type="flex" justify="space-between">
                  <Col>
                    <h3>
                      Общее количество обращений :{" "}
                      {resultSet
                        .rawData()
                        .reduce((a, b) => a + +b["Appeals.appealsNum"], 0)}
                    </h3>
                  </Col>
                </Row>
                <ChartCard title="Таблица">
                  <Table
                    className="dark_table"
                    scroll={{ y: "70vh" }}
                    loading={!resultSet}
                    dataSource={data.filter((el) => el["Appeals.category"])}
                    pagination={{
                      defaultPageSize: 100,
                      pageSizeOptions: ["10", "20", "50", "100"],
                      showSizeChanger: true,
                      locale: { items_per_page: "" },
                    }}
                    size="small"
                  >
                    <Table.Column
                      title="№"
                      dataIndex="key"
                      align="center"
                      width={40}
                      sorter={(a, b) => this.sortHandler(a, b, "key")}
                      textWrap="word-break"
                    />
                    <Table.Column
                      title="Категория"
                      dataIndex="Appeals.category"
                      align="left"
                      width={500}
                      sorter={(a, b) =>
                        this.sortHandler(a, b, "Appeals.category", true)
                      }
                      textWrap="word-break"
                    />
                    <Table.Column
                      title="Общее количество обращений"
                      dataIndex="Appeals.appealsNum"
                      align="center"
                      sorter={(a, b) =>
                        this.sortHandler(a, b, "Appeals.appealsNum")
                      }
                      render={(text, record, index) => {
                        return {
                          children: (
                            <div
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                color: "#1890ff",
                              }}
                              onClick={() =>
                                this.handleColumn(
                                  "Общее количество обращений",
                                  record,
                                  filters
                                )
                              }
                              className={index}
                            >
                              {text}
                            </div>
                          ),
                        };
                      }}
                    />
                    <Table.Column
                      title="На исполнении"
                      align="center"
                      dataIndex="Appeals.registredAppeals"
                      sorter={(a, b) =>
                        this.sortHandler(a, b, "Appeals.registredAppeals")
                      }
                      render={(text, record, index) => {
                        return {
                          children: (
                            <div
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                color: "#1890ff",
                              }}
                              onClick={() =>
                                this.handleColumn(
                                  "На исполнении",
                                  record,
                                  filters
                                )
                              }
                              className={index}
                            >
                              {text}
                            </div>
                          ),
                        };
                      }}
                    />
                    <Table.Column
                      title="Просрочено"
                      align="center"
                      dataIndex="Appeals.expiredForOperatorAppeals"
                      sorter={(a, b) =>
                        this.sortHandler(
                          a,
                          b,
                          "Appeals.expiredForOperatorAppeals"
                        )
                      }
                      render={(text, record, index) => {
                        return {
                          children: (
                            <div
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                color: "#1890ff",
                              }}
                              onClick={() =>
                                this.handleColumn("Просрочено", record, filters)
                              }
                              className={index}
                            >
                              {text}
                            </div>
                          ),
                        };
                      }}
                    />
                    <Table.Column
                      align="center"
                      title="Завершено"
                      dataIndex="Appeals.finishedAppeals"
                      sorter={(a, b) =>
                        this.sortHandler(a, b, "Appeals.finishedAppeals")
                      }
                      render={(text, record, index) => {
                        return {
                          children: (
                            <div
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                color: "#1890ff",
                              }}
                              onClick={() =>
                                this.handleColumn("Завершено", record, filters)
                              }
                              className={index}
                            >
                              {text}
                            </div>
                          ),
                        };
                      }}
                    />
                    <Table.Column
                      title="Исполнено с нарушением срока"
                      align="center"
                      dataIndex="Appeals.outOfTimeForOperator"
                      sorter={(a, b) =>
                        this.sortHandler(a, b, "Appeals.outOfTimeForOperator")
                      }
                      render={(text, record, index) => {
                        return {
                          children: (
                            <div
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                color: "#1890ff",
                              }}
                              onClick={() =>
                                this.handleColumn(
                                  "Исполнено с нарушением срока",
                                  record,
                                  filters
                                )
                              }
                              className={index}
                            >
                              {text}
                            </div>
                          ),
                        };
                      }}
                    />
                    <Table.Column
                      title="Коэффициент эффективности %"
                      align="center"
                      dataIndex="Appeals.efficiencyCoef"
                      sorter={(a, b) =>
                        this.sortHandler(a, b, "Appeals.efficiencyCoef")
                      }
                    />
                  </Table>
                </ChartCard>
              </div>
            );
          }}
        />
        <Modal
          title={
            <Row
              style={{ paddingRight: 20 }}
              type="flex"
              justify="space-between"
            >
              <div>
                <h5>{`Количество обращений по категории ${title}: ${titleCount} (с ${moment(
                  range[0]
                ).format("DD/MM/YY")} по ${moment(range[1]).format(
                  "DD/MM/YY"
                )})`}</h5>
              </div>
              <ExportExcel
                filename={`Количество обращений по категории ${title}: ${titleCount} (с ${moment(
                  range[0]
                ).format("DD/MM/YY")} по ${moment(range[1]).format(
                  "DD/MM/YY"
                )})`}
                data={records}
                fields={categoryFields}
              />
            </Row>
          }
          visible={visible}
          onCancel={this.hideModal}
          width="96%"
          footer={null}
          bodyStyle={{
            paddingTop: 0,
          }}
          centered
        >
          <Table
            columns={[
              {
                title: "№",
                dataIndex: "key",
                key: "key",
                width: 50,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a.key - b.key;
                },
              },
              {
                title: "Номер в системе",
                dataIndex: "Appeals.appealNum",
                key: "appealNum",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.appealNum"].localeCompare(
                    b["Appeals.appealNum"]
                  );
                },
              },
              {
                title: "Источник",
                dataIndex: "Appeals.source",
                key: "source",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.source"].localeCompare(b["Appeals.source"]);
                },
              },
              {
                title: "Регион",
                dataIndex: "Appeals.region",
                key: "region",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.region"].localeCompare(b["Appeals.region"]);
                },
              },
              {
                title: "Населенный пункт",
                dataIndex: "Appeals.district",
                key: "district",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.district"].localeCompare(
                    b["Appeals.district"]
                  );
                },
              },
              {
                title: "Тип обращения",
                dataIndex: "Appeals.appealType",
                key: "appealType",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.appealType"].localeCompare(
                    b["Appeals.appealType"]
                  );
                },
              },
              {
                title: "Категория",
                dataIndex: "Appeals.category",
                key: "category",
                width: 150,
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "15ch"),
                sorter: (a, b) => {
                  return a["Appeals.category"].localeCompare(
                    b["Appeals.category"]
                  );
                },
              },
              {
                title: "Подкатегория обращения",
                dataIndex: "Appeals.subCategory",
                key: "subCategory",
                width: 150,
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "15ch"),
                sorter: (a, b) => {
                  return a["Appeals.subCategory"].localeCompare(
                    b["Appeals.subCategory"]
                  );
                },
              },
              {
                title: "ФИО",
                dataIndex: "Appeals.fio",
                key: "theStatus",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.fio"].localeCompare(b["Appeals.fio"]);
                },
              },
              {
                title: "Телефон",
                dataIndex: "Appeals.phoneNumber",
                key: "phoneNumber",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.phoneNumber"].localeCompare(
                    b["Appeals.phoneNumber"]
                  );
                },
              },
              {
                title: "Текст обращения",
                dataIndex: "Appeals.appealText",
                key: "appealText",
                width: 300,
                textWrap: "word-break",
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "35ch"),
                sorter: (a, b) => {
                  return a["Appeals.appealText"].localeCompare(
                    b["Appeals.appealText"]
                  );
                },
              },
              {
                title: "Дата подачи",
                dataIndex: "Appeals.submissionDate",
                key: "submissionDate",
                width: 175,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return (
                    new Date(a["Appeals.submissionDate"]) -
                    new Date(b["Appeals.submissionDate"])
                  );
                },
              },
              {
                title: "Время подачи",
                dataIndex: "Appeals.submissionTime",
                key: "submissionTime",
                width: 175,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.submissionTime"].localeCompare(
                    b["Appeals.submissionTime"]
                  );
                },
              },
              {
                title: "Планируемая дата закрытия",
                dataIndex: "Appeals.plannedDate",
                key: "plannedDate",
                width: 175,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return (
                    new Date(a["Appeals.plannedDate"]) -
                    new Date(b["Appeals.plannedDate"])
                  );
                },
              },
              {
                title: "Дата закрытия (фактическая)",
                dataIndex: "Appeals.replyDate",
                key: "replyDate",
                width: 175,
                render: (d) =>
                  d ? moment(d).format("DD.MM.YYYY") : "В обработке",

                sorter: (a, b) => {
                  return (
                    new Date(a["Appeals.replyDate"]) -
                    new Date(b["Appeals.replyDate"])
                  );
                },
              },
              {
                title: "Ответ по обращению",
                dataIndex: "Appeals.answer",
                key: "answer",
                width: 600,
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "70ch"),
                sorter: (a, b) => {
                  return a["Appeals.answer"].localeCompare(b["Appeals.answer"]);
                },
              },

              {
                title: "Статус обращения",
                dataIndex: "Appeals.theStatus",
                key: "theStatus",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.theStatus"].localeCompare(
                    b["Appeals.theStatus"]
                  );
                },
              },
              {
                title: "Количество дней просрочки",
                dataIndex: "Appeals.replyDays",
                key: "replyDays",
                width: 100,
                sorter: (a, b) =>
                  a["Appeals.replyDays"] - b["Appeals.replyDays"],
                render: this.renderRowColor,
              },
            ]}
            size="small"
            scroll={{ y: "60vh", x: "max-content" }}
            pagination={{
              defaultPageSize: 100,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            dataSource={records}
          />
        </Modal>
      </MainWrapper>
    );
  }
}
