import { createAction } from "redux-actions";

import { getCrimeByDate, searchAppeals } from "../../services/operational";

import {
  // getCrimeFilters,
  getAppealsStartDate,
  getAppealsEndDate,
  getAppealFilters,
} from "./selectors";

export const SET_LOADING_CRIME = "scvko/operational/SET_LOADING_CRIME";
export const setLoadingCrime = createAction(SET_LOADING_CRIME);

export const SET_DATE_CRIME = "scvko/operational/SET_DATE_CRIME";
export const setDateCrime = createAction(SET_DATE_CRIME);

export const FETCH_CRIME = "scvko/operational/FETCH_CRIME";
export const fetchCrimeAll = (startDate, endDate) => async (
  dispatch,
  getState
) => {
  dispatch(setLoadingCrime(true));
  const crime = await getCrimeByDate(startDate, endDate);
  dispatch(saveCrime(crime));
  dispatch(setLoadingCrime(false));
};

export const SAVE_CRIME = "scvko/operational/SAVE_CRIME";
export const saveCrime = createAction(SAVE_CRIME);

export const SET_CRIME_FILTER = "scvko/operational/SET_CRIME_FILTER";
export const setCrimeFilter = createAction(SET_CRIME_FILTER);

export const SET_SELECTED_CATEGORY = "scvko/operational/SET_SELECTED_CATEGORY";
export const setSelectedCategory = createAction(SET_SELECTED_CATEGORY);

export const CLEAR_CRIME_FILTER = "scvko/operational/CLEAR_CRIME_FILTER";
export const clearCrimeFilter = createAction(CLEAR_CRIME_FILTER);

export const SELECT_CRIME = "scvko/operational/SELECT_CRIME";
export const selectCrime = createAction(SELECT_CRIME);

export const SET_FEATURED_CATEGORY = "scvko/operational/SET_FEATURED_CATEGORY";
export const setFeaturedCategory = createAction(SET_FEATURED_CATEGORY);

export const SET_LOADING_APPEALS =
  "scvko/operational/crime/SET_LOADING_APPEALS";
export const setLoadingAppeals = createAction(SET_LOADING_APPEALS);

export const SET_DATE_APPEALS = "scvko/operational/SET_DATE_APPEALS";
export const setDateAppeals = createAction(SET_DATE_APPEALS);

export const FETCH_APPEALS = "scvko/operational/FETCH_APPEALS";
export const fetchAppeals = () => async (dispatch, getState) => {
  const startDate = getAppealsStartDate(getState());
  const endDate = getAppealsEndDate(getState());
  const filters = getAppealFilters(getState());

  dispatch(setLoadingAppeals(true));
  const appeals = await searchAppeals({
    dateb: startDate,
    datee: endDate,
    ...filters,
  });
  dispatch(saveAppeals(appeals));
  dispatch(setLoadingAppeals(false));
};

export const SAVE_APPEALS = "scvko/operational/SAVE_APPEALS";
export const saveAppeals = createAction(SAVE_APPEALS);

export const SET_APPEALS_FILTER = "scvko/operational/SET_APPEALS_FILTER";
export const setAppealsFilter = createAction(SET_APPEALS_FILTER);

export const CLEAR_APPEALS_FILTER = "scvko/operational/CLEAR_APPEALS_FILTER";
export const clearAppealsFilter = createAction(CLEAR_APPEALS_FILTER);

export const SELECT_APPEAL = "scvko/operational/SELECT_APPEAL";
export const selectAppeal = createAction(SELECT_APPEAL);
