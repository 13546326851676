import request from "../utils/request";

export async function getCrimeAll() {
  return request("/crime/all");
}

export async function getCrimeByDate(startDate, endDate) {
  return request(`/crime/allbydate?dateb=${startDate}&datee=${endDate}`);
}

export async function searchAppeals(params) {
  return request("/appeal/all", {
    method: "POST",
    body: params,
  });
}
